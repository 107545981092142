import React, { useState } from "react";
import { useGoogleLogin } from "react-google-login";
import axios from "axios";

import { baseUrl } from "../../util/config";
import { clientId } from "../../util/constants/generalConstant";
/* const clientId =
  "749795274272-rbo6pubta1t12egbbbs96lj80mdssumj.apps.googleusercontent.com"; */

let publicUrl = process.env.PUBLIC_URL + "/";

function LoginHooks({ loginKey = "authToken", redirect = "/", closeDialog }) {
  const [isError, setIsError] = useState(false);
  const onSuccess = async (res) => {
    const newAuthRes = await res.reloadAuthResponse();
    if (newAuthRes.id_token) {
      const userDetails = res.profileObj;
      const payload = {
        user_id: userDetails.googleId || "",
        email: userDetails.email || "",
        name: userDetails.name || "",
      };
      axios
        .post(`${baseUrl}/plot_api/realestate/user/save`, payload)
        .then(
          (result) => {
            if (result.data && result.data.status) {
              localStorage.setItem(loginKey, newAuthRes.id_token);
              localStorage.setItem("user", JSON.stringify(res.profileObj));
              const lastEmail = localStorage.getItem("lastEmail") || null;
              if (lastEmail) {
                if (lastEmail !== userDetails.email) {
                  localStorage.removeItem("mIsVerified");
                  localStorage.removeItem("mUserId");
                }
              }
              localStorage.setItem("lastEmail", userDetails.email);
              closeDialog();
              window.location.reload();
            } else {
              setIsError(true);
            }
          },
          (err) => {
            setIsError(false);
            console.log("err", err);
          }
        );
    }
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: "offline",
  });

  return (
    <>
      <div className="googleContentWrapper" onClick={() => signIn()}>
        <div className="googleButtonIcon">
          <div className="googleButtonIconImage">
            <img
              src={publicUrl + "assets/img/google.svg"}
              alt="google login"
              className="icon googleButtonIconImage"
            ></img>
          </div>
        </div>
        <span className="googleButtonContents">
          <span>Sign in with Google</span>
        </span>
      </div>
      {isError && (
        <div>
          <span className="">Something went wrong, retry again.</span>
        </div>
      )}
    </>
  );
}

export default LoginHooks;
