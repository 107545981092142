import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

//Custom imports
import * as CSS from "./style";
import PromoterProfileStepOne from "./ProfileSteps/StepOne";
import PromoterProfileStepTwo from "./ProfileSteps/StepTwo";
import PromoterProfileStepThree from "./ProfileSteps/StepThree";
import PromoterProfileStepFour from "./ProfileSteps/StepFour";
import PromoterProfileStepFive from "./ProfileSteps/StepFive";
import PromoterProfileStepSix from "./ProfileSteps/StepSix";
import PromoterHeader from "../HeaderMenu/PromoterHeader";

//Other lib imports
import axios from "axios";
import { baseUrl } from "../../util/config";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#835400" : "#308fe8",
  },
}));

function PromoterProfile() {
  const navigate = useNavigate();
  const [profileStep, setProfileStep] = useState(1);

  const [userDetails, setUserDetails] = useState(null);

  const [data, setData] = useState({
    user_id: "",
    general_information: {
      door_no: "",
      street: "",
      address1: "",
      address2: "",
      town: "",
      city: "",
      taluk: "",
      district: "",
      state: "",
      pincode: "",
      country: "",
      landmark: "",
      no_of_projects: "",
      about_company: "",
    },
    board_of_directors: [],
    team: [],
    feedback: [],
    gallery: [],
  });

  useEffect(() => {
    const promotor_user = localStorage.getItem("promotor_user") || null;
    if (promotor_user) {
      const userData = JSON.parse(promotor_user);
      setUserDetails(userData);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      getPromotorDetails(userDetails.id);
    }
  }, [userDetails]);

  const getPromotorDetails = (data) => {
    // setIsLoading(true);
    const payload = { company_id: data };
    axios
      .post(`${baseUrl}/cms_api/realestate/promoters/get`, payload)
      .then(
        (res) => {
          if (res.data && res.data.companies) {
            setData((res.data && res.data.companies) || {});
          }
        },
        (err) => {
          // setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  const stepRender = () => {
    switch (profileStep) {
      case 1:
        return <PromoterProfileStepOne nextStep={setProfileStep} />;
      case 2:
        return <PromoterProfileStepTwo nextStep={setProfileStep} />;
      case 3:
        return <PromoterProfileStepThree nextStep={setProfileStep} />;
      case 4:
        return <PromoterProfileStepFour nextStep={setProfileStep} />;
      case 5:
        return <PromoterProfileStepFive nextStep={setProfileStep} />;
      case 6:
        return <PromoterProfileStepSix nextStep={setProfileStep} />;
    }
  };

  return (
    <CSS.ParentDiv>
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
      >
        <PromoterHeader />
      </Grid>
      <CSS.GlobalProgressBar>
        <BorderLinearProgress
          variant="determinate"
          value={profileStep * 16.6}
        />
      </CSS.GlobalProgressBar>
      {stepRender()}
    </CSS.ParentDiv>
  );
}

export default PromoterProfile;
