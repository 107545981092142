import plp from "./plp/reducer";
import common from "./common/reducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  plp,
  common,
});

export default rootReducer;
