/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import PLPHeader from "../HeaderMenu/PLPHeader";
import axios from "axios";
import {
  TlStart,
  TlMiddle,
  TlEnd,
  TlContent,
} from "../PDP/TimeLineDivider/TimeLineDivider";
//Material UI imports
import Grid from "@mui/material/Grid";

import { baseUrl } from "../../util/config";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [userType, setUserType] = useState("N");

  useEffect(() => {
    const user = localStorage.getItem("user") || null;
    if (user) {
      const userData = JSON.parse(user);
      setUserDetails(userData);
      getCompanyDetails(userData.googleId);
    }
  }, []);

  const getCompanyDetails = (user_id = "") => {
    setIsLoading(true);
    const payload = {
      user_id,
    };
    axios
      .post(`${baseUrl}/plot_api/realestate/company/get`, payload)
      .then(
        (res) => {
          setIsLoading(false);
          if (res.data && res.data.status) {
            if (res.data.isApproved) {
              setUserType(res.data.userType);
            }
            setCompanyDetails(res.data.company || null);
            if (res.data && !res.data.isMobileVerified) {
              // window.location.href = "/companymobileverify";
            }
          }
        },
        (err) => {
          setCompanyDetails(null);
          setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid
        item
        lg={10}
        md={10}
        sm={11}
        xs={11}
        style={{ textAlign: "center" }}
      >
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="2em 0 2em 0;"
        >
          <PLPHeader />
        </Grid>
      </Grid>
      <Grid
        item
        lg={10}
        md={10}
        sm={11}
        xs={11}
        style={{ textAlign: "center" }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <span className="pdp-about-size">
            Profile Details
          </span>
        </Grid>
        <Grid
          container
          margin="1em 0 1em 0;"
          direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <TlStart />
              <TlContent
                title="Name"
                value={(companyDetails && companyDetails.name) || " - "}
              />
              <TlMiddle />
              <TlContent
                title="Email"
                value={(companyDetails && companyDetails.email) || " - "}
              />
              <TlMiddle />
              <TlContent
                title="Contact Number"
                value={(companyDetails && companyDetails.contact) || " - "}
              />
              <TlMiddle />
              <TlContent
                title="Registered On"
                value={(companyDetails && companyDetails.ts) || " - "}
              />
              <TlEnd />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Profile;
