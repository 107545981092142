import React from "react";
import LoginHooks from "./LoginHooks";

import './Login.css';

function GoogleLogin(props) {

  const {closeDialog} = props;

  return (
    <div className="googleContainer">
      <LoginHooks redirect="/" closeDialog={closeDialog} />
    </div>
  );
}

export default GoogleLogin;
