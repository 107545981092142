import { useState } from "react";
import { isMobile } from "react-device-detect";
//Material UI imports
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//Custom imports
import * as CSS from "../Style.js";
import helperImg from "../../../Assets/youtube_upload_help.png";

const PropertyTypeHelp = (props) => {
  const {
    openFlag,
    setCloseFlag,
  } = props;

  return (
    <CSS.CustomizedDialog
      open={openFlag}
      onClose={() => setCloseFlag(false)}
      // maxWidth={"md"}
    >
      <Grid
        container
        direction="column"
        gap="1em"
        justifyContent="center"
        alignItems="center"
      >
        <CSS.CloseBtn>
          <IconButton
            aria-label="closePopup"
            onClick={() => setCloseFlag(false)}
          >
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn>
        <CSS.TitleContainer>
          <span className="title">Help content heading</span>
        </CSS.TitleContainer>
        <CSS.youtubeHelpText>
          <p>
            {/* <ol type="1">
              <li>
                On a computer, Goto youtube.com and search for your video.
              </li>
              <li>Click on share button as highlighted below.</li>
              <li>Click on copy button as highlighted below.</li>
              <li>
                Paste the copied URL on the 'Youtube Video URL' text box
                below.
              </li>
              <li>Repeat above steps to add multiple videos.</li>
              P.S - Above steps will not work on mobile devices.
            </ol> */}
            Dummy placeholder
          </p>
        </CSS.youtubeHelpText>
        {/* <img width="100%" src={helperImg} /> */}
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default PropertyTypeHelp;
