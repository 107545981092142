import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import WestIcon from "@mui/icons-material/West";
import Avatar from "@mui/material/Avatar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//Custom imports
import * as CSS from "../style";
import { useSnackbar } from "../../../hooks/useSnackbar";
import Snackbar from "../../snackbar";
import XHR from "../../../util/pxhr";

const PromoterProfileStepThree = (props) => {
  const navigate = useNavigate();
  const { nextStep } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    user_id: "",
    general_information: {
      door_no: "",
      street: "",
      address1: "",
      address2: "",
      town: "",
      city: "",
      taluk: "",
      district: "",
      state: "",
      pincode: "",
      country: "",
      landmark: "",
      no_of_projects: "",
      about_company: "",
    },
    board_of_directors: [],
    team: [],
    feedback: [],
    gallery: [],
  });

  const [updateData, setUpdateData] = useState(null);

  const [openUpload, setOpenUpload] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [uploadObj, setUploadObj] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");

  const [promotionData, setPromotionData] = useState([]);

  const handleUploadClose = () => {
    setOpenUpload(false);
    setUploadType(null);
    setUploadObj(null);
    setUploadStatus(null);
    setUploading(false);
  };

  const onFileChange = (event) => {
    setUploadStatus(null);
    setUploading(false);
    setUploadObj(event.target.files[0]);
  };

  const handleConfirmUpload = () => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append("file", uploadObj);
    formData.append("type", uploadType === "image" ? "I" : "S");
    XHR.post("ads/upload", formData, config).then(
      (res) => {
        setUploading(false);
        setUploadedFileName(uploadObj.name);
        setUploadStatus(res.data || null);
      },
      (err) => {
        setUploading(false);
        console.log("err", err);
      }
    );
  };

  const handlePreview = () => {
    if (uploadStatus && uploadStatus.url) {
      window.open(uploadStatus.url, "_blank");
    }
  };

  const handleSave = () => {
    if (uploadType === "image" || uploadType === "youtube") {
      const dataCopy = JSON.parse(JSON.stringify(data));
      let assets = dataCopy.gallery || [];
      assets.push({ type: uploadType, url: uploadStatus.url });
      console.log("assets", assets);
      setData({
        ...data,
        gallery: assets,
      });
    } else if (
      uploadType === "boardPhoto" ||
      uploadType === "teamPhoto" ||
      uploadType === "feedbackPhoto"
    ) {
      setUpdateData({
        ...updateData,
        photo: uploadStatus.url,
      });
    } else if (uploadType === "logo") {
      setData({
        ...data,
        general_information: {
          ...data.general_information,
          logo: uploadStatus.url,
        },
      });
    } else {
      setData({
        ...data,
        general_information: {
          ...data.general_information,
          layout: uploadStatus.url,
        },
      });
    }

    handleUploadClose();
  };

  useEffect(() => {
    const promotor_user = localStorage.getItem("promotor_user") || null;
    if (promotor_user) {
      const userData = JSON.parse(promotor_user);
      setUserDetails(userData);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      getAllPromotorsData(userDetails.id);
      getPromotorDetails(userDetails.id);
    }
  }, [userDetails]);

  const getPromotorDetails = (id) => {
    setIsLoading(true);
    const payload = { company_id: id };
    XHR.post("realestate/promoters/get", payload).then(
      (res) => {
        setIsLoading(false);
        setData((res.data && res.data.companies) || {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getAllPromotorsData = (id) => {
    setIsLoading(true);
    const payload = { promoters_id: id };
    XHR.post("realestate/promoters/getdata", payload).then(
      (res) => {
        setIsLoading(false);
        setPromotionData((res.data && res.data.list) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleAddMultipleData = () => {
    if (updateData) {
      if (
        !updateData.name ||
        !updateData.job_title ||
        !updateData.department ||
        (errors["email_id"] && errors["email_id"] !== "") ||
        (errors["contact_no"] && errors["contact_no"] !== "")
      ) {
        if (errors["email_id"] && errors["email_id"] !== "") {
          openSnackBar("Enter Valid Email Id", "error");
          return;
        }
        if (errors["contact_no"] && errors["contact_no"] !== "") {
          openSnackBar("Enter valid contact mobile no", "error");
          return;
        }
        openSnackBar("Enter mandatory fields.", "error");
        setIsLoading(false);
        return;
      }

      const payload = {
        ...updateData,
        promoters_id: userDetails.id,
        type: "team",
      };
      XHR.post("realestate/promoters/updatedata", payload).then(
        (res) => {
          console.log("res.status", res);
          if (res.data.status) {
            setIsLoading(false);
            setUpdateData(null);
            getAllPromotorsData(userDetails.id);
            setUploadedFileName('');
            openSnackBar("Added / Updated Successfully", "success");
          } else {
            openSnackBar("Already Exists", "error");
          }
        },
        (err) => {
          setIsLoading(false);
          alert("Something went wrong");
          console.log("err", err);
        }
      );
    } else {
      openSnackBar("Please fill the data", "error");
    }
  };

  const onBackBtnClk = () => {
    nextStep(2);
  };

  const onNextBtnClk = () => {
    nextStep(4);
  };

  const [isDelete, setIsDelete] = useState(false);

  const handleDeleteClick = (el, type) => {
    setUpdateData({ ...el, type });
    setIsDelete(true);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setIsLoading(true);
    const payload = { id: updateData.id };
    let url = "realestate/promoters/deletedata";
    XHR.post(url, payload).then(
      (res) => {
        setIsLoading(false);
        setUpdateData(null);
        getAllPromotorsData(userDetails.id);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };
    temp.email_id = "";
    temp.contact_no = "";
    if ("email_id" in fieldValues) {
      if (fieldValues.email_id)
        temp.email_id = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email_id)
          ? ""
          : "Email is not valid.";
    }

    if ("contact_no" in fieldValues) {
      if (fieldValues.contact_no)
        temp.contact_no =
          fieldValues.contact_no.length === 10 ? "" : "Mobile no is not valid.";
    }

    setErrors({
      ...temp,
    });
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Dialog
        open={isDelete}
        size="md"
        fullWidth
        onClose={() => {
          setUpdateData(null);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
        <DialogContent>Delete this item?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDelete(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmDelete()} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openUpload}
        size="md"
        fullWidth
        onClose={handleUploadClose}
        aria-labelledby="form-dialog-title"
      >
        {/* <DialogTitle id="form-dialog-title">Upload</DialogTitle> */}
        <DialogContent>
          <Grid
            container
            direction="column"
            gap="1em"
            justifyContent="center"
            alignItems="center"
          >
            <div style={{ position: "absolute", top: "0px", right: "0px" }}>
              <IconButton aria-label="closePopup" onClick={handleUploadClose}>
                <HighlightOffIcon />
              </IconButton>
            </div>
            <div style={{ marginTop: "2%" }}></div>
          {/* <Grid container spacing={1}> */}
            {(uploadType === "image" ||
              uploadType === "logo" ||
              uploadType === "layout" ||
              uploadType === "boardPhoto" ||
              uploadType === "teamPhoto" ||
              uploadType === "feedbackPhoto") && (
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="image-button-file"
                  onChange={onFileChange}
                  type="file"
                />
                {!uploadObj ? (
                  <label htmlFor="image-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className="login-button"
                    >
                      Choose Image
                    </Button>
                  </label>
                ) : (
                  <>
                    <Typography style={{ marginBottom: 10, textAlign: "center" }}>
                      {uploadObj.name || "Image"}
                    </Typography>
                    {!uploadStatus ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          onClick={handleConfirmUpload}
                          style={{ marginRight: 10 }}
                          className="login-button"
                        >
                          {uploading ? "Uploading" : "Upload Image"}
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            className="login-button"
                          >
                            Choose New Image
                          </Button>
                        </label>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePreview}
                          style={{ marginRight: 10 }}
                          component="span"
                          className="login-button"
                        >
                          Preview Image
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{ marginRight: 10 }}
                            className="login-button"
                          >
                            Choose New Image
                          </Button>
                        </label>
                        {uploadStatus && (
                          <label htmlFor="image-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              className="login-button"
                              onClick={handleSave}
                            >
                              Save
                            </Button>
                          </label>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleUploadClose} color="primary"> 
            Close
          </Button>
          {uploadStatus && (
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          )}
        </DialogActions> */}
      </Dialog>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Team Members</span>
                <div>
                  <span className="login-form-heading-step">(Optional Step 3 of 6)</span>
                </div>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Name"
                  variant="outlined"
                  required
                  className="login-inputWidth"
                  size="small"
                  value={updateData && updateData.name || ""}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Title"
                  variant="outlined"
                  required
                  className="login-inputWidth"
                  size="small"
                  value={updateData && updateData.job_title || ""}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      job_title: e.target.value,
                    })
                  }
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Department"
                  variant="outlined"
                  required
                  className="login-inputWidth"
                  size="small"
                  value={updateData && updateData.department || ""}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      department: e.target.value,
                    })
                  }
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Email ID"
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  value={updateData && updateData.email_id || ""}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      email_id: e.target.value,
                    });
                    validate({ ["email_id"]: e.target.value });
                  }}
                  {...(errors["email_id"] &&
                    errors["email_id"] !== "" && {
                      error: true,
                      helperText: errors["email_id"],
                    })}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Mobile Number"
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  value={updateData && updateData.contact_no || ""}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      contact_no: e.target.value,
                    });
                    validate({ ["contact_no"]: e.target.value });
                  }}
                  {...(errors["contact_no"] &&
                    errors["contact_no"] !== "" && {
                      error: true,
                      helperText: errors["contact_no"],
                    })}
                />
              </div>
              <div className="login-form-div">
                <Button
                  variant="outlined"
                  size="medium"
                  className="login-button"
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    setUploadType("boardPhoto");
                    setOpenUpload(true);
                  }}
                >
                  Upload Photo
                </Button>
                <div>
                  <span className="login-form-div-helper-text">
                    (PNG or JPG file less than 2MB)
                  </span>
                </div>
                {uploadedFileName && (
                  <div>
                    <span className="login-form-div-helper-text">
                      {uploadedFileName}
                    </span>
                  </div>
                )}
              </div>
              <div className="profile-back-next-btn-cntr">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onBackBtnClk}
                  className="login-button"
                  startIcon={<WestIcon />}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  className="login-button"
                  onClick={() => {
                    handleAddMultipleData();
                  }}
                >
                  Add / Update Team Member
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onNextBtnClk}
                  className="login-button"
                  endIcon={<EastIcon />}
                >
                  Next
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
        <Grid
          container
          gap="3em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="2em 0 2em 0"
          padding="0 4em 0 4em"
        >
          {promotionData &&
            promotionData
              .filter((el) => el.type === "team")
              .map((row, i) => (
                <CSS.PromoterDirCardContainer>
                  <Avatar
                    alt={row.name}
                    sx={{ width: 100, height: 100 }}
                    src={row.photo}
                  />
                  <div className="pdp-contact-card-nme">{row.name}</div>
                  <div className="pdp-contact-card-title">{row.job_title}</div>
                  {
                    row.email_id && <div className="pdp-contact-card-contacts">
                      <MailOutlineIcon />
                      {row.email_id}
                    </div>
                  }
                  {
                    row.contact_no && <div className="pdp-contact-card-contacts">
                      <LocalPhoneIcon />
                      {row.contact_no}
                    </div>
                  }
                  <div className="pdp-contact-card-contacts">
                    <IconButton
                      size="large"
                      className="login-button"
                      onClick={() => handleDeleteClick(row, "team")}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="large"
                      className="login-button"
                      onClick={() => {
                        setUpdateData(row);
                        setErrors({});
                      }}
                    >
                      <BorderColorIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                </CSS.PromoterDirCardContainer>
              ))}
        </Grid>
      </Grid>
    </>
  );
};

export default PromoterProfileStepThree;
