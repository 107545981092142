import { isMobile } from "react-device-detect";
//Material UI imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Avatar from "@mui/material/Avatar";
//Custom imports
import * as CSS from "../Style.js";

const ContactPopup = (props) => {
  const { openFlag, setCloseFlag, contact } = props;

  return (
    <CSS.CustomizedDialog
      open={openFlag}
      onClose={() => setCloseFlag(false)}
      isMobile={ isMobile }
    >
      <Grid
        container
        gap="2em"
        justifyContent="center"
        alignItems="center"
        direction={ isMobile ? "column" : "row" }
        margin="2em 0 2em 0;"
      >
        <CSS.CloseBtn>
          <IconButton
            aria-label="closePopup"
            onClick={() => setCloseFlag(false)}
          >
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn>
        {contact &&
          contact.map(
            (el, i) =>
              el.name !== "" && (
                <div className="pdp-contact-card" key={i}>
                  <Avatar
                    alt={el.name.toUpperCase()}
                    src={el.name !== "" ? el.name.charAt(0).toUpperCase() : ""}
                    sx={{ width: 100, height: 100 }}
                  />
                  <div className="pdp-contact-card-nme">{el.name}</div>
                  <div className="pdp-contact-card-cnt">{el.email}</div>
                  <div className="pdp-contact-card-cnt">{el.mobile}</div>
                </div>
              )
          )}
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default ContactPopup;
