/* eslint-disable react/jsx-pascal-case */
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import Avatar from "@mui/material/Avatar";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Button from "@mui/material/Button";
import Tooltip from '@mui/material/Tooltip';
//Other lib imports
//Custom imports
import logo from "../../Assets/logo_with_text.png";
import { useGoogleLogout } from "react-google-login";
import { prodBuild } from "../../util/config";
import * as CSS from "./Style.js";
import { clientId } from "../../util/constants/generalConstant";
/* const clientId =
  "749795274272-rbo6pubta1t12egbbbs96lj80mdssumj.apps.googleusercontent.com"; */

const PLPHeader = (props) => {
  const navigate = useNavigate();

  const { openLogin, setOpenGoogleLoginPopup, setOpenOTPLoginPopup, loginStateFromParent } = props;

  const menuOptions = [
    "View Profile",
    "View Favorites",
    "View Site Visit",
    "View Queries",
    // "View Preference",
    // "View My Listing",
    // "Submit Your Layout",
  ];

  if(!prodBuild) {
    menuOptions.push('View Preference');
    menuOptions.push('View My Listing');
  }

  const [loginState, setLoginState] = useState({
    isLogin: false,
    isOtp: false,
  });

  const [userDetails, setUserDetails] = useState(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMenuClose = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  useEffect(() => {
    setLoginState({...loginStateFromParent});
  }, [loginStateFromParent]);
  
  useEffect(() => {
    const token = localStorage.getItem("authToken") || null;
    const isOtpLogin = localStorage.getItem("mIsVerified") || false;
    if (token) {
      setLoginState({
        ...loginState,
        isLogin: true,
        isOtp: isOtpLogin,
      });
    } else {
      setLoginState({
        ...loginState,
        isOtp: isOtpLogin,
      });
    }

    const user = localStorage.getItem("user") || null;
    if (user) {
      const userData = JSON.parse(user);
      setUserDetails(userData);
    }
  }, []);

  const menuNavigate = (menuId) => {
    let redirectUrl = "profile";
    if (menuId === 1) {
      redirectUrl = "favourites";
    } else if (menuId === 2) {
      redirectUrl = "sitevisit";
    } else if (menuId === 3) {
      redirectUrl = "queries";
    } else if (menuId === 4) {
      redirectUrl = "preference";
    } else if (menuId === 5) {
      redirectUrl = "property";
    }

    if (redirectUrl !== "") {
      navigate(`/${redirectUrl}`, {
        state: {},
      });
    }
  };

  const onLogoutSuccess = (res) => {
    // console.log("Logged out Success");
    // alert("Logged out Successfully ✌");
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    window.location.reload();
  };

  const onFailure = () => {
    console.log("Handle failure cases");
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  const navigateLogic = () => {
    if(!loginState.isLogin) {
      setOpenGoogleLoginPopup(true)
    } else {
      if(!loginState.isOtp) {
        setOpenOTPLoginPopup(true)
      } else {
        navigate(`/addProperty`) 
      }
    }
  }

  return (
  <>
    <CSS.logoContainer
      src={logo}
      alt="#"
      onClick={() =>
        navigate("/", {
          state: {},
        })
      }
    />
    {
      !prodBuild && (
        <CSS.submitPropContainer>
          <Button
            variant="outlined"
            className="btn-class"
            onClick={ () => navigateLogic() }
          >
            Submit Your Property
          </Button>
        </CSS.submitPropContainer>
      )
    }
    <CSS.BtnContainer>
      <IconButton className={"iconBtns"}>
        <HomeIcon
          onClick={() =>
            navigate("/", {
              state: {},
            })
          }
        />
      </IconButton>
      {loginState.isLogin && (
        <>
          <Avatar
            alt="Remy Sharp"
            src={
              (userDetails && userDetails.imageUrl) ||
              ""
            }
            onClick={() => setMenuOpen(true)}
            onMouseOver={() => setMenuOpen(true)}
            ref={menuRef}
          />
          <IconButton className={"iconBtns"}>
            <LogoutIcon onClick={() => window.confirm("Are you sure you want to logout?") ? signOut() : ''} />
          </IconButton>
        </>
      )}
      {!loginState.isLogin && (
        <IconButton className={"iconBtns"}>
          <LoginIcon onClick={() => openLogin(true)} />
        </IconButton>
      )}
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={menuOpen}
        role={undefined}
        anchorEl={menuRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {menuOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => {
                        menuNavigate(index);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </CSS.BtnContainer>
  </>
  );
};

export default PLPHeader;
