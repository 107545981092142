import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
//Material UI imports
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
//Other lib imports
import axios from "axios";
//Custom imports
import * as CSS from "./Style";
import PreferenceHeader from "../HeaderMenu/PreferenceHeader";
import { baseUrl } from "../../util/config";
import PreferenceCard from './PreferenceCard/PreferenceCard';
import ReusableDialog from "../Overlays/ReusableDialog/ReusableDialog";
import './preference.css';

const Preference = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [preferenceList, setPreferenceList] = useState([]);
  const [username, setUsername] = useState('');
  const [reusableDialogObj, setReusableDialogObj] = useState({ open: false });

  useEffect(() => {
    const user_id = localStorage.getItem("mUserId");
    const userObj = JSON.parse(localStorage.getItem("user"));
    setUsername(userObj.givenName);
    const payload = { user_id };
    setIsLoading(true);
    axios.post(`${baseUrl}/cms_api/realestate/getPreferenceByUserId`, payload).then(
      (res) => {
        setIsLoading(false);
        if(res?.data?.data) {
          if(res?.data?.data.length === 0) {
            setPreferenceList(['NA']);
          } else {
            setPreferenceList([...res?.data?.data]);
          }
        } else {
          setPreferenceList(['NA']);
        }

      },
      (err) => {
        setIsLoading(false);
        console.log('err coming..');
        console.log(err);
      }
    );
  }, []);

  const preferenceHeaderPart = () => {
    return (
      <>
        {/* <div container padding={{ lg: "5%", md: "1%", sm: "1%", xs: "2%" }} justifyContent={'center'} alignItems={'center'}> */}
        <div className="prefmain-header">  
          <div className="prefmain-cnt">Hi <span className="capitalize">{username}</span></div>
          <div className="prefmain-desc">You got 5 layouts matched with your 6 preferences</div>
        </div>
        <ReusableDialog reusableDialogObj={reusableDialogObj} setReusableDialogObj={setReusableDialogObj} />
        <PreferenceCard preferenceList={preferenceList} setReusableDialogObj={setReusableDialogObj} setIsLoading={setIsLoading} />
      </>
    )
  }

  return (
    <CSS.ParentDiv isMobile={isMobile}>
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
      >
        <PreferenceHeader showAddPreference={true} />
      </Grid>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      {
        preferenceList.length && (
          <Grid item lg={10} md={10} sm={12} xs={12}>
            {
              preferenceList[0] === 'NA' ? (
                <div className='no-pref'>No Preferences found</div>
              ) : (
                preferenceHeaderPart()
              )
            }
          </Grid>
        )
      }
    </CSS.ParentDiv>
  );
};

export default Preference;
