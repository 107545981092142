import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import isEqual from 'lodash/isEqual';
import { baseUrl } from "../../../util/config";
import axios from "axios";
//Other lib imports
//Custom imports
import { priceRangeArr } from "../../../util/constants/preferenceConstant";

const readOnly = { opacity: '0.7', pointerEvents: 'none' }

const AddPreferenceStepFour = (props) => {
  const navigate = useNavigate();
  const { nextStep, propData, inputChange, viewOnly, setReusableDialogObj } = props;
  const closeReusableDialog = () => {
    setReusableDialogObj({
      open: false
    });
  }
  const onBackBtnClk = () => {
    nextStep(3);
  };

  const onNextBtnClk = () => {
    if(propData.maxBudget) {
      nextStep(5);
    } else {
      setReusableDialogObj({
        open: true,
        title: '',
        content: 'Please enter all mandatory inputs',
        btnArr: [
          {
            label: 'OK',
            fun: closeReusableDialog
          }
        ]
      });
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Budget Preference</span>
                <div>
                  <span className="heading-step">(Step 4 of 5)</span>
                </div>
              </div>
              <div style={viewOnly && readOnly}>
                <FormControl fullWidth className='form-control-container'>
                  <InputLabel>Minimum Budget*</InputLabel>
                  <Select
                    label='Minimum Budget*'
                    name='minBudget'
                    value={propData.minBudget}
                    onChange={ e => inputChange(e) }
                  >
                    {
                      priceRangeArr.map((prices, index) => {
                        if(priceRangeArr.length-1 === index || priceRangeArr.length-2 === index) {
                          return null
                        }
                      
                        return <MenuItem value={prices.val}>{prices.label}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
                <FormControl fullWidth className='form-control-container'>
                <InputLabel>Maximum Budget*</InputLabel>
                <Select
                  label='Maximum Budget*'
                  name='maxBudget'
                  value={propData.maxBudget}
                  onChange={ e => inputChange(e) }
                >
                  {
                    priceRangeArr.map(prices => {
                      if(prices.val > propData.minBudget) {
                        return <MenuItem value={prices.val}>{prices.label}</MenuItem>
                      }
                      return null
                  })
                  }
                </Select>
              </FormControl>
              </div>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPreferenceStepFour;
