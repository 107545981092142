import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import WestIcon from "@mui/icons-material/West";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
//Other lib imports
import axios from "axios";
import isEqual from 'lodash/isEqual';
//Custom imports
import { baseUrl } from "../../../util/config";

const readOnly = { opacity: '0.7', pointerEvents: 'none' }

const AddPreferenceStepFive = (props) => {

  const navigate = useNavigate();
  const { nextStep, propData, inputChange, viewOnly, setReusableDialogObj, fullDataOnLoad } = props;
  const [isLoading, setIsLoading] = useState(false);

  const closeReusableDialog = (arg) => {
    setReusableDialogObj({
      open: false
    });
    if(arg) {
      navigate(arg);
    }
  }

  const onBackBtnClk = () => {
    nextStep(4);
  };

  const onSubmitYesAction = () => {
    if(propData.id) {
        const { areaUint, city, direction, district, emi, id, loanAmount, loanSupport, maxArea, maxBudget, minArea, minBudget, otherCity, pinCode, propertyType, radius, state, user_id } = fullDataOnLoad;
        const onLoadData = { areaUint, city, direction, district, emi, id, loanAmount, loanSupport, maxArea, maxBudget, minArea, minBudget, otherCity, pinCode, propertyType, radius, state, user_id };
        if(isEqual(onLoadData, propData)) {
          setReusableDialogObj({
              open: true,
              title: '',
              content: 'No changes to submit.',
              btnArr: [
                {
                  label: 'OK',
                  fun: closeReusableDialog,
                  param: '/preference'
                }
              ]
            });
        }
      }
      const payload = { ...propData };
      const apiUrl = `${baseUrl}${propData.id ? '/cms_api/realestate/updatePreferenceById' : '/cms_api/realestate/addPreference' }`;
      setIsLoading(true);
      axios.post(apiUrl, payload).then(
        (res) => {
          if(res?.data?.id && res?.data?.status) {
            setIsLoading(false);
            if(propData.id) {
              setReusableDialogObj({
                  open: true,
                  title: '',
                  content: 'Preference updated successfully.',
                  btnArr: [
                    {
                      label: 'OK',
                      fun: closeReusableDialog,
                      param: '/preference'
                    }
                  ]
                });
            } else {
              setReusableDialogObj({
                  open: true,
                  title: '',
                  content: 'Your preference added successfully.',
                  btnArr: [
                    {
                      label: 'OK',
                      fun: closeReusableDialog,
                      param: '/preference'
                    }
                  ]
                });
            }
          } else {
            setIsLoading(false);
            setReusableDialogObj({
              open: true,
              title: '',
              content: 'Something went wrong.. Please try again later..',
              btnArr: [
                {
                  label: 'OK',
                  fun: closeReusableDialog,
                  param: '/'
                }
              ]
            });
          }
        },
        (err) => {
          setIsLoading(false);
          console.log('err came..');
          console.log(err);
          setReusableDialogObj({
              open: true,
              title: '',
              content: 'something went wrong.. Please try again later..',
              btnArr: [
                {
                  label: 'OK',
                  fun: closeReusableDialog,
                  param: '/'
                }
              ]
            });
        }
      );
  }
  
  const onSubmitBtnClk = () => {
    setReusableDialogObj({
        open: true,
        title: '',
        content: 'Are you sure to submit?',
        btnArr: [
          {
            label: 'Yes',
            fun: onSubmitYesAction,
          },
          {
            label: 'No',
            fun: closeReusableDialog,
          }
        ]
      });
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Loan Expectation (Optional)</span>
                <div>
                  <span className="heading-step">(Step 5 of 5)</span>
                </div>
              </div>
              <div style={viewOnly && readOnly}>
                <FormControl fullWidth className='form-control-container'>
                  <FormLabel className='form-control-label'>Looking for Loan support</FormLabel>
                  <RadioGroup
                    name={'loanSupport'}
                    value={propData.loanSupport}
                    onChange={ e => inputChange(e) }
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  {
                    viewOnly ? (
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => navigate('/preference')}
                        className="login-button"
                      >
                        View Preferences
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={onSubmitBtnClk}
                        className="login-button"
                      >
                        Submit
                      </Button>
                    )
                  }
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPreferenceStepFive;