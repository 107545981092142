import { GET_ALL_PLP, IS_LOADING } from "./actionType";
import XHR from "../../util/xhr";

export function getPLPList(inputs = {}) {
  return function (dispatch) {
    dispatch({
      type: IS_LOADING,
    });
    const updatedPayload = { ...inputs, count: 1000, offset: 0 }
    return XHR.post("realestate/api/plp", updatedPayload).then(
      (res) => {
        dispatch({
          type: GET_ALL_PLP,
          data: {
            projects: res.data.projects,
            total: res.data.total,
            isFav: res.data.isFav,
            promoterTotal: res.data.p_total
          },
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}

export function emptyPLPList() {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_PLP,
      data: { projects: [], total: 0, isFav: [], promoterTotal: 0 },
    });
  };
}
