/* eslint-disable react/jsx-pascal-case */
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
//Other lib imports
import { isBrowser, isMobile } from "react-device-detect";
import { useGoogleLogout } from "react-google-login";
//Custom imports
import logo from "../../Assets/logo_with_text.png";
import * as CSS from "./Style.js";

const PromoterHeader = (props) => {
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState(null);
  const [loginState, setLoginState] = useState(false);

  useEffect(() => {
    const promotor_user = localStorage.getItem("promotor_user") || null;
    if (promotor_user) {
      const userData = JSON.parse(promotor_user);
      setUserDetails(userData);
      setLoginState(true);
    }
  }, []);

  const signOut = () => {
    localStorage.removeItem("promotor_user");
    navigate("/login");
  };

  return (
  <>
    <CSS.logoContainer
      src={logo}
      alt="#"
      onClick={() =>
        navigate("/promoterWelcome", {
          state: {},
        })
      }
    />
    <CSS.BtnContainer>
      <IconButton className={"iconBtns"}>
        <HomeIcon
          onClick={() =>
            navigate("/promoterWelcome", {
              state: {},
            })
          }
        />
      </IconButton>
      {loginState && (
        <>
          <Tooltip title={(userDetails && userDetails.name) || ""}>
            <Avatar alt={(userDetails && userDetails.name) || ""} />
          </Tooltip>
          <IconButton className={"iconBtns"}>
            <LogoutIcon
              onClick={() =>
                window.confirm("Are you sure you want to logout?")
                  ? signOut()
                  : ""
              }
            />
          </IconButton>
        </>
      )}
    </CSS.BtnContainer>
  </>
  );
};

export default PromoterHeader;
