import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TextField from "@mui/material/TextField";

//Other lib imports
import axios from "axios";
import QueriesPopup from "../Overlays/QueriesPopup/QueriesPopup";
import PLPHeader from "../HeaderMenu/PLPHeader";

import { baseUrl } from "../../util/config";

//Custom imports
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid as DataGrid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";
import Dialog from "../Dialog";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";

//Styling imports
import "../PDP/Pdp.css";

const Queries = (props) => {
  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);
  const [messages, setMessages] = useState(null);
  const [details, setDetails] = useState(null);
  const [openQueriesPopup, setOpenQueriesPopup] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [userType, setUserType] = useState("N");
  const [userDetails, setUserDetails] = useState(null);

  const [pageSizes] = useState([5, 10, 15, 25, 50, 100, 500]);
  const [dialogShow, setDialogShow] = useState(false);
  const [dialogProps, setDialogProps] = useState({});

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [columns] = useState([
    { name: "sno", title: "S.No" },
    { name: "date", title: "Date" },
    { name: "time", title: "Time" },
    { name: "promoter_name", title: "Layout Name" },
    { name: "last_chat", title: "Last Reply From" },
    { name: "action", title: "Action" },
  ]);

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "action", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "action", sortingEnabled: false },
  ];

  useEffect(() => {
    const user = localStorage.getItem("user") || null;
    if (user) {
      const userData = JSON.parse(user);
      setUserDetails(userData);
      getCompanyDetails(userData.googleId);
      getAllQueries(userData.googleId);
    }
  }, []);

  const getCompanyDetails = (user_id = "") => {
    const payload = {
      user_id,
    };

    axios
      .post(`${baseUrl}/plot_api/realestate/company/get`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            if (res.data.isApproved) {
              setUserType(res.data.userType);
            }
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const getAllQueries = (user_id) => {
    const payload = {
      user_id,
      type: "W",
    };

    axios
      .post(`${baseUrl}/plot_api/realestate/query/list`, payload)
      .then(
        (res) => {
          setProjects((res.data && res.data.queries) || []);
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const actionClick = (type, data) => {
    console.log("data", data);
  };

  const closeDialog = () => {
    setDialogShow(false);
    setTimeout(() => {
      setDialogProps({});
    }, 100);
  };

  const viewBtn = ({ row }, viewClick) => (
    <div>
      <VisibilityIcon
        title={"View"}
        onClick={() => {
          viewClick(row);
        }}
        style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
      />
    </div>
  );

  const viewClick = (data) => {
    console.log("data", data);
    getAllMessages(data.id);
    setDetails(data);
    setOpenQueriesPopup(true);
  };

  const getAllMessages = (queryId) => {
    const payload = { query_id: queryId };
    axios
      .post(`${baseUrl}/plot_api/realestate/messages/get`, payload)
      .then(
        (res) => {
          if (res.data && res.data.queries) {
            setMessages(res.data.queries);
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const onQueriesSubmit = (data) => {
    console.log(data);
    const payload = {
      query_id: details.id,
      message: data.message,
      type: 1,
      user_id: userDetails.googleId || 0,
    };
    axios
      .post(
        `${baseUrl}/plot_api/realestate/messages/save`,
        payload
      )
      .then(
        (res) => {
          if (res.data && res.data.status) {
            getAllMessages(details.id);
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "success");
          console.log("err", err);
        }
      );
  };

  return (
    <>
      <Dialog open={dialogShow} {...dialogProps} />
      <QueriesPopup
        openFlag={openQueriesPopup}
        setCloseFlag={setOpenQueriesPopup}
        onSubmit={onQueriesSubmit}
        openSnackBar={openSnackBar}
        details={details}
        messages={messages}
      />
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        item
        lg={10}
        md={10}
        sm={11}
        xs={11}
        style={{ textAlign: "center" }}
      >
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="2em 0 2em 0;"
        >
          <PLPHeader />
        </Grid>
      </Grid>
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        padding="2em"
      >
        <span className="pdp-lo-dtls-txt">queries</span>
      </Grid>
      <Grid
        container
        gap="1em"
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="column"
        padding="2em"
      >
        <Grid item xs={12} md={12} lg={12} style={{ padding: 20 }}>
          <DataGrid rows={projects} columns={columns}>
            <SortingState
              defaultSorting={[]}
              columnExtensions={sortingStateColumnExtensions}
            />
            <IntegratedSorting />
            <FilteringState
              defaultFilters={[]}
              columnExtensions={filteringStateColumnExtensions}
            />
            <IntegratedFiltering />
            <PagingState defaultCurrentPage={0} pageSize={5} />
            <IntegratedPaging />
            <DataTypeProvider
              for={["action"]}
              formatterComponent={(row) => {
                return viewBtn(row, viewClick);
              }}
            />
            <Table />
            <TableHeaderRow showSortingControls />
            <PagingPanel pageSizes={pageSizes} />
            <TableFilterRow />
          </DataGrid>
        </Grid>
      </Grid>
    </>
  );
};

export default Queries;
