/* eslint-disable react/jsx-pascal-case */
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
//Other lib imports
//Custom imports
import OtpLoginPopup from "../Overlays/OtpLoginPopup/OtpLoginPopup";
import logo from "../../Assets/logo_with_text.png";
import * as CSS from "./Style.js";
import { prodBuild } from "../../util/config";
import { useGoogleLogout } from "react-google-login";
import { clientId } from "../../util/constants/generalConstant";
/* const clientId =
  "749795274272-rbo6pubta1t12egbbbs96lj80mdssumj.apps.googleusercontent.com"; */

const PreferenceHeader = (props) => {
  const {openLogin, showAddPreference } = props;
  const navigate = useNavigate();
  const menuOptions = [
    "View Profile",
    "View Favorites",
    "View Site Visit",
    "View Queries",
  ];
  if(!prodBuild) {
    // menuOptions.push('View Preference');
    menuOptions.push('View My Listing');
  }
  const [loginState, setLoginState] = useState({
    isLogin: false,
    isOtp: false,
  });
  const [openOTPLoginPopup, setOpenOTPLoginPopup] = useState(false);

  const [userDetails, setUserDetails] = useState(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMenuClose = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken") || null;
    const isOtpLogin = localStorage.getItem("mIsVerified") || false;
    if (token) {
      setLoginState({
        ...loginState,
        isLogin: true,
        isOtp: isOtpLogin,
      });
    } else {
      setLoginState({
        ...loginState,
        isOtp: isOtpLogin,
      });
    }
    if(!token && !isOtpLogin) {
      navigate('/', {
        state: {},
      });
      window.location.reload();
    }
    const user = localStorage.getItem("user") || null;
    if (user) {
      const userData = JSON.parse(user);
      setUserDetails(userData);
    }
  }, []);

  const setIsVerified = () => {
    setLoginState({
      ...loginState,
      isOtp: true,
    });
    setOpenOTPLoginPopup(false);
    navigate(`/addPreference`,{state:{id: null}});
  };
  const menuNavigate = (menuId) => {
    let redirectUrl = "profile";
    if (menuId === 1) {
      redirectUrl = "favourites";
    } else if (menuId === 2) {
      redirectUrl = "sitevisit";
    } else if (menuId === 3) {
      redirectUrl = "queries";
    } /* else if (menuId === 4) {
      redirectUrl = "preference";
    }  */else if (menuId === 4) {
      redirectUrl = "property";
    }

    if (redirectUrl !== "") {
      navigate(`/${redirectUrl}`, {
        state: {},
      });
    }
  };
  const onLogoutSuccess = (res) => {
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    // window.location.reload();
    navigate('/', {
      state: {},
    });
  };

  const onFailure = () => {
    console.log("Handle failure cases");
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });
  return (
    <>
      <CSS.logoContainer
        src={logo}
        alt="#"
        onClick={() =>
          navigate("/", {
            state: {},
          })
        }
      />
      <OtpLoginPopup
        openFlag={openOTPLoginPopup}
        setCloseFlag={setOpenOTPLoginPopup}
        setIsVerified={setIsVerified}
        layout_name={""}
        promoters_name={""}
      />
      {showAddPreference && (
        <CSS.submitPropContainer>
          <Button
            variant="outlined"
            className="btn-class"
            onClick={() =>
              loginState.isOtp
                ? navigate(`/addPreference`,{state:{id: null}})
                : setOpenOTPLoginPopup(true)
            }
          >
            Add Preference
          </Button>
        </CSS.submitPropContainer>
      )}
     
      <CSS.BtnContainer>
      <IconButton className={"iconBtns"}>
        <HomeIcon
          onClick={() =>
            navigate("/", {
              state: {},
            })
          }
        />
      </IconButton>
      {loginState.isLogin && (
        <>
          <Avatar
            alt="Remy Sharp"
            src={
              (userDetails && userDetails.imageUrl) ||
              ""
            }
            onClick={() => setMenuOpen(true)}
            onMouseOver={() => setMenuOpen(true)}
            ref={menuRef}
          />
          <IconButton className={"iconBtns"}>
            <LogoutIcon onClick={() => window.confirm("Are you sure you want to logout?") ? signOut() : ''} />
          </IconButton>
        </>
      )}
      {!loginState.isLogin && (
        <IconButton className={"iconBtns"}>
          <LoginIcon onClick={() => openLogin(true)} />
        </IconButton>
      )}
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={menuOpen}
        role={undefined}
        anchorEl={menuRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {menuOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => {
                        menuNavigate(index);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </CSS.BtnContainer>
    </>
  );
};

export default PreferenceHeader;
