import React from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

import Default from "../Components/Default";
import Plp from "../Components/PLP/Plp";
import Pdp from "../Components/PDP/Pdp";
import PromoterPDP from "../Components/PDP/PromoterPDP/PromoterPDP";
import ValidatePDP from "../Components/PDP/ValidatePDP";
import Profile from "../Components/Profile/Profile";
import Favorites from "../Components/Favorites/Favorites";
import SiteVisit from "../Components/SiteVisit/SiteVisit";
import Queries from "../Components/Queries/Queries";
import CreateSearch from "../Components/CreateSearch/CreateSearch";
import PromoterLogin from "../Components/Login/PromoterLogin";
import RegisterNewPromoter from "../Components/PromoterAdmin/PromoterRegistration";
import PromoterMobileVerification from "../Components/PromoterAdmin/PromoterMobileVerification";
import PromoterWelcome from "../Components/PromoterAdmin/PromoterWelcome";
import PromoterProfile from "../Components/PromoterAdmin/PromoterProfile";
import AddLayout from "../Components/PromoterAdmin/AddLayout";
import PromoterLayout from "../Components/PromoterAdmin/PromoterLayout";
import Mpdp from "../Components/PDP/Mpdp";
import PMpdp from "../Components/PDP/PMpdp";
import LuckyDraw from "../Components/LuckyDraw/LuckyDraw";
import Property from "../Components/Property/Property";
import AddProperty from "../Components/Property/AddProperty";
import AddPropertyOptional from "../Components/Property/AddPropertyOptional";
import AddPreference from "../Components/Preference/AddPreference";
import Preference from "../Components/Preference/Preference";

import "./protected.css";

const Protected = (props) => {
  return (
    <div className="">
      <Routes>
        <Route path="*" element={<Plp />} />
        {/* <Route path="/" exact element={<Plp />} /> */}
        {/* <Route path="/home/luckydraw" exact element={<LuckyDraw />} /> */}
        <Route
          path="/pdp/:state/:district/:taluk/:area1/:area2/:promotors/:projectname/:hash"
          exact
          element={<Pdp />}
        />
        <Route
          path="/pdp/:district/:taluk/:area1/:area2/:promotors/:projectname/:hash"
          exact
          element={<Pdp />}
        />
        <Route
          path="/pdp/:taluk/:area1/:area2/:promotors/:projectname/:hash"
          exact
          element={<Pdp />}
        />
        <Route
          path="/pdp/:area1/:area2/:promotors/:projectname/:hash"
          exact
          element={<Pdp />}
        />
        <Route
          path="/pdp/:area2/:promotors/:projectname/:hash"
          exact
          element={<Pdp />}
        />
        <Route
          path="/pdp/:promotors/:projectname/:hash"
          exact
          element={<Pdp />}
        />
        <Route path="/pdp/:projectname/:hash" exact element={<Pdp />} />
        <Route
          path="/pdp/:state/:district/:promotors/:projectname/:hash/:mobile"
          exact
          element={<ValidatePDP />}
        />
        <Route
          path="/ppdp/:state/:district/:promotors/:projectname/:hash"
          exact
          element={<PMpdp />}
        />
        <Route
          path="/mpdp/:state/:district/:promotors/:projectname/:hash/:mpdp_hash"
          exact
          element={<Mpdp />}
        />
        <Route
          path="/promoter-pdp/:state/:district/:promotors/:company_hash"
          exact
          element={<PromoterPDP />}
        />
        <Route path="/login" exact element={<PromoterLogin />} />
        <Route path="/profile" exact element={<Profile />} />
        <Route path="/favourites" exact element={<Favorites />} />
        <Route path="/sitevisit" exact element={<SiteVisit />} />
        <Route path="/queries" exact element={<Queries />} />
        <Route path="/create-search" exact element={<CreateSearch />} />
        <Route
          path="/promoterRegistration"
          exact
          element={<RegisterNewPromoter />}
        />
        <Route
          path="/promoterMobileVerification"
          exact
          element={<PromoterMobileVerification />}
        />
        <Route path="/promoterWelcome" exact element={<PromoterWelcome />} />
        <Route path="/promoterProfile" exact element={<PromoterProfile />} />
        <Route path="/addLayout" exact element={<AddLayout />} />
        <Route path="/promoterLayout" exact element={<PromoterLayout />} />
        <Route path="/property" exact element={<Property />} />
        <Route path="/addProperty" exact element={<AddProperty />} />
        <Route
          path="/addPropertyOptional"
          exact
          element={<AddPropertyOptional />}
        />
        <Route path="/preference" exact element={<Preference />} />
        <Route path="/addPreference" exact element={<AddPreference />} />
      </Routes>
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Protected);
