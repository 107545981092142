import * as React from "react";
// import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
//Material UI imports
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
//Other lib imports
import axios from "axios";
//Custom imports
import { baseUrl } from "../../../util/config";
import "./PreferenceCard.css";
import { priceRangeArr, propertyTypes, 
  directions, defaultDirectionArr } from "../../../util/constants/preferenceConstant";
//Icons
import LocationIcon from '../../../Assets/location.png';
import AreaIcon from '../../../Assets/area.png';
import DirectionIcon from '../../../Assets/direction.png';
import PriceTagIcon from '../../../Assets/priceTag.png';
import EditIcon from '../../../Assets/edit.png';
import DeleteIcon from '../../../Assets/delete.png';

import { map } from "lodash";
// import ReusableDialog from "../../Overlays/ReusableDialog/ReusableDialog";

const PreferenceCard = (props) => {
  const navigate = useNavigate();

  const { preferenceList, setIsLoading, setReusableDialogObj } = props;
  // const [reusableDialogObj, setReusableDialogObj] = React.useState({ open: false });
  // const [prefereceId, setPreferenceId] = React.useState(0);

  const getDirectionString = (dirArr) => {
    let dirStr = '';
    if(dirArr.length === 8 ) {
      return "All";
    } else if (dirArr.length < 8 && dirArr.length > 0) {
      dirArr.forEach((element, index) => {
        dirStr += directionLabel(element);
        dirStr += ' | '
      });
      return dirStr.slice(0, -2);
    } else {
      return "None";
    }
  }
  const closeReusableDialog = (arg) => {
    setReusableDialogObj({
      open: false
    });
    if(arg) {
      navigate(arg);
    }
  }

  const deleteConfirmation = (id)=> {
    console.log('prefernceID', id);
    // setPreferenceId(id);
    setReusableDialogObj({
      open: true,
      title: '',
      content: 'Are you sure, want to delete?',
      btnArr: [
        {
          label: 'Yes',
          fun: ()=> {
            const payload = { id };
            setIsLoading(true);
            axios.post(`${baseUrl}/cms_api/realestate/deletePreference`, payload).then(
              (res) => {
                if(res?.data?.id && res?.data?.status) {
                  setIsLoading(false);
                  setReusableDialogObj({
                    open: true,
                    title: '',
                    content: 'Preference deleted successfully.',
                    btnArr: [
                      {
                        label: 'OK',
                        fun: ()=> {
                          closeReusableDialog();
                          window.location.reload();
                        },
                        param: '/preference'
                      }
                    ]
                  });
                } else {
                  setIsLoading(false);
                  setReusableDialogObj({
                    open: true,
                    title: '',
                    content: 'something went wrong.. Please try again later..',
                    btnArr: [
                      {
                        label: 'OK',
                        fun: closeReusableDialog,
                        param: '/preference'
                      }
                    ]
                  });
                }
              },
              (err) => {
                setIsLoading(false);
                setReusableDialogObj({
                  open: true,
                  title: '',
                  content: 'something went wrong.. Please try again later..',
                  btnArr: [
                    {
                      label: 'OK',
                      fun: closeReusableDialog,
                      param: '/preference'
                    }
                  ]
                });
                console.log('err came..');
                console.log(err);
              }
            );
          }
        },
        {
          label: 'No',
          fun: closeReusableDialog,
        }
      ]
    });
  }
  
  const budgetLabel= (value)=> {
    const priceObj = priceRangeArr.find(obj => obj.val == value);
    return (priceObj) ? priceObj.label : "-";
  };

  const directionLabel = (value) => {
    const directObj = defaultDirectionArr.find(obj => obj.id == value);
    return (directObj) ? directObj.label : "-";
  }

  const onEdit = (fullData) => {
    navigate("/addPreference", {state: {fullData: {...fullData} } } );
  }

  const onView = (fullData) => {
    navigate("/addPreference", {state: {fullData: {...fullData}, viewOnly: true } } );
  }

  const setAreaContent = (data, value) => {
    switch(value) {
      case 'property':
        return  (data.propertyType) ? (
                    <div className='pref-content-text'>
                      {propertyTypes[data.propertyType]}
                    </div>
                ) : <></>
              
      case 'district': 
          return (data.district && data.district_name) ? (
            <div>
              <div className="pref-location-icon">
                <img src={LocationIcon} alt="Location" />
              </div>
              <div className='pref-title-text'>
                {data.district_name}
              </div>
            </div>
          ) : <></>
      case 'city': 
            return <span>{getCity(data)}</span>
      case 'cityDistrictDiv':
            return (data.city && data.city_name && data.district && data.district_name && data.city_name != "Other Places") || (data.district_name && data.otherCity)
             ? (
              <span className="padding-span">|</span>
           ) : <></>
      case 'districtContent' :
            return  (data.district && data.district_name) ? (
                <span> {data.district_name} </span>
            ) : <></>
      case 'districtStateDiv' :
            return (data.district && data.district_name && data.state && data.state_name) ? (
              <span className="padding-span">|</span>
            ) : <></>
      case 'state':
            return (data.state && data.state_name) ? (
              <span>
                {data.state_name}
              </span>
          ) : <></>
    }
  }

  const getCity = (data)=> {
    if(data.city_name && data.otherCity && data.city !== 99999 && data.city_name != "Other Places") {
      return `${data.otherCity} / ${data.city_name}`;
    } else if(data.otherCity && data.city == 99999){
      return `${data.otherCity} / Any Place`;
    } else if(data.otherCity && data.city_name == "Other Places") {
      return `${data.otherCity}`;
    } else if(data.city && data.city_name && data.otherCity == "" && data.city_name != "Other Places") {
      return `${data.city_name}`;
    } else if(data.city == 99999 && data.otherCity == "") {
      return `Any Place |`;
    } else if(data.city_name == "Other Places" && data.otherCity == "") {
      return "";
    } else {
      return "";
    }
  }

  return (
      <Grid container spacing="auto" padding={{ lg: "1%", md: "1%", sm: "1%", xs: "2%" }} justifyContent={'center'} alignItems={'center'}>
        {/* {isLoading && <PlotCardSkeleton />} */}
        {
          map(preferenceList, (data, index) => {
            return (
              <Grid
                lg={3}
                md={4}
                sm={6}
                xs={12}
                key={`item-${index}`}
                className="pref-grid"
              >
                <Card className="pref-card">
                  <CardActionArea>
                   {/*  <CardContent className="pref-card-cnt" onClick={() => onView(data)}> */}
                   <CardContent className="pref-card-cnt">
                    <div className='pref-matches-text'>
                      <div className='pref-match-count'>
                        <div className="pref-match-count-number">5</div>
                        </div>
                      <div>Matches Found</div>
                    </div>
                      <div className='pref-content' >
                        {setAreaContent(data, 'property')}
                        {setAreaContent(data, 'district')}
                      <div className="pref-area-text">
                        {setAreaContent(data, 'city')}
                        {setAreaContent(data, 'cityDistrictDiv')}
                        {setAreaContent(data, 'districtContent')}
                        {setAreaContent(data, 'districtStateDiv')}
                        {setAreaContent(data, 'state')}
                        </div>
                        {
                          data.minBudget && data.maxBudget && (
                            <div className="pref-content-budget">
                              <div className="pref-content-img">
                                <img src={PriceTagIcon} alt="Price" />
                              </div>
                              <div className='pref-content-text'>
                                ₹{budgetLabel(data.minBudget)} - ₹{budgetLabel(data.maxBudget)}
                              </div>
                            </div>
                          )
                        }
                         {
                          data.maxArea && (
                            <div className="pref-content-area">
                              <div className="pref-content-img">
                                <img src={AreaIcon} alt="Area" />
                              </div>
                              <span>
                                { (data.maxArea === "2501") ? '2500+': data.maxArea} {data.areaUint}
                              </span>
                            </div>
                          )
                        }
                        {
                          data.direction ? (
                            <div className="pref-content-direction">
                              <div className="pref-content-img">
                                <img src={DirectionIcon} alt="Direction" />
                              </div>
                              <span className='pref-content-text'>
                                {getDirectionString(data.direction)}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )
                        }
                      </div>
                    </CardContent>
                    <CardActions disableSpacing className="pref-card-actns">
                    <Tooltip title="Edit Preference">
                        <IconButton onClick={() => onEdit(data)}>
                          <img className="pref-action-icon" src={EditIcon} alt="Delete" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Preference">
                        <IconButton onClick={() => deleteConfirmation(data.id)} >
                          <img className="pref-action-icon" src={DeleteIcon} alt="Delete" />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="View Preference">
                        <IconButton onClick={() => onView(data)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip> */}
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
  );
};

export default PreferenceCard;
