import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

//Custom imports
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import axios from "axios";
import OtpLoginPopup from "../Overlays/OtpLoginPopup/OtpLoginPopup";
import MPDPHeader from "../HeaderMenu/MPDPHeader";

import { baseUrl } from "../../util/config";

const Mpdp = () => {
  let { state, district, promotors, projectname, hash, mpdp_hash } =
    useParams();
  const navigate = useNavigate();

  const [loginState, setLoginState] = useState({
    isOtp: false,
  });
  const [openOTPLoginPopup, setOpenOTPLoginPopup] = useState(false);
  const [id, setId] = useState(hash && hash !== "" ? hash : 0);
  const [product, setProduct] = useState(null);
  useEffect(() => {
    getProject(id);
  }, []);

  useEffect(() => {
    if (hash && state && district && promotors && projectname && mpdp_hash) {
      const isOtpLogin = localStorage.getItem("mIsVerified") || false;
      if (isOtpLogin) {
        handlePromotorMobile();
      }
    } else {
      openSnackBar("Not valid URL", "error");
    }
  }, [mpdp_hash]);

  const getProject = (id) => {
    // setIsLoading(true);
    const payload = { id };
    axios.post(`${baseUrl}/plot_api/realestate/get`, payload).then(
      (res) => {
        // setIsLoading(false);
        // console.log("res.data.data", res.data.data);
        if (res.data && res.data.data) {
          // setData(res.data.data);
          setProduct(res.data.data);
        }
      },
      (err) => {
        // setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const setIsVerified = () => {
    setLoginState({
      ...loginState,
      isOtp: true,
    });
    setOpenOTPLoginPopup(false);
    handlePromotorMobile();
  };

  useEffect(() => {
    const isOtpLogin = !!localStorage.getItem("mIsVerified") || false;
    setLoginState({
      ...loginState,
      isOtp: isOtpLogin,
    });
  }, []);

  const handlePromotorMobile = () => {
    const payload = {
      project_hash: hash,
      mUserId: localStorage.getItem("mUserId") || 0,
      mpdp_hash: mpdp_hash,
      type: "L",
    };
    axios
      .post(`${baseUrl}/plot_api/analytics/leads`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            let path = `/pdp/${state}/${district}/${promotors}/${projectname}/${hash}`;
            // console.log("HASHHHH&&&&&&&&", path);
            if (hash !== "") {
              // Call PDP
              navigate(path, {
                state: { id: hash, isfav: false },
              });
            }
          } else {
            openSnackBar("Not valid URL", "error");
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const promotors_general_information =
    product &&
    product.promotorsDetails &&
    product.promotorsDetails.general_information
      ? product.promotorsDetails.general_information
      : {};
  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      {loginState && !loginState.isOtp && (
        <>
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            padding="2em 2em 0 2em"
          >
            <MPDPHeader />
          </Grid>
          {product && (
            <Grid
              container
              gap="1em"
              justifyContent="center"
              alignItems="center"
              direction="column"
              margin="2em 0 2em 0;"
            >
              <div className="pdp-prj-by">
                A PROJECT BY{" "}
                <b>
                  {" "}
                  {promotors_general_information &&
                    promotors_general_information.company_name.toUpperCase()}
                </b>
              </div>
              <div className="pdp-prj-name">{product && product.name}</div>
              <div className="pdp-prj-address">
                {product && product.fulladdress}
              </div>
            </Grid>
          )}
          <OtpLoginPopup
            openFlag={openOTPLoginPopup}
            setCloseFlag={setOpenOTPLoginPopup}
            setIsVerified={setIsVerified}
            isDialog={false}
            layout_name={product && product.name}
            promoters_name={
              promotors_general_information &&
              promotors_general_information.company_name &&
              promotors_general_information.company_name.toUpperCase()
            }
          />
        </>
      )}
    </>
  );
};

export default Mpdp;
