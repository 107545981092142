import { useState } from "react";
import { isMobile } from "react-device-detect";
//Material UI imports
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
//Custom imports
import * as CSS from "../Style.js";

const ReusableDialog = (props) => {
  const { reusableDialogObj, setReusableDialogObj } = props;
  const { title, content, btnArr } = reusableDialogObj;

  return (
    <CSS.CustomizedDialog
      open={reusableDialogObj.open}
      onClose={() => setReusableDialogObj({ open: false })}
      // maxWidth={"md"}
    >
      <Grid
        container
        direction="column"
        gap="1em"
        justifyContent="center"
        alignItems="center"
      >
        {/* <CSS.CloseBtn>
          <IconButton
            aria-label="closePopup"
            onClick={() => setReusableDialogObj({ open: false })}
          >
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn> */}
        <CSS.TitleContainer>
          <span className="title">{title}</span>
        </CSS.TitleContainer>
        <CSS.youtubeHelpText>
          <p>{content}</p>
        </CSS.youtubeHelpText>
        <div style={{ width: '100%', textAlign: 'center' }}>
        {
          btnArr && btnArr.map((btn) => {
            return (
              <div className="login-form-div">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => btn.fun(btn.param)}
                  style={{ width: '75%' }}
                  className="login-button"
                >
                  {btn.label}
                </Button>
              </div>
            )
          })
        }
        </div>
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default ReusableDialog;
