import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//Custom imports
import * as CSS from "../Style.js";

const PromoterSubmittedDetailsPopup = (props) => {
  const { openFlag, setCloseFlag, submittedProfile } = props;

  return (
    <CSS.CustomizedDialog askUs={ isMobile ? false : true }
      open={openFlag}
      onClose={() => setCloseFlag(false)}
    >
      <Grid
        container
        direction="column"
        gap="1em"
        justifyContent="center"
        alignItems="center"
      >
        <CSS.CloseBtn>
          <IconButton
            aria-label="closePopup"
            onClick={() => setCloseFlag(false)}
          >
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn>
        <CSS.TitleContainer>
          <span className="title">Promoter Registration</span>
          <span className="info">Submitted details</span>
        </CSS.TitleContainer>
        <TextField
          value={submittedProfile?.promotor_name}
          label="Promoter Name"
          fullWidth={true}
          disabled={true}
        />
        <TextField
          value={submittedProfile?.email}
          label="Company E-Mail"
          fullWidth={true}
          disabled={true}
        />
        <TextField
          value={submittedProfile?.name}
          label="Proprietor / Contact Person Name"
          fullWidth={true}
          disabled={true}
        />
        <TextField
          value={submittedProfile?.mobile}
          label="Contact Mobile No"
          fullWidth={true}
          disabled={true}
        />
        <TextField
          value={submittedProfile?.website}
          label="Website"
          fullWidth={true}
          disabled={true}
        />
        <TextField
          value={submittedProfile?.address}
          label="Company HQ Address"
          fullWidth={true}
          disabled={true}
          multiline={true}
          rows={3}
        />
        <TextField
          value={submittedProfile?.comment}
          label="Comment"
          fullWidth={true}
          disabled={true}
          multiline={true}
          rows={4}
        />
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default PromoterSubmittedDetailsPopup;
