import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
//Other lib imports
//Custom imports

const AddPropertyStepNine = (props) => {
  const { nextStep, propData, inputChange, centCalculator, setReusableDialogObj } = props;
 
  const closeReusableDialog = () => {
    setReusableDialogObj({
      open: false
    });
  }

  const onBackBtnClk = () => {
    nextStep(8);
  };

  const onNextBtnClk = () => {
    if(propData.plotArea) {
      if(propData.plotArea.length > 5) {
        setReusableDialogObj({
          open: true,
          title: '',
          content: 'Plot Area should be within 5 characters',
          btnArr: [
            {
              label: 'OK',
              fun: closeReusableDialog
            }
          ]
        });
        return;
      }
      if(propData.plotArea.includes("e") || propData.plotArea.includes("+") || propData.plotArea.includes("-")) {
        setReusableDialogObj({
          open: true,
          title: '',
          content: 'Plot area value is invalid',
          btnArr: [
            {
              label: 'OK',
              fun: closeReusableDialog
            }
          ]
        });
        return;
      }
      nextStep(10);
    } else {
      setReusableDialogObj({
        open: true,
        title: '',
        content: 'Please enter all mandatory inputs',
        btnArr: [
          {
            label: 'OK',
            fun: closeReusableDialog
          }
        ]
      });
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Tell Me about your property measurements</span>
                <div>
                  <span className="heading-step">(Step 9 of 11)</span>
                </div>
              </div>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='Plot Area in sq.ft*'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='plotArea'
                  value={propData.plotArea}
                  type={'number'}
                  onChange={ e => inputChange(e) }
                />
              </FormControl>
              <div className="login-form-div">
                <b>Plot Area in cent:</b> {centCalculator()}
              </div>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label="All Side Measurement"
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='plotSides'
                  value={propData.plotSides}
                  onChange={ e => inputChange(e) }
                  InputProps={{
                    endAdornment: <InputAdornment position="end">feet</InputAdornment>,
                  }}
                />
                <FormHelperText className={'form-control-helper-text'}>Ex: 30.4 x 40.5 x 30.9 x 40.2</FormHelperText>
              </FormControl>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPropertyStepNine;
