import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

//Custom imports
import * as CSS from "../Style.js";
import Captcha from "../Captcha/Captcha";

const AskUsPopup = (props) => {
  const navigate = useNavigate(); 
  const { openFlag, setCloseFlag, onSubmit, openSnackBar } = props;
  const [askUsPopupData, setAskUsPopupData] = useState({
    name: "",
    category: "",
    query: "",
    captcha: "",
    is_done: false,
  });

  const askUsModalInputChange = (e) => {
    const modifiedKey = e.target.name;
    const modifiedValue = e.target.value;
    const updatedData = { ...askUsPopupData };
    updatedData[modifiedKey] = modifiedValue;
    setAskUsPopupData(updatedData);
  };

  const askUsModalSubmit = () => {
    onSubmit(askUsPopupData);
    setAskUsPopupData({
      ...askUsPopupData,
      is_done: true,
    });
  };

  return (
    <CSS.CustomizedDialog askUs={ isMobile ? false : true }
      open={openFlag}
      onClose={() => setCloseFlag(false)}
    >
      {!askUsPopupData.is_done ? (
        <Grid
          container
          direction="column"
          gap="1em"
          justifyContent="center"
          alignItems="center"
        >
          <CSS.CloseBtn>
            <IconButton
              aria-label="closePopup"
              onClick={() => setCloseFlag(false)}
            >
              <HighlightOffIcon />
            </IconButton>
          </CSS.CloseBtn>
          <CSS.TitleContainer>
            <span className="title">Ask Us</span>
            <span className="info">All info are mandatory</span>
          </CSS.TitleContainer>
          <TextField
            name={"name"}
            onChange={askUsModalInputChange}
            value={askUsPopupData.name}
            label="Your Name"
            fullWidth={true}
          />
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              variant="outlined"
              name={"category"}
              onChange={askUsModalInputChange}
              value={askUsPopupData.category}
              label="Category"
              fullWidth={true}
            >
              <MenuItem value={1}>Finance</MenuItem>
              <MenuItem value={2}>Registration</MenuItem>
              <MenuItem value={3}>Legal Opinion</MenuItem>
              <MenuItem value={4}>Others</MenuItem>
            </Select>
          </FormControl>
          <TextField
            name={"query"}
            onChange={askUsModalInputChange}
            value={askUsPopupData.description}
            label="Your Doubts"
            multiline
            rows={4}
            fullWidth={true}
          />
          <Captcha
            handleChatClose={setCloseFlag}
            handleSubmitQuery={onSubmit}
            askUsModalInputChange={askUsModalInputChange}
            askUsPopupData={askUsPopupData}
            askUsModalSubmit={askUsModalSubmit}
            openSnackBar={openSnackBar}
          />
        </Grid>
      ) : (
        <>
          <Grid
            container
            direction="column"
            gap="1em"
            justifyContent="center"
            alignItems="center"
          >
            <CSS.CloseBtn>
              <IconButton
                aria-label="closePopup"
                onClick={() => setCloseFlag(false)}
              >
                <HighlightOffIcon />
              </IconButton>
            </CSS.CloseBtn>
            <CSS.TitleContainer>
                <span className='site-visit-success'>Your query has been submitted Successfully</span>
            </CSS.TitleContainer>
            <CSS.submitBtn onClick={() => {
                 navigate('/queries');
              }}
            >
              View Queries
            </CSS.submitBtn>
          </Grid>
        </>
      )}
    </CSS.CustomizedDialog>
  );
};

export default AskUsPopup;
