import React, { useEffect, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
  Polyline,
  Marker,
  DirectionsRenderer,
  InfoWindow,
} from "react-google-maps";
import PlotDetailsToolTip from "../Overlays/PlotDetailsToolTip/PlotDetailsToolTip";
import NearByToolTip from "../Overlays/NearByToolTip/NearByToolTip";

import "../Maps/Map.css";

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const { compose, withState, withHandlers, lifecycle } = require("recompose");

const getCenter = (arr) => {
  var x = arr.map((x) => x["lat"]);
  var y = arr.map((x) => x["lng"]);
  var cx = (Math.min(...x) + Math.max(...x)) / 2;
  var cy = (Math.min(...y) + Math.max(...y)) / 2;
  return { lat: cx, lng: cy };
};

const labelSize = { width: 220 };

const DrawMaps = compose(
  withState("zooms", "onZoomChange", 7),
  withState("lats", "onLatChange", 11.127123),
  withState("lngs", "onLngChange", 78.656891),
  withState("directions", "onDirectionsChange", null),
  withState("showInfo", "setShowInfo", false),
  withState("infoWindow", "setInfoWindow", null),
  withState("directionHoverDetails", "setDirectionHoverDetails", null),
  withHandlers((props) => {
    const refs = {
      map: undefined,
    };
    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
      },
      onZoomChanged: (props) => (e) => {
        props.onZoomChanged(refs.map.getZoom());
      },
      fitBounds: (props) => (e) => {
        const bounds = new window.google.maps.LatLngBounds();
        const locations =
          (props.nearByLocations &&
            JSON.parse(JSON.stringify(props.nearByLocations || []))) ||
          [];
        locations.map((item) => {
          bounds.extend(item.gps);
          return item.id;
        });
        if (locations && locations.length === 1) {
          refs.map &&
            refs.map.panTo(
              new window.google.maps.LatLng(
                locations[0]["gps"]["lat"],
                locations[0]["gps"]["lng"]
              )
            );
        } else {
          refs.map && refs.map.fitBounds(bounds);
        }
      },
      getRupeesFormat:
        () =>
        (amountValue = "") => {
          return (amountValue || "").toLocaleString("en-IN");
        },
      getDirections:
        ({ onDirectionsChange }) =>
        (origin, destination, placeName) => {
          const directionsService = new window.google.maps.DirectionsService();
          if (origin !== null && destination !== null) {
            directionsService.route(
              {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
              },
              (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                  const myRoute = result.routes[0].legs[0];
                  onDirectionsChange(result);
                  if(props.setDirectionDetails) {
                    props.setDirectionDetails({
                      placeName,
                      from: myRoute.start_address,
                      to: myRoute.end_address,
                      distance: myRoute.distance.text,
                      minutes: myRoute.duration.text,
                    });
                    // props.setDirectionHoverDetails && props.setDirectionHoverDetails(null);
                  }
                } else {
                  onDirectionsChange(null);
                  if(props.setDirectionDetails) {
                    props.setDirectionDetails(null);
                    // props.setDirectionHoverDetails && props.setDirectionHoverDetails(null);
                  }
                }
              }
            );
          } else {
            onDirectionsChange(null);
            if(props.setDirectionDetails) {
              props.setDirectionDetails(null);
              // props.setDirectionHoverDetails && props.setDirectionHoverDetails(null);
            }
          }
        },
      onlocationMouseOver: () => (origin, destination, placeName) => {
        const directionsService = new window.google.maps.DirectionsService();
        if (props.directionDetails === null && origin !== null && destination !== null) {
          directionsService.route({
              origin: origin,
              destination: destination,
              travelMode: window.google.maps.TravelMode.DRIVING,
            }, (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                const toAddress = result.routes[0].legs[0].end_address;
                props.setDirectionHoverDetails && props.setDirectionHoverDetails({ 
                  placeName,
                  to: toAddress,
                  destination,
                  zoomLevel: refs.map.getZoom()
                });
              }
            }
          );
        }
      },
      // onlocationMouseOut: () => () => {
      //   if(props.directionDetails === null) {
      //     props.setDirectionHoverDetails && props.setDirectionHoverDetails(null);
      //   }
      // },
      onMapClick:
        ({ onLatChange, onLngChange }) =>
        (e) => {
          onLatChange(e.latLng.lat());
          onLngChange(e.latLng.lng());
        },
      getFillColor: () => (status) => {
        switch (parseInt(status)) {
          case 0:
            return "#26BD26";
          case 1:
            return "#F13030";
          case 2:
            return "#3088F1";
          default:
            return "transparent";
        }
      },
    };
  }),
  lifecycle({
    componentDidMount() {
      this.props.onZoomChange(this.props.zoom);
      this.props.onLatChange(this.props.lat);
      this.props.onLngChange(this.props.lng);
    },
    componentDidUpdate() {
      if (this.props.nearByLocations && this.props.nearByLocations.length > 0) {
        this.props.fitBounds(this.props.nearByLocations);
      }
    }
  }),
  withScriptjs,
  withGoogleMap
)((props) => {

  useEffect(() => {
    if(props.directionHoverDetails && props.directionHoverDetails?.placeName) {
      props.setDirectionHoverDetails(null);
    }
  }, [props.nearByLocations]);

  return <div>
    <GoogleMap
      defaultCenter={props.gps_location}
      center={props.gps_location}
      defaultZoom={props.zoom_level || 20}
      mapTypeId={props.type ? props.type : "satellite"}
      mapTypeControl={false}
      ref={props.onMapMounted}
      onUnmount={() => {
        console.log("onUnmount");
      }}
    >
      {props.plots &&
        props.plots !== null &&
        props.plots.map((path, i) => (
          <Polygon
            key={i}
            paths={path.gps}
            options={{
              fillColor: props.getFillColor(path.plot_status),
              strokeColor: "#000",
              strokeWeight: 1,
            }}
            onClick={(event) => props.onPolygonClick(path)}
            onMouseOver={(e) => {

              props.setShowInfo(true);
              props.setInfoWindow(path);
            }}
            onMouseOut={(e) => {
              props.setShowInfo(false);
              props.setInfoWindow(null);
            }}
          />
        ))}

      {props.show_boundary &&
        props.polyline &&
        props.polyline !== null &&
        props.polyline.map((path, i) => (
          <Polyline
            key={i}
            path={path.gps}
            options={{
              fillColor: props.getFillColor(path.plot_status),
              strokeColor: path.line_color
                ? path.line_color
                : props.boundary_color,
              strokeWeight: 3,
            }}
            onMouseover={(e) => {
              // console.log(path.name);
            }}
            onMouseout={(e) => {
              // console.log(path.name);
            }}
          />
        ))}

      <Marker position={props.gps_location} label="H" />

      {props.nearByLocations &&
        props.nearByLocations !== null &&
        props.nearByLocations.map((el, i) => (
          <Marker
            key={i}
            position={el.gps}
            icon={{
              url: el.icon || null,
              scaledSize: new window.google.maps.Size(30, 30),
            }}
            onClick={() =>
              props.getDirections &&
              props.getDirections(props.gps_location, el.gps, el.placeName)
            }
            onMouseOver={() => {
                props.onlocationMouseOver(props.gps_location, el.gps, el.placeName);
              }
            }
            onMouseOut={() => {
              // props.onlocationMouseOut()
              // props.setDirectionHoverDetails(null)
            }}
          />
        ))}
      
      {props.directions !== null && props.directionDetails != null && (
        <DirectionsRenderer
          directions={props.directions}
          defaultOptions={{
            preserveViewport: true,
            suppressMarkers: true,
            polylineOptions: { strokeColor: "#0000FF" },
          }}
        />
      )}

      {props.showInfo && props.infoWindow && !props.isMobile && (
        <InfoWindow
          position={getCenter(props.infoWindow.gps)}
          style={{ padding: 0 }}
        >
          <PlotDetailsToolTip
            plotNumber={props.infoWindow && props.infoWindow.name}
            plotStatus={
              props.infoWindow && props.infoWindow.plot_status !== ""
                ? props.infoWindow.plot_status === 0
                  ? "Unsold"
                  : props.infoWindow.plot_status === 1
                  ? "Sold"
                  : "Booked"
                : " - "
            }
            plotArea={
              props.infoWindow && props.infoWindow.area !== ""
                ? props.infoWindow.area
                : " - "
            }
          />
        </InfoWindow>
      )}

      {
        props.directionHoverDetails && props.directionHoverDetails?.placeName && (
          <InfoWindow
            position={props.directionHoverDetails?.destination}
            style={{ padding: 0 }}
          >
            <NearByToolTip
              placeName={props.directionHoverDetails?.placeName}
              placeAddress={props.directionHoverDetails?.to}
              setDirectionHoverDetails={props.setDirectionHoverDetails}
            />
          </InfoWindow>
        )
      }
    </GoogleMap>
  </div>
});

export default DrawMaps;
