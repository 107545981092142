import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import WestIcon from "@mui/icons-material/West";
//Other lib imports
//Custom imports

const readOnly = { opacity: '0.7', pointerEvents: 'none' }

const AddPreferenceStepOne = (props) => {
  const navigate = useNavigate();
  const { nextStep, propData, inputChange, setReusableDialogObj, stateDetails, districtDetails, cityDetails, viewOnly } = props;

  const closeReusableDialog = () => {
    setReusableDialogObj({
      open: false
    });
  }

  const onNextBtnClk = () => {
    if(propData.city && propData.district && propData.state) {
      if(propData.pinCode && !(/^[0-9]{6}$/).test(propData.pinCode)) {
        setReusableDialogObj({
          open: true,
          title: '',
          content: 'PIN code is invalid',
          btnArr: [
            {
              label: 'OK',
              fun: closeReusableDialog
            }
          ]
        });
      } else {
        nextStep(2);
      }
    } else {
      setReusableDialogObj({
        open: true,
        title: '',
        content: 'Please enter all mandatory inputs',
        btnArr: [
          {
            label: 'OK',
            fun: closeReusableDialog
          }
        ]
      });
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Your Preferred Plot Location</span>
                <div>
                  <span className="heading-step">(Step 1 of 5)</span>
                </div>
              </div>
              <div style={viewOnly && readOnly}>
                <FormControl fullWidth className='form-control-container'>
                  <InputLabel>State*</InputLabel>
                  <Select
                    label='State*'
                    name='state'
                    value={propData.state}
                    onChange={ e => inputChange(e) }
                  >
                    {
                      stateDetails.map((stateDetail, index) => {
                        return <MenuItem key={index} value={stateDetail.state_id}>{stateDetail.state_title}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
                <FormControl fullWidth className='form-control-container'>
                  <InputLabel>District*</InputLabel>
                  <Select
                    label='District*'
                    name='district'
                    value={propData.district}
                    onChange={ e => inputChange(e) }
                  >
                    {
                      districtDetails.map((districtDetail, index) => {
                        return <MenuItem key={index} value={districtDetail.districtid}>{districtDetail.district_title}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
                <FormControl fullWidth className='form-control-container'>
                  <InputLabel>City*</InputLabel>
                  <Select
                    label='City*'
                    name='city'
                    value={propData.city}
                    onChange={ e => inputChange(e) }
                  >
                    {
                      cityDetails.map((cityDetail, index) => {
                        return <MenuItem key={index} value={cityDetail.id}>{cityDetail.name}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
                <FormControl fullWidth className='form-control-container'>
                  <TextField
                    label='Other City/Area Name'
                    InputLabelProps={{style: {fontSize: '16px'}}}
                    inputProps={{style: {fontSize: '16px'}}}
                    variant="standard"
                    name='otherCity'
                    value={propData.otherCity}
                    onChange={ e => inputChange(e) }
                  />
                </FormControl>
                <FormControl fullWidth className='form-control-container'>
                  <TextField
                    label='PIN Code'
                    InputLabelProps={{style: {fontSize: '16px'}}}
                    inputProps={{style: {fontSize: '16px'}}}
                    variant="standard"
                    name='pinCode'
                    type={'number'}
                    value={propData.pinCode}
                    onChange={ e => inputChange(e) }
                  />
                </FormControl>
                <FormControl fullWidth className='form-control-container'>
                  <InputLabel>Looking within Radius*</InputLabel>
                  <Select
                    label='Looking within Radius*'
                    name='radius'
                    value={propData.radius}
                    onChange={ e => inputChange(e) }
                  >
                    <MenuItem value={'2'}>2 Kms</MenuItem>
                    <MenuItem value={'3'}>3 Kms</MenuItem>
                    <MenuItem value={'4'}>4 Kms</MenuItem>
                    <MenuItem value={'5'}>5 Kms</MenuItem>
                    <MenuItem value={'10'}>10 Kms</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => navigate('/preference')}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPreferenceStepOne;
