import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
//Material UI imports
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
//Custom imports
import * as CSS from "./style";
import AddLayoutStepOne from "./AddLayoutSteps/StepOne";
import AddLayoutStepTwo from "./AddLayoutSteps/StepTwo";
import AddLayoutStepThree from "./AddLayoutSteps/StepThree";
import AddLayoutStepFour from "./AddLayoutSteps/StepFour";
import AddLayoutStepFive from "./AddLayoutSteps/StepFive";
import AddLayoutStepSix from "./AddLayoutSteps/StepSix";
import AddLayoutStepSeven from "./AddLayoutSteps/StepSeven";
import PromoterHeader from "../HeaderMenu/PromoterHeader";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#835400" : "#308fe8",
  },
}));

function AddLayout() {
  const { state } = useLocation();

  const [profileStep, setProfileStep] = useState(1);

  const stepRender = () => {
    // eslint-disable-next-line default-case
    switch (profileStep) {
      case 1:
        return (
          <AddLayoutStepOne
            id={state && state.id}
            isSubmited={state && state.isSubmited}
            nextStep={setProfileStep}
          />
        );
      case 2:
        return (
          <AddLayoutStepTwo id={state && state.id} nextStep={setProfileStep} />
        );
      case 3:
        return (
          <AddLayoutStepThree
            id={state && state.id}
            nextStep={setProfileStep}
          />
        );
      case 4:
        return (
          <AddLayoutStepFour id={state && state.id} nextStep={setProfileStep} />
        );
      case 5:
        return (
          <AddLayoutStepFive id={state && state.id} nextStep={setProfileStep} />
        );
      case 6:
        return (
          <AddLayoutStepSix id={state && state.id} nextStep={setProfileStep} />
        );
      case 7:
        return (
          <AddLayoutStepSeven
            id={state && state.id}
            nextStep={setProfileStep}
          />
        );
    }
  };

  return (
    <CSS.ParentDiv isMobile={isMobile}>
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
      >
        <PromoterHeader />
      </Grid>
      <CSS.GlobalProgressBar>
        <BorderLinearProgress
          variant="determinate"
          value={profileStep * 14.2}
        />
      </CSS.GlobalProgressBar>
      {stepRender()}
    </CSS.ParentDiv>
  );
}

export default AddLayout;
