import { useState, useEffect } from "react";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import WestIcon from "@mui/icons-material/West";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
//Other lib imports
//Custom imports
const readOnly = { opacity: '0.7', pointerEvents: 'none' }

const defaultDirectionArr = [
  {
    id: 1,
    label: "East(E)",
    ticked: false,
  },
  {
    id: 2,
    label: "West(W)",
    ticked: false,
  },
  {
    id: 3,
    label: "North(N)",
    ticked: false,
  },
  {
    id: 4,
    label: "South(S)",
    ticked: false,
  },
  {
    id: 5,
    label: "North-East(NE)",
    ticked: false,
  },
  {
    id: 6,
    label: "North-West(NW)",
    ticked: false,
  },
  {
    id: 7,
    label: "South-East(SE)",
    ticked: false,
  },
  {
    id: 8,
    label: "South-West(SW)",
    ticked: false,
  },
];

const AddPreferenceStepThree = (props) => {

  const { nextStep, propData, inputChange, viewOnly } = props;
  const [directionArr, setDirectionArr] = useState([ ...defaultDirectionArr ]);

  useEffect(() => {
    if(propData.direction.length) {
      const updatedArr = directionArr.map((dirItem) => {
        const updatedItem = { ...dirItem }
        if(propData.direction.includes(dirItem.id)) {
          updatedItem.ticked = true;
        }
        return updatedItem;
      });
      setDirectionArr([ ...updatedArr ]);
    }
  }, []);

  const handleDirChange = (val, itemId) => {
    const updatedArr = directionArr.map((dirItem) => {
      const updatedItem = { ...dirItem }
      if(dirItem.id === itemId) {
        updatedItem.ticked = val;
      }
      return updatedItem;
    });
    setDirectionArr([ ...updatedArr ]);
    //Update global array
    const filteredArr = updatedArr.map(item => item.ticked ? item.id : null)
    const updatedFilteredArr = filteredArr.filter(Number);
    const simulatedEvent = {
      target: {
        name: 'direction',
        value: updatedFilteredArr
      }
    }
    inputChange(simulatedEvent);    
  }

  const onBackBtnClk = () => {
    nextStep(2);
  };

  const onNextBtnClk = () => {
    nextStep(4);
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading"> Plot Expectation (Optional) </span>
                <div>
                  <span className="heading-step">(Step 3 of 5)</span>
                </div>
              </div>
              <div style={viewOnly && readOnly}>
                <FormControl component="fieldset">
                <FormLabel component="legend" className="pref-label-18">Direction Facing</FormLabel>
                {
                  directionArr.map((dirItem) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={dirItem.ticked}
                            onChange={(e) => handleDirChange(e.target.checked, dirItem.id)}
                            sx={{
                              color: "#643f00",
                              "&.Mui-checked": { color: "#643f00" },
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2" color="textSecondary" className="checkbox-label" >
                            {dirItem.label}
                          </Typography>
                        }
                      />
                    )
                  })
                }
              </FormControl>
              </div>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPreferenceStepThree;
