import { GET_ALL_AMENITIES } from "./actionType";

export const initialState = {
  amenities: [],
};

const commonReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_ALL_AMENITIES: {
      const newState = {
        ...state,
        amenities: data,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default commonReducer;
