import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

//Other lib imports
import axios from "axios";

//Custom imports
import GoogleLoginPopup from "../Overlays/GoogleLoginPopup/GoogleLoginPopup";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";

import { baseUrl } from "../../util/config";

import "./Login.css";

function PromoterLogin() {
  const navigate = useNavigate();
  const [openGoogleLoginPopup, setOpenGoogleLoginPopup] = useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    otp: "",
    type: "V",
    isForgot: false,
  });
  const [loginUserDetails, setLoginUserDetails] = useState(null);
  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const loginInputChange = (e) => {
    const modifiedKey = e.target.name;
    const modifiedValue = e.target.value;
    const updatedData = { ...loginData };
    updatedData[modifiedKey] = modifiedValue;
    setLoginData(updatedData);
  };

  useEffect(() => {
    localStorage.removeItem("promotor_user");
  }, []);

  useEffect(() => {
    if (
      loginUserDetails &&
      loginUserDetails.is_approved === 1 &&
      loginUserDetails.isOtpSent &&
      loginUserDetails.login_type === "F"
    ) {
      setLoginData({
        ...loginData,
        type: "F",
      });
    } else if (
      loginUserDetails &&
      loginUserDetails.is_approved === 1 &&
      loginUserDetails.login_type === "N"
    ) {
      setLoginData({
        ...loginData,
        type: "N",
      });
    }
  }, [loginUserDetails]);

  const onForgotPwdBtnClk = () => {
    alert("onForgotPwdBtnClk");
  };

  const onLoginBtnClk = () => {
    if (loginData.type === "V") {
      if (!loginData.email) {
        openSnackBar("Enter your email / mobile no.", "error");
        return;
      }
      validateUser();
    } else if (loginData.type === "F") {
      if (!loginData.email) {
        openSnackBar("Enter your email / mobile no.", "error");
        return;
      } else if (!loginData.password) {
        openSnackBar("Please enter the password.", "error");
        return;
      } else if (!loginData.otp) {
        openSnackBar("Please enter the otp.", "error");
        return;
      }
      firstTimeUser();
    } else if (loginData.type === "N") {
      if (!loginData.email) {
        openSnackBar("Enter your email / mobile no.", "error");
        return;
      } else if (!loginData.password) {
        openSnackBar("Please enter the password.", "error");
        return;
      }
      validatePassword();
    }
  };

  const validatePassword = () => {
    const payload = {
      ...loginData,
      user_id: loginUserDetails && loginUserDetails.id,
    };
    axios
      .post(
        `${baseUrl}/plot_api/realestate/user/password`,
        payload
      )
      .then(
        (res) => {
          if (res.data.status) {
            localStorage.setItem(
              "promotor_user",
              JSON.stringify(loginUserDetails)
            );
            navigate("/promoterWelcome");
          } else {
            openSnackBar("Enter valid username and password.", "error");
          }
        },
        (err) => {
          // setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  const firstTimeUser = () => {
    const payload = { ...loginData };
    axios
      .post(`${baseUrl}/plot_api/realestate/user/update`, payload)
      .then(
        (res) => {
          if (res.data.status) {
            localStorage.setItem(
              "promotor_user",
              JSON.stringify(loginUserDetails)
            );
            navigate("/promoterWelcome");
          } else {
            openSnackBar("Enter valid OTP.", "error");
          }
        },
        (err) => {
          // setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  const validateUser = () => {
    const payload = { ...loginData };
    axios
      .post(
        `${baseUrl}/plot_api/realestate/user/validate`,
        payload
      )
      .then(
        (res) => {
          if (res.data.status) {
            setLoginUserDetails(res.data.user);
          } else {
            openSnackBar("Invalid User.", "error");
          }
        },
        (err) => {
          // setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  const onRegisterBtnClk = () => {
    navigate("/promoterRegistration");
  };

  const loadSetPassword = () => {
    return (
      <>
        <div className="login-form-div">
          <TextField
            name={"password"}
            label="Set Password"
            type="password"
            className="login-inputWidth"
            variant="outlined"
            onChange={loginInputChange}
            value={loginData.password}
          />
        </div>
        <div className="login-form-div">
          <TextField
            name={"otp"}
            label="OTP"
            className="login-inputWidth"
            variant="outlined"
            onChange={loginInputChange}
            value={loginData.otp}
          />
        </div>
        <div className="login-form-div">
          <p style={{ textAlignLast: "center" }}>
            <small style={{ color: "red" }}>
              {loginData.isForgot
                ? `Please set the password and enter OTP send in
                mobile.`
                : `First time login, Please set the password and enter OTP send in
                mobile.`}
            </small>
          </p>
        </div>
      </>
    );
  };

  const loadPassword = () => {
    return (
      <>
        <div className="login-form-div">
          <TextField
            name={"password"}
            label="Password"
            type="password"
            className="login-inputWidth"
            variant="outlined"
            onChange={loginInputChange}
            value={loginData.password}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <GoogleLoginPopup
        openFlag={openGoogleLoginPopup}
        setCloseFlag={setOpenGoogleLoginPopup}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">
                  {loginData && !loginData.isForgot
                    ? "Sign in"
                    : "Forgot Password"}
                </span>
              </div>
              <div className="login-form-div">
                <TextField
                  name={"email"}
                  label="Enter your email / mobile no"
                  variant="outlined"
                  className="login-inputWidth"
                  onChange={loginInputChange}
                  value={loginData.email}
                />
              </div>

              {(loginData.type === "F" || loginData.isForgot) &&
                loginUserDetails &&
                loginUserDetails.is_approved === 1 &&
                loadSetPassword()}
              {loginData.type === "N" &&
                loginUserDetails &&
                loginUserDetails.is_approved === 1 &&
                !loginData.isForgot &&
                loadPassword()}

              {loginUserDetails && loginUserDetails.is_approved === 0 && (
                <div className="login-form-div">
                  <p style={{ textAlignLast: "center" }}>
                    <small style={{ color: "red" }}>
                      Provided Email / Mobile doesn't approved, Please contact
                      administrator.
                    </small>
                  </p>
                </div>
              )}
              {loginData && !loginData.isForgot && (
                <div>
                  <span
                    onClick={() => {
                      setLoginData({
                        ...loginData,
                        isForgot: true,
                      });
                    }}
                    className="forgot-span"
                  >
                    Forgot Password
                  </span>
                </div>
              )}
              {loginData && loginData.isForgot && (
                <div>
                  <span
                    onClick={() => {
                      setLoginData({
                        ...loginData,
                        isForgot: false,
                      });
                    }}
                    className="forgot-span"
                  >
                    Back to Sign in
                  </span>
                </div>
              )}
              <div className="login-form-div">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onLoginBtnClk}
                  className="login-inputWidth login-button"
                  endIcon={<EastIcon />}
                >
                  Sign in
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default PromoterLogin;
