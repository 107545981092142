/* eslint-disable react/jsx-pascal-case */
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
//Other lib imports
//Custom imports
import OtpLoginPopup from "../Overlays/OtpLoginPopup/OtpLoginPopup";
import logo from "../../Assets/logo_with_text.png";
import * as CSS from "./Style.js";

const PropertyHeader = (props) => {
  const { showAddProperty } = props;
  const navigate = useNavigate();

  const [loginState, setLoginState] = useState({
    isOtp: false,
  });
  const [openOTPLoginPopup, setOpenOTPLoginPopup] = useState(false);

  useEffect(() => {
    const isOtpLogin = localStorage.getItem("mIsVerified") || false;
    setLoginState({
      ...loginState,
      isOtp: isOtpLogin,
    });
  }, []);

  const setIsVerified = () => {
    setLoginState({
      ...loginState,
      isOtp: true,
    });
    setOpenOTPLoginPopup(false);
    navigate(`/addProperty`,{state:{id: null}});
  };

  return (
    <>
      <CSS.logoContainer
        src={logo}
        alt="#"
        onClick={() =>
          navigate("/", {
            state: {},
          })
        }
      />
      <OtpLoginPopup
        openFlag={openOTPLoginPopup}
        setCloseFlag={setOpenOTPLoginPopup}
        setIsVerified={setIsVerified}
        layout_name={""}
        promoters_name={""}
      />
      {showAddProperty && (
        <CSS.submitPropContainer>
          <Button
            variant="outlined"
            className="btn-class"
            onClick={() =>
              loginState.isOtp
                ? navigate(`/addProperty`,{state:{id: null}})
                : setOpenOTPLoginPopup(true)
            }
          >
            Submit Your Property
          </Button>
        </CSS.submitPropContainer>
      )}
      <CSS.BtnContainer>
        <IconButton className={"iconBtns"}>
          <HomeIcon
            onClick={() =>
              navigate("/", {
                state: {},
              })
            }
          />
        </IconButton>
      </CSS.BtnContainer>
    </>
  );
};

export default PropertyHeader;
