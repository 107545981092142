import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
//Material UI imports
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
//Other lib imports
import axios from "axios";
//Custom imports
import * as CSS from "./Style";
import AddPropertyStepOne from "./AddPropertySteps/StepOne";
import AddPropertyStepTwo from "./AddPropertySteps/StepTwo";
import AddPropertyStepThree from "./AddPropertySteps/StepThree";
import AddPropertyStepFour from "./AddPropertySteps/StepFour";
import AddPropertyStepFive from "./AddPropertySteps/StepFive";
import AddPropertyStepSix from "./AddPropertySteps/StepSix";
import AddPropertyStepSeven from "./AddPropertySteps/StepSeven";
import AddPropertyStepEight from "./AddPropertySteps/StepEight";
import AddPropertyStepNine from "./AddPropertySteps/StepNine";
import AddPropertyStepTen from "./AddPropertySteps/StepTen";
import AddPropertyStepEleven from "./AddPropertySteps/StepEleven";
import PropertyHeader from "../HeaderMenu/PropertyHeader";
import { baseUrl } from "../../util/config";
import ReusableDialog from "../Overlays/ReusableDialog/ReusableDialog";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#835400" : "#308fe8",
  },
}));

const AddProperty = () => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [reusableDialogObj, setReusableDialogObj] = useState({ open: false });
  let user_id = localStorage.getItem("mUserId");
  if(user_id) {
    user_id = parseInt(user_id);
  }

  let cornerPlot = 1;
  if(state?.hasOwnProperty('fullData') && state?.fullData?.hasOwnProperty('cornerPlot')) {
    cornerPlot = state?.fullData?.cornerPlot;
  }

  const defaultState = {
    name: state?.fullData?.name || '', 
    sellerType: state?.fullData?.sellerType || 'Owner',
    saleType: state?.fullData?.saleType || 'sale',
    propertyType: state?.fullData?.propertyType || 'Residential Plot',
    location: state?.fullData?.location || '',
    address: state?.fullData?.address || '',
    otherCity: state?.fullData?.otherCity || '',
    city: state?.fullData?.city || '',
    district: state?.fullData?.district || '',
    state: state?.fullData?.state || '',
    pinCode: state?.fullData?.pinCode || '',
    reraNo: state?.fullData?.reraNo || '',
    approvalNo: state?.fullData?.approvalNo || '',
    layoutName: state?.fullData?.layoutName || '',
    plotNo: state?.fullData?.plotNo || '',
    plotArea: state?.fullData?.plotArea || '',
    plotSides: state?.fullData?.plotSides || '',
    plotDirection: state?.fullData?.plotDirection || 'E',
    cornerPlot,
    plotRdWidth: state?.fullData?.plotRdWidth || '',
    market_value: state?.fullData?.market_value || '',
    expectedPrice: state?.fullData?.expectedPrice || '',
    id: state?.fullData?.id || null,
    user_id
  }
  const [profileStep, setProfileStep] = useState(1);
  const [propData, setPropData] = useState(defaultState);
  const [stateDetails, setStateDetails] = useState([]);
  const [districtDetails, setDistrictDetails] = useState([]);
  const [cityDetails, setCityDetails] = useState([]);

  useEffect(() => {
    getStateDetails();
  }, []);

  const getStateDetails = () => {
    const payload = {};
    setIsLoading(true);
    axios.post(`${baseUrl}/plot_api/common/state`, payload).then(
      (res) => {
        if (res.data && res.data.state) {
          setStateDetails(res.data.state || []);
          setIsLoading(false);
          console.log('propData coming..')
          console.log(propData)
          if(propData.district) {
            setIsLoading(true);
            getDistrictDetails(propData.state);
          }
        }
      },
      (err) => {
        setStateDetails([]);
      }
    );
  };

  const getDistrictDetails = (state_id = '') => {
    const payload = {
      state_id,
    };
    axios
      .post(`${baseUrl}/plot_api/common/district`, payload)
      .then(
        (res) => {
          if (res.data && res.data.district) {
            setDistrictDetails(res.data.district || []);
            if(propData.city && propData.state && propData.district) {
              getCityDetails(propData.state, propData.district);
            }
          }
        },
        (err) => {
          setDistrictDetails([]);
        }
      );
  };

  const getCityDetails = (state_id = '', districtid = '') => {
    const payload = {
      state_id,
      districtid
    };
    axios
      .post(`${baseUrl}/plot_api/common/city`, payload)
      .then(
        (res) => {
          if (res.data && res.data.district) {
            setCityDetails(res.data.district || []);
            setIsLoading(false);
          }
        },
        (err) => {
          setCityDetails([]);
        }
      );
  };

  const inputChange = e => {
    if(e.target.name === 'state') {
      setPropData({ ...propData, [`${e.target.name}`]: e.target.value, district: '', city: '' });
      getDistrictDetails(e.target.value);
    } else if(e.target.name === 'district') {
      setPropData({ ...propData, [`${e.target.name}`]: e.target.value, city: '' });
      getCityDetails(propData.state, e.target.value);
    } else {
      setPropData({ ...propData, [`${e.target.name}`]: e.target.value });
    }
  }

  const centCalculator = () => {
    const calculatedVal = parseInt(propData.plotArea) * 0.0023;
    if(calculatedVal) {
      return calculatedVal.toFixed(4);
    }
    return 0;
  }

  const stepRender = () => {
    // eslint-disable-next-line default-case
    switch (profileStep) {
      case 1:
        return (
          <AddPropertyStepOne nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} />
        );
      case 2:
        return (
          <AddPropertyStepTwo nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} />
        );
      case 3:
        return (
          <AddPropertyStepThree nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} />
        );
      case 4:
        return (
          <AddPropertyStepFour nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} />
        );
      case 5:
        return (
          <AddPropertyStepFive nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} stateDetails={stateDetails} districtDetails={districtDetails} cityDetails={cityDetails} />
        );
      case 6:
        return (
          <AddPropertyStepSix nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} />
        );
      case 7:
        return (
          <AddPropertyStepSeven nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} />
        );
      case 8:
        return (
          <AddPropertyStepEight nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} />
        );
      case 9:
        return (
          <AddPropertyStepNine nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} centCalculator={centCalculator} />
        );
      case 10:
        return (
          <AddPropertyStepTen nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} />
        );
      case 11:
        return (
          <AddPropertyStepEleven nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} centCalculator={centCalculator} setPropData={setPropData} />
        );
    }
  };

  return (
    <CSS.ParentDiv isMobile={isMobile}>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <ReusableDialog reusableDialogObj={reusableDialogObj} setReusableDialogObj={setReusableDialogObj} />
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
      >
        <PropertyHeader />
      </Grid>
      <CSS.GlobalProgressBar>
        <BorderLinearProgress
          variant="determinate"
          value={profileStep * 9.09}
        />
      </CSS.GlobalProgressBar>
      {stepRender()}
    </CSS.ParentDiv>
  );
};

export default AddProperty;
