import styled from "styled-components";

const CompanyDesc = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${props => props.isMobile ? '13px' : '20px'};
  line-height: ${props => props.isMobile ? '20px' : '30px'};
  letter-spacing: -0.25px;
  color: #000000;
  padding: ${props => props.isMobile ? "10px 25px 0px 25px" : "10px"};
  text-align: justify;
`;

const TimeLineIconContr = styled.div`
  color: #4b4640;
  svg {
    font-size: ${props => props.isMobile ? "30px" : "40px"};
  }
`;

const CarouselWrapperDiv = styled.div`
  .carousel-slider {
    width: ${props => props.isMobile ? "100%" : "80%"} !important; 
    left: ${props => props.isMobile ? "1%" : "10%"} !important;
    border-radius: 25px;
  }
`;

const PromoterName = styled.div`
  font-family: "Roboto Serif";
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.isMobile ? "30px" : "50px")};
  line-height: ${(props) => (props.isMobile ? "35px" : "60px")};
  text-align: center;
  letter-spacing: -0.25px;
  background: linear-gradient(92.69deg, #5c6d4a 1.33%, #253514 98.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const PromoterAddress = styled.div`
  font-family: "Roboto Serif";
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => (props.isMobile ? "15px" : "24px")};
  line-height: ${(props) => (props.isMobile ? "19px" : "30px")};
  text-align: center;
  letter-spacing: -0.25px;
  background: linear-gradient(92.69deg, black 1.33%, black 98.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const PromoterAdditionalDetails = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  gap: ${(props) => (props.isMobile ? "5px" : "10px")};
`;

const PromoterGridTitles = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => (props.isMobile ? "25px" : "32px")};
  line-height: ${(props) => (props.isMobile ? "25px" : "38px")};
  letter-spacing: -0.25px;
  font-variant: small-caps;
  color: #4b4640;
  text-align: center;
  padding-top: ${(props) => (props.fromDivider ? "10px" : "0px")};
`;

const PromoterSelectTitles = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  letter-spacing: 0.25px;
  font-variant: small-caps;
  color: #4b4640;
  text-align: center;
  padding-top: ${(props) => (props.fromDivider ? "12px" : "5px")};
  padding-right: 12px;
`;

const PromoterDividerLable = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: -0.25px;
  font-variant: small-caps;
  color: #4b4640;
  text-align: center;
  padding-top: 5px;
  padding-right: 5px;
`;

const PromoterDirCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;

  .pdp-contact-avatar {
    width: ${(props) => (props.isMobile ? "75px" : "100px")};
    height: ${(props) => (props.isMobile ? "75px" : "100px")};
  }

  .pdp-contact-card-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: ${(props) => (props.isMobile ? "15px" : "20px")};
    line-height: ${(props) => (props.isMobile ? "15px" : "20px")};
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;
    color: #1f1b16;
  }

  .pdp-contact-card-nme {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;
    color: #1f1b16;
  }

  .pdp-feedback-txt {
    border: dashed 1px #57432b;
    width: 75%;
    border-radius: 5px;
    padding: 10px;
  }

  .pdp-contact-card-contacts {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: ${(props) => (props.isMobile ? "12px" : "15px")};
    line-height: ${(props) => (props.isMobile ? "12px" : "24px")};
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;
    color: #1f1b16;
    gap: 5px;
  }
`;

const ReviewsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .carousel-root {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel.carousel-slider .control-arrow {
    background-color: #4b4640;
    width: 10%;
  }
`;

export {
  CompanyDesc,
  TimeLineIconContr,
  CarouselWrapperDiv,
  PromoterName,
  PromoterAddress,
  PromoterAdditionalDetails,
  PromoterGridTitles,
  PromoterDividerLable,
  PromoterDirCardContainer,
  ReviewsContainer,
  PromoterSelectTitles,
};
