import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
//Material UI imports
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
//Other lib imports
import axios from "axios";
//Custom imports
import * as CSS from "./Style";
import AddPropertyOptionalStepOne from "./AddPropertyOptionalSteps/StepOne";
import AddPropertyOptionalStepTwo from "./AddPropertyOptionalSteps/StepTwo";
import PropertyHeader from "../HeaderMenu/PropertyHeader";
import { baseUrl } from "../../util/config";
import ReusableDialog from "../Overlays/ReusableDialog/ReusableDialog";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#835400" : "#308fe8",
  },
}));

const AddPropertyOptional = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const id = state?.id;
  let isEdit = false;
  let initlayoutImgArr = [];
  let initlayoutPhArr = [];
  if(state?.hasOwnProperty('fullData')) {
    isEdit = true;
  }
  if(state?.fullData?.layout_image) {
    initlayoutImgArr.push(state?.fullData?.layout_image);
  }
  if(state?.fullData?.galleryDetails && state?.fullData?.galleryDetails.length) {
    const layoutPhotosArr = state?.fullData?.galleryDetails.map((ph) => ph.url);
    initlayoutPhArr = [...layoutPhotosArr];
  }
  const [isLoading, setIsLoading] = useState(false);
  const [reusableDialogObj, setReusableDialogObj] = useState({ open: false });
  const [profileStep, setProfileStep] = useState(1);
  const [layoutDiagram, setLayoutDiagram] = useState(initlayoutImgArr);
  const [layoutPhotos, setLayoutPhotos] = useState(initlayoutPhArr);
  
  useEffect(() => {
    if(!id) {
      openPopup('Property not found. Please try again.')
    }
  }, []);

  const closeReusableDialog = () => {
    setReusableDialogObj({
      open: false
    });
    navigate('/property');
  }

  const openPopup = (msg) => {
    setReusableDialogObj({
      open: true,
      title: '',
      content: msg,
      btnArr: [
        {
          label: 'OK',
          fun: closeReusableDialog
        }
      ]
    });
  }

  const onOptionalSubmitBtnClk = () => {
    layoutDiagramSave();
  }

  const layoutDiagramSave = () => {
    setIsLoading(true);
    if(layoutDiagram.length === 0) {
      layoutPhotosSave();
      return;
    }
    let layout_image = layoutDiagram[0];
    const payload = { layout_image, id }
    axios.post(`${baseUrl}/plot_api/realestate_v1/property/addPropertyLayoutImage`, payload).then(
      (res) => {
        if(res?.data?.id && res?.data?.status) {
          layoutPhotosSave();
        } else {
          openPopup("something went wrong.. Please try again later..");
        }
      },
      (err) => {
        console.log('err came..');
        console.log(err);
        openPopup("something went wrong.. Please try again later..")
      }
    );
  };

  const layoutPhotosSave = () => {
    if(layoutPhotos.length === 0) {
      setIsLoading(false);
      openPopup('Your Property has been submitted successfully');
    }
    const payload = { gallery_url: layoutPhotos, id };
    axios.post(`${baseUrl}/plot_api/realestate_v1/property/addGallery`, payload).then(
      (res) => {
        if(res?.data?.status) {
          setIsLoading(false);
          openPopup('Your Property has been submitted successfully');
        } else {
          openPopup("something went wrong.. Please try again later..");
        }
      },
      (err) => {
        console.log('err came..');
        console.log(err);
        openPopup("something went wrong.. Please try again later..")
      }
    );
  }

  const stepRender = () => {
    // eslint-disable-next-line default-case
    switch (profileStep) {
      case 1:
        return (
          <AddPropertyOptionalStepOne nextStep={setProfileStep} id={id} setReusableDialogObj={setReusableDialogObj} layoutPhotos={layoutPhotos} setLayoutPhotos={setLayoutPhotos} />
        );
      case 2:
        return (
          <AddPropertyOptionalStepTwo nextStep={setProfileStep} id={id} setReusableDialogObj={setReusableDialogObj} layoutDiagram={layoutDiagram} setLayoutDiagram={setLayoutDiagram} onOptionalSubmitBtnClk={onOptionalSubmitBtnClk} isEdit={isEdit} />
        );
    }
  };

  return (
    <CSS.ParentDiv isMobile={isMobile}>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <ReusableDialog reusableDialogObj={reusableDialogObj} setReusableDialogObj={setReusableDialogObj} />
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
      >
        <PropertyHeader />
      </Grid>
      <CSS.GlobalProgressBar>
        <BorderLinearProgress
          variant="determinate"
          value={profileStep * 50}
        />
      </CSS.GlobalProgressBar>
      {stepRender()}
    </CSS.ParentDiv>
  );
};

export default AddPropertyOptional;
