import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import WestIcon from "@mui/icons-material/West";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SaveIcon from "@mui/icons-material/Save";
import ReorderIcon from "@mui/icons-material/Reorder";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";

//Custom imports
import * as XLSX from "xlsx";
import * as CSS from "../style";
import { useSnackbar } from "../../../hooks/useSnackbar";
import Snackbar from "../../snackbar";
import XHR from "../../../util/pxhr";

const PromoterProfileStepFour = (props) => {
  const navigate = useNavigate();
  const { nextStep, id = null } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [plots, setPlots] = useState([]);
  const [updateMoreData, setUpdateMoreData] = useState(null);
  const [data, setData] = useState({
    general_information: {
      project_status: "",
      price_range_from: "",
      price_range_to: "",
      project_size: "",
      started_year: "",
      rate_per_sq_feet_from: "",
      rate_per_sq_feet_to: "",
      description: "",
      layout: "",
    },
    amenities: [],
    salient: [],
    bank_details: [],
    gallery: [],
    about_company: {
      address: "",
      no_of_projects: 0,
      logo: "",
      description: "",
      dtcp_number: "",
      rera_no: "",
    },
    contact: [],
  });

  const handleMoreDataChange = (key, value) => {
    setUpdateMoreData({
      ...updateMoreData,
      [key]: value,
    });
  };

  const onBackBtnClk = () => {
    nextStep(5);
  };

  const onNextBtnClk = () => {
    nextStep(7);
  };

  useEffect(() => {
    loadData(id);
  }, [id]);

  const loadData = (refid) => {
    getPlotDetails(refid);
    getAddedPlots(refid);
  };

  const getPlotDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/get", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.data) {
          setData(res.data.data);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getAddedPlots = (id) => {
    setIsLoading(true);
    const payload = { project_id: id };
    XHR.post("realestate/plots/getplots", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.plots) {
          setPlots(res.data.plots);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
    updatePlotsRows();
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `http://myplot.store/Sample_Plots.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getDirectionId = (text) => {
    switch (text) {
      case "N":
        return 3;
      case "S":
        return 4;
      case "E":
        return 1;
      case "W":
        return 2;
      case "NE":
        return 5;
      case "NW":
        return 6;
      case "SE":
        return 7;
      case "SW":
        return 8;
      default:
        return 1;
    }
  };

  const getPlotStatusId = (text) => {
    switch (text) {
      case "A":
        return 0;
      case "S":
        return 1;
      case "B":
        return 2;
      default:
        return "";
    }
  };

  const updatePlotsRows = () => {
    const plotsArr = (JSON.parse(JSON.stringify(plots)) || []).slice(
      0,
      data.total_plots
    );
    if (data && data.total_plots > 0) {
      for (let i = plotsArr.length; i < data.total_plots; i++) {
        plotsArr.push({
          sno: i + 1,
          name: `Plot ${i + 1}`,
          area: "",
          per_s_feet: "",
          plot_status: "",
          cost: "",
          direction: "",
          is_corner: "",
        });
      }
      setPlots(plotsArr);
    } else {
      setPlots([]);
    }
  };

  const onExcelChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const excelData = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });

      let plotsArr = JSON.parse(JSON.stringify(plots));
      for (let i = 1; i < excelData.length; i++) {
        if (excelData[i][0]) {
          plotsArr.push({
            sno: i + 1,
            name: excelData[i][0],
            area: excelData[i][1],
            per_s_feet: excelData[i][2],
            cost:
              excelData[i][2] !== "" ? excelData[i][1] * excelData[i][2] : 0,
            is_corner: excelData[i][3],
            direction: getDirectionId(excelData[i][4]),
            plot_status: getPlotStatusId(excelData[i][5]),
            no_of_sides: excelData[i][6],
            side1: excelData[i][7],
            side2: excelData[i][8],
            side3: excelData[i][9],
            side4: excelData[i][10],
            side5: excelData[i][11],
            side6: excelData[i][12],
            side7: excelData[i][13],
            side8: excelData[i][14],
            side9: excelData[i][15],
            side10: excelData[i][16],
            front_road: excelData[i][17],
            front_road_feet: excelData[i][18],
            back_road: excelData[i][19],
            back_road_feet: excelData[i][20],
            left_road: excelData[i][21],
            left_road_feet: excelData[i][22],
            right_road: excelData[i][23],
            right_road_feet: excelData[i][24],
          });
        }
      }
      setPlots(plotsArr);
    };
    reader.readAsBinaryString(file);
  };

  const handlePlotsChange = (key, index, value) => {
    const plotsArr = JSON.parse(JSON.stringify(plots));
    plotsArr[index][key] = value;
    if (key === "area" || key === "per_s_feet") {
      const area = plotsArr[index]["area"] || 0;
      const perPrice = plotsArr[index]["per_s_feet"] || 0;
      plotsArr[index]["cost"] = Math.round(area * perPrice);
    }
    console.log("plotsArr", plotsArr);
    setPlots(plotsArr);
  };

  const handleSavePlot = (row, i) => {
    if (row.id) {
      updateUploadedPlot(row, i);
    } else {
      addUploadedPlot(row, i);
    }
  };

  const handleRemovePlots = (index, el) => {
    let plotsCopy = JSON.parse(JSON.stringify(plots));
    if (index > -1) {
      plotsCopy.splice(index, 1);
    }
    setPlots(plotsCopy);
    deleteUploadedPlot(el);
  };

  const updatePlotsId = (index, id) => {
    const plotsArr = JSON.parse(JSON.stringify(plots));
    plotsArr[index]["id"] = id;
    setPlots(plotsArr);
  };

  const addUploadedPlot = (addData, i) => {
    setIsLoading(true);
    const payload = { ...addData, project_id: id };
    XHR.post("realestate/plots/adduploaded", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.id) {
          updatePlotsId(i, res.data.id);
          openSnackBar("Added", "success");
          setOpenMoreInfo(false);
        } else {
          alert("Something went wrong");
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const updateUploadedPlot = (addData, i) => {
    setIsLoading(true);
    const payload = { ...addData, project_id: id };
    XHR.post("realestate/plots/updateuploaded", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.id) {
          openSnackBar("Updated", "success");
          setOpenMoreInfo(false);
        } else {
          alert("Something went wrong");
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const deleteUploadedPlot = (el) => {
    setIsLoading(true);
    if (!el.id) {
      setIsLoading(false);
      return;
    }
    const payload = { id: el.id };
    XHR.post("realestate/plots/deleteuploaded", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.id) {
          openSnackBar("Deleted", "success");
        } else {
          alert("Something went wrong");
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleUpdateMore = () => {
    handleSavePlot(updateMoreData, updateMoreData.index);
    const plotsArr = JSON.parse(JSON.stringify(plots));
    plotsArr[updateMoreData.index] = updateMoreData;
    setPlots(plotsArr);
  };

  const getRupeesFormat = (amountValue = "") => {
    return (amountValue || "").toLocaleString("en-IN");
  };

  const renderAddPlots = () => {
    return (
      <Grid
        container
        spacing={2}
        item
        xs={12}
        style={{ paddingTop: 40, maxWidth: "98%" }}
      >
        <Grid item container xs={12}>
          <Grid item xs={2} style={{ display: "flex" }}>
            <span style={{ marginRight: 10, width: 50 }}>S.No</span>
            <span style={{ marginRight: 10 }}>Name</span>
          </Grid>
          <Grid item xs={2}>
            <span style={{ marginRight: 10 }}>Total Area (in sfeet)</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Per Sq Feet</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Total Cost</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Is Corner Plot?</span>
          </Grid>
          <Grid item xs={2}>
            <span style={{ marginRight: 10 }}>Plot Status</span>
          </Grid>
          <Grid item xs={2}>
            <span style={{ marginRight: 10 }}>Direction Facing</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Action</span>
          </Grid>
        </Grid>
        {plots &&
          plots.map((el, i) => (
            <Grid item container xs={12} style={{ marginTop: 20 }}>
              <Grid item xs={2} style={{ paddingRight: 20, display: "flex" }}>
                <span
                  style={{ marginRight: 10, width: 55, alignSelf: "center" }}
                >
                  {i + 1}
                </span>
                <TextField
                  name="name"
                  value={el.name || ""}
                  onChange={(event) =>
                    handlePlotsChange("name", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: 20 }}>
                <TextField
                  name="area"
                  value={Math.round(el.area) || ""}
                  onChange={(event) =>
                    handlePlotsChange("area", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <TextField
                  name="per_s_feet"
                  value={Math.round(el.per_s_feet) || ""}
                  onChange={(event) =>
                    handlePlotsChange("per_s_feet", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <span style={{ marginRight: 10, width: 100 }}>
                  ₹{getRupeesFormat(el.cost || 0)}
                </span>
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <Select
                  labelId="demo-simple-select-label"
                  fullWidth
                  onChange={(event) =>
                    handlePlotsChange("is_corner", i, event.target.value)
                  }
                  value={el.is_corner || ""}
                >
                  <MenuItem value={"yes"}>Yes</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={2} style={{ paddingRight: 20 }}>
                <Select
                  labelId="demo-simple-select-label"
                  fullWidth
                  onChange={(event) =>
                    handlePlotsChange("plot_status", i, event.target.value)
                  }
                  value={el.plot_status}
                >
                  <MenuItem value={1}>Sold</MenuItem>
                  <MenuItem value={0}>Unsold</MenuItem>
                  <MenuItem value={2}>Booked</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={2} style={{ paddingRight: 20 }}>
                <Select
                  labelId="demo-simple-select-label"
                  value={parseInt(el.direction) || ""}
                  fullWidth
                  onChange={(event) =>
                    handlePlotsChange("direction", i, event.target.value)
                  }
                >
                  <MenuItem value={1}>East</MenuItem>
                  <MenuItem value={2}>West</MenuItem>
                  <MenuItem value={3}>North</MenuItem>
                  <MenuItem value={4}>South</MenuItem>
                  <MenuItem value={5}>North East</MenuItem>
                  <MenuItem value={6}>North West</MenuItem>
                  <MenuItem value={7}>South East</MenuItem>
                  <MenuItem value={8}>South West</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <SaveIcon
                  title={"Save"}
                  onClick={() => handleSavePlot(el, i)}
                  style={{
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                    cursor: "pointer",
                    color: el.id ? "red" : "",
                  }}
                />
                {el.id && (
                  <ReorderIcon
                    title={"Update More"}
                    onClick={() => {
                      setUpdateMoreData({
                        ...el,
                        index: i,
                      });
                      setOpenMoreInfo(true);
                    }}
                    style={{
                      fontSize: 20,
                      marginRight: 10,
                      marginTop: 5,
                      cursor: "pointer",
                      color: el.id ? "red" : "",
                    }}
                  />
                )}

                <span
                  onClick={() => handleRemovePlots(i, el)}
                  style={{ cursor: "pointer" }}
                >
                  X
                </span>
              </Grid>
            </Grid>
          ))}
      </Grid>
    );
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Dialog
        open={openMoreInfo}
        size="md"
        fullWidth
        onClose={() => {
          setUpdateMoreData(null);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update More info</DialogTitle>
        <DialogContent>
          <Grid spacing={1} container xs={12}>
            <Grid item xs={12} style={{}}>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#0000FF",
                  fontSize: 18,
                }}
              >
                NO OF SIDES
              </span>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                value={(updateMoreData && updateMoreData.no_of_sides) || ""}
                margin="dense"
                id="no_of_sides"
                label="Number of Sides"
                type="no_of_sides"
                fullWidth
                onChange={(e) =>
                  handleMoreDataChange("no_of_sides", e.target.value)
                }
              />
            </Grid>
            {updateMoreData && updateMoreData.no_of_sides > 0 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side1) || ""}
                  margin="dense"
                  id="direction"
                  label="Side A"
                  type="side1"
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange("side1", e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 1 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side2) || ""}
                  margin="dense"
                  id="direction"
                  label="Side B"
                  type="side2"
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange("side2", e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 2 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side3) || ""}
                  margin="dense"
                  id="direction"
                  label="Side C"
                  type="side3"
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange("side3", e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 3 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side4) || ""}
                  margin="dense"
                  id="direction"
                  label="Side D"
                  type="side4"
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange("side4", e.target.value)
                  }
                />
              </Grid>
            )}

            {updateMoreData && updateMoreData.no_of_sides > 4 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side5) || ""}
                  margin="dense"
                  id="direction"
                  label="Side E"
                  type="side5"
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange("side5", e.target.value)
                  }
                />
              </Grid>
            )}

            {updateMoreData && updateMoreData.no_of_sides > 5 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side6) || ""}
                  margin="dense"
                  id="direction"
                  label="Side F"
                  type="side6"
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange("side6", e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 6 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side7) || ""}
                  margin="dense"
                  id="direction"
                  label="Side G"
                  type="side7"
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange("side7", e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 7 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side8) || ""}
                  margin="dense"
                  id="direction"
                  label="Side H"
                  type="side8"
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange("side8", e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 8 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side9) || ""}
                  margin="dense"
                  id="direction"
                  label="Side I"
                  type="side9"
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange("side9", e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 9 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side10) || ""}
                  margin="dense"
                  id="direction"
                  label="Side J"
                  type="side10"
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange("side10", e.target.value)
                  }
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                borderTop: "1px solid #c1c1c1",
                paddingTop: 10,
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: "#0000FF",
                  fontSize: 18,
                }}
              >
                ROAD
              </span>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl component="fieldset" style={{ marginTop: 10 }}>
                  <FormLabel component="legend">Road in Front</FormLabel>
                  <RadioGroup
                    row
                    aria-label="front_road"
                    name="front_road"
                    value={
                      updateMoreData && updateMoreData.front_road
                        ? updateMoreData.front_road
                        : "no"
                    }
                    onChange={(e) =>
                      handleMoreDataChange("front_road", e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {updateMoreData && updateMoreData.front_road === "yes" && (
                <Grid item xs={6}>
                  <TextField
                    value={
                      (updateMoreData && updateMoreData.front_road_feet) || ""
                    }
                    margin="dense"
                    id="direction"
                    label="Front Road Length (In Feet)"
                    type="front_road_feet"
                    fullWidth
                    onChange={(e) =>
                      handleMoreDataChange("front_road_feet", e.target.value)
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl component="fieldset" style={{ marginTop: 10 }}>
                  <FormLabel component="legend">Road in Back</FormLabel>
                  <RadioGroup
                    row
                    aria-label="back_road"
                    name="back_road"
                    value={
                      updateMoreData && updateMoreData.back_road
                        ? updateMoreData.back_road
                        : "no"
                    }
                    onChange={(e) =>
                      handleMoreDataChange("back_road", e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {updateMoreData && updateMoreData.back_road === "yes" && (
                <Grid item xs={6}>
                  <TextField
                    value={
                      (updateMoreData && updateMoreData.back_road_feet) || ""
                    }
                    margin="dense"
                    id="direction"
                    label="Back Road Length (In Feet)"
                    type="back_road_feet"
                    fullWidth
                    onChange={(e) =>
                      handleMoreDataChange("back_road_feet", e.target.value)
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl component="fieldset" style={{ marginTop: 10 }}>
                  <FormLabel component="legend">Road in Left</FormLabel>
                  <RadioGroup
                    row
                    aria-label="left_road"
                    name="left_road"
                    value={
                      updateMoreData && updateMoreData.left_road
                        ? updateMoreData.left_road
                        : "no"
                    }
                    onChange={(e) =>
                      handleMoreDataChange("left_road", e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {updateMoreData && updateMoreData.left_road === "yes" && (
                <Grid item xs={6}>
                  <TextField
                    value={
                      (updateMoreData && updateMoreData.left_road_feet) || ""
                    }
                    margin="dense"
                    id="direction"
                    label="Left Road Length (In Feet)"
                    type="left_road_feet"
                    fullWidth
                    onChange={(e) =>
                      handleMoreDataChange("left_road_feet", e.target.value)
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl component="fieldset" style={{ marginTop: 10 }}>
                  <FormLabel component="legend">Road in Right</FormLabel>
                  <RadioGroup
                    row
                    aria-label="right_road"
                    name="right_road"
                    value={
                      updateMoreData && updateMoreData.right_road
                        ? updateMoreData.right_road
                        : "no"
                    }
                    onChange={(e) =>
                      handleMoreDataChange("right_road", e.target.value)
                    }
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {updateMoreData && updateMoreData.right_road === "yes" && (
                <Grid item xs={6}>
                  <TextField
                    value={
                      (updateMoreData && updateMoreData.right_road_feet) || ""
                    }
                    margin="dense"
                    id="direction"
                    label="Right Road Length (In Feet)"
                    type="right_road_feet"
                    fullWidth
                    onChange={(e) =>
                      handleMoreDataChange("right_road_feet", e.target.value)
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                borderTop: "1px solid #c1c1c1",
                paddingTop: 10,
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: "#0000FF",
                  fontSize: 18,
                }}
              >
                PLOT STATUS
              </span>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Plot Status</InputLabel>
              <Select
                style={{ width: "100%" }}
                value={(updateMoreData && updateMoreData.plot_status) || 0}
                onChange={(e) =>
                  handleMoreDataChange("plot_status", e.target.value)
                }
              >
                <MenuItem value={1}>Sold</MenuItem>
                <MenuItem value={0}>Unsold</MenuItem>
                <MenuItem value={2}>Booked</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMoreInfo(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleUpdateMore()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">
                  Upload Excel - Plot Details
                  <div>
                    <span className="login-form-heading-step">(Step 6 of 7)</span>
                  </div>
                </span>
              </div>
              <div className="login-form-div" style={{ marginTop: "6%" }}>
                <label htmlFor="excel-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    className="login-button"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Excel
                  </Button>
                </label>
                <input
                  accept="xlsx/*"
                  style={{ display: "none" }}
                  id="excel-button-file"
                  onChange={onExcelChange}
                  type="file"
                />
                {/* <IconButton
                  size="medium"
                  className="login-button view-uploaded-logo"
                >
                  <RemoveRedEyeIcon fontSize="inherit" />
                </IconButton> */}
              </div>
              <div className="profile-back-next-btn-cntr">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onBackBtnClk}
                  className="login-button"
                  startIcon={<WestIcon />}
                >
                  Back
                </Button>
                {/* <Button
                  variant="outlined"
                  size="medium"
                  className="login-button"
                  endIcon={<CloudDownloadIcon />}
                  onClick={() => handleDownload()}
                >
                  Download Sample Excel
                </Button> */}
                <a
                  href="#"
                  style={{marginTop: '1%'}}
                  // variant="outlined"
                  // size="medium"
                  // className="login-button"
                  // endIcon={<CloudDownloadIcon />}
                  onClick={() => handleDownload()}
                >
                  Download Sample Excel
                </a>
                
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onNextBtnClk}
                  className="login-button"
                  endIcon={<EastIcon />}
                >
                  Next
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
        {plots && plots.length > 0 && renderAddPlots()}
      </Grid>
    </>
  );
};

export default PromoterProfileStepFour;
