import styled from 'styled-components';
import { isMobile } from "react-device-detect";

const popupWrapper = styled.div`
    margin: 5px 15px;
`;

const headerWrapper = styled.div`
    background-color: #653f00;
    color: #feecd9;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const cardsWrapper = styled.div`
    border-radius: 7px;
    background-color: gray;
    background: rgba(128, 128, 128, 0.3);
    max-height: 455px;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 280px;
    overflow: hidden;
    overflow-y: scroll;
`;

const cardWrapper = styled.div`
    padding: 5px;
    background: ${ps => ((ps?.el?.gps_lat === ps?.iwt?.lat && ps?.el?.gps_long === ps?.iwt?.lng) ? '#ffeedd' : '#ffff')};
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    &:hover {
        background: ${ps => ((ps?.el?.gps_lat === ps?.iwt?.lat && ps?.el?.gps_long === ps?.iwt?.lng) ? '#ffeedd' : '#ededed')};
    }
`;

const cardLeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 15%;
`;

const snoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #ffd896;
    height: 25px;
    width: 25px;
    font-size: 12px;
    font-weight: bold;
`;

const cardRightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 85%;
`;

const cardPrice = styled.span`
    font-weight: 700;
    font-size: 15px;
    color: #645d57;
`;

const cardLayoutName = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #645d57;
`;

const cardAddress = styled.span`
    font-size: 12px;
    font-weight: 400;
    color: #645d57;

    &.lineBreak {
        padding-top: 6px;
    }
`;

export { 
    popupWrapper,
    headerWrapper,
    cardsWrapper,
    cardWrapper,
    cardLeftWrapper,
    cardRightWrapper,
    snoWrapper,
    cardPrice,
    cardLayoutName,
    cardAddress
}