import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

//Other lib imports
import { isMobile } from "react-device-detect";
import axios from "axios";

//Custom imports
import DataGrid from "../DataGrid";
import Dialog from "../Dialog";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import PlotDetailsPopup from "../Overlays/PlotDetailsPopup/PlotDetailsPopup";
import PLPHeader from "../HeaderMenu/PLPHeader";

import { baseUrl } from "../../util/config";

//Styling imports
import "../PDP/Pdp.css";

const Favorites = (props) => {
  const navigate = useNavigate();

  const [viewType, setViewType] = useState("layout");
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dialogShow, setDialogShow] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [prows, setPRows] = useState([]);
  const [ptotalRecords, setPTotalRecords] = useState(0);
  const [toolTipContent, setToolTipContent] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  // Table Properties

  const columns = [
    { name: "sno", title: "S.No" },
    { name: "company_promotors", title: "Promoter Name" },
    { name: "name", title: "Layout Name" },
    { name: "city", title: "Location" },
    { name: "available_plots", title: "Available Plots" },
    { name: "action", title: "Action" },
  ];

  const tableColumnExtensions = [
    { columnName: "sno", width: 100, wordWrapEnabled: true },
    { columnName: "company_promotors", wordWrapEnabled: true },
    { columnName: "name", wordWrapEnabled: true },
    { columnName: "city", wordWrapEnabled: true },
    { columnName: "available_plots", wordWrapEnabled: true },
    { columnName: "action", align: "center" },
  ];

  const defaultHiddenColumnNames = [];

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "company_promotors", filteringEnabled: false },
    { columnName: "available_plots", filteringEnabled: false },
    { columnName: "action", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "company_promotors", sortingEnabled: false },
    { columnName: "available_plots", sortingEnabled: false },
    { columnName: "action", sortingEnabled: false },
  ];

  const loadGrid = (inputs) => {
    const token = localStorage.getItem("user") || null;
    let userDetails;
    if (token) {
      userDetails = JSON.parse(token);
    }
    inputs["user"] =
      userDetails && userDetails.googleId ? userDetails.googleId : "";
    getTablePromoters(inputs);
  };

  const getTablePromoters = (inputs) => {
    const payload = inputs;
    axios.post(`${baseUrl}/plot_api/realestate/api/promotorfav`, payload).then(
      (res) => {
        if (res.data.status) {
          setRows((res.data && res.data.data.rows) || []);
          setTotalRecords((res.data && res.data.data.total) || 0);
          closeDialog();
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const p_columns = [
    { name: "sno", title: "S.No" },
    { name: "company_promotors", title: "Promoter Name" },
    { name: "name", title: "Layout Name" },
    { name: "city", title: "Location" },
    { name: "plot_no", title: "Plot No" },
    { name: "plot_status_disp", title: "Plot Status" },
    { name: "action", title: "Action" },
  ];

  const p_tableColumnExtensions = [
    { columnName: "sno", width: 100, wordWrapEnabled: true },
    { columnName: "company_promotors", wordWrapEnabled: true },
    { columnName: "name", wordWrapEnabled: true },
    { columnName: "city", wordWrapEnabled: true },
    { columnName: "plot_no", wordWrapEnabled: true },
    { columnName: "plot_status_disp", wordWrapEnabled: true },
    { columnName: "action", align: "center" },
  ];

  const p_defaultHiddenColumnNames = [];

  const p_filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "company_promotors", filteringEnabled: false },
    { columnName: "action", filteringEnabled: false },
  ];

  const p_sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "company_promotors", sortingEnabled: false },
    { columnName: "action", sortingEnabled: false },
  ];

  const p_loadGrid = (inputs) => {
    const token = localStorage.getItem("user") || null;
    let userDetails;
    if (token) {
      userDetails = JSON.parse(token);
    }
    inputs["user"] =
      userDetails && userDetails.googleId ? userDetails.googleId : "";
    getTablePlots(inputs);
  };

  const getTablePlots = (inputs) => {
    const payload = inputs;
    axios.post(`${baseUrl}/plot_api/realestate/api/plotfav`, payload).then(
      (res) => {
        if (res.data.status) {
          setPRows((res.data && res.data.data.rows) || []);
          setPTotalRecords((res.data && res.data.data.total) || 0);
          closeDialog();
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const actionClick = (type, data) => {
    if (type === "VIEW") {
      openPDP(
        data.company_district,
        data.company_state,
        data.company_promotors,
        data.hash_key,
        data.p_id,
        data.name
      );
    } else if (type === "MAPS") {
      let gps = JSON.parse(data.gps_location);
      if (gps.lat) {
        var url = "https://maps.google.com/?q=" + gps.lat + "," + gps.lng;
        window.open(url, "_blank");
      }
    } else if (type === "DELETE") {
      favouriteConfirm(data.p_id, 1);
    }
  };

  const p_actionClick = (type, data) => {
    if (type === "VIEW") {
      data.name = data.plot_no;
      setToolTipContent((data && data) || []);
      setOpen(true);
    } else if (type === "MAPS") {
      let gps = JSON.parse(data.gps_location);
      if (gps.lat) {
        var url = "https://maps.google.com/?q=" + gps.lat + "," + gps.lng;
        window.open(url, "_blank");
      }
    } else if (type === "DELETE") {
      favouriteConfirm(data.source_id, 2);
    } else if (type === "GOTO") {
      let seo_details = data.seo ? data.seo : {};
      openPDP(
        (seo_details && seo_details.state) || "",
        (seo_details && seo_details.district) || "",
        (seo_details && seo_details.taluk) || "",
        (seo_details && seo_details.area1) || "",
        (seo_details && seo_details.area2) || "",
        data.company_promotors,
        data.hash_key,
        data.id,
        data.name,
        data
      );
    }
  };

  const openPDP = (
    state,
    district,
    taluk,
    area1,
    area2,
    promotors,
    company_hash,
    company_userid,
    name,
    data
  ) => {
    let path = "/pdp";

    if (state) {
      path = `${path}/${state.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (district) {
      path = `${path}/${district.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (taluk) {
      path = `${path}/${taluk.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (area1) {
      path = `${path}/${area1.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (area2) {
      path = `${path}/${area2.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (promotors) {
      path = `${path}/${promotors.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (name) {
      path = `${path}/${name.replace(/\s+/g, "").toLowerCase()}`;
    }

    path = `${path}/${company_hash}`;

    if (company_hash != "") {
      // Call PDP

      navigate(path, {
        state: { id: company_userid, isfav: false },
      });
    }
  };

  const favouriteConfirm = (favId, type) => {
    setDialogShow(true);
    setDialogProps({
      title: `Remove from favourites?`,
      message: "",
      onClose: (yes) => {
        closeDialog(true);
      },
      button: [
        {
          title: "No",
          click: () => closeDialog(true),
        },
        {
          title: "Yes",
          click: () => updateFav(favId, type),
        },
      ],
    });
  };

  const closeDialog = () => {
    setDialogShow(false);
    setTimeout(() => {
      setDialogProps({});
    }, 100);
  };

  const updateFav = (id, type) => {
    const token = localStorage.getItem("user") || null;
    if (token) {
      const userDetails = JSON.parse(token);

      const payload = {
        user: (userDetails && userDetails.googleId) || "",
        project: id,
        source_id: id,
        is_fav: 0,
        type: type,
      };
      axios.post(`${baseUrl}/plot_api/realestate/api/fav`, payload).then(
        (res) => {
          if (res.data && res.data.status) {
            // handleChatClose();
            openSnackBar("Success", "success");
            type === 1 ? loadGrid({}) : p_loadGrid({});
          }
        },
        (err) => {
          openSnackBar("Failed to submit", "success");
          console.log("err", err);
        }
      );
    }
  };

  return (
    <>
      <Dialog open={dialogShow} {...dialogProps} />
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <PlotDetailsPopup
        popupOpen={open}
        toolTipContent={toolTipContent}
        setOpen={setOpen}
        openSnackBar={openSnackBar}
        showPrice={toolTipContent && toolTipContent.showPrice}
        isEmiAvailable={toolTipContent && toolTipContent.emi_available == "yes"}
      />
      <Grid
        item
        lg={10}
        md={10}
        sm={11}
        xs={11}
        style={{ textAlign: "center" }}
      >
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="2em 0 2em 0;"
        >
          <PLPHeader />
        </Grid>
      </Grid>
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        padding="2em"
      >
        <span className="pdp-lo-dtls-txt">your favorite plots and layouts</span>
        <ButtonGroup
          variant="contained"
          orientation={isMobile ? "vertical" : "horizontal"}
        >
          <Button
            className={`pdp-lo-button-grp ${
              viewType === "layout" ? "active-tab" : ""
            }`}
            onClick={() => setViewType("layout")}
          >
            Layouts
          </Button>
          <Button
            className={`pdp-lo-button-grp ${
              viewType === "plots" ? "active-tab" : ""
            }`}
            onClick={() => setViewType("plots")}
          >
            Plots
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid
        container
        gap="1em"
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="column"
        padding="2em"
      >
        {viewType === "layout" ? (
          <Grid item xs={12} md={12} lg={12} style={{ padding: 20 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              defaultHiddenColumnNames={defaultHiddenColumnNames}
              filteringStateColumnExtensions={filteringStateColumnExtensions}
              sortingStateColumnExtensions={sortingStateColumnExtensions}
              loadGrid={loadGrid}
              totalRecords={totalRecords}
              action={["VIEW", "DELETE", "MAPS"]}
              actionClick={actionClick}
            />
          </Grid>
        ) : null}

        {viewType === "plots" ? (
          <Grid item xs={12} md={12} lg={12} style={{ padding: 20 }}>
            <DataGrid
              rows={prows}
              columns={p_columns}
              tableColumnExtensions={p_tableColumnExtensions}
              defaultHiddenColumnNames={p_defaultHiddenColumnNames}
              filteringStateColumnExtensions={p_filteringStateColumnExtensions}
              sortingStateColumnExtensions={p_sortingStateColumnExtensions}
              loadGrid={p_loadGrid}
              totalRecords={ptotalRecords}
              action={["VIEW", "DELETE", "MAPS", "GOTO"]}
              actionClick={p_actionClick}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default Favorites;
