//Material UI imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
//Custom imports
import * as CSS from "../Style.js";
import MobileVerify from "../../Login/MobileVerify";

const OtpLoginPopup = (props) => {
  const {
    openFlag,
    setCloseFlag,
    setIsVerified,
    isDialog = true,
    layout_name,
    promoters_name
  } = props;

  return (
    <>
      {isDialog ? (
        <CSS.CustomizedDialog
          open={openFlag}
          onClose={() => setCloseFlag(false)}
          maxWidth={"lg"}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="row"
            margin="2em 0 2em 0;"
          >
            <CSS.CloseBtn>
              <IconButton
                aria-label="closePopup"
                onClick={() => setCloseFlag(false)}
              >
                <HighlightOffIcon />
              </IconButton>
            </CSS.CloseBtn>
            <MobileVerify
              setIsVerified={setIsVerified}
              layout_name={layout_name}
              promoters_name={promoters_name}
            />
          </Grid>
        </CSS.CustomizedDialog>
      ) : (
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="2em 0 2em 0;"
        >
          <MobileVerify
            setIsVerified={setIsVerified}
            layout_name={layout_name}
            promoters_name={promoters_name}
          />
        </Grid>
      )}
    </>
  );
};

export default OtpLoginPopup;
