import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import NavigationIcon from "@mui/icons-material/Navigation";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import ButtonGroup from "@mui/material/ButtonGroup";
import Avatar from "@mui/material/Avatar";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
//Other lib imports
import { map } from "lodash";
import { isMobile as uiIsMobile } from "react-device-detect";
import { Carousel } from "react-responsive-carousel";
import { getTouchDevice } from "../../util/config";
import axios from "axios";
//Custom imports
import LoadingScreen from "../Default/LoadingScreen";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import {
  TlStart,
  TlMiddle,
  TlEnd,
  TlContent,
} from "./TimeLineDivider/TimeLineDivider";
import Filter from "../PLP/Filter/Filter";
import Map from "../Maps/Map";
import DrawFabric from "../DrawFabric";
import NearBy from "../Maps/NearBy";
import StreetView from "../Maps/StreetView";
import PdpTable from "../Maps/Table";
import AskUsPopup from "../Overlays/AskUsPopup/AskUsPopup";
import SiteVisitPopup from "../Overlays/SiteVisitPopup/SiteVisitPopup";
import ContactPopup from "../Overlays/ContactPopup/ContactPopup";
import GoogleLoginPopup from "../Overlays/GoogleLoginPopup/GoogleLoginPopup";
import OtpLoginPopup from "../Overlays/OtpLoginPopup/OtpLoginPopup";
import LogoutHooks from "../Login/LogoutHooks";
import Dialog from "../Dialog";
import PDPHeader from "../HeaderMenu/PDPHeader";
//Styling imports
import * as CSS from "./Style.js";
import * as OverlaysCSS from "../Overlays/Style.js";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Pdp.css";
import partialImg from "../../Assets/partial_onboarding.png";
import API_KEY from "../../util/config";

import { baseUrl } from "../../util/config";

let publicUrl = process.env.PUBLIC_URL + "/";

const Pdp = () => {
  let { hash, isMobile } = useParams();
  const scrollRef = useRef([]);

  const [slides, setSlides] = useState([]);
  const [videoSlides, setVideoSlides] = useState([]);
  const [product, setProduct] = useState(null);
  const [plotStatus, setPlotStatus] = useState(-1);
  const [userDetails, setUserDetails] = useState(null);
  const [openAskUsPopup, setOpenAskUsPopup] = useState(false);
  const [openSiteVisitPopup, setOpenSiteVisitPopup] = useState(false);
  const [isSiteVisitDone, setIsSiteVisitDone] = useState({
    status: "",
    data: {},
  });
  const [compReadMore, setCompReadMore] = useState(false);
  const [footerDescReadMore, setFooterDescReadMore] = useState(false);
  const [openContactPopup, setOpenContactPopup] = useState(false);
  const [openGoogleLoginPopup, setOpenGoogleLoginPopup] = useState(false);
  const [openOTPLoginPopup, setOpenOTPLoginPopup] = useState(false);
  const [dialogShow, setDialogShow] = useState(false);
  const [nearbyReadyCheck, setNearbyReadyCheck] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [id, setId] = useState(hash && hash !== "" ? hash : 0);
  const [faq, setFaq] = useState([]);
  const [qa, setQA] = useState([]);

  const [viewType, setViewType] = useState("map");
  const [streetViewUrl, setStreetViewUrl] = useState("");

  const isMobileView = getTouchDevice();

  const [loginState, setLoginState] = useState({
    isLogin: false,
    isOtp: false,
  });
  const [layoutFilter, setLayoutFilter] = useState({
    availability: "ANY",
    corner: "ANY",
    direction: "ANY",
  });
  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();
  const promotors_general_information =
    product &&
    product.promotorsDetails &&
    product.promotorsDetails.general_information
      ? product.promotorsDetails.general_information
      : {};

  const setIsVerified = () => {
    setLoginState({
      ...loginState,
      isOtp: true,
    });
    setOpenOTPLoginPopup(false);
  };

  // console.log("googleApiKey", googleApiKey);
  // console.log("hash", hash);

  useEffect(() => {
    isNearbyReady();
  });

  useEffect(() => {
    const token = localStorage.getItem("authToken") || null;
    const isOtpLogin = localStorage.getItem("mIsVerified") || false;

    if (token) {
      setLoginState({
        ...loginState,
        isLogin: true,
        isOtp: isOtpLogin,
      });
    } else {
      setLoginState({
        ...loginState,
        isOtp: isOtpLogin,
      });
    }
    const user = localStorage.getItem("user") || null;
    if (user) {
      const userData = JSON.parse(user);
      setUserDetails(userData);
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getProject(id);
  }, []);

  const insertGoogleHistory = () => {
    const payload = {
      project_id: product && product.id,
      google_id: (userDetails && userDetails.googleId) || "",
    };
    axios.post(`${baseUrl}/plot_api/analytics/googlehistory`, payload).then(
      (res) => {},
      (err) => {
        console.log("err", err);
      }
    );
  };

  useEffect(() => {
    let slideImage = [];
    let slideVideos = [];
    if (product && product.gallery.length) {
      product.gallery.forEach((ele, index) => {
        if (ele.type === "image") {
          slideImage.push({
            name: "Image_" + index,
            imgTag: <img src={ele.url} alt={index} />,
          });
        }
        if (ele.type === "youtube") {
          const vdId = ele.url.split("/").pop();
          slideVideos.push(vdId);
        }
      });
    }
    slideImage.length > 0 && setSlides(slideImage);
    slideVideos.length > 0 && setVideoSlides(slideVideos);

    if (product && product.id !== "") {
      setViewType(
        product && product.is_map_view_enable === 1
          ? "map"
          : product && product.is_image_view_enable === 1
          ? "fabric"
          : product && product.is_table_view_enable === 1
          ? "table"
          : ""
      );

      getAllFaq(product.id);

      const token = localStorage.getItem("authToken") || null;
      if (token) {
        insertGoogleHistory();
      }

      if (product && product.street_view_url != "") {
        setStreetViewUrl(product.street_view_url);
      }
    }
  }, [product]);

  // console.log("view Type", viewType);

  const getProject = (id) => {
    // setIsLoading(true);
    const payload = { id };
    axios.post(`${baseUrl}/plot_api/realestate/get`, payload).then(
      (res) => {
        // setIsLoading(false);
        // console.log("res.data.data", res.data.data);
        if (res.data && res.data.data) {
          // setData(res.data.data);
          setProduct(res.data.data);
        }
      },
      (err) => {
        // setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const onAskUsSubmit = (data) => {
    console.log(data);
    const payload = {
      user_id: (userDetails && userDetails.googleId) || "",
      m_user_id: localStorage.getItem("mUserId") || 0,
      project_id: product.id,
      ...data,
    };
    axios.post(`${baseUrl}/plot_api/common/query/add`, payload).then(
      (res) => {
        if (res.data && res.data.status) {
          updateHistory("A");
        }
      },
      (err) => {
        openSnackBar("Failed to submit", "success");
        console.log("err", err);
      }
    );
  };

  const onSiteVisitSubmit = (data) => {
    if (data.name !== "" && data.date !== "") {
      const payload = {
        project_id: product && product.id,
        ...data,
        visitors_id: localStorage.getItem("mUserId") || 0,
        user_id: (userDetails && userDetails.googleId) || "",
      };
      axios
        .post(`${baseUrl}/plot_api/realestate/visitors/site/add`, payload)
        .then(
          (res) => {
            console.log("res", res);
            if (res && res.data && res.data.status) {
              updateHistory("S");
              setIsSiteVisitDone({
                ...isSiteVisitDone,
                status: "S",
              });
            } else {
              setIsSiteVisitDone({
                ...isSiteVisitDone,
                status: "F",
                data: res.data.data,
              });
            }
          },
          (err) => {
            setIsSiteVisitDone({
              ...isSiteVisitDone,
              status: "F",
              data: {},
            });
            console.log("err", err);
          }
        );
    } else {
      openSnackBar("Please fill all the data", "error");
    }
  };

  const getAllFaq = (id) => {
    const payload = { project_id: id };
    axios.post(`${baseUrl}/plot_api/realestate/api/faq/get`, payload).then(
      (res) => {
        setFaq((res.data && res.data.list) || []);
      },
      (err) => {
        console.log("err", err);
      }
    );

    axios.post(`${baseUrl}/plot_api/realestate/api/qa/get`, payload).then(
      (res) => {
        setQA((res.data && res.data.list) || []);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const updateHistory = (type) => {
    const payload = {
      project_id: product && product.id,
      type: type,
      google_id: (userDetails && userDetails.googleId) || "",
      visitors_id: localStorage.getItem("mUserId") || 0,
    };
    axios.post(`${baseUrl}/plot_api/analytics/viewhistory`, payload).then(
      (res) => {},
      (err) => {
        console.log("err", err);
      }
    );

    // Update the site Visit Leads
    handleLeads();
  };

  const handleLeads = () => {
    const payload = {
      project_hash: hash,
      mUserId: localStorage.getItem("mUserId") || 0,
      mpdp_hash: hash,
      type: "S",
    };
    axios.post(`${baseUrl}/plot_api/analytics/leads`, payload).then(
      (res) => {
        if (res.data && res.data.status) {
        } else {
          openSnackBar("Not valid URL", "error");
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const handleLocationClick = (gps) => {
    if (gps.lat) {
      var url = "https://maps.google.com/?q=" + gps.lat + "," + gps.lng;
      window.open(url, "_blank");
    }
  };

  const getPromotorAddress = (promotersDetails) => {
    let add = "";
    if (promotersDetails && promotersDetails.door_no) {
      add = `${promotersDetails.door_no}`;
    }
    if (promotersDetails && promotersDetails.street) {
      add = `${add}, ${promotersDetails.street}`;
    }
    if (promotersDetails && promotersDetails.address1) {
      add = `${add}, ${promotersDetails.address1}`;
    }
    if (promotersDetails && promotersDetails.address2) {
      add = `${add}, ${promotersDetails.address2}`;
    }
    if (promotersDetails && promotersDetails.town) {
      add = `${add}, ${promotersDetails.town}`;
    }
    if (promotersDetails && promotersDetails.city) {
      add = `${add}, ${promotersDetails.city}`;
    }
    if (promotersDetails && promotersDetails.pincode) {
      add = `${add}, ${promotersDetails.pincode}`;
    }
    return add;
  };

  const filterOnChange = (filterData) => {
    // console.log("$$$$$$$$Test", filterData);
    let newFilter = {};
    filterData.map((ele) => {
      if (ele.displayVal !== "ANY") {
        // console.log("ele", ele);
        if (
          ele.key === "direction" ||
          ele.key === "corner" ||
          ele.key === "availability"
        ) {
          let dataId = [];
          ele.val.filter((data) => {
            if (data.ticked) dataId.push(data.id);
          });
          newFilter[ele.key] = dataId;
        }
      } else {
        newFilter[ele.key] = "ANY";
      }
    });
    // console.log("$$$$$$$$Test", newFilter);
    setLayoutFilter({
      ...layoutFilter,
      ...newFilter,
    });
  };

  const goToTab = (index) => {
    console.log("index", index);
    const next = scrollRef.current[index];
    if (next) {
      next.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const getLayoutImage = () => {
    if (
      product &&
      product.general_information &&
      product.general_information.new_logo
    ) {
      return product.general_information.new_logo;
    } else if (
      promotors_general_information &&
      promotors_general_information.logo
    ) {
      return promotors_general_information.logo;
    }
    return "";
  };

  const getPromotorsImage = () => {
    if (promotors_general_information && promotors_general_information.logo) {
      return promotors_general_information.logo;
    }
    return "";
  };

  const getAddress = () => {
    let add = "";
    if (product && product.street) {
      add = `${product.street}`;
    }

    if (product && product.add1) {
      add = `${add}, ${product.add1}`;
    }

    if (product && product.add2) {
      add = `${add}, ${product.add2}`;
    }

    if (product && product.town) {
      add = `${add}, ${product.town}`;
    }

    if (product && product.city) {
      add = `${add}, ${product.city}`;
    }

    if (product && product.taluk) {
      add = `${add}, ${product.taluk}`;
    }

    if (product && product.district) {
      add = `${add}, ${product.district}`;
    }

    if (product && product.state) {
      add = `${add}, ${product.state}`;
    }

    if (product && product.zipcode) {
      add = `${add}, ${product.zipcode}`;
    }

    if (product && product.country) {
      add = `${add}, ${product.country}`;
    }

    if (product && product.landmark) {
      add = `${add}, ${product.landmark}`;
    }
    return add;
  };

  const isNearbyReady = () => {
    if (window?.google?.maps) {
      setNearbyReadyCheck(true);
    } else {
      console.log(
        "window?.google?.maps is still not ready.. so waiting for 500 millisec.."
      );
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`;
      script.async = true;
      document.body.appendChild(script);
      setTimeout(() => {
        isNearbyReady();
      }, 1000);
    }
  };

  let emi_details = product ? product.emi : {};

  // console.log("openSiteVisitPopup && isVerified", (openSiteVisitPopup && isVerified));

  return (
    <>
      {Object.keys(promotors_general_information).length === 0 && (
        <LoadingScreen />
      )}
      {id === 0 ? (
        <div className="error" style={{ paddingBottom: 150 }}>
          <div className="container">
            <div className="content centered">
              <img
                style={{ width: 500 }}
                alt="#"
                src={publicUrl + "assets/img/bad_request.png"}
              />
              <h1>Oops, looks like the invalid URL.</h1>
              <p
                style={{ fontSize: 22 }}
                className="sub-header text-block-narrow"
              >
                Please contact administrator
              </p>
            </div>
          </div>
        </div>
      ) : isMobile === "view" && loginState && !loginState.isOtp ? (
        <OtpLoginPopup
          openFlag={openOTPLoginPopup}
          setCloseFlag={setOpenOTPLoginPopup}
          setIsVerified={setIsVerified}
          isDialog={false}
          layout_name={product && product.name}
          promoters_name={
            promotors_general_information &&
            promotors_general_information.company_name.toUpperCase()
          }
        />
      ) : (
        product && (
          <>
            <Snackbar
              severity={severity}
              open={isActive}
              message={message}
              onClose={setIsActive}
            />
            <Dialog open={dialogShow} {...dialogProps} />
            <AskUsPopup
              openFlag={openAskUsPopup}
              setCloseFlag={setOpenAskUsPopup}
              onSubmit={onAskUsSubmit}
              openSnackBar={openSnackBar}
            />
            <SiteVisitPopup
              openFlag={openSiteVisitPopup && loginState.isOtp}
              setCloseFlag={setOpenSiteVisitPopup}
              onSubmit={onSiteVisitSubmit}
              isSiteVisitDone={isSiteVisitDone}
              setIsSiteVisitDone={setIsSiteVisitDone}
            />
            <ContactPopup
              openFlag={openContactPopup && loginState.isOtp}
              setCloseFlag={setOpenContactPopup}
              contact={product && product.contact}
            />
            <GoogleLoginPopup
              openFlag={openGoogleLoginPopup}
              setCloseFlag={setOpenGoogleLoginPopup}
            />
            <OtpLoginPopup
              openFlag={openOTPLoginPopup}
              setCloseFlag={setOpenOTPLoginPopup}
              setIsVerified={setIsVerified}
              layout_name={product && product.name}
              promoters_name={
                promotors_general_information &&
                promotors_general_information.company_name.toUpperCase()
              }
            />
            <CSS.ParentDiv isMobile={uiIsMobile || isMobileView}>
              <Grid
                container
                gap="1em"
                justifyContent="center"
                alignItems="center"
                direction="column"
                margin="2em 0 2em 0;"
              >
                <div className="scroll-top-container">
                  <IconButton
                    size="large"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                </div>
                <PDPHeader
                  openLogin={setOpenGoogleLoginPopup}
                  goToTab={goToTab}
                  headerDetails={[
                    {
                      name: "Property",
                      id: 0,
                      visible: true,
                    },
                    {
                      name: "Gallery",
                      id: 1,
                      visible:
                        product &&
                        product.gallery &&
                        product.gallery.length > 0,
                    },
                    {
                      name: "General Info",
                      id: 2,
                      visible: true,
                    },
                    {
                      name: "Layout",
                      id: 3,
                      visible: true,
                    },
                    {
                      name: "Nearby Places",
                      id: 4,
                      visible: true,
                    },
                    {
                      name: "Amenities",
                      id: 5,
                      visible:
                        (product &&
                          product.amenities_list &&
                          product.amenities_list.length > 0) ||
                        (product &&
                          product.salient_list &&
                          product.salient_list.length > 0),
                    },
                    {
                      name: "FAQ",
                      id: 6,
                      visible:
                        faq &&
                        faq.length &&
                        product &&
                        product.show_pdp_faq === 1,
                    },
                    {
                      name: "Q & A",
                      id: 7,
                      visible:
                        qa && qa.length && product && product.show_pdp_qa === 1,
                    },
                    {
                      name: "Contact",
                      id: 8,
                      visible: product && product.contact.length > 0,
                    },
                    {
                      name: "About Company",
                      id: 9,
                      visible: true,
                    },
                  ]}
                />
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                margin="2em 0 0 0;"
                ref={(el) => (scrollRef.current[0] = el)}
              >
                {getLayoutImage() !== "" && (
                  <div className="">
                    <img
                      className="headerImage"
                      height={150}
                      src={getLayoutImage()}
                      alt="#"
                    />
                  </div>
                )}
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                margin="2em 0 2em 0;"
              >
                {product.status === 3 && (
                  <div className="pdp-verified-cntr">
                    <img
                      className="pdp-verified-tick"
                      src={partialImg}
                      width={"20px"}
                    />
                    <span className="pdp-verified-txt">
                      full onboarding in progress
                    </span>
                  </div>
                )}
                {product.status === 2 && (
                  <div className="pdp-verified-cntr">
                    <CheckCircleIcon className="pdp-verified-tick" />
                    <span className="pdp-verified-txt">verified</span>
                  </div>
                )}
              </Grid>
              <Grid
                container
                gap="1em"
                justifyContent="center"
                alignItems="center"
                direction="column"
                margin="2em 0 2em 0;"
              >
                <div className="pdp-prj-by">
                  A PROJECT BY{" "}
                  <b>
                    {" "}
                    {promotors_general_information &&
                      promotors_general_information.company_name.toUpperCase()}
                  </b>
                </div>
                <div className="pdp-prj-name">{product && product.name}</div>
                <div className="pdp-prj-address">
                  {product && product.fulladdress}
                </div>
                <div>
                  <Stack
                    direction={{
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    }}
                    spacing={2}
                    className={uiIsMobile ? "filterGroupMenu" : ""}
                  >
                    <Button
                      variant="outlined"
                      className="pdp-tlt-button"
                      startIcon={<NavigationIcon />}
                      onClick={() =>
                        handleLocationClick(product && product.gps_location)
                      }
                    >
                      Get Directions
                    </Button>
                    <Button
                      variant="outlined"
                      className="pdp-tlt-button"
                      startIcon={<CalendarMonthIcon />}
                      onClick={() =>
                        loginState && loginState.isLogin && loginState.isOtp
                          ? setOpenSiteVisitPopup(true)
                          : !loginState.isLogin
                          ? setOpenGoogleLoginPopup(true)
                          : setOpenOTPLoginPopup(true)
                      }
                    >
                      Site Visit
                    </Button>
                    {product &&
                      product.contact &&
                      product.contact.length > 0 && (
                        <Button
                          variant="outlined"
                          className="pdp-tlt-button"
                          startIcon={<PhoneForwardedIcon />}
                          onClick={() => {
                            loginState && loginState.isOtp
                              ? setOpenContactPopup(true)
                              : setOpenOTPLoginPopup(true);
                            loginState &&
                              loginState.isOtp &&
                              updateHistory("C");
                          }}
                        >
                          Get Contacts
                        </Button>
                      )}
                    <Button
                      variant="outlined"
                      className="pdp-tlt-button"
                      startIcon={<ContactSupportIcon />}
                      onClick={() =>
                        loginState && loginState.isLogin && loginState.isOtp
                          ? setOpenAskUsPopup(true)
                          : !loginState.isLogin
                          ? setOpenGoogleLoginPopup(true)
                          : setOpenOTPLoginPopup(true)
                      }
                    >
                      Ask Us
                    </Button>
                    {loginState && loginState.isOtp ? (
                      <a
                        href={product.general_information.brochure}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="outlined"
                          className="pdp-tlt-button"
                          startIcon={<DownloadForOfflineOutlinedIcon />}
                          onClick={(e) => {
                            updateHistory("D");
                          }}
                        >
                          Download Brochure
                        </Button>
                      </a>
                    ) : (
                      <Button
                        variant="outlined"
                        className="pdp-tlt-button"
                        startIcon={<DownloadForOfflineOutlinedIcon />}
                        onClick={() => setOpenOTPLoginPopup(true)}
                      >
                        Download Brochure
                      </Button>
                    )}
                  </Stack>
                </div>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                paddingLeft={"12%"}
                paddingRight={"12%"}
                ref={(el) => (scrollRef.current[1] = el)}
                // style={{maxHeight: 500}}
              >
                {slides.length && (
                  <Carousel
                    showArrows={true}
                    stopOnHover={false}
                    showThumbs={false}
                    autoPlay={true}
                    infiniteLoop={true}
                  >
                    {slides.map((ele) => {
                      return (
                        <div>
                          <img src={ele.imgTag.props.src} />
                        </div>
                      );
                    })}
                  </Carousel>
                )}
              </Grid>
              {product &&
                product.status === 3 &&
                product?.general_information?.layout && (
                  <>
                    <Grid
                      container
                      gap="1em"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      margin="2em 0 2em 0;"
                      ref={(el) => (scrollRef.current[3] = el)}
                    >
                      <span className="pdp-lo-dtls-txt">Layout diagram</span>
                    </Grid>
                    <Grid
                      container
                      gap="1em"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      margin="2em 0 2em 0;"
                    >
                      <img
                        onClick={() =>
                          window.open(
                            product?.general_information?.layout,
                            "_blank"
                          )
                        }
                        className={"pdp-layout-diagram-img"}
                        src={product?.general_information?.layout}
                      />
                    </Grid>
                  </>
                )}
              {product &&
                product.status !== 3 &&
                (product.is_map_view_enable === 1 ||
                  product.is_image_view_enable === 1 ||
                  product.is_table_view_enable === 1) && (
                  <>
                    <Grid
                      container
                      gap="1em"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                      margin="2em 0 2em 0;"
                      ref={(el) => (scrollRef.current[3] = el)}
                    >
                      <span className="pdp-lo-dtls-txt">Layout details</span>
                      <ButtonGroup
                        variant="contained"
                        orientation={isMobile ? "vertical" : "horizontal"}
                      >
                        {product && product.is_map_view_enable === 1 && (
                          <Button
                            className={`pdp-lo-button-grp ${
                              viewType === "map" ? "active-tab" : ""
                            }`}
                            onClick={() => setViewType("map")}
                          >
                            Google Map View
                          </Button>
                        )}
                        {product && product.is_image_view_enable === 1 && (
                          <Button
                            className={`pdp-lo-button-grp ${
                              viewType === "fabric" ? "active-tab" : ""
                            }`}
                            onClick={() => setViewType("fabric")}
                          >
                            2D CAD View
                          </Button>
                        )}
                        {product && product.is_table_view_enable === 1 && (
                          <Button
                            className={`pdp-lo-button-grp ${
                              viewType === "table" ? "active-tab" : ""
                            }`}
                            onClick={() => setViewType("table")}
                          >
                            Detailed Table View
                          </Button>
                        )}
                      </ButtonGroup>
                    </Grid>
                    {product &&
                      (product.is_map_view_enable === 1 ||
                        product.is_image_view_enable === 1) &&
                      viewType !== "table" && (
                        <Grid
                          container
                          gap="1em"
                          justifyContent="center"
                          alignItems="center"
                          direction="column"
                          margin="2em 0 2em 0;"
                        >
                          <Filter
                            fliterFor={"pdp"}
                            filterOnChange={filterOnChange}
                          />
                        </Grid>
                      )}
                    {/* Map */}
                    {viewType === "map" && (
                      <Map
                        product={product}
                        plotStatus={plotStatus}
                        setPlotStatus={setPlotStatus}
                        lat={product && parseFloat(product.gps_lat)}
                        lng={product && parseFloat(product.gps_long)}
                        polyline={(product && product.polyline) || []}
                        show_boundary={(product && product.show_boundary) || 0}
                        boundary_color={
                          (product && product.boundary_color) || ""
                        }
                        id={(product && product.id) || null}
                        layout_image={(product && product.layout_image) || null}
                        isFav={null}
                        zoom={(product && product.zoom_level) || 16}
                        layoutFilter={layoutFilter}
                        openSnackBar={openSnackBar}
                        showPrice={product && product.show_price}
                        isEmiAvailable={
                          emi_details && emi_details.available == "yes"
                        }
                        isMobile={isMobileView}
                      />
                    )}
                    {viewType === "fabric" && (
                      <Grid
                        container
                        gap="1em"
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        margin="2em 0 2em 0;"
                      >
                        <DrawFabric
                          id={product && product.id}
                          layout_image={
                            (product && product.layout_image) || null
                          }
                          plotStatus={plotStatus}
                          showPrice={product && product.show_price}
                          setPlotStatus={setPlotStatus}
                          layoutFilter={layoutFilter}
                          openSnackBar={openSnackBar}
                          isEmiAvailable={
                            emi_details && emi_details.available == "yes"
                          }
                          isMobile={uiIsMobile || isMobileView}
                        />
                      </Grid>
                    )}
                    {viewType === "table" && (
                      <Grid
                        container
                        gap="1em"
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                        margin="2em 0 2em 0;"
                      >
                        <PdpTable
                          product={product}
                          plotStatus={plotStatus}
                          setPlotStatus={setPlotStatus}
                          lat={product && parseFloat(product.gps_lat)}
                          lng={product && parseFloat(product.gps_long)}
                          polyline={(product && product.polyline) || []}
                          show_boundary={
                            (product && product.show_boundary) || 0
                          }
                          boundary_color={
                            (product && product.boundary_color) || ""
                          }
                          id={(product && product.id) || null}
                          layout_image={
                            (product && product.layout_image) || null
                          }
                          isFav={null}
                          zoom={product.zoom_level || 16}
                          openSnackBar={openSnackBar}
                          showPrice={product && product.show_price}
                          isEmiAvailable={
                            emi_details && emi_details.available == "yes"
                          }
                        />
                      </Grid>
                    )}
                  </>
                )}
              {videoSlides.length > 0 && (
                <Grid
                  container
                  gap="1em"
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  margin="2em 0 2em 0;"
                >
                  <span className="pdp-lo-dtls-txt">videos</span>
                  <div className="videos">
                    <iframe
                      style={
                        uiIsMobile || isMobileView
                          ? { width: "85vw", height: "auto" }
                          : { width: "60vw", height: "75vh" }
                      }
                      src={
                        "https://www.youtube.com/embed?rel=0&playlist=" +
                        videoSlides
                      }
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen="true"
                    ></iframe>
                  </div>
                </Grid>
              )}
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                margin="2em 0 2em 0;"
                ref={(el) => (scrollRef.current[2] = el)}
              >
                <span className="pdp-about-size">
                  {product && product.total_area} acres and{" "}
                  {product && product.total_plots} plots.
                </span>
              </Grid>
              <Grid
                container
                margin="3em 0 2em 0;"
                direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
              >
                <Grid item lg={2} md={2} sm={0} xs={0}></Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                  >
                    <TlStart />
                    <TlContent
                      title="Started in"
                      value={
                        product.general_information.started_year !== ""
                          ? product.general_information.started_year
                          : " - "
                      }
                    />
                    {product.general_information.rera_no &&
                      product.general_information.rera_no !== "" && (
                        <>
                          <TlMiddle />
                          <TlContent
                            title="Registered with RERA"
                            value={product.general_information.rera_no}
                          />
                        </>
                      )}
                    {product.general_information.dtcp_number &&
                      product.general_information.dtcp_number !== "" && (
                        <>
                          <TlMiddle />
                          <TlContent
                            title="DTCP number"
                            value={product.general_information.dtcp_number}
                          />
                        </>
                      )}
                    <TlMiddle />
                    <TlContent
                      title="Current Status"
                      value={
                        product.general_information.project_status !== ""
                          ? product.general_information.project_status
                          : " - "
                      }
                    />
                    <TlEnd />
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  className={`pdp-tl-about-company ${
                    compReadMore ? "" : uiIsMobile ? "pdp-generic-readmore" : ""
                  }`}
                  onClick={() => uiIsMobile && setCompReadMore(!compReadMore)}
                  textAlign={{
                    lg: "left",
                    md: "left",
                    sm: "center",
                    xs: "center",
                  }}
                >
                  {product.general_information.description}
                </Grid>
                {!compReadMore && uiIsMobile ? (
                  <Grid
                    item
                    xs={12}
                    onClick={() => uiIsMobile && setCompReadMore(!compReadMore)}
                    className="pdp-tl-about-company-rm"
                  >
                    Read more..
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid
                container
                gap="1em"
                justifyContent="center"
                alignItems="center"
                direction="column"
                margin="2em 0 2em 0;"
                ref={(el) => (scrollRef.current[4] = el)}
              >
                <span className="pdp-lo-dtls-txt">
                  near by essential places
                </span>
              </Grid>
              {nearbyReadyCheck && (
                <NearBy
                  product={product}
                  plotStatus={plotStatus}
                  setPlotStatus={setPlotStatus}
                  lat={product && parseFloat(product.gps_lat)}
                  lng={product && parseFloat(product.gps_long)}
                  polyline={(product && product.polyline) || []}
                  show_boundary={(product && product.show_boundary) || 0}
                  boundary_color={(product && product.boundary_color) || ""}
                  id={(product && product.id) || null}
                  layout_image={(product && product.layout_image) || null}
                  isFav={null}
                  zoom={(product && product.zoom_level) || 16}
                />
              )}
              {product && product.street_view_url != "" && (
                <Grid
                  container
                  gap="1em"
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  margin="2em 0 2em 0;"
                  ref={(el) => (scrollRef.current[4] = el)}
                >
                  <span className="pdp-lo-dtls-txt">layout street view</span>
                  <StreetView
                    layout_id={(product && product.id) || null}
                    street_view_url={streetViewUrl}
                    updateStreetViewUrl={(url) => setStreetViewUrl(url)}
                  />
                </Grid>
              )}
              <Grid
                container
                gap="1em"
                justifyContent="center"
                alignItems="center"
                direction="column"
                margin="2em 0 2em 0;"
                ref={(el) => (scrollRef.current[5] = el)}
              >
                <span className="pdp-lo-dtls-txt">amenities and features</span>
                <Grid
                  container
                  gap="1em"
                  justifyContent="center"
                  alignItems="center"
                  margin="2em 0 2em 0;"
                >
                  {product &&
                    product.amenities_list &&
                    product.amenities_list.map((el, i) => (
                      <div className="pdp-amen-card-cntr">
                        <img
                          src={
                            el.image !== ""
                              ? el.image
                              : publicUrl + "assets/img/No_Image_Available.jpg"
                          }
                          alt="#"
                          style={{
                            width: 75,
                          }}
                        />
                        <span className="pdp-amen-card-txt">
                          {el.name.toUpperCase()}
                        </span>
                      </div>
                    ))}

                  {product &&
                    product.salient_list &&
                    product.salient_list.map((el, i) => (
                      <div className="pdp-amen-card-cntr">
                        <img
                          src={
                            el.image !== ""
                              ? el.image
                              : publicUrl + "assets/img/No_Image_Available.jpg"
                          }
                          alt="#"
                          style={{
                            width: 75,
                          }}
                        />
                        <span className="pdp-amen-card-txt">
                          {el.name.toUpperCase()}
                        </span>
                      </div>
                    ))}
                </Grid>
              </Grid>
              {faq && faq.length > 0 && product.show_pdp_faq === 1 && (
                <Grid
                  container
                  gap="1em"
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  margin="2em 0 2em 0;"
                  ref={(el) => (scrollRef.current[6] = el)}
                >
                  <span className="pdp-lo-dtls-txt">faq</span>
                  <Grid
                    container
                    gap="1em"
                    justifyContent="center"
                    alignItems="center"
                    margin="2em 0 2em 0;"
                    style={{ flexDirection: "column" }}
                  >
                    {faq &&
                      faq.length > 0 &&
                      faq.map((el, i) => (
                        <Accordion className="accordion-div">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={`panel${i}a-header`}
                          >
                            <Typography className="accordion-summary">
                              {el.question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="accordion-details">
                              {el.answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </Grid>
                </Grid>
              )}
              {qa && qa.length > 0 && (
                <Grid
                  container
                  gap="1em"
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  margin="2em 0 2em 0;"
                  ref={(el) => (scrollRef.current[7] = el)}
                >
                  <span className="pdp-lo-dtls-txt">Q&A</span>
                  <Grid
                    container
                    gap="1em"
                    justifyContent="center"
                    alignItems="center"
                    margin="2em 0 2em 0;"
                  >
                    <List
                      sx={{
                        width: "100%",
                        minWidth: "80%",
                        maxWidth: 1000,
                        bgcolor: "#FFF8F4",
                        borderRadius: "12px",
                      }}
                    >
                      {qa &&
                        qa.length > 0 &&
                        qa.map((el, i) => (
                          <ListItem
                            alignItems="flex-start"
                            secondaryAction={el.date}
                          >
                            <ListItemText
                              className="accordion-summary"
                              primary={el.category ? el.category : el.query}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="h6"
                                    color="text.primary"
                                    className="accordion-details"
                                  ></Typography>
                                  {el.reply}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Grid>
                </Grid>
              )}
              {product && product.contact && product.contact.length && (
                <>
                  <Grid
                    container
                    gap="1em"
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    margin="2em 0 2em 0;"
                    ref={(el) => (scrollRef.current[8] = el)}
                  >
                    <span className="pdp-lo-dtls-txt">contact</span>
                  </Grid>
                  <Grid
                    container
                    gap="1em"
                    justifyContent="center"
                    alignItems="center"
                    direction={uiIsMobile ? "column" : "row"}
                    margin="2em 0 2em 0;"
                  >
                    {product.contact.map(
                      (el, i) =>
                        el.name !== "" && (
                          <div className="pdp-contact-card">
                            <Avatar
                              alt={el.name.toUpperCase()}
                              src={
                                el.name !== ""
                                  ? el.name.charAt(0).toUpperCase()
                                  : ""
                              }
                              sx={{ width: 100, height: 100 }}
                            />
                            <div className="pdp-contact-card-nme">
                              {el.name}
                            </div>
                            {!loginState.isOtp ? (
                              <Button
                                variant="outlined"
                                className="pdp-tlt-button"
                                startIcon={<PhoneForwardedIcon />}
                                onClick={() =>
                                  loginState && loginState.isOtp
                                    ? setOpenContactPopup(true)
                                    : setOpenOTPLoginPopup(true)
                                }
                              >
                                Get Contacts
                              </Button>
                            ) : (
                              <>
                                <div className="pdp-contact-card-cnt">
                                  {el.email}
                                </div>
                                <div className="pdp-contact-card-cnt">
                                  {el.mobile}
                                </div>
                              </>
                            )}
                          </div>
                        )
                    )}
                  </Grid>
                </>
              )}
              <Grid
                container
                gap="1em"
                justifyContent="center"
                alignItems="center"
                direction="column"
                margin="2em 0 2em 0;"
                ref={(el) => (scrollRef.current[9] = el)}
              >
                <span className="pdp-lo-dtls-txt">about company</span>
              </Grid>
              {getPromotorsImage() !== "" && (
                <Grid
                  container
                  gap="0em"
                  xs={11}
                  justifyContent="center"
                  alignItems="center"
                  className="pdp-prj-by"
                >
                  <div style={{ marginBottom: 40 }}>
                    <img height={150} className="headerImage" src={getPromotorsImage()} alt="#" />
                  </div>
                </Grid>
              )}
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Grid
                  container
                  xs={11}
                  justifyContent="center"
                  alignItems="center"
                  className="pdp-prj-by"
                >
                  A PROJECT BY{" "}
                </Grid>
                <Grid
                  container
                  xs={11}
                  justifyContent="center"
                  alignItems="center"
                  className="pdp-prj-by"
                  style={{ marginTop: 10 }}
                >
                  <b>
                    {promotors_general_information &&
                      promotors_general_information.company_name.toUpperCase()}
                  </b>
                </Grid>
                <Grid
                  container
                  xs={11}
                  justifyContent="center"
                  alignItems="center"
                  className="pdp-footer-address"
                >
                  {getPromotorAddress(promotors_general_information)}
                </Grid>
                <Grid
                  container
                  xs={11}
                  justifyContent="center"
                  alignItems="center"
                  className={`pdp-footer-desc ${
                    footerDescReadMore ? "" : "pdp-generic-readmore"
                  }`}
                  onClick={() => setFooterDescReadMore(!footerDescReadMore)}
                  width={"50%"}
                >
                  {promotors_general_information &&
                    promotors_general_information.about_us}
                </Grid>
                {product &&
                  product.bank_details_list &&
                  product.bank_details_list.length > 0 && (
                    <>
                      <Grid
                        container
                        xs={11}
                        justifyContent="center"
                        alignItems="center"
                        className="pdp-prj-by"
                        style={{ marginTop: 40 }}
                      >
                        SUPPORTED BY
                      </Grid>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction={{
                          lg: "row",
                          md: "row",
                          sm: "column",
                          xs: "column",
                        }}
                        gap="30px"
                        style={{ marginTop: 20 }}
                      >
                        {map(
                          (product && product.bank_details_list) || [],
                          function (data) {
                            return (
                              <img
                                src={
                                  data.image !== ""
                                    ? data.image
                                    : publicUrl +
                                      "assets/img/No_Image_Available.jpg"
                                }
                                alt="#"
                                className="pdp-bnk-logo"
                              />
                            );
                          }
                        )}
                      </Grid>
                    </>
                  )}
              </Grid>
            </CSS.ParentDiv>
          </>
        )
      )}
    </>
  );
};

export default Pdp;
