import { useState, useEffect, useStateCallback } from "react";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import WestIcon from "@mui/icons-material/West";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
//Other lib imports
//Custom imports
import {defaultDirectionArr} from '../../../util/constants/preferenceConstant';
const readOnly = { opacity: '0.7', pointerEvents: 'none' }

const AddPreferenceStepTwo = (props) => {

  const { nextStep, propData, inputChange, viewOnly, setReusableDialogObj } = props;
  const [unitDisplayFlag, setUnitDisplayFlag] = useState(true);
  const [unit, setArea] = useState('sq.ft.');

  const closeReusableDialog = () => {
    setReusableDialogObj({
      open: false
    });
  }

  useEffect(() => {
    setUnitDropdown(propData.propertyType);
    setArea(propData.areaUint);
  }, []);

  const onBackBtnClk = () => {
    nextStep(1);
  };

  const onNextBtnClk = () => {
    if(!propData.maxArea){
      setReusableDialogObj({
        open: true,
        title: '',
        content: 'Please enter all mandatory inputs',
        btnArr: [
          {
            label: 'OK',
            fun: closeReusableDialog
          }
        ]
      });
    } else {
    nextStep(3);
    }
  };

  const setUnitDropdown = (value) => {
    if([1,2,5,6].includes(value)) {
      setUnitDisplayFlag(true);
      (propData.areaUint === 'sq.ft.') ? setArea('sq.ft.') : setArea('cent');
    } else {
      setUnitDisplayFlag(false);
      setArea('acres');
    }
  }

  const onPropertyClick = (e) => {
    inputChange(e);
    setUnitDropdown(e.target.value);
  };

  const onUnitClick = (e) => {
    inputChange(e);
    setArea(e.target.value);
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Preferred Plot Details</span>
                <div>
                  <span className="heading-step">(Step 2 of 5)</span>
                </div>
              </div>
              <div style={viewOnly && readOnly}>
                <FormControl fullWidth className='form-control-container'>
                  <InputLabel>Property Type*</InputLabel>
                  <Select
                    label='Property Type*'
                    name='propertyType'
                    value={propData.propertyType}
                    onChange={ e => onPropertyClick(e) }
                  >
                    <MenuItem value={1}>Residential plots</MenuItem>
                    <MenuItem value={2}>Villas</MenuItem>
                    <MenuItem value={3}>Farm Lands</MenuItem>
                    <MenuItem value={4}>Farm House</MenuItem>
                    <MenuItem value={5}>Gated Community</MenuItem>
                    <MenuItem value={6}>Commercial Land</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth className='form-control-container'>
                  <InputLabel>Select Unit*</InputLabel>
                  <Select
                    label='Select Unit*'
                    name='areaUint'
                    value={propData.areaUint}
                    onChange={ e => onUnitClick(e) }
                  >
                    { unitDisplayFlag ? 
                      [ 
                        <MenuItem value={'sq.ft.'}>Square Feet</MenuItem>,
                        <MenuItem value={'cent'}>Cent</MenuItem>
                      ] :
                        <MenuItem value={'acres'}>Acres</MenuItem> 
                    }
                  </Select>
                </FormControl>
                <FormControl fullWidth className='form-control-container'>
                  <InputLabel>Area *</InputLabel>
                  <Select
                    label='Area*'
                    name='maxArea'
                    value={propData.maxArea}
                    onChange={ e => inputChange(e) }
                  >
                    { (unit === 'sq.ft.') ? 
                      [ 
                        <MenuItem value={'500'}>500</MenuItem>,
                        <MenuItem value={'1000'}>1000</MenuItem>,
                        <MenuItem value={'1500'}>1500</MenuItem>,
                        <MenuItem value={'2000'}>2000</MenuItem>,
                        <MenuItem value={'2500'}>2500</MenuItem>,
                        <MenuItem value={'2501'}>2500+</MenuItem>
                      ] : ((unit === 'cent') ?
                        [
                        <MenuItem value={'1c'}>1</MenuItem>,
                        <MenuItem value={'2c'}>2</MenuItem>,
                        <MenuItem value={'3c'}>3</MenuItem>,
                        <MenuItem value={'4c'}>4</MenuItem>,
                        <MenuItem value={'5c'}>5</MenuItem>,
                        <MenuItem value={'5pc'}>5+</MenuItem>
                      ] : ((unit === 'acres') ?
                      [
                        <MenuItem value={'25ac'}>0.25</MenuItem>,
                        <MenuItem value={'50ac'}>0.5</MenuItem>,
                        <MenuItem value={'100ac'}>1</MenuItem>,
                        <MenuItem value={'100pac'}>1+</MenuItem>
                      ] :
                      []
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPreferenceStepTwo;
