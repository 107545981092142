import styled from 'styled-components';

const ParentDiv = styled.div`

    text-align: ${props => props.fromCheckBoxFilter ? "center" : ""}; 

    .filter-marker {
        border-bottom: 5px solid #643F00;
        border-radius: 5px;
        width: ${props => props.isMobile ? "15px" : "35px"};
    }

    .showBoxes {
        display: ${props => props.isMobile && props.contentClassNameChecker === 'filter-heading' ? 'none' : '' };
    }

    .filter-min-box {
        border: 1px dashed #462B00;
        border-radius: 8px;
        padding: ${props => props.isMobile ? "10px 10px 10px" : "16px 16px 8px"};
        gap: ${props => props.isMobile ? "5px" : "10px"};
        background: #FFDDB5;
        cursor: pointer;
    }

    .filter-max-box {
        border: 1px dashed #462B00;
        border-radius: 8px;
        padding: 16px 16px 8px;
        gap: 10px;
        background: white;
    }

    .filter-heading {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #462B00;
    }

    .filter-content {
        font-style: normal;
        font-weight: 500;
        font-size: ${props => props.isMobile ? "15px" : "25px"};
        line-height: ${props => props.isMobile ? "20px" : "25px"};
        color: #462B00;
    }

    .JoySlider-rail {
        background-color: #ffddb5 !important;
    }

    .JoySlider-track {
        background-color: #a56b00 !important;
    }

    .JoySlider-thumb {
        background-color: #462b00 !important;
        border-color: #462b00 !important;
    }

    .JoySlider-thumb::before {
        border: none;
    }

    .JoySlider-valueLabel {
        background-color: #462b00 !important;
    }

    .filter-button {
        background-color: #643f00 !important;
        color: white !important;
        border-color: #643f00 !important;
        text-transform: none !important;
    }

    .filter-button-radio {
        width: 10%;
    }

    .filter-button-radio-cntr {
        display: flex;
        justify-content: center;
    }

    .filter-range-cntr {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px 20px 15px 20px;
        gap: 10px;
    }

    .filter-checkbox-cntr {
        max-height: 150px;
        overflow: auto;
        flex-wrap: nowrap !important;
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
    }

    .filter-checkbox-cntr::-webkit-scrollbar {
        display: none;
        /* for Chrome, Safari, and Opera */
    }

    @media (min-width: 1200px) {
        .direction {
            width: 23% !important;
        }

        .property_type {
            width: 28% !important;
        }

        .amenities {
            width: 40% !important;
        }
    }


    /* === Animation effects using CSS transition - Start === */
    /* === Appear Transition === */
    .myclass-appear {
        opacity: 0;
    }

    .myclass-appear-active {
        opacity: 1;
        transition: opacity 2s;
    }

    /* === Enter Transition === */
    .myclass-enter {
        opacity: 0;
    }

    .myclass-enter-active {
        opacity: 1;
        transition: opacity 2s;
    }

    .myclass-enter-done {
        opacity: 1;
    }

    /* === Exit Transition === */
    .myclass-exit {
        opacity: 1;
    }

    .myclass-exit-active {
        opacity: 0;
        transition: opacity 2s;
    }

    .myclass-exit-done {
        opacity: 0;
    }

    /* === Animation effects using CSS transition - End === */
`;

export { 
    ParentDiv, 
}