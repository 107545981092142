//Custom imports
import './TimeLineDivider.css';

const TlStart = () => {
    return (
        <>
            <div className='pdp-tl-circle' />
            <div className='pdp-tl-vl' />
        </>
    )
}

const TlMiddle = () => {
    return (
        <>
            <div className='pdp-tl-vl' />
            <div className='pdp-tl-circle' />
            <div className='pdp-tl-vl' />
        </>
    )
}

const TlEnd = () => {
    return (
        <>
            <div className='pdp-tl-vl' />
        </>
    )
}

const TlContent = (props) => {
    return (
        <>
            <div className='pdp-tl-txt-cntr'>
                {props.title}
                <br/>
                <b>
                    {props.value}
                </b>
            </div>
        </>
    )
}

export {TlStart, TlMiddle, TlEnd, TlContent};
