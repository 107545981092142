import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
//Material UI imports
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
//Custom imports
import * as CSS from "./FilterStyle";

const CheckboxFilter = (props) => {
  const { objIndex, objContent, onModify, onDone, divider } = props;
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    let selectAllChecker = objContent.val.some((ele) => ele.ticked === false);
    selectAllChecker ? setCheckAll(false) : setCheckAll(true);
  }, [objContent]);

  const handleChange = (userUpdatedVal, index) => {
    const updatedObj = { ...objContent };
    //Update Checked/Uncheck values
    updatedObj.val[index].ticked = userUpdatedVal;
    //Update display text
    let selctedArr = updatedObj.val.filter((ele) => ele.ticked);
    if (selctedArr.length === updatedObj.val.length) {
      updatedObj.displayVal = "ANY";
    } else if (selctedArr.length === 0) {
      updatedObj.displayVal = "NONE";
    } else {
      if (objContent.key === "direction") {
        let newDispVal = "";
        updatedObj.val.forEach((ele) => {
          if (ele.ticked) {
            let lblval = ele.label;
            newDispVal += `${lblval.substring(
              lblval.indexOf("(") + 1,
              lblval.lastIndexOf(")")
            )},`;
          }
        });
        updatedObj.displayVal = newDispVal ? newDispVal.slice(0, -1) : "";
      } else if (
        objContent.key === "amenities" ||
        objContent.key === "places"
      ) {
        updatedObj.displayVal = selctedArr.length;
      } else if ( objContent.key === "property_type" ) {
        updatedObj.displayVal = selctedArr.length + `${selctedArr.length === 1 ? ' Type' : ' Types'}`;
      } else if (
        objContent.key === "availability" ||
        objContent.key === "corner"
      ) {
        updatedObj.displayVal = selctedArr[0].label.toUpperCase();
      }
    }
    onModify(objIndex, updatedObj);
  };

  const handleCheckAllChange = (e) => {
    setCheckAll(e.target.checked);
    const updatedObj = { ...objContent };
    updatedObj.val.forEach((item) => {
      item.ticked = e.target.checked;
    });
    updatedObj.displayVal = e.target.checked ? "ANY" : "NONE";
    onModify(objIndex, updatedObj);
  };

  return (
    <CSS.ParentDiv isMobile={isMobile} fromCheckBoxFilter={true}>
      <Checkbox
        checked={checkAll}
        onChange={handleCheckAllChange}
        sx={{ color: "#643f00", "&.Mui-checked": { color: "#643f00" } }}
      />
      {divider > 0 ? (
        <Grid container style={ isMobile ? {textAlign: 'left', maxHeight: '600px', overflow: 'scroll'} : {textAlign: 'left'}} >
          {objContent.val.map((element, index) => (
            <Grid item lg={divider} md={12} sm={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={element.ticked}
                    onChange={(e) => handleChange(e.target.checked, index)}
                    sx={{
                      color: "#643f00",
                      "&.Mui-checked": { color: "#643f00" },
                    }}
                  />
                }
                label={
                  <Typography variant="body2" color="textSecondary">
                    {element.label}
                  </Typography>
                }
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <FormGroup className="filter-checkbox-cntr">
          {objContent.val.map((element, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={element.ticked}
                  onChange={(e) => handleChange(e.target.checked, index)}
                  sx={{
                    color: "#643f00",
                    "&.Mui-checked": { color: "#643f00" },
                  }}
                />
              }
              label={
                <Typography variant="body2" color="textSecondary">
                  {element.label}
                </Typography>
              }
            />
          ))}
        </FormGroup>
      )}
      <Button
        variant="outlined"
        size={ isMobile ? 'large' : 'small' }
        onClick={onDone}
        className="filter-button"
        style={ isMobile ? {marginTop: '10px'} : {} }
      >
        Done
      </Button>
    </CSS.ParentDiv>
  );
};

export default CheckboxFilter;
