//Material UI imports
import Grid from "@mui/material/Grid";
//Custom imports
import * as CSS from "../Style.js";

const PlotDetailsToolTip = (props) => {
  const { plotNumber, plotStatus, plotArea } = props;

  return (
    <Grid
      container
      direction="column"
      gap="1em"
      justifyContent="center"
      alignItems="center"
      style={CSS.GlobalGrid}
    >
      <CSS.CardContainer>
        <CSS.CardContainerTitle>PLOT NO.</CSS.CardContainerTitle>
        <CSS.CardContainerContent>{plotNumber}</CSS.CardContainerContent>
      </CSS.CardContainer>
      <CSS.Sqft>{plotArea} Sq.ft</CSS.Sqft>
      <CSS.ChipContainer plotStatus={plotStatus.toLowerCase()}>
        <CSS.ChipContent plotStatus={plotStatus.toLowerCase()}>
          {plotStatus.toLowerCase() === "unsold"
            ? "Available"
            : plotStatus.toLowerCase()}
        </CSS.ChipContent>
      </CSS.ChipContainer>
      <CSS.CardMessage>Click on the plot for details</CSS.CardMessage>
    </Grid>
  );
};

export default PlotDetailsToolTip;
