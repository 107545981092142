import React, { useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  Polygon,
  Polyline,
  InfoWindow,
} from "react-google-maps";
import normalMarker from '../../Assets/plp_loc.png';
import highlightedMarker from '../../Assets/plp_loc_hl.png';
import favMarker from '../../Assets/plp_loc_fv.png';
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import PLPPlotDetailsToolTip from "../Overlays/PLPPlotDetailsToolTip/PLPPlotDetailsToolTip";
const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const { compose, withState, withHandlers, lifecycle } = require("recompose");
const OPTIONS = {
  minZoom: 4,
  maxZoom: 100,
};
const getCenter = (arr) => {
  var x = arr["lat"];
  var y = arr["lng"];
  return { lat: x, lng: y };
};

const labelSize = { width: 220 };
const labelPadding = 8;

const getIconUrl = (defaultIcon, mapEle, popupObj) => {
  let iconUrl = normalMarker || null;
  if(mapEle?.project_data?.is_fav) {
    iconUrl = favMarker;
  } else {
    if(popupObj.open && popupObj?.project_data?.gps_lat === mapEle?.project_data?.gps_lat && popupObj?.project_data?.gps_long === mapEle?.project_data?.gps_long) {
      iconUrl = highlightedMarker;
    }
  }
  return iconUrl;
}

const DrawMaps = compose(
  withState("zooms", "onZoomChange", 7),
  withState("lats", "onLatChange", 11.127123),
  withState("lngs", "onLngChange", 78.656891),
  withState("showInfo", "setShowInfo", false),
  withState("infoWindow", "setInfoWindow", null),
  withHandlers((props) => {
    const refs = {
      map: undefined,
      circle: undefined,
    };
    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
      },
      onZoomChanged: (props) => (e) => {
        props.onZoomChanged(refs.map.getZoom());
      },
      fitBounds: (props) => (e) => {
        const bounds = new window.google.maps.LatLngBounds();
        const locations =
          (props.searchLocations &&
            JSON.parse(JSON.stringify(props.searchLocations || []))) ||
          [];
        // locations.map((item) => {
        //   console.log("item", item);
        //   bounds.extend(item.gps);
        //   return item.id;
        // });
        if (locations && locations.length === 1) {
          // refs.map &&
          //   refs.map.panTo(
          //     new window.google.maps.LatLng(
          //       locations[0]["gps"]["lat"],
          //       locations[0]["gps"]["lng"]
          //     )
          //   );
        } else {
          // refs.map && refs.map.fitBounds(bounds);
        }
      },
      circleRef: (props) => (circleRef) => {
        refs.circle = circleRef;
        // var bounds = new window.google.maps.LatLngBounds();
        // bounds.union(circleRef.getBounds());
        // refs.map && refs.map.fitBounds(bounds);
      },
      circleFitBounds: () => () => {},
      onMapClick:
        ({ onLatChange, onLngChange }) =>
        (e) => {
          onLatChange(e.latLng.lat());
          onLngChange(e.latLng.lng());
        },
    };
  }),
  lifecycle({
    componentDidMount() {
      this.props.onZoomChange(this.props.zoom);
      this.props.onLatChange(this.props.lat);
      this.props.onLngChange(this.props.lng);
      if (this.props.circle) {
        // this.props.circleFitBounds();
      }
    },
    componentDidUpdate() {
      if (this.props.searchLocations && this.props.searchLocations.length > 0) {
        this.props.fitBounds(this.props.searchLocations);
      }
      if (this.props.circle) {
        // this.props.circleFitBounds();
      }
    },
    componentWillMount() {
      if (this.props.circle) {
        // this.props.circleFitBounds();
      }
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {

  useEffect(() => {
    if(props.infoWindowTrigger) {
      if(props.infoWindowTrigger === 'close') {
        props.setShowInfo(false);
        props.setInfoWindow(null);
      } else {
        const newEl = props.searchLocations.filter((el) => parseFloat(el.project_data.gps_lat) === props.infoWindowTrigger.lat && parseFloat(el.project_data.gps_long) === props.infoWindowTrigger.lng);
        if(newEl.length === 1) {
          props.setShowInfo(true);
          props.setInfoWindow(newEl[0].project_data);
        }
      }
    }
  }, [props.infoWindowTrigger]);


  return <div>
    <GoogleMap
      options={OPTIONS}
      defaultCenter={props.gps_location}
      defaultZoom={props.zoom_level || 20}
      mapTypeControl={true}
      mapTypeControlOptions={{
        position: window.google.maps.ControlPosition.TOP_LEFT,
        mapTypeIds: [
          window.google.maps.MapTypeId.ROADMAP,
          window.google.maps.MapTypeId.SATELLITE,
          window.google.maps.MapTypeId.HYBRID,
        ],
      }}
      mapTypeId={"roadmap"}
      onClick={props.onClick}
      ref={props.onMapMounted}
      onZoomChanged={props.onZoomChanged}
      center={props.gps_location}
    >
      <DrawingManager
        onOverlayComplete={props.overlay}
        defaultOptions={{
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              window.google.maps.drawing.OverlayType.CIRCLE,
              window.google.maps.drawing.OverlayType.POLYGON,
              window.google.maps.drawing.OverlayType.POLYLINE,
              window.google.maps.drawing.OverlayType.RECTANGLE,
            ],
          },
        }}
        options={{
          drawingControl: props.drawingControlEnabled,
        }}
      />
      {props.polygon &&
        props.polygon !== null &&
        props.polygon.length > 0 &&
        props.polygon.map((path, i) => (
          <Polygon
            key={i}
            paths={path.gps}
            options={{
              fillColor: path.fill_color ? path.fill_color : "transparent",
              strokeColor: path.line_color ? path.line_color : "#000",
              strokeWeight: 1,
            }}
            onClick={(event) => props.onPolygonClick(path, i)}
          />
        ))}
      {props.show_boundary &&
        props.polyline &&
        props.polyline !== null &&
        props.polyline.length > 0 &&
        props.polyline.map((path, i) => (
          <Polyline
            // key={`POLYLINE_${i}`}
            path={path.gps}
            options={{
              fillColor: path.fill_color ? path.fill_color : "transparent",
              strokeColor: path.line_color
                ? path.line_color
                : props.boundary_color,
              strokeWeight: 3,
            }}
          />
        ))}

      {props.isProject && props.gps_location && props.gps_location !== null && (
        <Marker position={props.gps_location} />
      )}

      {props.searchLocations &&
        props.searchLocations !== null &&
        props.searchLocations.map((el, i) => (
          <Marker
            key={i}
            position={el.gps}
            icon={{
              url: getIconUrl(el.icon, el, props.PDPpopupObj),
              scaledSize: new window.google.maps.Size(30, 30),
            }}
            onClick={() => {
              // !props.isMobile &&
              //   props.projectClick &&
              //   props.projectClick(el.project_data, "C");
              if (props.isMobile) {
                props.setShowInfo(true);
                props.setInfoWindow(el.project_data);
              } else {
                props.projectClick && props.projectClick(el.project_data, "C");
              }
            }}
            onMouseOver={(e) => {
              // e.stopPropagation();
              props.setShowInfo(true);
              props.setInfoWindow(el.project_data);
            }}
            onMouseOut={(e) => {
              // e.stopPropagation();
              props.setShowInfo(false);
              props.setInfoWindow(null);
            }}
          />
        ))}

      {props.polygon &&
        props.polygon !== null &&
        props.polygon.map((path, i) => (
          <MarkerWithLabel
            labelStyle={{
              textAlign: "center",
              fontSize: "12px",
            }}
            labelClass="map-label"
            labelAnchor={{ x: labelSize.width / 10, y: 15 }}
            key={i}
            icon={" "}
            position={getCenter(path.gps)}
          >
            <span>{path.name}</span>
          </MarkerWithLabel>
        ))}

      {props.circle && (
        <Circle
          ref={props.circleRef}
          center={props.circle.position}
          radius={props.circle.radius}
          options={props.circle.options}
          onClick={props.onClick}
        />
      )}

      {props.showInfo && props.infoWindow && (
        <InfoWindow
          position={props.infoWindow.gps_location}
          onCloseClick={() => {
            props.setShowInfo(false);
            props.setInfoWindow(null);
          }}
          style={{ padding: 0 }}
        >
          <PLPPlotDetailsToolTip
            layoutName={props.infoWindow && props.infoWindow.name}
            promoterName={
              props.infoWindow && props.infoWindow.company_promotors
            }
            noPlots={props.infoWindow && props.infoWindow.unsold_cnt}
            centPr={ props?.infoWindow?.plp_price_cent }
            sqftPr={ props?.infoWindow?.plp_price_sq_ft }
            projectClick={() => props.projectClick(props.infoWindow, "C")}
          />
        </InfoWindow>
      )}
    </GoogleMap>
  </div>
});

export default DrawMaps;
