import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//Custom imports
import { useSnackbar } from "../../../hooks/useSnackbar";
import Snackbar from "../../snackbar";
import XHR from "../../../util/pxhr";

import { baseUrl } from "../../../util/config";

//Other lib imports
import axios from "axios";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const PromoterProfileStepOne = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { nextStep } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    user_id: "",
    general_information: {
      door_no: "",
      street: "",
      address1: "",
      address2: "",
      town: "",
      city: "",
      taluk: "",
      district: "",
      state: "",
      pincode: "",
      country: "",
      landmark: "",
      no_of_projects: "",
      about_company: "",
    },
    board_of_directors: [],
    team: [],
    feedback: [],
    gallery: [],
  });

  const [openUpload, setOpenUpload] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [uploadObj, setUploadObj] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const handleUploadClose = () => {
    setOpenUpload(false);
    setUploadType(null);
    setUploadObj(null);
    setUploadStatus(null);
    setUploading(false);
  };

  const onFileChange = (event) => {
    setUploadStatus(null);
    setUploading(false);
    setUploadObj(event.target.files[0]);
  };

  const handleConfirmUpload = () => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append("file", uploadObj);
    formData.append("type", uploadType === "image" ? "I" : "S");
    XHR.post("ads/upload", formData, config).then(
      (res) => {
        setUploading(false);
        setUploadStatus(res.data || null);
        openSnackBar("Successfully uploaded.", "success");
      },
      (err) => {
        setUploading(false);
        console.log("err", err);
      }
    );
  };

  const handlePreview = () => {
    if (uploadStatus && uploadStatus.url) {
      window.open(uploadStatus.url, "_blank");
    }
  };

  const handleSave = () => {
    if (uploadType === "image" || uploadType === "youtube") {
      const dataCopy = JSON.parse(JSON.stringify(data));
      let assets = dataCopy.gallery || [];
      assets.push({ type: uploadType, url: uploadStatus.url });
      console.log("assets", assets);
      setData({
        ...data,
        gallery: assets,
      });
    } else if (
      uploadType === "boardPhoto" ||
      uploadType === "teamPhoto" ||
      uploadType === "feedbackPhoto"
    ) {
      //   setUpdateData({
      //     ...updateData,
      //     photo: uploadStatus.url,
      //   });
    } else if (uploadType === "logo") {
      setData({
        ...data,
        general_information: {
          ...data.general_information,
          logo: uploadStatus.url,
        },
      });
    } else {
      setData({
        ...data,
        general_information: {
          ...data.general_information,
          layout: uploadStatus.url,
        },
      });
    }

    handleUploadClose();
  };

  useEffect(() => {
    const promotor_user = localStorage.getItem("promotor_user") || null;
    if (promotor_user) {
      const userData = JSON.parse(promotor_user);
      setUserDetails(userData);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      getPromotorDetails(userDetails.id);
    }
  }, [userDetails]);

  const getPromotorDetails = (data) => {
    setIsLoading(true);
    const payload = { company_id: data };
    axios
      .post(`${baseUrl}/cms_api/realestate/promoters/get`, payload)
      .then(
        (res) => {
          setIsLoading(false);
          if (res.data && res.data.companies) {
            setData((res.data && res.data.companies) || {});
          }
        },
        (err) => {
          setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  const handleChange = (key1, key2, value) => {
    setData({
      ...data,
      [key1]: {
        ...data[key1],
        [key2]: value,
      },
    });
  };

  const updateProject = () => {
    setIsLoading(true);
    const payload = { ...data, company_id: userDetails.id };
    if (
      !payload.general_information.no_of_years ||
      !payload.general_information.no_of_cities_covered ||
      !payload.general_information.no_of_project_completed ||
      !payload.general_information.no_of_project_on_going ||
      !payload.general_information.about_us
    ) {
      openSnackBar("Enter all fields.", "error");
      setIsLoading(false);
      return;
    }
    axios
      .post(
        `${baseUrl}/cms_api/realestate/promoters/update`,
        payload
      )
      .then(
        (res) => {
          setIsLoading(false);
          getPromotorDetails(userDetails.id);
          openSnackBar("Updated Successfully", "success");
        },
        (err) => {
          setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  const onBackBtnClk = () => {
    navigate("/promoterWelcome");
  };

  const onNextBtnClk = () => {
    nextStep(2);
  };

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Dialog
        open={openUpload}
        size="md"
        fullWidth
        onClose={handleUploadClose}
        aria-labelledby="form-dialog-title"
      >
        {/* <DialogTitle id="form-dialog-title">Upload</DialogTitle> */}
        <DialogContent>
          <Grid
            container
            direction="column"
            gap="1em"
            justifyContent="center"
            alignItems="center"
          >
            <div style={{ position: "absolute", top: "0px", right: "0px" }}>
              <IconButton aria-label="closePopup" onClick={handleUploadClose}>
                <HighlightOffIcon />
              </IconButton>
            </div>
            <div style={{ marginTop: "2%" }}></div>
          {/* <Grid container spacing={1}> */}
            {(uploadType === "image" ||
              uploadType === "logo" ||
              uploadType === "layout" ||
              uploadType === "boardPhoto" ||
              uploadType === "teamPhoto" ||
              uploadType === "feedbackPhoto") && (
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="image-button-file"
                  onChange={onFileChange}
                  type="file"
                />
                {!uploadObj ? (
                  <label htmlFor="image-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className="login-button"
                    >
                      Choose Image
                    </Button>
                  </label>
                ) : (
                  <>
                    <Typography style={{ marginBottom: 10, textAlign: "center" }}>
                      {uploadObj.name || "Image"}
                    </Typography>
                    {!uploadStatus ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          onClick={handleConfirmUpload}
                          style={{ marginRight: 10 }}
                          className="login-button"
                        >
                          {uploading ? "Uploading" : "Upload Image"}
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            className="login-button"
                          >
                            Choose New Image
                          </Button>
                        </label>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePreview}
                          style={{ marginRight: 10 }}
                          component="span"
                          className="login-button"
                        >
                          Preview Image
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{ marginRight: 10 }}
                            className="login-button"
                          >
                            Choose New Image
                          </Button>
                        </label>
                        {uploadStatus && (
                          <label htmlFor="image-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              className="login-button"
                              onClick={handleSave}
                            >
                              Save
                            </Button>
                          </label>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleUploadClose} color="primary">
            Close
          </Button>
          {uploadStatus && (
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          )}
        </DialogActions> */}
      </Dialog>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">About The Company</span>
                <div>
                  <span className="login-form-heading-step">(Step 1 of 6)</span>
                </div>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Number of years in field"
                  variant="outlined"
                  required
                  className={`${classes.input} login-inputWidth`}
                  size="small"
                  value={
                    (data &&
                      data.general_information &&
                      data.general_information.no_of_years) ||
                    ""
                  }
                  onChange={(event) =>
                    handleChange(
                      "general_information",
                      "no_of_years",
                      event.target.value
                    )
                  }
                  type="number"
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Number of cities covered"
                  variant="outlined"
                  required
                  className={`${classes.input} login-inputWidth`}
                  size="small"
                  value={
                    (data &&
                      data.general_information &&
                      data.general_information.no_of_cities_covered) ||
                    ""
                  }
                  onChange={(event) =>
                    handleChange(
                      "general_information",
                      "no_of_cities_covered",
                      event.target.value
                    )
                  }
                  type="number"
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Number of completed projects"
                  variant="outlined"
                  required
                  className={`${classes.input} login-inputWidth`}
                  size="small"
                  value={
                    (data &&
                      data.general_information &&
                      data.general_information.no_of_project_completed) ||
                    ""
                  }
                  onChange={(event) =>
                    handleChange(
                      "general_information",
                      "no_of_project_completed",
                      event.target.value
                    )
                  }
                  type="number"
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Number of ongoing projects"
                  variant="outlined"
                  required
                  size="small"
                  value={
                    (data &&
                      data.general_information &&
                      data.general_information.no_of_project_on_going) ||
                    ""
                  }
                  onChange={(event) =>
                    handleChange(
                      "general_information",
                      "no_of_project_on_going",
                      event.target.value
                    )
                  }
                  type="number"
                  className={`${classes.input} login-inputWidth`}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="About Us (max 1500 characters)"
                  multiline
                  rows={4}
                  variant="outlined"
                  required
                  className="login-inputWidth"
                  size="small"
                  value={
                    (data &&
                      data.general_information &&
                      data.general_information.about_us) ||
                    ""
                  }
                  onChange={(event) =>
                    handleChange(
                      "general_information",
                      "about_us",
                      event.target.value
                    )
                  }
                  inputProps={{ maxLength: 1500 }}
                />
              </div>
              <div className="login-form-upload-div">
                <label htmlFor="brochure-button-file">
                  <Button
                    onClick={() => {
                      setUploadType("logo");
                      setOpenUpload(true);
                    }}
                    variant="contained"
                    color="primary"
                    component="span"
                    className="login-button"
                  >
                    Upload Logo
                  </Button>
                </label>
                <div>
                  <span className="login-form-div-helper-text"> 
                    (PNG or JPG file less than 2MB)
                  </span>
                </div>
                {data &&
                data.general_information &&
                data.general_information.logo ? (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <div>
                  <img
                    src={data.general_information.logo}
                    style={{ width: '125px'}}
                  />
                  </div>
                ) : null}
                {data &&
                data.general_information &&
                data.general_information.logo ? (
                  // <label htmlFor="brochure-button-file" style={{paddingTop: '10px'}}>
                  //   <Button
                  //     variant="contained"
                  //     component="span"
                  //     size="small"
                  //     className="login-button"
                  //     onClick={() => window.open(data.general_information.logo)}
                  //     endIcon={<RemoveRedEyeIcon />}
                  //   >
                  //     Preview File
                  //   </Button>
                  // </label>
                  <></>
                ) : null}
              </div>
              <div className="profile-back-next-btn-cntr" style={{marginTop: '5%'}}>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => updateProject()}
                    className="login-button"
                  >
                    Save
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default PromoterProfileStepOne;
