//Material UI imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
//Custom imports
import * as CSS from "../Overlays/Style.js";
import { map } from "lodash";
import "./index.css";

const Dialog = (props) => {
  const { open, onClose, title, message, button } = props;

  return (
    <CSS.CustomizedDialog
      open={open}
      onClose={() => onClose(false)}
      maxWidth={"lg"}
    >
      <Grid
        container
        direction="column"
        gap="1em"
        justifyContent="flex-start"
        alignItems="flex-start"
        // minWidth={500}
      >
        <CSS.CloseBtn>
          <IconButton aria-label="closePopup" onClick={() => onClose(false)}>
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn>
        <CSS.TitleContainer style={{ paddingTop: '25px' }}>
          <span className="title">{title}</span>
        </CSS.TitleContainer>
        {message !== "" && message}
        {button && button.length > 0 && (
          <div className="actionButton">
            {map(button || [], function (data) {
              return (
                <CSS.customBtn
                  variant="outlined"
                  onClick={() => data && data.click()}
                >
                  {data.title}
                </CSS.customBtn>
              );
            })}
          </div>
        )}
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default Dialog;
