/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import axios from "axios";
import DataGrid from "../DataGrid";
import Grid from "@mui/material/Grid";
import Dialog from "../Dialog";
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import SiteVisitUpdatePopup from "../Overlays/SiteVisitUpdatePopup/SiteVisitUpdatePopup";
import PLPHeader from "../HeaderMenu/PLPHeader";

import { baseUrl } from "../../util/config";

const SiteVisit = (props) => {
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [editDate, setEditDate] = useState('');

  const [viewType, setViewType] = useState("promotors");
  const [dialogShow, setDialogShow] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [openSiteVisitPopup, setOpenSiteVisitPopup] = useState(false);

  const [siteVisitPopupId, setSiteVisitPopupId] = useState(null);

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  useEffect(() => {
    const token = localStorage.getItem("user") || null;
    let userDetails;
    if (token) {
      userDetails = JSON.parse(token);
    }
    if (viewType === "promotors") {
      let inputs = {
        user: userDetails && userDetails.googleId ? userDetails.googleId : "",
      };
      getTablePromoters(inputs);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("user") || null;
    let userDetails;
    if (token) {
      userDetails = JSON.parse(token);
    }
    if (viewType === "promotors") {
      let inputs = {
        user: userDetails && userDetails.googleId ? userDetails.googleId : "",
      };
      getTablePromoters(inputs);
    }
  }, [viewType]);

  // Table Properties

  const columns = [
    { name: "sno", title: "S.No" },
    { name: "company_promotors", title: "Promoter Name" },
    { name: "name", title: "Layout Name" },
    { name: "city", title: "Location" },
    { name: "mobile", title: "Mobile" },
    { name: "date", title: "Visit Date" },
    { name: "action", title: "Change Date" },
  ];

  const tableColumnExtensions = [
    { columnName: "sno", width: 100, wordWrapEnabled: true },
    { columnName: "company_promotors", wordWrapEnabled: true },
    { columnName: "name", wordWrapEnabled: true },
    { columnName: "city", wordWrapEnabled: true },
    { columnName: "mobile", wordWrapEnabled: true },
    { columnName: "date", wordWrapEnabled: true },
    { columnName: "action", wordWrapEnabled: true },
  ];

  const defaultHiddenColumnNames = [];

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "company_promotors", filteringEnabled: false },
    { columnName: "mobile", filteringEnabled: false },
    { columnName: "action", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "company_promotors", sortingEnabled: false },
    { columnName: "mobile", sortingEnabled: false },
    { columnName: "action", sortingEnabled: false },
  ];

  const loadGrid = (inputs) => {
    const token = localStorage.getItem("user") || null;
    let userDetails;
    if (token) {
      userDetails = JSON.parse(token);
    }
    inputs["user"] =
      userDetails && userDetails.googleId ? userDetails.googleId : "";
    getTablePromoters(inputs);
  };

  const getTablePromoters = (inputs) => {
    const payload = inputs;
    console.log("payload", payload);
    axios
      .post(
        `${baseUrl}/plot_api/realestate/visitors/sitevisit`,
        payload
      )
      .then(
        (res) => {
          if (res.data.status) {
            console.log("res.status", res.data.status);
            setRows((res.data && res.data.data.rows) || []);
            setTotalRecords((res.data && res.data.data.total) || 0);
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const actionClick = (type, data) => {
    if (type === "VIEW") {
      setSiteVisitPopupId(data.sId);
      setEditDate(data.date);
      setOpenSiteVisitPopup(true);
    }
  };

  const closeDialog = () => {
    setDialogShow(false);
    setTimeout(() => {
      setDialogProps({});
    }, 100);
  };

  const onSiteVisitSubmit = (data) => {
    if (data.id !== "" && data.date !== "") {
      const payload = {
        ...data,
      };
      axios
        .post(
          `${baseUrl}/plot_api/realestate/visitors/site/update`,
          payload
        )
        .then(
          (res) => {
            console.log("res", res);
            if (res && res.data && res.data.status) {
              openSnackBar("Success", "success");
              loadGrid({});
              setOpenSiteVisitPopup(false);
            }
          },
          (err) => {
            console.log("err", err);
          }
        );
    } else {
      openSnackBar("Please fill all the data", "error");
    }
  };

  return (
    <div>
      <>
        <Dialog open={dialogShow} {...dialogProps} />
        <Snackbar
          severity={severity}
          open={isActive}
          message={message}
          onClose={setIsActive}
        />
        <SiteVisitUpdatePopup
          openFlag={openSiteVisitPopup}
          setCloseFlag={setOpenSiteVisitPopup}
          onSubmit={onSiteVisitSubmit}
          id={siteVisitPopupId}
          editDate={editDate}
        />
        <Grid
          item
          lg={10}
          md={10}
          sm={11}
          xs={11}
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="2em 0 2em 0;"
          >
            <PLPHeader />
          </Grid>
        </Grid>
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          padding="2em"
        >
          <span className="pdp-lo-dtls-txt">site visit details</span>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12} style={{ padding: 20 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              defaultHiddenColumnNames={defaultHiddenColumnNames}
              filteringStateColumnExtensions={filteringStateColumnExtensions}
              sortingStateColumnExtensions={sortingStateColumnExtensions}
              loadGrid={loadGrid}
              totalRecords={totalRecords}
              action={["SITE_VISIT_EDIT"]}
              actionClick={actionClick}
            />
          </Grid>
        </Grid>
      </>
    </div>
  );
};

export default SiteVisit;
