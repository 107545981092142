//Material UI imports
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Grid from "@mui/material/Grid";
//Custom imports
import * as CSS from "../Style.js";

const NearByToolTip = (props) => {
  const { placeName, placeAddress, setDirectionHoverDetails } = props;

  return (
    <Grid
      container
      direction="column"
      gap="1em"
      justifyContent="center"
      alignItems="center"
      style={CSS.GlobalGrid}
    >
      <div className='nb-popup-close-btn'>
          <IconButton
            onClick={() => setDirectionHoverDetails(null)}
          >
            <HighlightOffIcon />
          </IconButton>
      </div>
      <CSS.Sqft style={{ paddingTop: '25px'}}>{placeName}</CSS.Sqft>
      <CSS.CardMessage promoterName={true}>{placeAddress}</CSS.CardMessage>
    </Grid>
  );
};

export default NearByToolTip; 
