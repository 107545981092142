import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import WestIcon from "@mui/icons-material/West";
import InfoIcon from '@mui/icons-material/Info';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
//Other lib imports
//Custom imports
import PropertyTypeHelp from "../../Overlays/PropertyTypeHelp/PropertyTypeHelp";

const AddPropertyStepThree = (props) => {
  const { nextStep, propData, inputChange } = props;
  const [open, setOpen] = useState(false);

  const onBackBtnClk = () => {
    nextStep(2);
  };

  const onNextBtnClk = () => {
    nextStep(4);
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Tell me about your Property Type</span>
                <div>
                  <span className="heading-step">(Step 3 of 11)</span>
                </div>
              </div>
              {
                propData.sellerType === `agent` && (
                  <FormControl fullWidth className='form-control-container'>
                    <FormLabel className='form-control-label'>Property is for*</FormLabel>
                    <RadioGroup
                      name={'saleType'}
                      value={propData.saleType}
                      onChange={ e => inputChange(e) }
                    >
                      <FormControlLabel
                        value="sale"
                        control={<Radio />}
                        label="Sale"
                      />
                      <FormControlLabel
                        value="resale"
                        control={<Radio />}
                        label="Resale"
                      />
                    </RadioGroup>
                  </FormControl>
                )
              }
              <FormControl fullWidth className='form-control-container'>
                <FormLabel className='form-control-label'>Property Type*</FormLabel>
                <RadioGroup
                  name={'propertyType'}
                  value={propData.propertyType}
                  onChange={ e => inputChange(e) }
                >
                  <FormControlLabel
                    value="Residential Plot"
                    control={<Radio />}
                    label="Residential Plot"
                  />
                  <FormControlLabel
                    value="Farm Land"
                    control={<Radio />}
                    label="Farm Land"
                  />
                  <FormControlLabel
                    value="Farm House"
                    control={<Radio />}
                    label="Farm House"
                  />
                  <FormControlLabel
                    value="Villa Plot"
                    control={<Radio />}
                    label="Villa Plot"
                  />
                  <FormControlLabel
                    value="Residential Plot in Gated Community"
                    control={<Radio />}
                    label="Residential Plot in Gated Community"
                  />
                </RadioGroup>
              </FormControl>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => setOpen(true)}
                    className="login-button"
                    endIcon={<InfoIcon />}
                  >
                    Help
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <PropertyTypeHelp openFlag={open} setCloseFlag={setOpen} />
    </>
  );
};

export default AddPropertyStepThree;
