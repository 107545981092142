import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
//Other lib imports
//Custom imports

const AddPropertyStepSeven = (props) => {
  const { nextStep, propData, inputChange } = props;
 
  const onBackBtnClk = () => {
    nextStep(6);
  };

  const onNextBtnClk = () => {
    nextStep(8);
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Please share about the Govt. Approval Details</span>
                <div>
                  <span className="heading-step">(Step 7 of 11)</span>
                </div>
              </div>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='RERA Number'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='reraNo'
                  value={propData.reraNo}
                  onChange={ e => inputChange(e) }
                />
              </FormControl>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='Other State Approval Number'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='approvalNo'
                  value={propData.approvalNo}
                  onChange={ e => inputChange(e) }
                />
                <FormHelperText className={'form-control-helper-text'}>Ex: DTCP:158/2023</FormHelperText>
              </FormControl>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPropertyStepSeven;
