import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem"; 
//Other lib imports
//Custom imports
import * as CSS from "../Style";
import { useSnackbar } from "../../../hooks/useSnackbar";
import Snackbar from "../../snackbar";
import XHR from "../../../util/pxhr";

const AddPropertyOptionalStepOne = (props) => {
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const { nextStep, layoutPhotos, setLayoutPhotos, setReusableDialogObj } = props;
  const { isActive, message, severity, openSnackBar, setIsActive } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [disableUploadBtn, setDisableUploadBtn] = useState(false);
  const [imgUrls, setImgUrls] = useState([]);
  
  useEffect(() => {
    setImgUrls([ ...layoutPhotos ]);
  }, []);

  useEffect(() => {
    setDisableUploadBtn(imgUrls.length === 5 ? true : false);
    setLayoutPhotos([ ...imgUrls ]);
  }, [imgUrls]);

  const closeReusableDialog = () => {
    setReusableDialogObj({
      open: false
    });
  }

  const onFilePick = (event) => {
    if(event && event?.target?.files[0]) {
      const fileObj = event?.target?.files[0];
      if(fileObj.size > 2000000) {
        setReusableDialogObj({
          open: true,
          title: '',
          content: 'File size should be less than 2MB.',
          btnArr: [
            {
              label: 'OK',
              fun: closeReusableDialog
            }
          ]
        });
        return;
      }
      if(fileObj.type === 'image/png' || fileObj.type === 'image/jpeg') {
        const config = {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          }
        };
        const formData = new FormData();
        formData.append("file", fileObj);
        formData.append("type", "I");
        setIsLoading(true);
        XHR.post("ads/upload", formData, config).then(
          (res) => {
            if(res?.data?.status) {
              setIsLoading(false);
              openSnackBar("Successfully uploaded.", "success");
              setImgUrls([...imgUrls, res?.data?.url]);
            } else {
              setIsLoading(false);
              openSnackBar("Layout Photos upload failed.", "error");
              console.log(res);
            }
          },
          (err) => {
            setIsLoading(false);
            openSnackBar("Layout Photos upload failed.", "error");
            console.log("err", err);
          }
        );

      } else {
        setReusableDialogObj({
          open: true,
          title: '',
          content: 'Only JPG/JPEG/PNG files are allowed for upload.',
          btnArr: [
            {
              label: 'OK',
              fun: closeReusableDialog
            }
          ]
        });
      }
    }
  }

  const deleteConf = (i) => {
    if(i === 'noSelection') {
      closeReusableDialog();
    } else {
      const copyImgUrls = [ ...imgUrls ];
      copyImgUrls.splice(i, 1);
      setImgUrls([ ...copyImgUrls ]);
      closeReusableDialog();
    }
  }

  const onDeleteImg = (i) => {
    setReusableDialogObj({
      open: true,
      title: 'Are you sure?',
      content: 'Do you want to delete?',
      btnArr: [
        {
          label: 'Yes',
          fun: deleteConf,
          param: i
        },
        {
          label: 'No',
          fun: deleteConf,
          param: 'noSelection'
        }
      ]
    });
  }

  const onOpenImg = (el) => {
    window.open(el,'_blank');
  }

  const onNextBtnClk = () => {
    nextStep(2);
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Please upload Property Photos</span>
                <div>
                  <span className="login-form-heading-step">(Optional Step 1 of 2)</span>
                </div>
              </div>
              <input
                accept="image/jpeg"
                style={{ display: "none" }}
                id="pick-file"
                ref={hiddenFileInput}
                onChange={onFilePick}
                type="file"
              />
              <div className='how-to-gmaps' style={{ marginTop: '34px' }}>
                <Button
                  variant="outlined"
                  size="medium"
                  className={`login-button ${disableUploadBtn && 'disable-button'}`}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => hiddenFileInput.current.click() }
                >
                  Upload Photo
                </Button>
              </div>
              <div>
                <span className="login-form-div-helper-text">
                  (jpg/jpeg/png file less than 2MB)
                </span>
              </div>
              <div>
                <span className="login-form-div-helper-text">
                  (Maximum 5 photos)
                </span>
              </div>
              <div className="profile-back-next-btn-cntr">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => navigate('/property')}
                  className="login-button"
                >
                  My Properties
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onNextBtnClk}
                  className="login-button"
                  endIcon={<EastIcon />}
                >
                  Next
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="2em 0 2em 0"
          padding="0 4em 0 4em"
        >
          <CSS.PromoterDirCardContainer>
            <ImageList cols={6}>
              {imgUrls.map((el, i) => (
                <ImageListItem key={i}>
                  <img
                    src={el}
                    srcSet={el}
                    alt={el}
                    loading="lazy"
                    style={{ height: 100, cursor: "pointer" }}
                    onClick={() => onOpenImg(el)}
                  />
                  <div className="photos-delete" loading="lazy">
                    <Tooltip
                      title="Delete"
                      onClick={() => onDeleteImg(i)}
                    >
                      <DeleteIcon />
                    </Tooltip>
                  </div>
                </ImageListItem>
              ))}
            </ImageList>
          </CSS.PromoterDirCardContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPropertyOptionalStepOne;
