import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import WestIcon from "@mui/icons-material/West";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
//Other lib imports
//Custom imports

const AddPropertyStepTwo = (props) => {
  const navigate = useNavigate();
  const { nextStep, propData, inputChange } = props;

  const onBackBtnClk = () => {
    nextStep(1);
  };

  const onNextBtnClk = () => {
    if(propData.sellerType === 'Plot Promoter') {
      navigate(`/promoterRegistration`);
    } else {
      nextStep(3);
    }
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">What Describes you the best?</span>
                <div>
                  <span className="heading-step">(Step 2 of 11)</span>
                </div>
              </div>
              <FormControl fullWidth className='form-control-container'>
                <FormLabel className='form-control-label'>I am*</FormLabel>
                <RadioGroup
                  name={'sellerType'}
                  value={propData.sellerType}
                  onChange={ e => inputChange(e) }
                >
                  <FormControlLabel
                    value="Owner"
                    control={<Radio />}
                    label="Owner"
                  />
                  <FormControlLabel
                    value="Agent"
                    control={<Radio />}
                    label="Agent"
                  />
                  <FormControlLabel
                    value="Plot Promoter"
                    control={<Radio />}
                    label="Plot Promoter"
                  />
                </RadioGroup>
              </FormControl>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPropertyStepTwo;
