import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
//Material UI imports
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
//Other lib imports
import axios from "axios";
//Custom imports
import * as CSS from "./Style";
import AddPreferenceStepOne from "./AddPreferenceSteps/StepOne";
import AddPreferenceStepTwo from "./AddPreferenceSteps/StepTwo";
import AddPreferenceStepThree from "./AddPreferenceSteps/StepThree";
import AddPreferenceStepFour from "./AddPreferenceSteps/StepFour";
import AddPreferenceStepFive from "./AddPreferenceSteps/StepFive";
import PreferenceHeader from "../HeaderMenu/PreferenceHeader";
import { baseUrl } from "../../util/config";
import ReusableDialog from "../Overlays/ReusableDialog/ReusableDialog";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#835400" : "#308fe8",
  },
}));

const AddPreference = () => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [reusableDialogObj, setReusableDialogObj] = useState({ open: false });
  let user_id = localStorage.getItem("mUserId");
  if(user_id) {
    user_id = parseInt(user_id);
  }

  let loanSupport = false;
  if(state.hasOwnProperty('fullData') && state.fullData.hasOwnProperty('loanSupport')) {
    loanSupport = state?.fullData?.loanSupport;
  }
  let emi = false;
  if(state.hasOwnProperty('fullData') && state.fullData.hasOwnProperty('emi')) {
    emi = state?.fullData?.emi;
  }

  const defaultState = {
    state: state?.fullData?.state || '',
    district: state?.fullData?.district || '',
    city: state?.fullData?.city || '',
    otherCity: state?.fullData?.otherCity || '',
    pinCode: state?.fullData?.pinCode || '',
    radius: state?.fullData?.radius || '2',
    propertyType: state?.fullData?.propertyType || 1, //Same IDs as on filters
    areaUint: state?.fullData?.areaUint || 'sq.ft.',
    minArea: state?.fullData?.minArea || '',
    maxArea: state?.fullData?.maxArea || '',
    direction: state?.fullData?.direction || [], //Same IDs as on filters
    minBudget: state?.fullData?.minBudget || 500000,
    maxBudget: state?.fullData?.maxBudget || 5000000,
    loanSupport,
    loanAmount: state?.fullData?.loanAmount || '',
    emi,
    id: state?.fullData?.id,
    user_id
  }
  const [profileStep, setProfileStep] = useState(1);
  const [propData, setPropData] = useState(defaultState);
  const [stateDetails, setStateDetails] = useState([]);
  const [districtDetails, setDistrictDetails] = useState([]);
  const [cityDetails, setCityDetails] = useState([]);
  const [fullDataOnLoad, setFullDataOnLoad] = useState(null);

  useEffect(() => {
    getStateDetails();
    if(state.fullData) {
      setFullDataOnLoad(state.fullData);
    }
  }, []);

  const getStateDetails = () => {
    const payload = {};
    setIsLoading(true);
    axios.post(`${baseUrl}/plot_api/common/state`, payload).then(
      (res) => {
        if (res.data && res.data.state) {
          setStateDetails(res.data.state || []);
          setIsLoading(false);
          if(propData.district) {
            setIsLoading(true);
            getDistrictDetails(propData.state);
          }
        }
      },
      (err) => {
        setStateDetails([]);
      }
    );
  };

  const getDistrictDetails = (state_id = "") => {
    const payload = {
      state_id,
    };
    axios
      .post(`${baseUrl}/plot_api/common/district`, payload)
      .then(
        (res) => {
          if (res.data && res.data.district) {
            setDistrictDetails(res.data.district || []);
            if(propData.city && propData.state && propData.district) {
              getCityDetails(propData.state, propData.district);
            }
          }
        },
        (err) => {
          setDistrictDetails([]);
        }
      );
  };

  const getCityDetails = (state_id = "", districtid = "") => {
    const payload = {
      state_id,
      districtid,
    };
    axios
      .post(`${baseUrl}/plot_api/common/city`, payload)
      .then(
        (res) => {
          if (res.data && res.data.district) {
            setCityDetails(res.data.district || []);
            setIsLoading(false);
          }
        },
        (err) => {
          setCityDetails([]);
        }
      );
  };

  const inputChange = (e) => {
    if (e.target.name === "state") {
      setPropData({
        ...propData,
        [`${e.target.name}`]: e.target.value,
        district: "",
        city: "",
      });
      getDistrictDetails(e.target.value);
    } else if (e.target.name === "district") {
      setPropData({
        ...propData,
        [`${e.target.name}`]: e.target.value,
        city: "",
      });
      getCityDetails(propData.state, e.target.value);
    } else if (e.target.name === "minBudget") {
      if (e.target.value < propData.maxBudget) {
        setPropData({ ...propData, [`${e.target.name}`]: e.target.value });
      } else {
        setPropData({
          ...propData,
          [`${e.target.name}`]: e.target.value,
          maxBudget: "",
        });
      }
    } else if (e.target.name === "loanSupport") {
      if(e.target.value === false || e.target.value === 'false') {
        setPropData({ ...propData, [`${e.target.name}`]: false, loanAmount: '' });
      } else {
        setPropData({ ...propData, [`${e.target.name}`]: true });
      }
    } else if (e.target.name === "emi") {
      if(e.target.value === false || e.target.value === 'false') {
        setPropData({ ...propData, [`${e.target.name}`]: false });
      } else {
        setPropData({ ...propData, [`${e.target.name}`]: true });
      }
    } else {
      setPropData({ ...propData, [`${e.target.name}`]: e.target.value });
    }
  };

  const stepRender = () => {
    // eslint-disable-next-line default-case
    switch (profileStep) {
      case 1:
        return (
          <AddPreferenceStepOne nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} stateDetails={stateDetails} districtDetails={districtDetails} cityDetails={cityDetails} viewOnly={state.viewOnly} />
        );
      case 2:
        return (
          <AddPreferenceStepTwo nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} viewOnly={state.viewOnly} />
        );
      case 3:
        return (
          <AddPreferenceStepThree nextStep={setProfileStep} propData={propData} inputChange={inputChange} viewOnly={state.viewOnly} />
        );
      case 4:
        return (
          <AddPreferenceStepFour nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} viewOnly={state.viewOnly} fullDataOnLoad={fullDataOnLoad}/>
        );
      case 5:
        return (
          <AddPreferenceStepFive nextStep={setProfileStep} propData={propData} inputChange={inputChange} setReusableDialogObj={setReusableDialogObj} viewOnly={state.viewOnly} askLoanAmount={propData.loanSupport} fullDataOnLoad={fullDataOnLoad}/>
        );
    }
  };

  return (
    <CSS.ParentDiv isMobile={isMobile}>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <ReusableDialog reusableDialogObj={reusableDialogObj} setReusableDialogObj={setReusableDialogObj} />
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
      >
        <PreferenceHeader />
      </Grid>
      <CSS.GlobalProgressBar>
        <BorderLinearProgress variant="determinate" value={profileStep * 20} />
      </CSS.GlobalProgressBar>
      {stepRender()}
    </CSS.ParentDiv>
  );
};

export default AddPreference;
