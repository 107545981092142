import { useState, useEffect } from "react";
//Material UI imports
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//Custom imports
import * as CSS from "../Style.js";

const QueriesPopup = (props) => {
  const { openFlag, setCloseFlag, onSubmit, openSnackBar, details, messages } =
    props;
  const [queriesPopupData, setQueriesPopupData] = useState({
    message: "",
  });

  const queriesModalInputChange = (e) => {
    const modifiedKey = e.target.name;
    const modifiedValue = e.target.value;
    const updatedData = { ...queriesPopupData };
    updatedData[modifiedKey] = modifiedValue;
    setQueriesPopupData(updatedData);
  };

  const doSubmit = () => {
    if (!queriesPopupData.message) {
      openSnackBar("Enter all inputs", "error");
      return;
    }
    onSubmit(queriesPopupData);
    setQueriesPopupData({
      ...queriesPopupData,
      message: "",
    });
  };

  return (
    <CSS.CustomizedDialog
      open={openFlag}
      onClose={() => setCloseFlag(false)}
      maxWidth={"md"}
      fullWidth={true}
    >
      <Grid
        container
        direction="column"
        gap="1em"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <CSS.CloseBtn>
          <IconButton
            aria-label="closePopup"
            onClick={() => setCloseFlag(false)}
          >
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn>
        <CSS.TitleContainer>
          <span className="title">Your Query</span>
        </CSS.TitleContainer>
        <div
          className=""
          style={{ fontSize: 18, padding: 10, background: "#74747429" }}
        >
          <div className="">{(details && details.query) || ""}</div>
        </div>
        <div className="col-md-12 mt-10" style={{ width: "100%" }}>
          <div style={{ fontSize: 18, padding: 10 }}>Messages</div>
          <div
            className="input-item input-item-name"
            style={{
              fontSize: 18,
              padding: 10,
              background: "#74747429",
              height: 200,
              overflowY: "auto",
            }}
          >
            {(messages || []).map((el) => {
              return (
                <div
                  style={{
                    display: "grid",
                    textAlign: `${el.type === 1 ? "left" : "right"}`,
                  }}
                >
                  <span>{el.message}</span>
                  <span style={{ fontSize: 10 }}>
                    {el.date} | {el.time}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <TextField
          name={"message"}
          onChange={queriesModalInputChange}
          value={queriesPopupData.message}
          label="Enter Reply"
          multiline
          rows={4}
          fullWidth={true}
        />
        <CSS.customBtn variant="outlined" onClick={doSubmit}>
          Submit
        </CSS.customBtn>
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default QueriesPopup;
