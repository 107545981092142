import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
//Material UI imports
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CardMedia from "@mui/material/CardMedia";
//Other lib imports
import axios from "axios";
//Custom imports
import { baseUrl } from "../../../util/config";
import "./PropertyCard.css";
import noImg from "../../../Assets/No_Image_Available.jpg";

import { map } from "lodash";

const directions = {
  E: "East",
  W: "West",
  N: "North",
  S: "South",
  NE: "North-East",
  NW: "North-West",
  SE: "South-East",
  SW: "South-West"
}

const PropertyCard = (props) => {
  const navigate = useNavigate();

  const { propertyList, setIsLoading } = props;

  console.log('propertyList coming..')
  console.log(propertyList)

  const onDelete = (id) => {
    const submitUsCnf = window.confirm("Are you sure?");
    if(!submitUsCnf) {
      return;
    }
    const payload = { id };
    setIsLoading(true);
    axios.post(`${baseUrl}/plot_api/realestate_v1/property/delete`, payload).then(
      (res) => {
        if(res?.data?.id && res?.data?.status) {
          setIsLoading(false);
          alert('Property deleted successfully')
          window.location.reload();
        } else {
          setIsLoading(false);
          alert("something went wrong.. Please try again later..");
          window.location.reload();
        }
      },
      (err) => {
        setIsLoading(false);
        alert("something went wrong.. Please try again later..")
        console.log('err came..');
        console.log(err);
        navigate('/');
      }
    );
  }

  const onEdit = (fullData) => {
    navigate("/addProperty", {state: {fullData: {...fullData} } } );
  }

  const onView = (fullData) => {
    // navigate("/addPreference", {state: {fullData: {...fullData}, viewOnly: true } } );
  }

  const onUpdatePhotos = (fullData) => {
    navigate("/addPropertyOptional", {state: {id: fullData.id, fullData: {...fullData} } } );
  }

  const getAddedDate = (rawStr) => {
    const [dateStr, timeStr] = rawStr.split(' ');
    let [yr, mn, dt] = dateStr.split('-');
    yr = parseInt(yr);
    mn = parseInt(mn);
    dt = parseInt(dt);

    const currentDayObj = new Date();
    const currentDate = currentDayObj.getDate();
    const currentMonth = currentDayObj.getMonth() + 1;
    const currentYear = currentDayObj.getFullYear();

    if(currentYear !== yr) {
      return `An year ago`
    }
    if(currentMonth === mn) {
      if(currentDate === dt) {
        return `Today`;
      } else {
        const dayDifference = currentDate - dt;
        return `${dayDifference} day(s) ago`;
      }
    } else {
      const monthDifference = currentMonth - mn;
      return `${monthDifference} month(s) ago`;
    }
  }

  const getDisplayPic = (galleryDetails) => {
    let displayPicUrl = noImg;
    if(galleryDetails && galleryDetails.length) {
      displayPicUrl = galleryDetails[0].url;
    }
    return displayPicUrl;
  }

  return (
      <Grid container padding={{ lg: "1%", md: "1%", sm: "1%", xs: "2%" }} justifyContent={'center'} alignItems={'center'}>
        {/* {isLoading && <PlotCardSkeleton />} */}
        {
          map(propertyList, (data, index) => {
            return (
              <Grid
                lg={3}
                md={6}
                sm={12}
                xs={12}
                key={`item-${index}`}
                className="plot-grid"
              >
                <Card
                  className="plot-card"
                  style={
                    isMobile ? { padding: "0px" } : { padding: "15px 10px" }
                  }
                >
                  <CardActionArea className="plot-action">
                    <CardContent className="prop-card-cnt">
                      <div className="card-heading-contr">
                        <div className="card-heading">
                          <p className='prop-card-heading-name'>
                            {data.layoutName}
                          </p>
                          <span className={'card-heading-type'} >
                            { `Plot No. ${data.plotNo}`}
                          </span>
                          <p className={'card-heading-type prop-card-heading-bottom'} >
                            {`${data.propertyType} in ${data.city_name}`}
                          </p>
                          {/* <p className={'card-heading-address'} > 
                            {data.plotNo}
                          </p> */}
                        </div>
                      </div>
                      <CardMedia
                        component="img"
                        height="140"
                        image={getDisplayPic(data.galleryDetails)}
                        alt="green iguana"
                        className="card-img"
                        // style={ loading ? { display: 'none' } : { display: 'block' } }
                        // onLoad={() => { setLoading(false)} }
                      />
                      <div className="card-info-contr">
                        <div
                          className="prop-card-info-left-cont"
                          style={
                            isMobile
                              ? { paddingTop: "5px" }
                              : { paddingTop: "10px" }
                          }
                        >
                          <span>Area</span>
                          <div>
                            <span className="card-info-text">
                              {`${data.plotArea} sq.ft.`} 
                            </span>
                          </div>
                        </div>
                        <div
                          className="prop-card-info-right-cont"
                          style={
                            isMobile
                              ? { paddingTop: "5px", minHeight: "40px" }
                              : { paddingTop: "10px" }
                          }
                        >
                          <span>Price</span>
                          <div>
                            <span className="card-info-text">
                              {`₹${data.expectedPrice}`}
                            </span>
                          </div>
                        </div>
                        <div
                          className="prop-card-info-left-cont"
                          style={
                            isMobile
                              ? { paddingTop: "5px" }
                              : { paddingTop: "10px" }
                          }
                        >
                          <span>Direction Facing</span>
                          <div>
                            <span className="card-info-text">
                              {directions[data.plotDirection]}
                            </span>
                          </div>
                        </div>
                        <div
                          className="prop-card-info-right-cont"
                          style={
                            isMobile
                              ? { paddingTop: "5px", minHeight: "40px" }
                              : { paddingTop: "10px" }
                          }
                        >
                          <span>Listed By</span>
                          <div>
                            <span className="card-info-text">
                              {data.sellerType}
                            </span>
                          </div>
                        </div>
                        <div
                          className="prop-card-info-left-cont"
                          style={
                            isMobile
                              ? { paddingTop: "5px" }
                              : { paddingTop: "10px" }
                          }
                        >
                          <span>Added/Updated</span>
                          <div>
                            <span className="card-info-text">
                              {getAddedDate(data.ts)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                    <CardActions disableSpacing className="card-actns">
                      <Tooltip title="Update Photos">
                        <IconButton onClick={() => onUpdatePhotos(data)} >
                          <AddPhotoAlternateIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Property">
                        <IconButton onClick={() => onEdit(data)} >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Property">
                        <IconButton onClick={() => onDelete(data.id)} >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
  );
};

export default PropertyCard;
