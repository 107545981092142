import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
// import Login from './Components/Login/Login';
// import Home from './Components/Home/Home';
// import Searchresults from './Components/Searchresults/Searchresults';
// import Psp from './Components/PSP/Psp';
// import Pdp from './Components/PDP/Pdp';

import ProtectedLayout from "./Layout/Protected";

function App() {
  return (
    // <Login />
    // <Searchresults />
    // <Psp />
    // <Pdp />
    <>
      <div className="App">
        <ProtectedLayout />
      </div>
      <footer>
        <div className="social-media">
          <a href="https://www.facebook.com/profile.php?id=100090891621355" target="_blank"><FacebookIcon /></a>
          <a href="https://www.instagram.com/findmyplots_com/" target="_blank"><InstagramIcon /></a>
          <a href="https://www.linkedin.com/company/findmyplots-com/" target="_blank"><LinkedInIcon /></a>
          <a href="https://www.youtube.com/@findmyplotsdotcom" target="_blank"><YouTubeIcon /></a>
        </div>
        <div>
          <a href="https://findmyplots.com/about/terms/" target="_blank">Terms of Use</a>
          &nbsp;|&nbsp; 
          <a href="https://findmyplots.com/about/privacy/" target="_blank">Privacy Policy</a>
          &nbsp;|&nbsp; 
          <a href="https://findmyplots.com/about/refund/" target="_blank">Refund Policy</a>
        </div>
        <div>
          ©findmyplots.com - A Product of Omega Innovative Solutions Private Limited 
        </div>
      </footer>
    </>
  );
}

export default App;
