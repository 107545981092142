import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import FormControl from '@mui/material/FormControl';
//Other lib imports
//Custom imports

const AddPropertyStepEight = (props) => {
  const { nextStep, propData, inputChange, setReusableDialogObj } = props;

  const closeReusableDialog = () => {
    setReusableDialogObj({
      open: false
    });
  }

  const onBackBtnClk = () => {
    nextStep(7);
  };

  const onNextBtnClk = () => {
    if(propData.layoutName) {
      if(propData.layoutName.length > 50) {
        setReusableDialogObj({
          open: true,
          title: '',
          content: 'Layout Name should be within 50 characters',
          btnArr: [
            {
              label: 'OK',
              fun: closeReusableDialog
            }
          ]
        });
        return;
      }
    }
    if(propData.plotNo) {
      if(propData.plotNo.length > 10) {
        setReusableDialogObj({
          open: true,
          title: '',
          content: 'Plot Number should be within 10 alpha numeric characters',
          btnArr: [
            {
              label: 'OK',
              fun: closeReusableDialog
            }
          ]
        });
        return;
      }
      if((/^[a-zA-Z0-9]*$/).test(propData.plotNo.length)) {
        //Validation Passed
      } else {
        setReusableDialogObj({
          open: true,
          title: '',
          content: 'Plot Number should be within 10 alpha numeric characters',
          btnArr: [
            {
              label: 'OK',
              fun: closeReusableDialog
            }
          ]
        });
        return;
      }
      nextStep(9);
    } else {
      setReusableDialogObj({
        open: true,
        title: '',
        content: 'Please enter all mandatory inputs',
        btnArr: [
          {
            label: 'OK',
            fun: closeReusableDialog
          }
        ]
      });
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Tell me about the Layout and your Property</span>
                <div>
                  <span className="heading-step">(Step 8 of 11)</span>
                </div>
              </div>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='Layout Name'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='layoutName'
                  value={propData.layoutName}
                  onChange={ e => inputChange(e) }
                />
              </FormControl>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='Plot Number*'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='plotNo'
                  value={propData.plotNo}
                  onChange={ e => inputChange(e) }
                />
              </FormControl>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPropertyStepEight;
