import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EastIcon from '@mui/icons-material/East';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
//Custom imports
import * as CSS from "./style";

function PromoterMobileVerification() {

    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = useState('')
    const [verified, setVerified] = useState(false)

    const onGetOtpBtnClk = () => {
        alert("onGetOtpBtnClk");
    }

    const onVerifyBtnClk = () => {
        setVerified(!verified);
    }

    const onDashboardBtnClk = () => {
        navigate("/promoterWelcome")
    }

    return (
        <CSS.ParentDiv>
        {
            !verified && (
                <Grid container justifyContent="center" alignItems="center" height='100vh'>
                    <Grid item lg={4} md={8} xs={11}>
                        <Paper elevation={24} bgcolor='white' style={{borderRadius: '20px'}}>
                        <Box justifyContent="center" alignItems='center' textAlign='center' flexDirection='column' padding='25px'>
                            <div className='login-form-div'>
                                <span className='login-form-heading'>Mobile Verification</span>
                            </div>
                            <div className='login-form-div'>
                                <TextField 
                                    label="Mobile Number" 
                                    variant="outlined" 
                                    className='login-inputWidth' 
                                    size='small' 
                                    error={ mobileNumber && !((/^\d{10}$/).test(mobileNumber)) } 
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                />
                            </div>
                            <div className='login-form-div'>
                                <Button variant="outlined" size='medium' onClick={onGetOtpBtnClk} className='login-button'>
                                    Get OTP
                                </Button>
                            </div>
                            <div className='login-form-div'>
                                <TextField label="Enter OTP" variant="outlined" className='login-inputWidth' size='small' />
                            </div>
                            <div className='login-form-div'>
                                <Button variant="outlined" size='medium' onClick={onVerifyBtnClk} className='login-inputWidth login-button' endIcon={<EastIcon />}>
                                    Verify
                                </Button>
                            </div>
                        </Box>
                        </Paper>
                    </Grid>
                </Grid>
            )
        }
        {
            verified && (
                <Grid container justifyContent="center" alignItems="center" height='100vh'>
                    <Grid item lg={4} md={8} xs={11}>
                        <Paper elevation={24} bgcolor='white' style={{borderRadius: '20px'}}>
                            <Box justifyContent="center" alignItems='center' textAlign='center' flexDirection='column' padding='25px'>
                                <div className='login-form-div'>
                                    <span className='login-form-heading'>Basic Details</span>
                                </div>
                                <div className='login-form-div'>
                                    <TextField label="Promoter Name" variant="outlined" required className='login-inputWidth' size='small' />
                                </div>
                                <div className='login-form-div'>
                                    <TextField label="Proprietor Name" variant="outlined" required className='login-inputWidth' size='small' />
                                </div>
                                <div className='login-form-div'>
                                    <TextField label="E-Mail" variant="outlined" required className='login-inputWidth' size='small' />
                                </div>
                                <div className='login-form-div'>
                                    <TextField label="Website" variant="outlined" required className='login-inputWidth' size='small' />
                                </div>
                                <div className='login-form-div'>
                                    <TextField label="Office Number" variant="outlined" required className='login-inputWidth' size='small' />
                                </div>
                                <div className='login-form-div'>
                                    <TextField label="Office Address" variant="outlined" required multiline rows={4} maxRows={4} className='login-inputWidth' size='small' />
                                </div>
                                <div className='login-form-div'>
                                    <Button variant="outlined" size='medium' onClick={onDashboardBtnClk} className='login-inputWidth login-button' endIcon={<EastIcon />}>
                                        Enter Dashboard
                                    </Button>
                                </div>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            )
        }  
        </CSS.ParentDiv>
    );
}

export default PromoterMobileVerification;
