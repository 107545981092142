import { useState, useEffect } from "react";
//Material UI imports
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//Custom imports
import * as CSS from "../Style.js";

const SiteVisitUpdatePopup = (props) => {
  const { openFlag, setCloseFlag, onSubmit, id, editDate } = props;
  const [siteVisitPopupData, setSiteVisitPopupData] = useState({
    id: "",
    date: new Date(),
  });

  const siteVisitModalInputChange = (e) => {
    let modifiedKey = "";
    let modifiedValue = "";
    if (e.$d) {
      modifiedKey = "date";
      modifiedValue = e.format("YYYY-MM-DD");
    } else {
      modifiedKey = e.target.name;
      modifiedValue = e.target.value;
    }
    const updatedData = { ...siteVisitPopupData };
    updatedData[modifiedKey] = modifiedValue;
    setSiteVisitPopupData(updatedData);
  };

  const siteVisitModalSubmit = () => {
    onSubmit(siteVisitPopupData);
  };

  useEffect(() => {
    setSiteVisitPopupData({
      ...siteVisitPopupData,
      date: editDate,
      id: id,
    });
  }, [id]);

  return (
    <CSS.CustomizedDialog
      open={openFlag}
      onClose={() => setCloseFlag(false)}
      maxWidth={"lg"}
    >
      <Grid
        container
        direction="column"
        gap="1em"
        justifyContent="center"
        alignItems="center"
      >
        <CSS.CloseBtn>
          <IconButton
            aria-label="closePopup"
            onClick={() => setCloseFlag(false)}
          >
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn>
        <CSS.TitleContainer>
          <span className="update-schedule-title">Update Site Visit Date</span>
        </CSS.TitleContainer>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Site Visit Date"
            minDate={new Date()}
            maxDate={new Date().setMonth(new Date().getMonth() + 1)}
            value={siteVisitPopupData.date}
            onChange={(newValue) => siteVisitModalInputChange(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth={true} />}
          />
        </LocalizationProvider>
        <CSS.customBtn variant="outlined" onClick={siteVisitModalSubmit}>
          Submit
        </CSS.customBtn>
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default SiteVisitUpdatePopup;
