import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Chip from "@mui/material/Chip";
import LanguageIcon from "@mui/icons-material/Language";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
//Other lib imports
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { map } from "lodash";

//Custom imports
import LoadingScreen from "../../Default/LoadingScreen";
import { TlEnd, TlContent } from "../TimeLineDivider/TimeLineDivider";
import PromoterPDPHeader from "../../HeaderMenu/PromoterPDPHeader";
import PromoterPDPDivider from "./Divider/PromoterPDPDivider";
import PlotCard from "../../PLP/PlotCard/PlotCard";
import Dialog from "../../Dialog";
import PartialOnboard from "../../Overlays/PartialOnboard/PartialOnboard";
import GoogleLoginPopup from "../../Overlays/GoogleLoginPopup/GoogleLoginPopup";
//Styling imports
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as CSS from "./Style";

import { baseUrl } from "../../../util/config";

const PromoterPdp = () => {
  let { id, company_hash } = useParams();

  const scrollRef = useRef([]);
  const navigate = useNavigate();
  const [dialogShow, setDialogShow] = React.useState(false);
  const [dialogProps, setDialogProps] = React.useState({});
  const [openGoogleLoginPopup, setOpenGoogleLoginPopup] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [companyDetails, setCompanyDetails] = useState({});
  const [openPartialOnboardPopup, setOpenPartialOnboardPopup] =
    React.useState(false);
  const [partialData, setPartialData] = React.useState(null);
  const [fav, setFav] = useState([]);
  const [slides, setSlides] = useState([]);
  const [videoSlides, setVideoSlides] = useState([]);
  const [promotionData, setPromotionData] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [city, setCity] = useState("All Cities");
  const [data, setData] = useState({
    general_information: {
      door_no: "",
      street: "",
      address1: "",
      address2: "",
      town: "",
      city: "",
      taluk: "",
      district: "",
      state: "",
      pincode: "",
      country: "",
      landmark: "",
      no_of_projects: "",
      about_company: "",
    },
    board_of_directors: [],
    team: [],
    feedback: [],
    gallery: [],
  });

  useEffect(() => {
    let slideImage = [];
    let slideVideos = [];
    if (data && data.gallery.length) {
      data.gallery.forEach((ele, index) => {
        if (ele.type === "image") {
          slideImage.push({
            name: "Image_" + index,
            imgTag: <img src={ele.url} alt={index} />,
          });
        }
        if (ele.type === "youtube") {
          const vdId = ele.url.split("/").pop();
          slideVideos.push(vdId);
        }
      });
    }
    slideImage.length > 0 && setSlides(slideImage);
    slideVideos.length > 0 && setVideoSlides(slideVideos);

    // data &&
    //   data.gallery &&
    //   data.gallery.filter((d) => d.type === "image" || d.type === "youtube")
    //     .length > 0 &&
    //   data.gallery
    //     .filter((d) => d.type === "image" || d.type === "youtube")
    //     .map((ele, index) => {
    //       slideImage.push({
    //         name: "Image_" + index,
    //         imgTag: <img src={ele.url} alt={index} />,
    //       });
    //     });
    // // console.log("slideImage", slideImage);

    // slideImage.length > 0 && setSlides(slideImage);

    data && data.promoters_id && getAllPromotorsData(data.promoters_id);
    data && data.promoters_id && getCompanyDetails(data.promoters_id);
  }, [data]);

  useEffect(() => {
    const user = localStorage.getItem("user") || null;
    if (user) {
      const userData = JSON.parse(user);
      setUserDetails(userData);
    }
  }, []);

  useEffect(() => {
    if (id !== "") {
      getPlotDetails(company_hash);
    }
  }, [company_hash]);

  const getAllPromotorsData = (promoters_id) => {
    const payload = { promoters_id };
    axios
      .post(`${baseUrl}/plot_api/realestate/promoters/getdata`, payload)
      .then(
        (res) => {
          setPromotionData((res.data && res.data.list) || []);
        },
        (err) => {
          // setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  const getPlotDetails = (company_id) => {
    const payload = { company_id };
    axios.post(`${baseUrl}/plot_api/realestate/promoters/get`, payload).then(
      (res) => {
        setData((res.data && res.data.companies) || []);
      },
      (err) => {
        // setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getCompanyDetails = (user_id = "", city = "") => {
    let user = "";
    const user_data = localStorage.getItem("user") || null;
    if (user_data) {
      const userData = JSON.parse(user_data);
      user = userData.googleId;
    }

    const payload = {
      user_id,
      city,
      user,
    };
    axios
      .post(`${baseUrl}/plot_api/realestate/company/companyplp`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            setProjectDetails(res.data.pData || null);
            setCityData(res.data.city || null);
            setFav(res.data.fav || []);
            setProjectCount(
              (res.data.projects && res.data.projects.length) || 0
            );
          }
        },
        (err) => {
          setCompanyDetails(null);
          setProjectDetails(null);
          console.log("err", err);
        }
      );
  };

  const getPromotorAddress = (promotersDetails) => {
    let add = "";
    if (promotersDetails && promotersDetails.door_no) {
      add = `${promotersDetails.door_no}`;
    }
    if (promotersDetails && promotersDetails.street) {
      add = `${add}, ${promotersDetails.street}`;
    }
    if (promotersDetails && promotersDetails.address1) {
      add = `${add}, ${promotersDetails.address1}`;
    }
    if (promotersDetails && promotersDetails.address2) {
      add = `${add}, ${promotersDetails.address2}`;
    }
    if (promotersDetails && promotersDetails.town) {
      add = `${add}, ${promotersDetails.town}`;
    }
    if (promotersDetails && promotersDetails.city) {
      add = `${add}, ${promotersDetails.city}`;
    }
    if (promotersDetails && promotersDetails.pincode) {
      add = `${add}, ${promotersDetails.pincode}`;
    }
    return add;
  };

  const openPDP = (
    e,
    state,
    district,
    taluk,
    area1,
    area2,
    promotors,
    company_hash,
    company_userid,
    name,
    data
  ) => {
    e.stopPropagation();

    let path = "/pdp";

    if (state) {
      path = `${path}/${state.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (district) {
      path = `${path}/${district.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (taluk) {
      path = `${path}/${taluk.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (area1) {
      path = `${path}/${area1.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (area2) {
      path = `${path}/${area2.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (promotors) {
      path = `${path}/${promotors.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (name) {
      path = `${path}/${name.replace(/\s+/g, "").toLowerCase()}`;
    }

    path = `${path}/${company_hash}`;

    if (company_hash != "") {
      // Call PDP

      navigate(path, {
        state: { id: company_userid, isfav: false },
      });
    }
  };

  const closeDialog = () => {
    setDialogShow(false);
    setTimeout(() => {
      setDialogProps({});
    }, 100);
  };

  const updateFav = (id, data) => {
    if (userDetails && userDetails.googleId) {
      var isFavAction = 0;
      setFav((fav) => {
        const copyItems = fav.length > 0 ? [...fav] : [...fav];
        let isFavStatus = 0;
        if (copyItems.includes(id)) {
          copyItems.splice(copyItems.indexOf(id), 1);
        } else {
          copyItems.push(id);
          isFavStatus = 1;
          isFavAction = 1;
        }
        const payload = {
          project: id,
          source_id: id,
          is_fav: isFavStatus,
          user: userDetails && userDetails.googleId ? userDetails.googleId : "",
          type: 1,
        };
        axios.post(`${baseUrl}/plot_api/realestate/api/fav`, payload).then(
          (res) => {
            if (res.data && res.data.status) {
              // handleChatClose();
              // openSnackBar("Success", "success");
            }
          },
          (err) => {
            // openSnackBar("Failed to submit", "success");
            console.log("err", err);
          }
        );
        return copyItems;
      });
      sucessFavourite(data, isFavAction);
    }
  };

  const sucessFavourite = (data, isFavAction) => {
    setDialogShow(true);
    setDialogProps({
      title: "Favourite",
      message: (
        <h1>
          {isFavAction === 1
            ? `Successfully added to your Favourite`
            : `Successfully Removed your Favourite`}
        </h1>
      ),
      onClose: (yes) => {
        closeDialog(true);
      },
      button: [],
    });
  };

  const goToTab = (index) => {
    console.log("index", index);
    const next = scrollRef.current[index];
    if (next) {
      next.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {!data.general_information.company_name && <LoadingScreen />}
      <Dialog open={dialogShow} {...dialogProps} />
      <PartialOnboard
        openFlag={openPartialOnboardPopup}
        setCloseFlag={setOpenPartialOnboardPopup}
        toolTipContent={partialData}
      />
      <GoogleLoginPopup
        openFlag={openGoogleLoginPopup}
        setCloseFlag={setOpenGoogleLoginPopup}
      />
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
      >
        <PromoterPDPHeader
          openLogin={setOpenGoogleLoginPopup}
          goToTab={goToTab}
          headerDetails={[
            {
              name: "Gallery",
              id: 0,
              visible: slides.length > 0,
            },
            {
              name: "Board of Directors",
              id: 1,
              visible:
                data &&
                data.show_pdp_directors === 1 &&
                promotionData &&
                promotionData.filter((el) => el.type === "board_of_directors")
                  .length > 0,
            },
            {
              name: "About Us",
              id: 2,
              visible: true,
            },
            {
              name: "Team",
              id: 3,
              visible:
                data &&
                data.show_pdp_team === 1 &&
                promotionData &&
                promotionData.filter((el) => el.type === "team").length > 0,
            },
            {
              name: "Review",
              id: 4,
              visible:
                data &&
                data.show_pdp_feedback === 1 &&
                promotionData &&
                promotionData.filter((el) => el.type === "feedback").length > 0,
            },
            {
              name: "Available Projects",
              id: 5,
              visible: true,
            },
          ]}
        />
      </Grid>
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="2em 0 2em 0;"
          ref={(el) => (scrollRef.current[0] = el)}
        >
          {data &&
            data.general_information &&
            data.general_information.logo !== "" && (
              <div className="">
                <img height={150} src={data.general_information.logo} alt="#" />
              </div>
            )}
        </Grid>
        <CSS.PromoterName isMobile={isMobile}>
          {(data &&
            data.general_information &&
            data.general_information.company_name) ||
            ""}
        </CSS.PromoterName>
        <CSS.PromoterAddress isMobile={isMobile}>
          {getPromotorAddress(data && data.general_information)}
        </CSS.PromoterAddress>
        <CSS.PromoterAdditionalDetails isMobile={isMobile}>
          {data &&
            data.general_information &&
            data.general_information.email !== undefined && (
              <Chip
                icon={<EmailIcon />}
                label={data.general_information.email}
                variant="outlined"
              />
            )}
          {data &&
            data.general_information &&
            data.general_information.website !== undefined && (
              <Chip
                icon={<LanguageIcon />}
                label={data.general_information.website}
                variant="outlined"
                onClick={() => window.open(data.general_information.website)}
              />
            )}
          {data &&
            data.general_information &&
            data.general_information.contact !== undefined && (
              <Chip
                icon={<PhoneIphoneIcon />}
                label={data.general_information.contact}
                variant="outlined"
              />
            )}
        </CSS.PromoterAdditionalDetails>
      </Grid>
      <CSS.CarouselWrapperDiv
        isMobile={isMobile}
        ref={(el) => (scrollRef.current[0] = el)}
      >
        {slides.length > 0 && (
          <Carousel
            showArrows={true}
            stopOnHover={false}
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
            animationHandler="fade"
          >
            {slides.map((ele) => {
              return (
                <div>
                  <img src={ele.imgTag.props.src} />
                </div>
              );
            })}
          </Carousel>
        )}
      </CSS.CarouselWrapperDiv>

      {data &&
        data.show_pdp_directors === 1 &&
        promotionData &&
        promotionData.filter((el) => el.type === "board_of_directors").length >
          0 && (
          <>
            {videoSlides.length && (
              <Grid
                container
                gap="1em"
                justifyContent="center"
                alignItems="center"
                direction="column"
                margin="2em 0 2em 0;"
              >
                <CSS.PromoterGridTitles isMobile={isMobile}>
                  videos
                </CSS.PromoterGridTitles>
                <div className="videos">
                  <iframe
                    style={
                      isMobile
                        ? { width: "85vw", height: "auto" }
                        : { width: "60vw", height: "75vh" }
                    }
                    src={
                      "https://www.youtube.com/embed?rel=0&playlist=" +
                      videoSlides
                    }
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="true"
                  ></iframe>
                </div>
              </Grid>
            )}
            <Grid
              container
              gap="1em"
              justifyContent="center"
              alignItems="center"
              direction="column"
              margin="2em 0 2em 0;"
              ref={(el) => (scrollRef.current[1] = el)}
            >
              <CSS.PromoterGridTitles isMobile={isMobile}>
                board of directors
              </CSS.PromoterGridTitles>
            </Grid>
            <Grid
              container
              gap={isMobile ? "1em" : "3em"}
              justifyContent="center"
              alignItems="center"
              direction="row"
              margin="2em 0 2em 0"
              padding="0 4em 0 4em"
            >
              {promotionData &&
                promotionData
                  .filter((el) => el.type === "board_of_directors")
                  .map((row, i) => (
                    <CSS.PromoterDirCardContainer isMobile={isMobile}>
                      <Avatar
                        alt={row.name.toUpperCase()}
                        src={row.photo}
                        className="pdp-contact-avatar"
                      />
                      <div className="pdp-contact-card-nme">
                        {row.name || ""}
                      </div>
                      {row.job_title !== "" && (
                        <div className="pdp-contact-card-title">
                          {row.job_title || ""}
                        </div>
                      )}
                      {row.email_id !== "" && (
                        <div className="pdp-contact-card-contacts">
                          <MailOutlineIcon />
                          {row.email_id || ""}
                        </div>
                      )}
                      {row.contact_no !== "" && (
                        <div className="pdp-contact-card-contacts">
                          <LocalPhoneIcon />
                          {row.contact_no || ""}
                        </div>
                      )}
                    </CSS.PromoterDirCardContainer>
                  ))}
            </Grid>
          </>
        )}
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
        ref={(el) => (scrollRef.current[2] = el)}
      >
        <CSS.PromoterGridTitles isMobile={isMobile}>
          about us
        </CSS.PromoterGridTitles>
      </Grid>
      <Grid
        container
        margin={isMobile ? "2em 0 2em 0" : "3em 0 2em 0"}
        direction={{ lg: "row", md: "row", sm: "column", xs: "column" }}
      >
        <Grid item lg={2} md={2} sm={0} xs={0}></Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            {data &&
              data.general_information &&
              data.general_information.no_of_years !== "" &&
              parseInt(data.general_information.no_of_years) > 0 && (
                <>
                  <TlEnd />
                  <CSS.TimeLineIconContr isMobile={isMobile}>
                    <CalendarMonthIcon />
                  </CSS.TimeLineIconContr>
                  <TlContent
                    title="Number of years"
                    value={parseInt(data.general_information.no_of_years)}
                  />
                </>
              )}
            {data &&
              data.general_information &&
              data.general_information.no_of_cities_covered !== "" &&
              parseInt(data.general_information.no_of_cities_covered) > 0 && (
                <>
                  <TlEnd />
                  <CSS.TimeLineIconContr isMobile={isMobile}>
                    <LocationCityIcon />
                  </CSS.TimeLineIconContr>
                  <TlContent
                    title="Number of Cities Covered"
                    value={parseInt(
                      data.general_information.no_of_cities_covered
                    )}
                  />
                </>
              )}
            {data &&
              data.general_information &&
              data.general_information.no_of_project_completed !== "" &&
              parseInt(data.general_information.no_of_project_completed) >
                0 && (
                <>
                  <TlEnd />
                  <CSS.TimeLineIconContr isMobile={isMobile}>
                    <FactCheckIcon />
                  </CSS.TimeLineIconContr>
                  <TlContent
                    title="Number of Project Completed"
                    value={parseInt(
                      data.general_information.no_of_project_completed
                    )}
                  />
                </>
              )}
            {data &&
              data.general_information &&
              data.general_information.no_of_project_on_going !== "" &&
              parseInt(data.general_information.no_of_project_on_going) > 0 && (
                <>
                  <TlEnd />
                  <CSS.TimeLineIconContr isMobile={isMobile}>
                    <ManageHistoryIcon />
                  </CSS.TimeLineIconContr>
                  <TlContent
                    title="Number of On-Going Projects"
                    value={parseInt(
                      data.general_information.no_of_project_on_going
                    )}
                  />
                </>
              )}
          </Grid>
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          textAlign={{ lg: "left", md: "left", sm: "center", xs: "center" }}
        >
          <CSS.CompanyDesc isMobile={isMobile}>
            {(data &&
              data.general_information &&
              data.general_information.about_us) ||
              ""}
          </CSS.CompanyDesc>
        </Grid>
      </Grid>
      {data &&
        data.show_pdp_team === 1 &&
        promotionData &&
        promotionData.filter((el) => el.type === "team").length > 0 && (
          <>
            <Grid
              container
              gap="1em"
              justifyContent="center"
              alignItems="center"
              direction="column"
              margin="2em 0 2em 0;"
              ref={(el) => (scrollRef.current[3] = el)}
            >
              <CSS.PromoterGridTitles isMobile={isMobile}>
                team
              </CSS.PromoterGridTitles>
            </Grid>
            <Grid
              container
              gap={isMobile ? "1em" : "3em"}
              justifyContent="center"
              alignItems="center"
              direction="row"
              margin="2em 0 2em 0;"
            >
              {promotionData &&
                promotionData
                  .filter((el) => el.type === "team")
                  .map((row, i) => (
                    <CSS.PromoterDirCardContainer isMobile={isMobile}>
                      <Avatar
                        alt={row.name.toUpperCase()}
                        src={row.photo}
                        className="pdp-contact-avatar"
                      />
                      <div className="pdp-contact-card-nme">
                        {row.name || ""}
                      </div>
                      {row.job_title !== "" && (
                        <div className="pdp-contact-card-title">
                          {row.job_title || ""}
                        </div>
                      )}
                      {row.email_id !== "" && (
                        <div className="pdp-contact-card-contacts">
                          <MailOutlineIcon />
                          {row.email_id || ""}
                        </div>
                      )}
                      {row.contact_no !== "" && (
                        <div className="pdp-contact-card-contacts">
                          <LocalPhoneIcon />
                          {row.contact_no || ""}
                        </div>
                      )}
                    </CSS.PromoterDirCardContainer>
                  ))}
            </Grid>
          </>
        )}

      {data &&
        data.show_pdp_feedback === 1 &&
        promotionData &&
        promotionData.filter((el) => el.type === "feedback").length > 0 && (
          <>
            <Grid
              container
              gap="1em"
              justifyContent="center"
              alignItems="center"
              direction="column"
              margin="2em 0 2em 0;"
              ref={(el) => (scrollRef.current[4] = el)}
            >
              <CSS.PromoterGridTitles isMobile={isMobile}>
                what clients say
              </CSS.PromoterGridTitles>
            </Grid>
            <CSS.ReviewsContainer>
              <Carousel
                centerMode={true}
                showStatus={false}
                showArrows={true}
                stopOnHover={true}
                showThumbs={false}
                autoPlay={true}
                infiniteLoop={true}
                showIndicators={false}
                width={isMobile ? "100vw" : "50vw"}
              >
                {promotionData &&
                  promotionData
                    .filter((el) => el.type === "feedback")
                    .map((row, i) => (
                      <CSS.PromoterDirCardContainer isMobile={isMobile}>
                        {row.photo && (
                          <Avatar
                            alt={row.name.toUpperCase()}
                            src={row.photo}
                            className="pdp-contact-avatar"
                          />
                        )}
                        {row.name && (
                          <div className="pdp-contact-card-nme">
                            {row.name || ""}
                          </div>
                        )}
                        {row.feedback && (
                          <div className="pdp-feedback-txt">
                            {row.feedback || ""}
                          </div>
                        )}
                        {row.location && (
                          <Typography variant="h3">
                            <Chip
                              icon={<LocationOnIcon />}
                              label={row.location}
                            />
                          </Typography>
                        )}
                      </CSS.PromoterDirCardContainer>
                    ))}
              </Carousel>
            </CSS.ReviewsContainer>
          </>
        )}
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        padding="2em 4em 0 4em"
        marginBottom="2em"
        ref={(el) => (scrollRef.current[5] = el)}
      >
        <PromoterPDPDivider
          cityData={cityData}
          sortingOnChange={(e) => setCity(e)}
          totalCnt={projectCount}
        />
      </Grid>
      <Grid
        container
        spacing={isMobile ? 0 : 2}
        justifyContent="center"
        alignItems="center"
      >
        {projectDetails &&
          map(projectDetails, (data, index) => {
            return (
              <>
                {city === "All Cities" || city === index ? (
                  <Grid item xs={12}>
                    <CSS.PromoterGridTitles isMobile={isMobile}>
                      {index.toLowerCase()} ({data.length})
                    </CSS.PromoterGridTitles>
                  </Grid>
                ) : null}
                {city === "All Cities" || city === index ? (
                  <Grid container lg={11} md={11} sm={12} xs={12}>
                    <PlotCard
                      list={data}
                      openPDP={openPDP}
                      setOpenGoogleLoginPopup={() => console.log("clicked")}
                      fav={fav}
                      updateFav={updateFav}
                      isPromotor={true}
                    />
                  </Grid>
                ) : null}
              </>
            );
          })}
      </Grid>
    </>
  );
};

export default PromoterPdp;
