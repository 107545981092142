import { useState } from "react";
//Material UI imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import CardMedia from "@mui/material/CardMedia";
//Custom imports
import "./PlotCard.css";

const PlotCardSkeleton = () => {
    return (
        <>
            <Grid lg={3} md={6} sm={12} xs={12} className="plot-grid">
                <Card className="plot-skeleton-card">
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" variant="rectangular" width="100%" style={{ borderRadius: '10px', height: '140px', marginTop: '13px' }}>
                        <div style={{ paddingTop: '57%' }} />
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                </Card>
            </Grid>
            <Grid lg={3} md={6} sm={12} xs={12} className="plot-grid">
                <Card className="plot-skeleton-card">
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" variant="rectangular" width="100%" style={{ borderRadius: '10px', height: '140px', marginTop: '13px' }}>
                        <div style={{ paddingTop: '57%' }} />
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                </Card>
            </Grid>
            <Grid lg={3} md={6} sm={12} xs={12} className="plot-grid">
                <Card className="plot-skeleton-card">
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" variant="rectangular" width="100%" style={{ borderRadius: '10px', height: '140px', marginTop: '13px' }}>
                        <div style={{ paddingTop: '57%' }} />
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                </Card>
            </Grid>
            <Grid lg={3} md={6} sm={12} xs={12} className="plot-grid">
                <Card className="plot-skeleton-card">
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" variant="rectangular" width="100%" style={{ borderRadius: '10px', height: '140px', marginTop: '13px' }}>
                        <div style={{ paddingTop: '57%' }} />
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                    <Skeleton animation="wave" width="100%" height='65px'>
                        <Typography>.</Typography>
                    </Skeleton>
                </Card>
            </Grid>
        </>
    )
}

const PlotCardImg = (props) => {

    const { data, plotImg } = props;
    const [ loading, setLoading ] = useState(true);

    return (
        <>
            <CardMedia
                component="img"
                height="140"
                image={
                    data.image_url && data.image_url !== ""
                    ? data.image_url
                    : plotImg
                }
                alt="green iguana"
                className="card-img"
                style={ loading ? { display: 'none' } : { display: 'block' } }
                onLoad={() => { setLoading(false)} }
            />
            {
                loading && (
                    <Skeleton animation="wave" variant="rectangular" width="100%" style={{ borderRadius: '10px', height: '140px' }}>
                        <div style={{ paddingTop: '57%' }} />
                    </Skeleton>
                )
            }
        </>
    )
}

export { PlotCardSkeleton, PlotCardImg };