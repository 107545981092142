import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { isMobile } from "react-device-detect";
//Material UI imports
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
//Custom imports
// import "./Filter.css";
import * as CSS from "./FilterStyle";
import RangeFilter from "./RangeFilter";
import CheckboxFilter from "./CheckboxFilter";
import RadioFilter from "./RadioFilter";

const initValForPlp = [
  {
    heading: "AREA SQ/FT",
    val: [0, 5000],
    displayVal: "ANY",
    key: "area",
    minimize: true,
  },
  // {
  //   heading: "RATE PER SQ/FT",
  //   val: [1000, 30000],
  //   displayVal: "ANY",
  //   key: "rate",
  //   minimize: true,
  // },
  {
    heading: "PRICE RANGE",
    val: [100000, 50000000],
    displayVal: "ANY",
    key: "range",
    minimize: true,
  },
  {
    heading: "DIRECTION FACING",
    val: [
      {
        id: 1,
        label: "East(E)",
        ticked: true,
      },
      {
        id: 5,
        label: "North-East(NE)",
        ticked: true,
      },
      {
        id: 3,
        label: "North(N)",
        ticked: true,
      },
      {
        id: 6,
        label: "North-West(NW)",
        ticked: true,
      },
      {
        id: 2,
        label: "West(W)",
        ticked: true,
      },
      {
        id: 7,
        label: "South-East(SE)",
        ticked: true,
      },
      {
        id: 4,
        label: "South(S)",
        ticked: true,
      },
      {
        id: 8,
        label: "South-West(SW)",
        ticked: true,
      },
    ],
    displayVal: "ANY",
    key: "direction",
    minimize: true,
  },
  {
    heading: "AMENITIES",
    val: [
      {
        label: "Park",
        ticked: true,
      },
      {
        label: "Swiming Pool",
        ticked: true,
      },
      {
        label: "Gym",
        ticked: true,
      },
      {
        label: "Temple",
        ticked: true,
      },
      {
        label: "ATMs",
        ticked: true,
      },
      {
        label: "Community Hall",
        ticked: true,
      },
      {
        label: "Security",
        ticked: true,
      },
      {
        label: "Hospital",
        ticked: true,
      },
      {
        label: "Playground",
        ticked: true,
      },
      {
        label: "Schools",
        ticked: true,
      },
      {
        label: "24/7 Power",
        ticked: true,
      },
      {
        label: "RO Water",
        ticked: true,
      },
    ],
    displayVal: "ANY",
    key: "amenities",
    minimize: true,
  },
  // {
  //   heading: "AVAILABILITY",
  //   val: [
  //     {
  //       id: 0,
  //       label: "Available",
  //       ticked: true,
  //     },
  //     {
  //       id: 1,
  //       label: "Sold",
  //       ticked: false,
  //     },
  //     {
  //       id: 2,
  //       label: "Booked",
  //       ticked: false,
  //     },
  //   ],
  //   displayVal: "AVAILABLE",
  //   key: "availability",
  //   minimize: true,
  // },
  {
    heading: "EMI",
    val: "ALL",
    displayVal: "ALL",
    key: "emi",
    minimize: true,
  },
  {
    heading: "PROPERTY TYPE",
    val: [
      {
        id: 1,
        label: "Residential plots",
        ticked: true,
      },
      {
        id: 2,
        label: "Villas",
        ticked: true,
      },
      {
        id: 3,
        label: "Farm Lands",
        ticked: true,
      },
      {
        id: 4,
        label: "Farm House",
        ticked: true,
      },
      {
        id: 5,
        label: "Gated Community",
        ticked: true,
      },
      {
        id: 6,
        label: "Commercial Land",
        ticked: true,
      },
    ],
    displayVal: "ANY",
    key: "property_type",
    minimize: true,
  },
];

const initValForPdp = [
  {
    heading: "AVAILABILITY",
    val: [
      {
        id: 0,
        label: "Available",
        ticked: true,
      },
      {
        id: 1,
        label: "Sold",
        ticked: true,
      },
      {
        id: 2,
        label: "Booked",
        ticked: true,
      },
    ],
    displayVal: "ANY",
    key: "availability",
    minimize: true,
  },
  {
    heading: "CORNER PLOT",
    val: [
      {
        id: "yes",
        label: "Yes",
        ticked: true,
      },
      {
        id: "no",
        label: "No",
        ticked: true,
      },
    ],
    displayVal: "ANY",
    key: "corner",
    minimize: true,
  },
  {
    heading: "DIRECTION FACING",
    val: [
      {
        id: 1,
        label: "East(E)",
        ticked: true,
      },
      {
        id: 5,
        label: "North-East(NE)",
        ticked: true,
      },
      {
        id: 3,
        label: "North(N)",
        ticked: true,
      },
      {
        id: 6,
        label: "North-West(NW)",
        ticked: true,
      },
      {
        id: 2,
        label: "West(W)",
        ticked: true,
      },
      {
        id: 7,
        label: "South-East(SE)",
        ticked: true,
      },
      {
        id: 4,
        label: "South(S)",
        ticked: true,
      },
      {
        id: 8,
        label: "South-West(SW)",
        ticked: true,
      },
    ],
    displayVal: "ANY",
    key: "direction",
    minimize: true,
  },
];

const Filter = (props) => {
  const [filterData, setFilterData] = useState([]);
  const [openDrawer, setopenDrawer] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState("ontouchstart" in document.documentElement);

  useEffect(() => {
    // console.log("props.fliterFor", props.fliterFor);
    if (props.fliterFor === "plp") {
      setFilterData(initValForPlp);
    } else if (props.fliterFor === "pdp") {
      setFilterData(initValForPdp);
    }
  }, []);

  const { filterOnChange, amenities } = props;

  useEffect(() => {
    // console.log("amenities", amenities);
    if (filterData.length > 0) {
      const newFilterData = filterData.map((ele) => {
        if (ele.key === "amenities") {
          let amenitiesData = [];
          amenities.map((data) => {
            amenitiesData.push({
              id: data.id,
              label: data.name,
              ticked: true,
            });
          });
          return { ...ele, val: amenitiesData };
        } else {
          return { ...ele };
        }
      });
      setFilterData(newFilterData);
    }
  }, [amenities]);

  const onFilterExpandBtnClk = (key) => {
    const newFilterData = filterData.map((ele) => {
      if (ele.key === key) {
        return { ...ele, minimize: false };
      } else {
        return { ...ele, minimize: true };
      }
    });
    setFilterData(newFilterData);
    if (isMobile) {
      setopenDrawer(true);
    }
  };

  const filterValChange = (index, newObj) => {
    const updatedFilterData = filterData.map((ele, loop) => {
      if (loop === index) {
        return newObj;
      } else {
        return ele;
      }
    });
    setFilterData(updatedFilterData);
  };

  const onFilterMinimizeBtnClk = () => {
    const newFilterData = filterData.map((ele) => {
      return { ...ele, minimize: true };
    });
    setFilterData(newFilterData);
    filterOnChange(newFilterData);
    if (isMobile) {
      setopenDrawer(false);
    }
  };

  const contentClassNameChecker = () => {
    let maximizedCount = filterData.filter((ele) => ele.minimize === false);
    return maximizedCount.length ? "filter-heading" : "filter-content";
  };

  const drawerContent = () => {
    const maximizedContentArr = filterData.map((filterEle, index) => {
      if (!filterEle.minimize && filterEle.key === "area") {
        return (
          <CSSTransition
            in={!filterEle.minimize}
            timeout={5000}
            appear={true}
            classNames="myclass"
          >
            <RangeFilter
              min={0}
              max={5000}
              objIndex={index}
              objContent={filterEle}
              onModify={filterValChange}
              onDone={onFilterMinimizeBtnClk}
            />
          </CSSTransition>
        );
      }
      if (!filterEle.minimize && filterEle.key === "rate") {
        return (
          <CSSTransition
            in={!filterEle.minimize}
            timeout={5000}
            appear={true}
            classNames="myclass"
          >
            <RangeFilter
              min={1000}
              max={30000}
              objIndex={index}
              objContent={filterEle}
              onModify={filterValChange}
              onDone={onFilterMinimizeBtnClk}
            />
          </CSSTransition>
        );
      }
      if (!filterEle.minimize && filterEle.key === "range") {
        return (
          <CSSTransition
            in={!filterEle.minimize}
            timeout={5000}
            appear={true}
            classNames="myclass"
          >
            <RangeFilter
              min={100000}
              max={50000000}
              objIndex={index}
              objContent={filterEle}
              onModify={filterValChange}
              onDone={onFilterMinimizeBtnClk}
            />
          </CSSTransition>
        );
      }
      if (!filterEle.minimize && filterEle.key === "direction") {
        return (
          <CSSTransition
            in={!filterEle.minimize}
            timeout={5000}
            appear={true}
            classNames="myclass"
          >
            <CheckboxFilter
              objIndex={index}
              objContent={filterEle}
              onModify={filterValChange}
              onDone={onFilterMinimizeBtnClk}
              divider={6}
            />
          </CSSTransition>
        );
      }
      if (!filterEle.minimize && filterEle.key === "amenities") {
        return (
          <CSSTransition
            in={!filterEle.minimize}
            timeout={5000}
            appear={true}
            classNames="myclass"
          >
            <CheckboxFilter
              objIndex={index}
              objContent={filterEle}
              onModify={filterValChange}
              onDone={onFilterMinimizeBtnClk}
              divider={4}
            />
          </CSSTransition>
        );
      }
      if (!filterEle.minimize && filterEle.key === "availability") {
        return (
          <CSSTransition
            in={!filterEle.minimize}
            timeout={5000}
            appear={true}
            classNames="myclass"
          >
            <CheckboxFilter
              objIndex={index}
              objContent={filterEle}
              onModify={filterValChange}
              onDone={onFilterMinimizeBtnClk}
              divider={0}
            />
          </CSSTransition>
        );
      }
      if (!filterEle.minimize && filterEle.key === "emi") {
        return (
          <CSSTransition
            in={!filterEle.minimize}
            timeout={5000}
            appear={true}
            classNames="myclass"
          >
            <RadioFilter
              objIndex={index}
              objContent={filterEle}
              onModify={filterValChange}
              onDone={onFilterMinimizeBtnClk}
            />
          </CSSTransition>
        );
      }
      if (!filterEle.minimize && filterEle.key === "corner") {
        return (
          <CSSTransition
            in={!filterEle.minimize}
            timeout={5000}
            appear={true}
            classNames="myclass"
          >
            <CheckboxFilter
              objIndex={index}
              objContent={filterEle}
              onModify={filterValChange}
              onDone={onFilterMinimizeBtnClk}
              divider={0}
            />
          </CSSTransition>
        );
      }
      if (!filterEle.minimize && filterEle.key === "property_type") {
        return (
          <CSSTransition
            in={!filterEle.minimize}
            timeout={5000}
            appear={true}
            classNames="myclass"
          >
            <CheckboxFilter
              objIndex={index}
              objContent={filterEle}
              onModify={filterValChange}
              onDone={onFilterMinimizeBtnClk}
              divider={0}
            />
          </CSSTransition>
        );
      }
      return false;
    });

    let maximizedDataArr = maximizedContentArr.filter((ele) => ele !== false);

    return maximizedDataArr[0];
  };

  return (
    <CSS.ParentDiv
      isMobile={isMobile}
      contentClassNameChecker={contentClassNameChecker()}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        spacing={1}
        style={{ zoom: isTouchDevice ? 0.8 : 1 }}
      >
        {filterData.map((filterEle, index) => {
          return (
            <Grid
              item
              key={filterEle.key}
              className={!filterEle.minimize ? filterEle.key : ""}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                style={{ minWidth: 130 }}
                className={
                  filterEle.minimize
                    ? "filter-min-box"
                    : isMobile
                    ? "filter-min-box"
                    : "filter-max-box"
                }
                onClick={() =>
                  filterEle.minimize ? onFilterExpandBtnClk(filterEle.key) : ""
                }
              >
                <span className="filter-heading">{filterEle.heading}</span>
                <span
                  className={
                    filterEle.minimize
                      ? contentClassNameChecker()
                      : "filter-content"
                  }
                >
                  {filterEle.displayVal}
                </span>
                {filterEle.minimize && <div className="filter-marker" />}
                {!isMobile && !filterEle.minimize && filterEle.key === "area" && (
                  <CSSTransition
                    in={!filterEle.minimize}
                    timeout={5000}
                    appear={true}
                    classNames="myclass"
                  >
                    <RangeFilter
                      min={0}
                      max={5000}
                      objIndex={index}
                      objContent={filterEle}
                      onModify={filterValChange}
                      onDone={onFilterMinimizeBtnClk}
                    />
                  </CSSTransition>
                )}
                {!isMobile && !filterEle.minimize && filterEle.key === "rate" && (
                  <CSSTransition
                    in={!filterEle.minimize}
                    timeout={5000}
                    appear={true}
                    classNames="myclass"
                  >
                    <RangeFilter
                      min={1000}
                      max={30000}
                      objIndex={index}
                      objContent={filterEle}
                      onModify={filterValChange}
                      onDone={onFilterMinimizeBtnClk}
                    />
                  </CSSTransition>
                )}
                {!isMobile && !filterEle.minimize && filterEle.key === "range" && (
                  <CSSTransition
                    in={!filterEle.minimize}
                    timeout={5000}
                    appear={true}
                    classNames="myclass"
                  >
                    <RangeFilter
                      min={100000}
                      max={50000000}
                      objIndex={index}
                      objContent={filterEle}
                      onModify={filterValChange}
                      onDone={onFilterMinimizeBtnClk}
                    />
                  </CSSTransition>
                )}
                {!isMobile &&
                  !filterEle.minimize &&
                  filterEle.key === "direction" && (
                    <CSSTransition
                      in={!filterEle.minimize}
                      timeout={5000}
                      appear={true}
                      classNames="myclass"
                    >
                      <CheckboxFilter
                        objIndex={index}
                        objContent={filterEle}
                        onModify={filterValChange}
                        onDone={onFilterMinimizeBtnClk}
                        divider={6}
                      />
                    </CSSTransition>
                  )}
                {!isMobile &&
                  !filterEle.minimize &&
                  filterEle.key === "amenities" && (
                    <CSSTransition
                      in={!filterEle.minimize}
                      timeout={5000}
                      appear={true}
                      classNames="myclass"
                    >
                      <CheckboxFilter
                        objIndex={index}
                        objContent={filterEle}
                        onModify={filterValChange}
                        onDone={onFilterMinimizeBtnClk}
                        divider={4}
                      />
                    </CSSTransition>
                  )}
                {!isMobile &&
                  !filterEle.minimize &&
                  filterEle.key === "availability" && (
                    <CSSTransition
                      in={!filterEle.minimize}
                      timeout={5000}
                      appear={true}
                      classNames="myclass"
                    >
                      <CheckboxFilter
                        objIndex={index}
                        objContent={filterEle}
                        onModify={filterValChange}
                        onDone={onFilterMinimizeBtnClk}
                        divider={0}
                      />
                    </CSSTransition>
                  )}
                {!isMobile && !filterEle.minimize && filterEle.key === "emi" && (
                  <CSSTransition
                    in={!filterEle.minimize}
                    timeout={5000}
                    appear={true}
                    classNames="myclass"
                  >
                    <RadioFilter
                      objIndex={index}
                      objContent={filterEle}
                      onModify={filterValChange}
                      onDone={onFilterMinimizeBtnClk}
                    />
                  </CSSTransition>
                )}
                {!isMobile &&
                  !filterEle.minimize &&
                  filterEle.key === "corner" && (
                    <CSSTransition
                      in={!filterEle.minimize}
                      timeout={5000}
                      appear={true}
                      classNames="myclass"
                    >
                      <CheckboxFilter
                        objIndex={index}
                        objContent={filterEle}
                        onModify={filterValChange}
                        onDone={onFilterMinimizeBtnClk}
                        divider={0}
                      />
                    </CSSTransition>
                  )}
                {!isMobile &&
                  !filterEle.minimize &&
                  filterEle.key === "property_type" && (
                    <CSSTransition
                      in={!filterEle.minimize}
                      timeout={5000}
                      appear={true}
                      classNames="myclass"
                    >
                      <CheckboxFilter
                        objIndex={index}
                        objContent={filterEle}
                        onModify={filterValChange}
                        onDone={onFilterMinimizeBtnClk}
                        divider={6}
                      />
                    </CSSTransition>
                  )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Drawer
        anchor={"bottom"}
        open={openDrawer}
        onOpen={() => setopenDrawer(true)}
        onClose={() => onFilterMinimizeBtnClk()}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "25px",
          }}
        >
          {drawerContent()}
        </div>
      </Drawer>
    </CSS.ParentDiv>
  );
};

export default Filter;
