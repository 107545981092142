import styled from 'styled-components';

const ParentDiv = styled.div`

    .no-prop {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15%;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    // new styles - start
    .heading-container {
        margin-top: 3%;
        margin-bottom: 7%;
        text-align: center;
    }

    .heading {
        font-weight: bold;
        color: #835400;
        font-size: x-large;
    }

    .heading-step {
        font-weight: bold;
        color: #835400;
        font-size: medium;
    }

    .action-btn-cntr {
        display: flex;
        justify-content: space-between;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    .action-btn-first-step {
        display: flex;
        justify-content: end;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    .form-control-container {
        margin-bottom: 24px;
    }

    .form-control-label {
        font-size: 22px;
    }

    .form-control-helper-text {
        font-size: 15px;
    }

    .how-to-gmaps {
        text-align: center;
    }
    // new styles - end

    margin-top: 2%;

    .login-inputWidth {
        width: 70%;
    }

    .login-form-heading {
        font-weight: bold;
        color: #835400;
        font-size: x-large;
    }

    .login-form-heading-step {
        font-weight: bold;
        color: #835400;
        font-size: medium;
    }

    .login-form-content-step {
        font-weight: normal;
        color: #835400;
        font-size: small;
    }

    .photos-instructions {
        text-align: left;
        display: flex;
        gap: 10px;
        flex-direction: column;
    }

    .login-form-content {
        color: #835400;
    }

    .login-button {
        text-align: center;
        background-color: #ffddb5 !important;
        color: #835400 !important;
        border-color: wheat !important;
        text-transform: none !important;
    }

    .disable-button {
        opacity: 0.5;
        pointer-events: none;
    }

    .choose-photo-popup-close {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .photos-delete {
        text-align: center;
        background-color: #ffddb5 !important;
        color: #835400 !important;
        border-color: wheat !important;
        text-transform: none !important;
        cursor: pointer; 
    }

    .login-icon-button {
        background-color: #ffddb5 !important;
        color: #835400 !important;
        border-color: black !important;
        margin: 1% !important;
    }

    .dashboard-first-btn {
        margin-top: 10%;
        margin-bottom: 3%;
    }

    .dashboard-progressbar-contr {
        display: flex;
        margin: 5% 15% 5% 15%;
    }

    .dashboard-progressbar {
        padding-top: 10px;
        width: 75%;
    }

    .dashboard-progress-text {
        width: 25%;
    }

    .view-uploaded-logo {
        background-color: white !important;
    }

    .profile-back-next-btn-cntr {
        display: flex;
        justify-content: space-around;
        margin-top: 9%;
        margin-bottom: 3%;
    }

    .login-form-div {
        margin-top: 3%;
        margin-bottom: 3%;
    }

    .profile-incomplete-div {
        margin-top: 10%;
        margin-bottom: 1%;
    }

    .profile-incomplete-text {
        font-weight: normal;
        color: #835400;
        font-size: medium;
    }

    .login-form-upload-div {
        margin-top: 3%;
        margin-bottom: 3%;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .login-form-div-helper-text-contr {
        margin-top: -2%;
        margin-bottom: 5%;
    }

    .login-form-div-helper-text {
        color: rgba(0, 0, 0, 0.6);
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        letter-spacing: 0.03333em;
        text-align: left;
        margin-top: 4px;
        margin-right: 14px;
        margin-bottom: 0;
        margin-left: 14px;
    }

    .login-form-div-with-space {
        margin-top: 3%;
        margin-bottom: 3%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
    }

    .select-status {
        width: 300px;
    }

    .pdp-prj-by {
        font-style: normal;
        font-weight: 500;
        font-size: ${(props) => (props.isMobile ? "20px" : "25px")};
        line-height: ${(props) => (props.isMobile ? "25px" : "32px")};
        text-align: center;
        letter-spacing: -0.25px;
        font-variant: small-caps;
        color: #462b00;
        display: inline;
    }

    .youtube-help-text {
        text-align: left;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 30px;
        letter-spacing: -0.25px;
        color: #000000;
        padding: 0px 10px;
    }

    .youtube-video-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .youtube-video-links {
        cursor: pointer !important;
        text-decoration: none;
    }

    .MuiFormHelperText-root {
        cursor: pointer !important;
    }

    .googleContainer {
        background-color: #4285f4;
        border: none;
        color: #fff;
        height: 50px;
        width: 240px;
        padding: 0px;
        margin: 40px;
    }

    .pdp-amen-card-cntr-selected {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 150px;
        background: #fbdebc;
        border: 1px solid #645d57;
        border-radius: 16px;
        flex: none;
        order: 0;
        flex-grow: 0;
        gap: 20px;
    }

    .googleContentWrapper {
        border: 1px solid transparent;
        cursor: pointer;
    }

    .googleButtonIcon {
        padding: 15px;
        background-color: #fff;
        border-radius: 1px;
        float: left;
    }

    .googleButtonIconImage {
        width: 18px;
        height: 18px;
    }

    .googleButtonContents {
        font-weight: 500;
        letter-spacing: 0.21px;
        margin-left: 20px;
        margin-right: 6px;
        vertical-align: top;
        font-size: 16px;
        line-height: 48px;
    }
`;

const GlobalProgressBar = styled.div`
    margin-bottom: 1%;
    position: fixed;
    top: 0px;
    width: 100%;
`;

const PromoterDirCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;

  .pdp-contact-card-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;
    color: #1f1b16;
  }

  .pdp-contact-card-nme {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;
    color: #1f1b16;
  }

  .pdp-feedback-txt {
    border: dashed 1px #57432b;
    width: 75%;
    border-radius: 5px;
    padding: 10px;
  }

  .pdp-contact-card-contacts {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;
    color: #1f1b16;
    gap: 5px;
  }
`;

export { 
    ParentDiv,
    GlobalProgressBar,
    PromoterDirCardContainer
}