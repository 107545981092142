import React, { useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  Polygon,
  Polyline,
} from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";

import ErrorBoundary from "./ErrorBoundary";

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const { compose, withState, withHandlers, lifecycle } = require("recompose");
const OPTIONS = {
  minZoom: 4,
  maxZoom: 100,
};
const getCenter = (arr) => {
  var x = arr.map((x) => x["lat"]);
  var y = arr.map((x) => x["lng"]);
  var cx = (Math.min(...x) + Math.max(...x)) / 2;
  var cy = (Math.min(...y) + Math.max(...y)) / 2;
  return { lat: cx, lng: cy };
};

const labelSize = { width: 220 };
const labelPadding = 8;

const DrawMaps = compose(
  withState("zooms", "onZoomChange", 7),
  withState("lats", "onLatChange", 11.127123),
  withState("lngs", "onLngChange", 78.656891),
  withHandlers((props) => {
    const refs = {
      map: undefined,
    };
    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
      },
      onZoomChanged: (props) => (e) => {
        props.onZoomChanged(refs.map.getZoom());
      },
      onMapClick:
        ({ onLatChange, onLngChange }) =>
        (e) => {
          onLatChange(e.latLng.lat());
          onLngChange(e.latLng.lng());
        },
    };
  }),
  lifecycle({
    componentDidMount() {
      this.props.onZoomChange(this.props.zoom);
      this.props.onLatChange(this.props.lat);
      this.props.onLngChange(this.props.lng);
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <ErrorBoundary>
    <div>
      <GoogleMap
        options={OPTIONS}
        defaultCenter={props.gps_location}
        defaultZoom={props.zoom_level || 20}
        mapTypeId="roadmap"
        onClick={props.onClick}
        ref={props.onMapMounted}
        onZoomChanged={props.onZoomChanged}
        center={props.gps_location}
      >
        <DrawingManager
          onOverlayComplete={props.overlay}
          defaultOptions={{
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [
                window.google.maps &&
                  window.google.maps.drawing &&
                  window.google.maps.drawing.OverlayType &&
                  window.google.maps.drawing.OverlayType.POLYGON,
                window.google.maps &&
                  window.google.maps.drawing &&
                  window.google.maps.drawing.OverlayType &&
                  window.google.maps.drawing.OverlayType.POLYLINE,
              ],
            },
          }}
          options={{
            drawingControl: props.drawingControlEnabled,
          }}
        />
        {/* {console.log("props.polyline", props.polyline, props.polygon)} */}
        {props.polygon &&
          props.polygon !== null &&
          props.polygon.length > 0 &&
          props.polygon.map((path, i) => (
            <Polygon
              key={i}
              paths={path.gps}
              options={{
                fillColor: path.fill_color ? path.fill_color : "transparent",
                strokeColor: path.line_color ? path.line_color : "#000",
                strokeWeight: 1,
              }}
              onClick={(event) => props.onPolygonClick(path, i)}
              onMouseover={(e) => {
                console.log(path.name);
              }}
              onMouseout={(e) => {
                console.log(path.name);
              }}
            />
          ))}
        {/* {console.log(
          "props.show_boundary",
          props.show_boundary,
          props.polyline
        )} */}
        {props.show_boundary &&
          props.polyline &&
          props.polyline !== null &&
          props.polyline.length > 0 &&
          props.polyline.map((path, i) => (
            <Polyline
              // key={`POLYLINE_${i}`}
              path={path.gps}
              options={{
                fillColor: path.fill_color ? path.fill_color : "transparent",
                strokeColor: path.line_color
                  ? path.line_color
                  : props.boundary_color,
                strokeWeight: 3,
              }}
              onMouseover={(e) => {
                console.log(path.name);
              }}
              onMouseout={(e) => {
                console.log(path.name);
              }}
            />
          ))}

        {props.isProject &&
          props.gps_location &&
          props.gps_location !== null && (
            <Marker position={props.gps_location} />
          )}
        {props.polygon &&
          props.polygon !== null &&
          props.polygon.map((path, i) => (
            <MarkerWithLabel
              labelStyle={{
                textAlign: "center",
                fontSize: "12px",
              }}
              labelClass="map-label"
              labelAnchor={{ x: labelSize.width / 10, y: 15 }}
              key={i}
              icon={" "}
              position={getCenter(path.gps)}
            >
              <span>{path.name}</span>
            </MarkerWithLabel>
          ))}
      </GoogleMap>
    </div>
  </ErrorBoundary>
));

export default DrawMaps;
