/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useState, useEffect, useRef } from "react";

import Grid from "@mui/material/Grid";
import axios from "axios";
import DataGrid from "../DataGrid";
// import PlotOverlay from "../DrawPlot/overlay";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { baseUrl } from "../../util/config";

// import ReactGA from "react-ga";

import PlotDetailsPopup from "../Overlays/PlotDetailsPopup/PlotDetailsPopup";

import "./Map.css";
// import "../DrawPlot/poly.css";

// ReactGA.initialize("G-8FXNV1NVFX", {
//   debug: true,
//   titleCase: false,
//   gaOptions: {},
// });

const IMP_ICON =
  "https://storage.googleapis.com/am-songs/songs/20220620031236_important.png?GoogleAccessId=ads-395%40am-ads.iam.gserviceaccount.com&Expires=1686841956&Signature=Ws1uiWPxnfxmWEZ1u0wWPfh%2FZ0%2BwIz%2FDoWZC9EkHU1AQ5qzRI3GTJTji977KidjrQZNiSZP2aAKKiwV58I0SjhqMyXUHKPYxS%2BJ5jztEu%2BiySy7UTkurhdCr4UmL7U6gjaAUgAIaCZb3LIE9HVYCh77D%2BxaiiG7d4fLvWRuWJY%2BZl31uEupmqvdDDQwv17SEYOj4blscRCrhz%2BBVRCOqzIwzGu%2BNx26Gd9tkeEJDnclsdewVMMYMaAyHE8QaULdToLnZxaDDZr%2FDYp%2BrLu1CDX9FrwZwxjcEA12FGy%2FoOy0dssmiclvQhenGYnZ%2FZ9qGaglwOkHBxt82C5aLrBASyw%3D%3D";

const API_KEY = "AIzaSyBtRJ59UWCyTVENScWckaaqJas16Ymuuic";

const Table = ({
  google,
  zones = [],
  center = {},
  lat = "13.086289405322379",
  lng = "80.29239116173903",
  polyline = [],
  show_boundary = 0,
  boundary_color = "",
  id,
  layout_image,
  isFav,
  plotStatus = -1,
  setPlotStatus,
  product,
  zoom = 16,
  openSnackBar,
  showPrice = true,
  isEmiAvailable = false,
}) => {
  const [allPlots, setAllPlots] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const [nearByLocations, setNearByLocations] = useState([]);
  const [showType, setShowType] = useState(null);

  const [allNearby, setAllNearby] = useState([]);
  const [nearbyShow, setNearbyShow] = useState(false);

  const [directionDetails, setDirectionDetails] = useState(null);
  const [allNearbyTypes, setAllNearbyTypes] = useState([]);

  const [favStatus, setFavStatus] = useState(isFav);

  const [openFilter, setOpenFilter] = useState(false);

  const [viewType, setViewType] = useState(
    isFav
      ? "table"
      : product && product.is_map_view_enable === 1
      ? "map"
      : product && product.is_image_view_enable === 1
      ? "image"
      : product && product.is_table_view_enable === 1
      ? "table"
      : ""
  );

  const [toolTipContent, setToolTipContent] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const [filter, setFilter] = useState({
    status: "",
    corner: -1,
    direction: [],
  });

  const handleClose = () => {
    setOpen(false);
    setOpenFilter(false);
  };

  // Table Properties

  const columns = [
    { name: "sno", title: "S.No" },
    { name: "name", title: "Plot Number" },
    { name: "area", title: "Total SqFeet" },
    { name: "cost", title: "Total Price" },
    { name: "plot_status_disp", title: "Current Status" },
    { name: "direction_disp", title: "Direction Facing" },
    { name: "is_corner", title: "Corner Plot" },
    { name: "no_of_sides", title: "No of Slides" },
    { name: "action", title: "Action" },
  ];

  const tableColumnExtensions = [
    { columnName: "sno", width: 100, wordWrapEnabled: true },
    { columnName: "name", wordWrapEnabled: true },
    { columnName: "area", wordWrapEnabled: true },
    { columnName: "cost", wordWrapEnabled: true },
    { columnName: "plot_status_disp", wordWrapEnabled: true },
    { columnName: "direction_disp", wordWrapEnabled: true },
    { columnName: "is_corner", wordWrapEnabled: true },
    { columnName: "no_of_sides", wordWrapEnabled: true },
    { columnName: "no_of_sides", wordWrapEnabled: true },
    { columnName: "action", align: "center" },
  ];

  const defaultHiddenColumnNames = [];

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "action", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "action", sortingEnabled: false },
  ];

  const loadGrid = (inputs) => {
    const token = localStorage.getItem("user") || null;
    let userDetails;
    if (token) {
      userDetails = JSON.parse(token);
    }
    inputs["project_id"] = id;
    inputs["onlyPublish"] = true;
    inputs["user"] =
      userDetails && userDetails.googleId ? userDetails.googleId : "";
    inputs["isFav"] = isFav;
    getTablePlotDetails(inputs);
  };

  useEffect(() => {
    setFavStatus(isFav);
  }, []);

  const actionClick = (type, data) => {
    console.log("data");
    const token = localStorage.getItem("user") || null;
    let userDetails;
    if (token) {
      userDetails = JSON.parse(token);
    }

    if (type === "VIEW") {
      setToolTipContent((data && data) || []);
      setOpen(true);
    }
  };

  const getTablePlotDetails = (inputs) => {
    const payload = inputs;
    axios
      .post(
        `${baseUrl}/plot_api/realestate/plots/datatable`,
        payload
      )
      .then(
        (res) => {
          setRows((res.data && res.data.data.rows) || []);
          setTotalRecords((res.data && res.data.data.total) || 0);
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  useEffect(() => {
    const token = localStorage.getItem("user") || null;
    let userDetails;
    if (token) {
      userDetails = JSON.parse(token);
    }
    if (id) {
      setAllPlots([]);
      getPlotDetails(id);
      getAllNearbyType(id);
      getAllNearby();
      getTablePlotDetails({
        project_id: id,
        onlyPublish: true,
        user: userDetails && userDetails.googleId ? userDetails.googleId : "",
        isFav: isFav,
      });
    }
  }, [id]);

  const getPlotDetails = (id) => {
    const token = localStorage.getItem("user") || null;
    let userDetails;
    if (token) {
      userDetails = JSON.parse(token);
    }
    const payload = {
      project_id: id,
      onlyPublish: true,
      user: userDetails && userDetails.googleId ? userDetails.googleId : "",
    };
    axios
      .post(`${baseUrl}/plot_api/realestate/plots/list`, payload)
      .then(
        (res) => {
          setAllPlots((res.data && res.data.plots) || []);
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const getAllNearbyType = (id) => {
    const payload = { project_id: id };
    axios
      .post(
        `${baseUrl}/plot_api/realestate/nearbytypes/get`,
        payload
      )
      .then(
        (res) => {
          setAllNearbyTypes((res.data && res.data.list) || []);
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  useEffect(() => {
    setDirectionDetails(null);
    if (showType && showType.name !== "local") {
      setNearbyShow(false);
      const payload = {
        url: `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${lng}&radius=${showType.radius}&type=${showType.name}&name=${showType.name}&key=${API_KEY}`,
      };
      axios
        .post(
          `${baseUrl}/plot_api/realestate/nearby/getnearby`,
          payload
        )
        .then(
          (response) => {
            if (response.data && response.data.results) {
              const results = response.data.results;
              const locations = [];
              for (let i = 0; i < results.length; i++) {
                locations.push({
                  gps: results[i]["geometry"]["location"],
                  icon: showType.image || results[i]["icon"],
                });
              }
              setNearByLocations(locations);
            } else {
              setNearByLocations([]);
            }
          },
          (err) => {
            console.log("err", err);
          }
        );
    } else {
      if (showType && showType.name === "local") {
        setNearbyShow(true);
      }
      setNearByLocations([]);
    }
  }, [showType]);

  const getAllNearby = () => {
    const payload = { project_id: id };
    axios
      .post(`${baseUrl}/plot_api/realestate/nearby/get`, payload)
      .then(
        (res) => {
          const allRes = (res.data && res.data.list) || [];
          setAllNearby(allRes);
          const locations = [];
          for (let i = 0; i < allRes.length; i++) {
            locations.push({
              gps: allRes[i]["gps_location"],
              icon: IMP_ICON,
            });
          }
          setNearByLocations(locations);
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const getSoldStatus = (type) => {
    switch (type) {
      case 1:
        return "Sold";
      case 2:
        return "Booked";
      case 0:
        return "Un Sold";
      default:
        return "";
    }
  };

  const directionOption = [
    { value: 1, label: "East" },
    { value: 2, label: "West" },
    { value: 3, label: "North" },
    { value: 4, label: "South" },
    { value: 5, label: "North East" },
    { value: 6, label: "North West" },
    { value: 7, label: "South East" },
    { value: 8, label: "South West" },
  ];

  const [mapFilter, setMapFilter] = useState({
    status: plotStatus,
    corner: -1,
    direction: [],
  });

  const handleRadioChange = (e) => {
    if (e.target.name == "status") {
      setMapFilter({
        ...mapFilter,
        status: parseInt(e.target.value),
      });
    } else if (e.target.name == "corner") {
      setMapFilter({
        ...mapFilter,
        corner: e.target.value,
      });
    }
  };

  const handleSelectChange = (option) => {
    setMapFilter({
      ...mapFilter,
      direction: option,
    });
  };

  const applyFilter = () => {
    setFilter(mapFilter);
    setPlotStatus(mapFilter.status);
    handleClose();
  };

  const resetFilter = () => {
    setFilter({
      status: -1,
      corner: -1,
      direction: [],
    });
    setMapFilter({
      status: -1,
      corner: -1,
      direction: [],
    });
    setPlotStatus(-1);
    handleClose();
  };

  return (
    <div style={{ width: "100%" }}>
      {/* <Dialog
        open={open}
        fullWidth
        size="md"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent style={{height: 600}}>
        
        </DialogContent>
      </Dialog> */}
      <PlotDetailsPopup
        popupOpen={open}
        toolTipContent={toolTipContent}
        setOpen={setOpen}
        openSnackBar={openSnackBar}
        showPrice={showPrice}
        isEmiAvailable={isEmiAvailable}
      />
      <Dialog
        open={openFilter}
        fullWidth
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="filter-dialog"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ background: "#ff5a3c" }}
          onClose={handleClose}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ color: "#ffffff" }}
          >
            Filters
            <div>
              <div
                className="header-top-btn"
                style={{ marginLeft: 4, marginRight: 12 }}
              >
                <a
                  onClick={() => {
                    applyFilter();
                  }}
                  style={{
                    color: "white",
                    cursor: "pointer",
                    backgroundColor: "#cb3421",
                    borderRadius: 13,
                  }}
                >
                  Apply Filter
                </a>
              </div>
              <IconButton
                aria-label="close"
                style={{ color: "#ffffff" }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Status
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  style={{ padding: 10 }}
                  color="success"
                  onChange={handleRadioChange}
                  name="status"
                  value={mapFilter.status}
                >
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={-1}
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={1}
                    control={<Radio />}
                    label="Sold"
                  />
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={2}
                    control={<Radio />}
                    label="Booked"
                  />
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={0}
                    control={<Radio />}
                    label="Available"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Corner Plot
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  style={{ padding: 10 }}
                  onChange={handleRadioChange}
                  name="corner"
                  value={mapFilter.corner}
                >
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={-1}
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={1}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={0}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Direction Facing
              </FormLabel>
              {/* <Select
                options={directionOption}
                defaultValue={mapFilter.direction}
                isMulti
                onChange={handleSelectChange}
                placeholder="Select Direction"
              /> */}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {/* <Grid container justifyContent='center' alignItems='center' spacing={1}> */}
      {/* <Grid item xs={12} md={12} lg={12}> */}
      {/* <div className="sb-filter-widget"> */}
      {/* <Grid item xs={12} md={12} lg={12}> */}
      <DataGrid
        rows={rows}
        columns={columns}
        tableColumnExtensions={tableColumnExtensions}
        defaultHiddenColumnNames={defaultHiddenColumnNames}
        filteringStateColumnExtensions={filteringStateColumnExtensions}
        sortingStateColumnExtensions={sortingStateColumnExtensions}
        loadGrid={loadGrid}
        totalRecords={totalRecords}
        action={["VIEW"]}
        actionClick={actionClick}
      />
      {/* </Grid> */}
      {/* </div> */}
      {/* </Grid> */}
      {/* </Grid> */}
    </div>
  );
};
export default Table;
