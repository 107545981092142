import { useState, useEffect } from 'react';
import { isMobile } from "react-device-detect";
//Material UI imports
import Box from '@mui/joy/Box';
import Slider from '@mui/joy/Slider';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
//Custom imports
import * as CSS from "./FilterStyle";

const RangeChkInitVal = [
    {
        label: 'Low (1-25 lakhs)',
        fromVal: 100000,
        toVal: 2500000,
        ticked: true
    },
    {
        label: 'Medium (25-50 lakhs)',
        fromVal: 2500000,
        toVal: 5000000,
        ticked: true
    },
    {
        label: 'High (50 lakhs - 1 crore)',
        fromVal: 5000000,
        toVal: 10000000,
        ticked: true
    },
    {
        label: 'Premium (1 crore and above)',
        fromVal: 10000000,
        toVal: 50000000,
        ticked: true
    }
]

const RangeFilter = (props) => {

    const { min, max, objIndex, objContent, onModify, onDone } = props;
    const [stepVal, setStepVal] = useState(0);
    const [chkBoxVal, setChkBoxVal] = useState(RangeChkInitVal);

    useEffect(() => {
        if(objContent.key === 'rate') {
            setStepVal(100);
        } else if(objContent.key === 'range') {
            setStepVal(50000);
        } else if(objContent.key === 'area') {
            setStepVal(100);
        } 
    }, [objContent.key]);

    const handleChange = (event, newValue) => {
        let val = newValue;
        let displayVal = '';
        if(newValue[0] === min && newValue[1] === max) {
            displayVal = 'ANY';
        } else {
            if(objContent.key === 'rate') {
                displayVal = `₹${newValue[0]} - ₹${newValue[1]}`;
            } else if(objContent.key === 'range') {
                displayVal = `₹${convertRateToString(newValue[0])} - ₹${convertRateToString(newValue[1])}`;;
            } else if(objContent.key === 'area') {
                displayVal = `${newValue[0]} - ${newValue[1]}`;
            }
        }
        const updatedObj = {...objContent, val, displayVal};
        onModify(objIndex, updatedObj);
    };

    const convertRateToString = (rateVal) => {
        let returnVal = '';
        let rateStr = rateVal.toString();
        if(rateStr.length === 6) {
            if(rateStr[1] === '0' && rateStr[2] === '0') {
                returnVal = `${rateStr[0]}L`;
            } else {
                returnVal = `${rateStr[0]}.${rateStr[1]}${rateStr[2]}L`;
            }
        } if(rateStr.length === 7) {
            if(rateStr[2] === '0' && rateStr[3] === '0') {
                returnVal = `${rateStr[0]}${rateStr[1]}L`;
            } else {
                returnVal = `${rateStr[0]}${rateStr[1]}.${rateStr[2]}${rateStr[3]}L`;
            }
        } if(rateStr.length === 8) {
            if(rateStr[1] === '0' && rateStr[2] === '0') {
                returnVal = `${rateStr[0]}CR`;
            } else {
                returnVal = `${rateStr[0]}CR ${rateStr[1]}${rateStr[2]}L`;
            }
        }
        return returnVal;
    }

    const handleCheckBoxChange = (e, index) => {
        const updatedVals = [...chkBoxVal]
        updatedVals[index].ticked = e.target.checked;
        setChkBoxVal(updatedVals);
        //Update slider
        let sliderVals = [];
        updatedVals.forEach(item => {
            if(item.ticked) {
                sliderVals.push(item.fromVal);
                sliderVals.push(item.toVal);
            }
        });
        if(sliderVals.length) {
            handleChange('', [Math.min(...sliderVals), Math.max(...sliderVals)] )
        }
    }

    return (
        <CSS.ParentDiv isMobile={isMobile}>
            {
                objContent.key === 'range' &&
                <Grid container direction='row' paddingLeft='2%' justifyContent='left' spacing={1}>
                    <FormGroup>
                        {
                            chkBoxVal.map((chkEle, index) => {
                                return <FormControlLabel 
                                            control={
                                                <Checkbox 
                                                    checked={chkEle.ticked}
                                                    onChange={(e) => handleCheckBoxChange(e, index)}
                                                    sx={{ color: '#643f00', '&.Mui-checked': {color: '#643f00'} }}                                                    
                                                />
                                            }
                                            label={<Typography variant="body2" color="textSecondary">{chkEle.label}</Typography>}
                                        />
                            })
                        }
                    </FormGroup>
                    <Grid container justifyContent='center' alignItems='center'>
                        <Button variant="outlined" size={ isMobile ? 'large' : 'small' } onClick={onDone} className='filter-button'>
                            Done
                        </Button>
                    </Grid>
                </Grid>
            }
            {
                objContent.key !== 'range' &&
                <Box sx={{ width: 300 }} className='filter-range-cntr'>
                    <Slider
                        min={min}
                        max={max}
                        value={objContent.val}
                        onChange={handleChange}
                        valueLabelDisplay={ isMobile ? "on" : "auto"}
                        step={stepVal}
                    />
                    <Button variant="outlined" size={ isMobile ? 'large' : 'small' } onClick={onDone} className='filter-button'>
                        Done
                    </Button>
                </Box>
            }
        </CSS.ParentDiv>
    )
}

export default RangeFilter;