import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import { baseUrl } from "../../util/config";
//Custom imports

//Other lib imports
import axios from "axios";

import PromoterHeader from "../HeaderMenu/PromoterHeader";
import * as CSS from "./style";

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor:
//       theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === "light" ? "#4DE45C" : "#308fe8",
//   },
// }));

function PromoterLayout() {
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState(null);
  const [layoutData, setLayoutData] = useState([]);

  const onLayoutBtnClk = (id) => {
    navigate(`/addLayout`, {
      state: {
        id: id,
        isSubmited: false,
      },
    });
  };

  useEffect(() => {
    const promotor_user = localStorage.getItem("promotor_user") || null;
    if (promotor_user) {
      const userData = JSON.parse(promotor_user);
      setUserDetails(userData);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      getPromotorLayoutDetails(userDetails.id);
    }
  }, [userDetails]);

  const getPromotorLayoutDetails = (id) => {
    // setIsLoading(true);
    const payload = { user_id: id };
    axios.post(`${baseUrl}/cms_api/realestate/list`, payload).then(
      (res) => {
        if (res.data && res.data.projects) {
          setLayoutData((res.data && res.data.projects) || {});
        }
      },
      (err) => {
        // setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  return (
    <CSS.ParentDiv>
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
      >
        <PromoterHeader />
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            {userDetails && (
              <Box
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                flexDirection="column"
                padding="25px"
              >
                <div className="login-form-div">
                  <span className="login-form-heading">Layouts List</span>
                </div>
                {layoutData && layoutData.length > 0 && (
                  <div className="dashboard-first-btn">
                    {(layoutData || []).map((el) => (
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => onLayoutBtnClk(el.id)}
                        className="login-inputWidth login-button"
                        style={{ margin: 10 }}
                        endIcon={<EastIcon />}
                      >
                        {el.name}
                      </Button>
                    ))}
                  </div>
                )}
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
}

export default PromoterLayout;
