import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import WestIcon from "@mui/icons-material/West";
import Avatar from "@mui/material/Avatar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CircularProgress from "@mui/material/CircularProgress";

//Custom imports
import * as CSS from "../style";
import { useSnackbar } from "../../../hooks/useSnackbar";
import Snackbar from "../../snackbar";
import XHR from "../../../util/pxhr";

let publicUrl = process.env.PUBLIC_URL + "/";

const AddLayoutStepOne = (props) => {
  const navigate = useNavigate();
  const { nextStep, id = null } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const promotor_user = localStorage.getItem("promotor_user") || null;
    if (promotor_user) {
      const userData = JSON.parse(promotor_user);
      setUserDetails(userData);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (id) {
      getProjectDetails(id);
    }
  }, [id]);

  const getProjectDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/get", payload).then(
      (res) => {
        setIsLoading(false);
        setData((res.data && res.data.data) || {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const updateProjectData = () => {
    setIsLoading(true);
    const payload1 = JSON.parse(JSON.stringify({ ...data }));
    XHR.post("realestate/updateinfo", payload1).then(
      (res) => {
        setIsLoading(false);
        openSnackBar("Success", "success");
        getProjectDetails(id);
        navigate("/promoterWelcome");
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleChange = (key1, key2, value) => {
    setData({
      ...data,
      [key1]: {
        ...data[key1],
        [key2]: value,
      },
    });
  };

  const onBackBtnClk = () => {
    nextStep(6);
  };

  const onSubmitBtnClk = () => {
    alert("submit button clicked..");
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={6} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">
                  Description
                  <div>
                    <span className="login-form-heading-step">(Step 7 of 7)</span>
                  </div>
                </span>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Description"
                  multiline
                  rows={6}
                  variant="outlined"
                  helperText={"Max 1500 characters"}
                  inputProps={{ maxLength: 1500 }}
                  required
                  className="login-inputWidth"
                  size="small"
                  value={
                    (data &&
                      data.general_information &&
                      data.general_information.description) ||
                    ""
                  }
                  onChange={(event) =>
                    handleChange(
                      "general_information",
                      "description",
                      event.target.value
                    )
                  }
                />
              </div>
              <div className="profile-back-next-btn-cntr">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onBackBtnClk}
                  className="login-button"
                  startIcon={<WestIcon />}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  className="login-button"
                  onClick={() => updateProjectData()}
                >
                  Submit for Review
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddLayoutStepOne;
