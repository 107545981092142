import React from "react";
import Autocomplete from "react-google-autocomplete";

const API_KEY = "AIzaSyBtRJ59UWCyTVENScWckaaqJas16Ymuuic";

const AutoComplete = (props) => {
  const onPlaceSelected = (place) => {
    props.setLocation({
      coor: {
        lat: place && place.geometry && place.geometry.location.lat(),
        lng: place && place.geometry && place.geometry.location.lng(),
      },
    });
  };

  return (
    <Autocomplete
      placeholder="Search location"
      apiKey={API_KEY}
      style={{ width: "90%", height: "40px", padding: "5px" }}
      onPlaceSelected={(place) => onPlaceSelected(place)}
      inputAutocompleteValue="country"
      options={{
        types: ["(regions)"],
      }}
    />
  );
};
export default AutoComplete;
