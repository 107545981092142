//Material UI imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//Custom imports
import * as CSS from "../Style.js";

const PartialOnboard = (props) => {
  const { openFlag, setCloseFlag, toolTipContent } = props;

  const getAddress = () => {
    let add = "";
    if (toolTipContent && toolTipContent.street) {
      add = `${toolTipContent.street}`;
    }

    if (toolTipContent && toolTipContent.add1) {
      add = `${add}, ${toolTipContent.add1}`;
    }

    if (toolTipContent && toolTipContent.add2) {
      add = `${add}, ${toolTipContent.add2}`;
    }

    if (toolTipContent && toolTipContent.town) {
      add = `${add}, ${toolTipContent.town}`;
    }

    if (toolTipContent && toolTipContent.city) {
      add = `${add}, ${toolTipContent.city}`;
    }

    if (toolTipContent && toolTipContent.taluk) {
      add = `${add}, ${toolTipContent.taluk}`;
    }

    if (toolTipContent && toolTipContent.district) {
      add = `${add}, ${toolTipContent.district}`;
    }

    if (toolTipContent && toolTipContent.state) {
      add = `${add}, ${toolTipContent.state}`;
    }

    if (toolTipContent && toolTipContent.zipcode) {
      add = `${add}, ${toolTipContent.zipcode}`;
    }

    if (toolTipContent && toolTipContent.country) {
      add = `${add}, ${toolTipContent.country}`;
    }

    if (toolTipContent && toolTipContent.landmark) {
      add = `${add}, ${toolTipContent.landmark}`;
    }

    return add;
  };

  const handleLocationClick = (gps) => {
    if (gps.lat) {
      var url = "https://maps.google.com/?q=" + gps.lat + "," + gps.lng;
      window.open(url, "_blank");
    }
  };

  return (
    <CSS.CustomizedDialog
      open={openFlag}
      onClose={() => setCloseFlag(false)}
      maxWidth={"lg"}
    >
      <Grid
        container
        gap="2em"
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="row"
        margin="2em 0 2em 0;"
        flexDirection={"column"}
        minWidth={500}
      >
        <CSS.CloseBtn>
          <IconButton
            aria-label="closePopup"
            onClick={() => setCloseFlag(false)}
          >
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn>
        <CSS.TitleContainer>
          <span className="title">Full Onboarding In Progress...</span>
        </CSS.TitleContainer>
      </Grid>
      <Grid
        item
        display="flex"
        direction="column"
        gap="1em"
        justifyContent="flex-start"
        alignItems="flex-start"
        textAlign="left"
      >
        <div className="pdp-map-popup-bigs-left">
          <span className="pdp-map-popup-heading-text">layout name : </span>
          <span className="pdp-map-popup-big-text">
            {(toolTipContent && toolTipContent.name) || ""}
          </span>
        </div>
        <div className="pdp-map-popup-bigs-left">
          <span className="pdp-map-popup-heading-text">layout address : </span>
          <span className="pdp-map-popup-big-text">
            {(toolTipContent && getAddress()) || ""}
          </span>
        </div>
        <div className="pdp-map-popup-bigs-left">
          <span className="pdp-map-popup-heading-text">layout location : </span>
          <span className="pdp-map-popup-big-text">
            <a
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() =>
                handleLocationClick(
                  toolTipContent && toolTipContent.gps_location
                )
              }
            >
              Click here
            </a>
          </span>
        </div>
        <div className="pdp-map-popup-bigs-left">
          <span className="pdp-map-popup-heading-text">no of plots : </span>
          <span className="pdp-map-popup-big-text">
            {(toolTipContent && toolTipContent.total_cnt) || ""}
          </span>
        </div>
        <div className="pdp-map-popup-bigs-left">
          <span className="pdp-map-popup-heading-text">promoter name : </span>
          <span className="pdp-map-popup-big-text">
            {(toolTipContent && toolTipContent.company_promotors) || ""}
          </span>
        </div>
        {toolTipContent &&
        toolTipContent.general_information &&
        toolTipContent.general_information.layout != "" ? (
          <div className="pdp-map-popup-bigs-left">
            <span className="pdp-map-popup-heading-text">
              download layout diagram :{" "}
            </span>
            <span className="pdp-map-popup-big-text">
              <a
                href={toolTipContent.general_information.layout}
                style={{
                  marginTop: 5,
                  fontSize: 18,
                  marginBottom: 5,
                  cursor: "pointer",
                  color: "#6e56ff",
                }}
                target="_blank"
              >
                Download Layout Diagram
              </a>
            </span>
          </div>
        ) : null}
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default PartialOnboard;
