import React from "react";
import Autocomplete from "react-google-autocomplete";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { prodBuild } from "../../util/config";
import "./Map.css";

const custRadio = { color: '#643f00', '&.Mui-checked': {color: '#643f00'} }

const GoogleAutoComplete = (props) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchBy, setSearchBy] = React.useState('promoter');
  const [searchVal, setSearchVal] = React.useState(props.setDefaultInput);
  const shouldOpenPopover = Boolean(anchorEl);

  React.useEffect(() => {
    if(searchVal === '') {
      localStorage.removeItem("GEO_LOCATION");
      props.setLocation({
        lat: "",
        lng: "",
        address: "",
      });
    }
  }, [searchVal]);

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const onPlaceSelected = (place) => {
    localStorage.setItem("GEO_LOCATION", JSON.stringify(place));
    if (place && !place.geometry) {
      return;
    }
    props.setLocation({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      address: place.formatted_address,
    });
    setSearchVal(place.formatted_address);
  };

  const onSearchByChange = (e) => {
    setSearchBy(e.target.value);
    setAnchorEl(null);
  }

  const googleApiKey = "AIzaSyBtRJ59UWCyTVENScWckaaqJas16Ymuuic";

  // if (!props.googleApiKey) {
  //   return null;
  // }

  // console.log("setDefaultInput", window.google);

  return (
  <>
    <Autocomplete
      className="pac-target-input"
      apiKey={googleApiKey}
      onPlaceSelected={(place) => onPlaceSelected(place)}
      inputAutocompleteValue="country"
      options={{
        types: ["(regions)"],
      }}
      onChange={(e) => setSearchVal(e.target.value)}
      value={searchVal}
    />
    <div className='clear-btn-cntr'>
      {
        searchVal && (
          <IconButton className='extr-btns' onClick={() => setSearchVal('')}>
            <HighlightOffIcon />
          </IconButton>
        )
      }
    </div>
    <div className='dp-btn-cntr'>
      {
        !prodBuild && (
          <IconButton className='extr-btns' onClick={openPopover}>
            <KeyboardArrowDownIcon />
          </IconButton>
        )
      }
    </div>
    <Popover
      open={shouldOpenPopover}
      anchorEl={anchorEl}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Typography sx={{ p: 2 }}>
        Search for
        <RadioGroup value={searchBy} onChange={onSearchByChange}>
          <FormControlLabel value="promoter" control={<Radio sx={custRadio} />} label="Promoter (New Property)" />
          <FormControlLabel value="individual" control={<Radio sx={custRadio} />} label="Individual (Resale Property)" />
        </RadioGroup>
      </Typography>
    </Popover>
  </>
  );
};
export default GoogleAutoComplete;
