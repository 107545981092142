import React, { useState, useEffect, useRef } from "react";
import { fabric } from "fabric";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";

import "../DrawFabric/poly.css";
import PlotDetailsPopup from "../Overlays/PlotDetailsPopup/PlotDetailsPopup";
import PlotDetailsToolTip from "../Overlays/PlotDetailsToolTip/PlotDetailsToolTip";

import * as CSS from "../../Components/Overlays/Style.js";

import { baseUrl } from "../../util/config";

var canvas;
var panning = false;
let prevX;
let prevY;
let startX;
let startY;
let fabricImageObject;

const getTouchDevice = () =>
  !!(
    typeof window !== "undefined" &&
    window.matchMedia("(pointer: coarse)").matches
  );

function DrawFabric(props) {
  const canvasNewContainer = useRef();
  const canvasRefV1 = useRef();
  const toolTipRef = useRef();
  const spanRef = useRef();
  const {
    id,
    layoutFilter = {},
    openSnackBar,
    showPrice = true,
    isEmiAvailable = false,
    isMobile = false,
  } = props;

  const touchDevice = getTouchDevice();

  const [open, setOpen] = React.useState(false);
  const [isTouchDevice, setIsTouchDevice] = React.useState(
    "ontouchstart" in document.documentElement
  );
  const [isLoading, setIsLoading] = useState(false);
  const polyIndex = useRef(0);
  const polyList = useRef([]);
  const [toolTipContent, setToolTipContent] = React.useState(null);
  const [isDragged, setIsDragged] = React.useState(false);
  const [onMouseUp, setonMouseUp] = React.useState(false);
  const [fabricImage, setFabricImage] = useState(null);
  const [plotStatus, setPlotStatus] = useState(-1);
  const [filter, setFilter] = useState({
    availability: "ANY",
    corner: "ANY",
    direction: "ANY",
  });
  const [state, setState] = useState({
    polygon: [],
  });

  const [timer, setTimer] = useState(0);
  const [start, setStart] = useState(false);
  const firstStart = useRef(true);
  const tick = useRef();

  useEffect(() => {
    if (onMouseUp) {
      if (!isDragged) {
        setOpen(true);
      } else {
        setToolTipContent(null);
        setIsDragged(false);
      }
      setonMouseUp(false);
    }
  }, [onMouseUp]);

  // var pausePanning, zoomStartScale, currentX, currentY, xChange, lastX, yChange, lastY;

  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;
      return;
    }

    if (start) {
      tick.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1);
    } else {
      clearInterval(tick.current);
    }

    return () => clearInterval(tick.current);
  }, [start]);

  useEffect(() => {
    setFilter({
      ...filter,
      ...layoutFilter,
    });
  }, [layoutFilter]);

  useEffect(() => {
    canvas = null;
    initCanvas();
    setState({
      ...state,
      polygon: [],
    });
  }, []);

  useEffect(() => {
    if (state && state.polygon) {
      redrawPoints(state.polygon);
    }
  }, [plotStatus]);

  useEffect(() => {
    if (id) {
      getProject(id);
      getPlotDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (fabricImage) {
      initFabricImage();
    }
  }, [fabricImage]);

  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      getPlotDetails(id);
    }, 2000);

    return () => window.clearTimeout(timeoutID);
  }, []);

  useEffect(() => {
    if (state && state.polygon) {
      redrawPoints(state.polygon);
    }
  }, [state && state.polygon]);

  const getFilteredPlots = (plots) => {
    let plotCopy = JSON.parse(JSON.stringify(plots));

    if (plotStatus !== -1) {
      plotCopy =
        plotCopy &&
        plotCopy.filter(
          (el) =>
            el.plot_status == plotStatus &&
            el.drawn_type === 2 &&
            el.uploaded_id > 0
        );
    }

    if (filter.availability !== "ANY") {
      if (filter.availability.length > 0) {
        let filterAvailability = filter.availability.map((data) => data);
        plotCopy =
          plotCopy &&
          plotCopy.filter(
            (el) =>
              filterAvailability.includes(parseInt(el.plot_status)) &&
              el.drawn_type === 2 &&
              el.uploaded_id > 0
          );
      }
    }

    if (filter.direction !== "ANY") {
      if (filter.direction.length > 0) {
        let filterDirection = filter.direction.map((data) => data);
        plotCopy =
          plotCopy &&
          plotCopy.filter(
            (el) =>
              filterDirection.includes(parseInt(el.direction)) &&
              el.drawn_type === 2 &&
              el.uploaded_id > 0
          );
      }
    }

    if (filter.corner !== "ANY") {
      if (filter.corner.length > 0) {
        let filterCorner = filter.corner.map((data) => data);
        plotCopy =
          plotCopy &&
          plotCopy.filter(
            (el) =>
              filterCorner.includes(el.is_corner) &&
              el.drawn_type === 2 &&
              el.uploaded_id > 0
          );
      }
    }

    return plotCopy;
  };

  const redrawPoints = () => {
    const fabricPolygons =
      state.polygon &&
      state.polygon.filter((el) => el.drawn_type === 2 && el.uploaded_id > 0);

    if (fabricPolygons) {
      if (fabricPolygons.length > 0 && canvas) {
        canvas.getObjects().map(function (o) {
          if (o.objectType !== "image") canvas.remove(o);
        });
      }
      const filteredPlot = getFilteredPlots(fabricPolygons);
      for (const element of filteredPlot) {
        reGeneratePolygon(element);
      }
    }
  };

  const reGeneratePolygon = (element) => {
    if (!canvas) {
      return;
    }

    polyIndex.current++;

    const polyListDetails = {
      id: element.id,
      points: element.points,
    };

    polyList.current.push(polyListDetails);

    var polygon = new fabric.Polygon(element.points, {
      id: element.id,
      name: element.name,
      objectValue: element,
      objectType: "polygon",
      fill:
        element.plot_status == 0 && element.plot_type == 2
          ? "#006400	"
          : element.plot_status == 1 && element.plot_type == 2
          ? "#FF0000"
          : element.plot_status == 2 && element.plot_type == 2
          ? "#0000FF"
          : "#808080",
      stroke: "#000",
      strokeWidth: 2,
      opacity: 0.5,
      objectCaching: false,
      transparentCorners: false,
      selectable: false,
      hoverCursor: "pointer",
    });

    polygon.on("mousedown", function (options) {
      if (!touchDevice) {
        setToolTipContent(options.target.objectValue);
        setOpen(true);
      }
    });

    polygon.on("mouseup", function (options) {
      if (touchDevice) {
        setToolTipContent(options.target.objectValue);
        setonMouseUp(true);
      }
    });

    polygon.on("mouseover", function (e) {
      if (e.target.objectValue && e.target.objectValue.id) {
        // ReactGA.set({
        //   PLOTS_CLICKED: e.target.objectValue.id,
        // });
      }
      if (!touchDevice) {
        if (
          setToolTipContent &&
          setToolTipContent.id != e.target.objectValue.id
        ) {
          setToolTipContent(e.target.objectValue);
          toolTipRef.current.style.visibility = "visible";
          toolTipRef.current.style.left = e.e.pageX + "px";
          toolTipRef.current.style.top = e.e.pageY + "px";

          canvas.renderAll();
        }
      }
    });

    polygon.on("mouseout", function (e) {
      toolTipRef.current.style.visibility = "hidden";
      canvas.renderAll();
    });

    canvas.add(polygon);
    // canvas.selection = true;
  };

  const getPlotDetails = (id, user = "") => {
    setIsLoading(true);
    const payload = { project_id: id, user: "101591075483903609630" };
    axios
      .post(`${baseUrl}/plot_api/realestate/plots/list`, payload)
      .then(
        (res) => {
          setState({
            ...state,
            polygon: (res.data && res.data.plots) || [],
          });
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const getProject = (id) => {
    setIsLoading(true);
    const payload = { id };
    axios.post(`${baseUrl}/plot_api/realestate/get`, payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.data) {
          setFabricImage(res.data.data.layout_image);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const initCanvas = () => {
    if (!canvasRefV1.current) {
      return;
    }
    if (!canvas) {
      canvas = new fabric.Canvas("canvas_div", {
        selection: false,
        allowTouchScrolling: true,
      });
      canvas.on("mouse:up", function (e) {
        panning = false;
        if (e.e.changedTouches) {
          const { clientX, clientY } = e.e.changedTouches[0];
          const x = startX - clientX;
          const y = startY - clientY;
          const dist = x * x + y * y;
          // console.log(dist)
          if (dist > 20) {
            setIsDragged(true);
          }
        }
      });

      canvas.on("mouse:down", function (e) {
        panning = true;
        if (e.e instanceof TouchEvent) {
          const { clientX, clientY } = e.e.touches[0];
          prevX = clientX;
          startX = clientX;
          prevY = clientY;
          startY = clientY;
        }
      });
      canvas.on("mouse:move", function (e) {
        if (panning) {
          let delta;
          if (e.e instanceof TouchEvent) {
            // we're on mobile
            const { clientX, clientY } = e.e.touches[0];
            delta = new fabric.Point(clientX - prevX, clientY - prevY);

            const x = prevX - clientX;
            const y = prevY - clientY;
            prevX = clientX;
            prevY = clientY;
          } else {
            // we're on desktop
            delta = new fabric.Point(e.e.movementX, e.e.movementY);
          }
          canvas.relativePan(delta);
        }
      });
    }
  };

  const initFabricImage = () => {
    fabric.Image.fromURL(
      fabricImage,
      function (myImg) {
        myImg.set({
          id: "img",
          left: 0,
          top: 0,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          hasRotatingPoint: false,
          name: "image",
          objectType: "image",
          lockMovementX: true,
        });

        myImg.scaleToHeight(myImg.getScaledHeight());
        myImg.scaleToWidth(myImg.getScaledWidth());
        fabricImageObject = myImg;
        canvas.add(myImg);
        canvas.setWidth(myImg.getScaledWidth());
        canvas.setHeight(myImg.getScaledHeight());
      },
      { crossOrigin: "anonymous" }
    );
    getPlotDetails(id);
  };

  useEffect(() => {
    if (state && state.polygon) {
      redrawPoints(state.polygon);
    }
  }, [filter]);

  const downloadCanvas = () => {
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "canvas_div.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  function resetCanvas(position) {
    let origZoom = canvas.getZoom();

    // Pan the entire canvas so that the active object is centered in the viewport.
    // The object's relative position in the canvas should not change.

    let object = fabricImageObject;
    let objWidth = object.getScaledWidth();
    let objHeight = object.getScaledHeight();
    let zoom = canvas.getZoom();
    let panX = 0;
    let panY = 0;

    // WORKS - setViewportTransform
    panX =
      (canvas.getWidth() / zoom / 2 - object.aCoords.tl.x - objWidth / 2) *
      zoom;
    panY =
      (canvas.getHeight() / zoom / 2 - object.aCoords.tl.y - objHeight / 2) *
      zoom;

    canvas.setViewportTransform([
      origZoom,
      0,
      0,
      origZoom,
      panX,
      canvas.viewportTransform[5],
    ]);

    canvas.setViewportTransform([
      origZoom,
      0,
      0,
      origZoom,
      canvas.viewportTransform[4],
      panY,
    ]);

    canvas.renderAll();
  }

  return (
    <>
      <div style={{ textAlign: "end", margin: 10 }}>
        <CSS.customBtn
          variant="outlined"
          onClick={() => {
            downloadCanvas();
          }}
        >
          Download Current Layout Diagram
        </CSS.customBtn>
      </div>
      {state.polygon && state.polygon.length > 0 && (
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="0 0 2em 0;"
          >
            <ButtonGroup
              variant="contained"
              orientation={isMobile ? "vertical" : "horizontal"}
              className={isMobile ? "filterGroup" : ""}
            >
              <Button
                className={`pdp-lo-button-grp ${
                  plotStatus == -1 ? "active-tab" : ""
                }`}
                onClick={() => setPlotStatus(-1)}
              >
                <span className="all-dot"></span>
                <span className="dot-text">
                  All (
                  {state.polygon &&
                    state.polygon.filter(
                      (el) => el.drawn_type === 2 && el.uploaded_id > 0
                    ).length}
                  )
                </span>
              </Button>
              <Button
                className={`pdp-lo-button-grp ${
                  plotStatus == 0 ? "active-tab" : ""
                }`}
                onClick={() => setPlotStatus(0)}
              >
                <span className="available-dot"></span>
                <span className="dot-text">
                  Available (
                  {state.polygon &&
                    state.polygon.filter(
                      (el) =>
                        el.drawn_type === 2 &&
                        el.uploaded_id > 0 &&
                        el.plot_status === 0
                    ).length}
                  )
                </span>
              </Button>
              <Button
                className={`pdp-lo-button-grp ${
                  plotStatus == 1 ? "active-tab" : ""
                }`}
                onClick={() => setPlotStatus(1)}
              >
                <span className="sold-dot"></span>
                <span className="dot-text">
                  Sold (
                  {state.polygon &&
                    state.polygon.filter(
                      (el) =>
                        el.drawn_type === 2 &&
                        el.uploaded_id > 0 &&
                        el.plot_status === 1
                    ).length}
                  )
                </span>
              </Button>
              <Button
                className={`pdp-lo-button-grp ${
                  plotStatus == 2 ? "active-tab" : ""
                }`}
                onClick={() => setPlotStatus(2)}
              >
                <span className="booked-dot"></span>
                <span className="dot-text">
                  Booked (
                  {state.polygon &&
                    state.polygon.filter(
                      (el) =>
                        el.drawn_type === 2 &&
                        el.uploaded_id > 0 &&
                        el.plot_status === 2
                    ).length}
                  )
                </span>
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="0 0 2em 0;"
          >
            <CSS.customBtn
              variant="outlined"
              onClick={() => {
                resetCanvas();
              }}
            >
              Reset 2D CAD View
            </CSS.customBtn>
          </Grid>
        </Grid>
      )}
      <div
        className={`${isTouchDevice ? "fabricMobileView" : ""}`}
        style={{
          // width: "93vw",
          // overflowX: "scroll",
          // overflowY: isTouchDevice ? "scroll" : "hidden",
          width: "90vw",
          height: isTouchDevice ? "120vw" : "auto",
          overflow: "hidden",
          borderRadius: 20,
          border: "1px solid #a56b00",
        }}
      >
        <PlotDetailsPopup
          popupOpen={open}
          toolTipContent={toolTipContent}
          setOpen={setOpen}
          openSnackBar={openSnackBar}
          showPrice={showPrice}
          isEmiAvailable={isEmiAvailable}
        />
        <Grid container spacing={2}>
          <Grid container xs={12} style={{ maxWidth: "100%" }}>
            <Grid item xs={12}>
              <div
                ref={canvasNewContainer}
                id="canvas_container"
                style={{
                  width: "100%",
                  textAlign: "-webkit-center",
                }}
              >
                <span ref={spanRef}></span>
                <div ref={toolTipRef} className="toolTip">
                  <PlotDetailsToolTip
                    plotNumber={toolTipContent && toolTipContent.name}
                    plotStatus={
                      toolTipContent && toolTipContent.plot_status !== ""
                        ? toolTipContent.plot_status === 0
                          ? "Unsold"
                          : toolTipContent.plot_status === 1
                          ? "Sold"
                          : "Booked"
                        : " - "
                    }
                    plotArea={
                      toolTipContent && toolTipContent.area !== ""
                        ? toolTipContent.area
                        : " - "
                    }
                  />
                </div>
                <canvas
                  id="canvas_div"
                  ref={canvasRefV1}
                  style={{
                    // border: "1px solid red",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        {/* {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )} */}
      </div>
    </>
  );
}

export default React.memo(DrawFabric);
