import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
//Other lib imports
//Custom imports

const AddPropertyStepTen = (props) => {
  const { nextStep, propData, inputChange, setReusableDialogObj } = props;
 
  const closeReusableDialog = () => {
    setReusableDialogObj({
      open: false
    });
  }

  const onBackBtnClk = () => {
    nextStep(9);
  };

  const onNextBtnClk = () => {
    if(propData.plotRdWidth.includes("e") || propData.plotRdWidth.includes("+") || propData.plotRdWidth.includes("-")) {
      setReusableDialogObj({
        open: true,
        title: '',
        content: 'Plot road width value is invalid',
        btnArr: [
          {
            label: 'OK',
            fun: closeReusableDialog
          }
        ]
      });
    } else {
      nextStep(11);
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Some more Details about your Property</span>
                <div>
                  <span className="heading-step">(Step 10 of 11)</span>
                </div>
              </div>
              <FormControl fullWidth className='form-control-container'>
                <FormLabel className='form-control-label'>Plot Direction*</FormLabel>
                <RadioGroup
                  row
                  name={'plotDirection'}
                  value={propData.plotDirection}
                  onChange={ e => inputChange(e) }
                >
                  <FormControlLabel
                    value="E"
                    control={<Radio />}
                    label="E"
                  />
                  <FormControlLabel
                    value="W"
                    control={<Radio />}
                    label="W"
                  />
                  <FormControlLabel
                    value="N"
                    control={<Radio />}
                    label="N"
                  />
                  <FormControlLabel
                    value="S"
                    control={<Radio />}
                    label="S"
                  />
                  <FormControlLabel
                    value="NE"
                    control={<Radio />}
                    label="NE"
                  />
                  <FormControlLabel
                    value="NW"
                    control={<Radio />}
                    label="NW"
                  />
                  <FormControlLabel
                    value="SE"
                    control={<Radio />}
                    label="SE"
                  />
                  <FormControlLabel
                    value="SW"
                    control={<Radio />}
                    label="SW"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth className='form-control-container'>
                <FormLabel className='form-control-label'>Corner Plot*</FormLabel>
                <RadioGroup
                  row
                  name={'cornerPlot'}
                  value={propData.cornerPlot}
                  onChange={ e => inputChange(e) }
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='Front Side Road width'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='plotRdWidth'
                  type={'number'}
                  value={propData.plotRdWidth}
                  onChange={ e => inputChange(e) }
                  InputProps={{
                    endAdornment: <InputAdornment position="end">feet</InputAdornment>,
                  }}
                />
                <FormHelperText className={'form-control-helper-text'}>Ex: 40</FormHelperText>
              </FormControl>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPropertyStepTen;
