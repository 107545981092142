/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import DrawMaps from "./DrawMaps";
import axios from "axios";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import PlotDetailsPopup from "../Overlays/PlotDetailsPopup/PlotDetailsPopup";
// import ReactGA from "react-ga";

import "./Map.css";

import { baseUrl } from "../../util/config";

const API_KEY = "AIzaSyBtRJ59UWCyTVENScWckaaqJas16Ymuuic";

const viewType = "map";

const ProjectMap = ({
  lat = "13.086289405322379",
  lng = "80.29239116173903",
  polyline = [],
  show_boundary = 0,
  boundary_color = "",
  id,
  product,
  zoom = 16,
  layoutFilter = {},
  openSnackBar,
  showPrice = true,
  isEmiAvailable = false,
  isMobile = false,
}) => {
  const [allPlots, setAllPlots] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [toolTipContent, setToolTipContent] = React.useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = React.useState(false);
  const [plotStatus, setPlotStatus] = useState(-1);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [filter, setFilter] = useState({
    availability: "ANY",
    corner: "ANY",
    direction: "ANY",
  });

  useEffect(() => {
    setFilter({
      ...filter,
      ...layoutFilter,
    });
  }, [layoutFilter]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (id) {
      setAllPlots([]);
      getPlotDetails(id);
    }
  }, [id]);

  const getPlotDetails = (id) => {
    const token = localStorage.getItem("user") || null;
    let userDetails;
    if (token) {
      userDetails = JSON.parse(token);
    }
    const payload = {
      project_id: id,
      onlyPublish: true,
      user: userDetails && userDetails.googleId ? userDetails.googleId : "",
    };
    axios
      .post(`${baseUrl}/plot_api/realestate/plots/list`, payload)
      .then(
        (res) => {
          setAllPlots((res.data && res.data.plots) || []);
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const getFilteredPlots = (plots) => {
    let plotCopy = JSON.parse(JSON.stringify(plots));

    if (plotStatus !== -1) {
      plotCopy =
        plotCopy &&
        plotCopy.filter(
          (el) =>
            el.plot_status == plotStatus &&
            el.drawn_type === 1 &&
            el.uploaded_id > 0
        );
    }

    if (filter.availability !== "ANY") {
      if (filter.availability.length > 0) {
        let filterAvailability = filter.availability.map((data) => data);
        plotCopy =
          plotCopy &&
          plotCopy.filter(
            (el) =>
              filterAvailability.includes(parseInt(el.plot_status)) &&
              el.drawn_type === 1 &&
              el.uploaded_id > 0
          );
      }
    }

    if (filter.direction !== "ANY") {
      if (filter.direction.length > 0) {
        let filterDirection = filter.direction.map((data) => data);
        plotCopy =
          plotCopy &&
          plotCopy.filter(
            (el) =>
              filterDirection.includes(parseInt(el.direction)) &&
              el.drawn_type === 1 &&
              el.uploaded_id > 0
          );
      }
    }

    if (filter.corner !== "ANY") {
      if (filter.corner.length > 0) {
        let filterCorner = filter.corner.map((data) => data);
        plotCopy =
          plotCopy &&
          plotCopy.filter(
            (el) =>
              filterCorner.includes(el.is_corner) &&
              el.drawn_type === 1 &&
              el.uploaded_id > 0
          );
      }
    }

    return plotCopy;
  };

  console.log("plotStatus", plotStatus);

  return (
    <>
      <PlotDetailsPopup
        popupOpen={open}
        toolTipContent={toolTipContent}
        setOpen={setOpen}
        openSnackBar={openSnackBar}
        showPrice={showPrice}
        isEmiAvailable={isEmiAvailable}
      />
      {allPlots && allPlots.length > 0 && (
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            direction="column"
            margin="0 0 2em 0;"
          >
            <ButtonGroup
              variant="contained"
              orientation={isMobile ? "vertical" : "horizontal"}
            >
              <Button
                className={`pdp-lo-button-grp ${
                  plotStatus == -1 ? "active-tab" : ""
                }`}
                onClick={() => setPlotStatus(-1)}
              >
                <span className="all-dot"></span>
                <span className="dot-text">
                  All (
                  {
                    allPlots.filter(
                      (el) => el.drawn_type === 1 && el.uploaded_id > 0
                    ).length
                  }
                  )
                </span>
              </Button>
              <Button
                className={`pdp-lo-button-grp ${
                  plotStatus == 0 ? "active-tab" : ""
                }`}
                onClick={() => setPlotStatus(0)}
              >
                <span className="available-dot"></span>
                <span className="dot-text">
                  Available (
                  {
                    allPlots.filter(
                      (el) =>
                        el.drawn_type === 1 &&
                        el.uploaded_id > 0 &&
                        el.plot_status === 0
                    ).length
                  }
                  )
                </span>
              </Button>
              <Button
                className={`pdp-lo-button-grp ${
                  plotStatus == 1 ? "active-tab" : ""
                }`}
                onClick={() => setPlotStatus(1)}
              >
                <span className="sold-dot"></span>
                <span className="dot-text">
                  Sold (
                  {
                    allPlots.filter(
                      (el) =>
                        el.drawn_type === 1 &&
                        el.uploaded_id > 0 &&
                        el.plot_status === 1
                    ).length
                  }
                  )
                </span>
              </Button>
              <Button
                className={`pdp-lo-button-grp ${
                  plotStatus == 2 ? "active-tab" : ""
                }`}
                onClick={() => setPlotStatus(2)}
              >
                <span className="booked-dot"></span>
                <span className="dot-text">
                  Booked (
                  {
                    allPlots.filter(
                      (el) =>
                        el.drawn_type === 1 &&
                        el.uploaded_id > 0 &&
                        el.plot_status === 2
                    ).length
                  }
                  )
                </span>
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <div className="sb-filter-widget">
          <div
            className="sb-filter__map mainmap"
            style={{ position: "relative", overflow: "hidden" }}
          >
            {/* {console.log("filters")} */}
            <DrawMaps
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              gps_location={{
                lat,
                lng,
              }}
              zoom_level={zoom || 16}
              polyline={polyline}
              plots={getFilteredPlots(allPlots)}
              show_boundary={show_boundary}
              boundary_color={boundary_color}
              onPolygonClick={(plot) => {
                // clickedPlots(plot.id, "MAP");
                setToolTipContent(plot || []);
                setOpen(true);
              }}
              showPrice={product && product.show_price}
            />
          </div>
        </div>
      </Grid>
    </>
  );
};
export default ProjectMap;
