export const priceRangeArr = [
    {
      val: 500000,
      label: '5 L'
    },
    {
      val: 1000000,
      label: '10 L'
    },
    {
      val: 1500000,
      label: '15 L'
    },
    {
      val: 2000000,
      label: '20 L'
    },
    {
      val: 2500000,
      label: '25 L'
    },
    {
      val: 3000000,
      label: '30 L'
    },
    {
      val: 4000000,
      label: '40 L'
    },
    {
      val: 5000000,
      label: '50 L'
    },
    {
      val: 6000000,
      label: '60 L'
    },
    {
      val: 7500000,
      label: '75 L'
    },
    {
      val: 9000000,
      label: '90 L'
    },
    {
      val: 10000000,
      label: '1 C'
    },
    {
      val: 12500000,
      label: '1.25 C'
    },
    {
      val: 15000000,
      label: '1.5 C'
    },
    {
      val: 17500000,
      label: '1.75 C'
    },
    {
      val: 20000000,
      label: '2 C'
    },
    {
      val: 30000000,
      label: '3 C'
    },
    {
      val: 40000000,
      label: '4 C'
    },
    {
      val: 50000000,
      label: '5 C'
    },
    {
      val: 100000000,
      label: '10 C'
    },
    {
      val: 100000001,
      label: '10 C+'
    }
  ];

  export const propertyTypes = {
    1: 'Residential plots',
    2: 'Villas',
    3: 'Farm Lands',
    4: 'Farm House',
    5: 'Gated Community',
    6: 'Commercial Land'
  };
  
  export const directions = {
    1: "E",
    2: "W",
    3: "N",
    4: "S",
    5: "NE",
    6: "NW",
    7: "SE",
    8: "SW"
  };

  export const defaultDirectionArr = [
    {
      id: 1,
      label: "East(E)",
      ticked: false,
    },
    {
      id: 2,
      label: "West(W)",
      ticked: false,
    },
    {
      id: 3,
      label: "North(N)",
      ticked: false,
    },
    {
      id: 4,
      label: "South(S)",
      ticked: false,
    },
    {
      id: 5,
      label: "North-East(NE)",
      ticked: false,
    },
    {
      id: 6,
      label: "North-West(NW)",
      ticked: false,
    },
    {
      id: 7,
      label: "South-East(SE)",
      ticked: false,
    },
    {
      id: 8,
      label: "South-West(SW)",
      ticked: false,
    },
  ];