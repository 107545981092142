import styled from 'styled-components';

const ParentDiv = styled.div`

    padding-bottom: 60px;

    .scroll-top-container {
        position: fixed;
        bottom: ${props => props.isMobile ? "10px" : "25px"};
        right: ${props => props.isMobile ? "10px" : "25px"};
        z-index: 9;
        background: white;
        opacity: 0.7;
        border-radius: 50%;
    }

    .pdp-verified-cntr {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px;
        gap: 2px;
        background: #F8FFE9;
        border: 1px solid #52643E;
        box-shadow: 0px -10px 16px rgb(0 0 0 / 10%);
        border-radius: 32px;
    }

    .pdp-verified-tick {
        color: #52643E;
    }

    .pdp-verified-txt {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 10px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.25px;
        font-feature-settings: "smcp";
        font-variant: small-caps;
        color: #52643E;
    }

    .pdp-prj-by {
        font-style: normal;
        font-weight: 400;
        font-size: ${props => props.isMobile ? "20px" : "25px"};
        line-height: ${props => props.isMobile ? "25px" : "32px"};
        text-align: center;
        letter-spacing: -0.25px;
        font-variant: small-caps;
        color: #462B00;
        display: inline;
    }

    .pdp-prj-name {
        font-family: 'Roboto Serif';
        font-style: normal;
        font-weight: 700;
        font-size: ${props => props.isMobile ? "40px" : "50px"};
        line-height: ${props => props.isMobile ? "45px" : "60px"};
        text-align: center;
        letter-spacing: -0.25px;
        background: linear-gradient(92.69deg, #5C6D4A 1.33%, #253514 98.29%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    .pdp-prj-address {
        font-family: 'Roboto Serif';
        font-style: normal;
        font-weight: 700;
        font-size: ${props => props.isMobile ? "20px" : "24px"};
        line-height: ${props => props.isMobile ? "24px" : "30px"};
        text-align: center;
        letter-spacing: -0.25px;
        background: linear-gradient(92.69deg, black 1.33%, black 98.29%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    .pdp-tlt-button {
        background-color: #ffddb5 !important;
        color: #835400 !important;
        border-color: wheat !important;
        text-transform: none !important;
    }

    .pdp-lo-button-grp {
        background-color: #ffddb5 !important;
        color: #835400 !important;
        border: 1px solid #A56B00 !important;
        text-transform: none !important;
    }

    .pdp-lo-button-grp-clckd {
        background-color: white !important;
        color: #835400 !important;
        border: 1px solid #A56B00 !important;
        text-transform: none !important;
    }

    .pdp-lo-button-grp-fav {
        background-color: darkgray !important;
        color: white !important;
        border: 1px solid white !important;
        text-transform: none !important;
    }

    .pdp-lo-button-grp-disable {
        background-color: #ff5a3c !important;
        color: white !important;
        border: 1px solid white !important;
        text-transform: none !important;
    }

    .active-tab {
        background-color: #FFF8F4 !important;
    }

    .slider-left,
    .slider-right {
        margin-top: 30%;
    }

    .slider-left div,
    .slider-right div {
        border: none !important;
        color: #1c1b1f !important;
    }

    .pdp-about-size {
        font-family: 'Roboto Serif';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.25px;
        color: #52643E;
    }

    .pdp-tl-about-company {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.25px;
        color: #000000;
        padding: 5px;
        cursor: zoom-out;
    }

    .pdp-generic-readmore {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: fit-content;
        cursor: zoom-in !important;
    }

    .pdp-lo-dtls-txt {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: -0.25px;
        font-variant: small-caps;
        color: #4B4640;
        text-align: center;
    }

    .pdp-amen-card-cntr {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 150px;
        background: #FFF8F4;
        border: 1px solid #645D57;
        border-radius: 16px;
        flex: none;
        order: 0;
        flex-grow: 0;
        gap: 20px;
    }

    .pdp-amen-card-icon {
        transform: scale(4);
        color: #645D57;
    }

    .pdp-amen-card-txt {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.25px;
        color: #685C50;
        text-align: center;
    }

    .pdp-contact-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .pdp-contact-card-nme {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.25px;
        color: #1F1B16;
    }

    .pdp-contact-card-cnt {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.25px;
        color: #835400;
    }

    .pdp-footer-address {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.25px;
        color: #000000;
    }

    .pdp-footer-desc {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: justify;
        letter-spacing: -0.25px;
        color: #000000;
        cursor: zoom-out;
    }

    .pdp-bnk-logo {
        height: 30px;
    }

    .pdp-bnk-contr {
        display: flex;
        direction: row;
        gap: 10px;
    }

    .otp-login {
        padding-top: 64px;
        padding-bottom: 16px;
        padding-left: 32px;
    }

    .pb-120 {
        padding-bottom: 120px;
    }

    .pt-50 {
        padding-top: 50px;
    }

    .container {
        max-width: 90%;
    }

    .container {
        padding-right: 15px;
        padding-left: 15px;
    }

    .row {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: calc(var(--bs-gutter-y) * -1);
        margin-right: calc(var(--bs-gutter-x) * -.5);
        margin-left: calc(var(--bs-gutter-x) * -.5);
    }

    .mobile-label {
        font-size: 20px;
        margin: 0px;
        margin-bottom: 12px;
    }

    .mobile-span{
        font-size: 14px;
    }

    .carousel-slider {
        width: ${props => props.isMobile ? "100%" : "80%"} !important; 
        left: ${props => props.isMobile ? "1%" : "10%"} !important;
        border-radius: 25px;
    }

    .videos .carousel.carousel-slider .control-arrow {
        background-color: #4b4640;
        width: ${props => props.isMobile ? "auto" : "4%"} !important;
    }

    .layout-street iframe{
        border-radius: 20px;
    }
`;

export { 
    ParentDiv, 
}