/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "axios";

import { baseUrl } from "../../util/config";

const StreetView = ({ layout_id, street_view_url }) => {
  const [streetViewUrl, setStreetViewUrl] = useState(street_view_url);
  const [showType, setShowType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allStreetView, setAllStreetView] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (layout_id) {
      getallStreetView(layout_id);
    }
  }, [layout_id]);

  useEffect(() => {
    if (street_view_url) {
      setStreetViewUrl(street_view_url);
    }
  }, [street_view_url]);

  const getallStreetView = (project_id) => {
    const payload = { project_id };
    axios.post(`${baseUrl}/cms_api/realestate/streetview/get`, payload).then(
      (res) => {
        const allRes = (res.data && res.data.list) || [];
        setAllStreetView(allRes);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    var partsOfStr = street_view_url.split("&");
    if (partsOfStr) {
      let replaceString = partsOfStr.filter((element) =>
        element.includes("image_key")
      );
      if (
        replaceString.length > 0 &&
        showType &&
        showType.place_image_id != ""
      ) {
        let replace_street_view_url = street_view_url.replace(
          replaceString[0],
          `image_key=${showType.place_image_id}`
        );
        setStreetViewUrl(replace_street_view_url);
        setIsLoading(false);
      }
    }
  }, [showType]);

  // const nearByHelperText = () => {
  //   if (showType) {
  //     if (showType.name === "local") {
  //       return "";
  //     } else {
  //       try {
  //         const selectedName = document.querySelector(
  //           ".pdp-lo-button-grp-clckd .MuiChip-label"
  //         ).innerHTML;
  //         const selectedRadius = parseInt(showType.radius) / 1000;
  //         return `${nearByLocations.length} ${selectedName} within ${selectedRadius} Kms`;
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     }
  //   }
  //   return;
  // };

  return (
    <>
      {isLoading && <CircularProgress color="success" />}
      {!isLoading && streetViewUrl && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid
              container
              gap="1em"
              justifyContent="center"
              alignItems="center"
              margin="0 0 2em 0"
            >
              {(allStreetView || []).map((el, i) => (
                <Chip
                  label={capitalizeFirstLetter(el.place_name)}
                  size="large"
                  className={
                    showType === el ||
                    (showType && showType.name === el.place_name)
                      ? "pdp-lo-button-grp-clckd"
                      : "pdp-lo-button-grp"
                  }
                  onClick={() => {
                    setIsLoading(true);
                    setShowType(el);
                  }}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container justifyContent="center" alignItems="center">
              <div
                style={{ width: "90%", height: "90vh" }}
                dangerouslySetInnerHTML={{
                  __html: streetViewUrl,
                }}
                className="layout-street"
              ></div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default StreetView;
