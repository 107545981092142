import React, { useState, useEffect, useRef } from "react";
import IconButton from "@mui/material/IconButton";
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import axios from "axios";

import { baseUrl } from "../../../util/config";
import * as CSS from "./MapSortPopup_Style";

const MapSortPopup = (props) => {

    const { defaultSortArr, setZoom, setGpsLocation, gpsLocation, setInfoWindowTrigger, infoWindowTrigger, setOpenGoogleLoginPopup } = props;
    const [userDetails, setUserDetails] = React.useState(null);
    const [ascSort, setAscSort] = useState(true);
    const [expand, setExpand] = useState(true);
    const [sortedList, setSortedList] = useState([]);

    React.useEffect(() => {
        const user = localStorage.getItem("user") || null;
        if (user) {
          const userData = JSON.parse(user);
          setUserDetails(userData);
        }
    }, []);

    useEffect(() => {
        const defArr = [...defaultSortArr];
        defArr.sort((a, b) => a.plp_price_sq_ft - b.plp_price_sq_ft);
        setSortedList(defArr);
    }, [defaultSortArr]);

    const sortPr = () => {
        const defArr = [...sortedList].reverse();
        setSortedList(defArr);
        setAscSort(!ascSort);
    }

    const expColSortPopup = () => {
        setExpand(!expand);
        setInfoWindowTrigger('close');
        setZoom(11);
    }

    const cardClick = (clickedElement) => {        
        if(gpsLocation.lat === clickedElement.gps_lat && gpsLocation.lng === clickedElement.gps_long) {
            // console.log('same card clicked again..');
            setInfoWindowTrigger('close');
            setZoom(11);
        } else {
            setGpsLocation({ lat: clickedElement.gps_lat, lng: clickedElement.gps_long });
            setInfoWindowTrigger({ lat: clickedElement.gps_lat, lng: clickedElement.gps_long });
            setZoom(13);
        }
    }

    const makeFav = (el) => {
        console.log(el);
        if (userDetails && userDetails.googleId) {
            const is_fav = el.is_fav ? 0 : 1;
            const payload = {
                project: el.id,
                source_id: el.id,
                is_fav,
                user: userDetails && userDetails.googleId ? userDetails.googleId : "",
                type: 1,
            };
            axios.post(`${baseUrl}/plot_api/realestate/api/fav`, payload).then((res) => {
                    if (res.data && res.data.status) {
                        console.log('Fav updated..');
                        const newSortedList = sortedList.map(sortEl => {
                            if(sortEl.id === el.id) {
                                const newItem = { ...sortEl };
                                newItem.is_fav = is_fav;
                                return newItem;
                            } else {
                                return sortEl;
                            }
                        });
                        setSortedList(newSortedList);
                    }
                }, (err) => {
                    console.log("err", err);
                }
            );
        } else {
            setOpenGoogleLoginPopup(true);
        }
    }

    return (
        <CSS.popupWrapper>
            <CSS.headerWrapper>
                {
                    expand && (
                        <>
                            <IconButton style={{ color: '#feecd9', opacity: `${expand ? '1' : '0.5'}` }} disabled={!expand} onClick={sortPr}>
                            {
                                ascSort ? <FilterListRoundedIcon style={{ transform: 'rotate(180deg)' }} /> : <FilterListRoundedIcon />
                            }
                            </IconButton>
                            SORT BY PRICE { ascSort ? 'LOW TO HIGH' : 'HIGH TO LOW' }
                        </>
                    )
                }
                <IconButton style={{ color: '#feecd9' }} onClick={() => expColSortPopup()}>
                {
                    expand ? <KeyboardDoubleArrowLeftIcon /> : <FormatListBulletedRoundedIcon />
                }
                </IconButton>
            </CSS.headerWrapper>
            {
                expand && (
                    <CSS.cardsWrapper className='sortCard'>
                        {
                            sortedList.map((el, index) => {
                                return (
                                    <CSS.cardWrapper el={el} iwt={infoWindowTrigger} onClick={() => cardClick(el)}>
                                        <CSS.cardLeftWrapper>
                                            <CSS.snoWrapper>
                                                { index+1 }
                                            </CSS.snoWrapper>
                                            <IconButton>
                                                {
                                                    el.is_fav ? (
                                                        <FavoriteIcon style={{ color: '#cc0000' }} onClick={() => makeFav(el)} />
                                                    ) : (
                                                        <FavoriteBorderIcon onClick={() => makeFav(el)} />
                                                    )
                                                }
                                            </IconButton>
                                        </CSS.cardLeftWrapper>
                                        <CSS.cardRightWrapper>
                                            <CSS.cardPrice>₹{(el.plp_price_sq_ft).toLocaleString('en-IN')}/sq.ft | ₹{(el.plp_price_cent).toLocaleString('en-IN')}/cent</CSS.cardPrice>
                                            <CSS.cardAddress className='lineBreak'><b>{el.name}</b></CSS.cardAddress>
                                            <CSS.cardAddress>{el.plp_address} - {el.zipcode}</CSS.cardAddress>
                                            <CSS.cardAddress className='lineBreak'>{el.company_promotors}</CSS.cardAddress>
                                        </CSS.cardRightWrapper>
                                    </CSS.cardWrapper>
                                )
                            })
                        }
                    </CSS.cardsWrapper>
                )
            }
        </CSS.popupWrapper>
    )
}

export default MapSortPopup;