import { useState, useRef, useEffect } from "react";
//Material UI imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
//Custom imports
import * as CSS from "../Style.js";
import AutoComplete from "../GoogleLocationPopup/AutoComplete";
import DrawMaps from "../GoogleLocationPopup/DrawMapsNew";

const API_KEY = "AIzaSyBtRJ59UWCyTVENScWckaaqJas16Ymuuic";

const GoogleLocationPopup = (props) => {
  const {
    openFlag,
    setCloseFlag,
    googleLocationData,
    setAutoCompleteLocation,
  } = props;

  const [data, setData] = useState({
    gps_location: null,
    zoom_level: 18,
  });
  const [location, setLocation] = useState(null);

  const onMapClick = (e) => {
    setData({
      ...data,
      gps_location: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    });
  };

  const onZoomChanged = (zoom) => {
    setData({
      ...data,
      zoom_level: zoom,
    });
  };

  useEffect(() => {
    setData({
      ...data,
      gps_location: location,
    });
  }, [location]);

  useEffect(() => {
    if (googleLocationData.gps_location) {
      setData({
        ...data,
        gps_location: googleLocationData.gps_location,
        zoom_level: googleLocationData.zoom_level,
      });
    }
  }, [googleLocationData]);

  const Map = (props) => (
    <DrawMaps
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `500px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      gps_location={props.gps_location}
      onClick={props.onMapClick}
      isProject={props.isProject}
      zoom_level={props.zoom_level}
      onZoomChanged={props.onZoomChanged}
      drawingControlEnabled={props.drawingControlEnabled}
      overlay={props.overlay}
      polyline={props.polyline}
      show_boundary={props.show_boundary}
      boundary_color={props.boundary_color}
    />
  );

  return (
    <CSS.CustomizedDialog
      open={openFlag}
      onClose={() => setCloseFlag(false)}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="row"
        margin="2em 0 2em 0;"
      >
        <CSS.CloseBtn>
          <IconButton
            aria-label="closePopup"
            onClick={() => setCloseFlag(false)}
          >
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn>
        <CSS.TitleContainer>
          <span className="title">Google Location</span>
        </CSS.TitleContainer>
        <Grid container xs={12} md={12} lg={12}>
          <Grid item xs={10} md={10} lg={10}>
            <AutoComplete
              setLocation={(val) => {
                setLocation(val && val.coor);
              }}
            />
          </Grid>
          {data && data.gps_location && (
            <Grid item xs={2} md={2} lg={2} style={{ alignSelf: "center" }}>
              <CSS.customBtn
                variant="outlined"
                onClick={() => setAutoCompleteLocation(data)}
              >
                Save
              </CSS.customBtn>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {data && data.gps_location && (
            <Map
              gps_location={
                (data && data.gps_location) || {
                  lat: 13.086289405322379,
                  lng: 80.29239116173903,
                }
              }
              isProject={true}
              onMapClick={onMapClick}
              onZoomChanged={onZoomChanged}
              zoom_level={(data && data.zoom_level) || 18}
              drawingControlEnabled={false}
              polyline={(data && data.polyline) || []}
              show_boundary={data && data.show_boundary}
              boundary_color={data && data.boundary_color}
            />
          )}
        </Grid>
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default GoogleLocationPopup;
