import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
//Other lib imports
import axios from "axios";
//Custom imports
import XHR from "../../util/pxhr";
import PromoterHeader from "../HeaderMenu/PromoterHeader";
import * as CSS from "./style";
import PromoterSubmittedDetailsPopup from '../Overlays/PromoterSubmittedDetailsPopup/PromoterSubmittedDetailsPopup';

import { baseUrl } from "../../util/config";

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor:
//       theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === "light" ? "#4DE45C" : "#308fe8",
//   },
// }));

function PromoterWelcome() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [profileCheckOne, setProfileCompletionOne] = useState(false);
  const [profileCheckTwo, setProfileCompletionTwo] = useState(false);
  const [submittedProfile, setSubmittedProfile] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const onAddLayoutBtnClk = () => {
    navigate("/addLayout");
  };

  const onSubmitBtnClk = () => {
    navigate("/promoterLayout");
  };

  const onProfileBtnClk = () => {
    navigate("/promoterProfile");
  };

  useEffect(() => {
    const promotor_user = localStorage.getItem("promotor_user") || null;
    if (promotor_user) {
      const userData = JSON.parse(promotor_user);
      setUserDetails(userData);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      getPromotorDetails(userDetails.id);
      getAllPromotorsData(userDetails.id);
      getPopupData(userDetails.id);
    }
  }, [userDetails]);
  
  const getPromotorDetails = (data) => {
    setIsLoading(true);
    const payload = { company_id: data };
    axios
      .post(`${baseUrl}/cms_api/realestate/promoters/get`, payload)
      .then(
        (res) => {
          setIsLoading(false);
          if (res.data && res.data.companies) {
            let companyData = res.data.companies;
            let incomingData = {};
            const image = companyData.gallery.filter((el) => el.type === 'image');
            incomingData['image'] = image.length ? true : false;
            incomingData['no_of_years'] = companyData.general_information.no_of_years || false;
            incomingData['no_of_cities_covered'] = companyData.general_information.no_of_cities_covered || false;
            incomingData['no_of_project_completed'] = companyData.general_information.no_of_project_completed || false;
            incomingData['no_of_project_on_going'] = companyData.general_information.no_of_project_on_going || false;
            incomingData['about_us'] = companyData.general_information.about_us || false;
            incomingData['logo'] = companyData.general_information.logo || false;
            let setProfileCompletionOneVal = 'valid';
            for (const property in incomingData) {
              if(incomingData[property] === false) {
                setProfileCompletionOneVal = 'invalid';
              }
            }
            setProfileCompletionOne(setProfileCompletionOneVal === 'valid' ? true : false);
          }
        },
        (err) => {
          setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  const getAllPromotorsData = (id) => {
    setIsLoading(true);
    const payload = { promoters_id: id };
    XHR.post("realestate/promoters/getdata", payload).then(
      (res) => {
        setIsLoading(false);
        if(res.data && res.data.list) {
          const directors = res.data.list.filter((el) => el.type === "board_of_directors");
          setProfileCompletionTwo(directors.length ? true : false);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getPopupData = (id) => {
    setIsLoading(true);
    const payload = { user_id: id };
    XHR.post("realestate_v1/user/id", payload).then(
      (res) => {
        setIsLoading(false);
        setSubmittedProfile(res?.data?.users[0]);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  }

  return (
    <CSS.ParentDiv>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: '999',
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <PromoterSubmittedDetailsPopup openFlag={openPopup} setCloseFlag={setOpenPopup} submittedProfile={submittedProfile} />
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 6em 0;"
      >
        <PromoterHeader />
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            {userDetails && (
              <Box
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                flexDirection="column"
                padding="25px"
              >
                <div className="login-form-div">
                  <span className="login-form-heading">Welcome!!!</span>
                </div>
                <div className="login-form-div">
                  <span className="login-form-heading">
                    {userDetails.promotor_name}
                  </span>
                </div>
                <div className="profile-incomplete-div dashboard-first-btn">
                  <a className="profile-incomplete-text" href='#' onClick={() => submittedProfile ? setOpenPopup(true) : ''}>
                    view submitted detais
                  </a>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onProfileBtnClk}
                    className="login-inputWidth login-button"
                    endIcon={<EastIcon />}
                  >
                    Company Profile
                  </Button>
                </div>
                {
                  (!profileCheckOne || !profileCheckTwo) && <>
                    <div className="profile-incomplete-div">
                      <span className="profile-incomplete-text">
                        Please complete your company profile to add layout.
                      </span>
                    </div>
                  </>
                }
                {
                  profileCheckOne && profileCheckTwo && <>
                    <div className="login-form-div">
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={onAddLayoutBtnClk}
                        className="login-inputWidth login-button"
                        endIcon={<EastIcon />}
                      >
                        Add New Layout
                      </Button>
                    </div>
                    <div className="login-form-div">
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={onSubmitBtnClk}
                        className="login-inputWidth login-button"
                        endIcon={<EastIcon />}
                      >
                        View Added Layout
                      </Button>
                    </div>
                  </>
                }
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
}

export default PromoterWelcome;
