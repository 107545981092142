import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import PostAddIcon from "@mui/icons-material/PostAdd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RoomIcon from "@mui/icons-material/Room";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DirectionsIcon from "@mui/icons-material/Directions";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AudiotrackOutlinedIcon from "@mui/icons-material/AudiotrackOutlined";
import LaunchIcon from '@mui/icons-material/Launch';
import AlarmAddIcon from "@mui/icons-material/AlarmAdd";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EmailIcon from "@mui/icons-material/Email";
import {
  PagingState,
  SortingState,
  CustomPaging,
  DataTypeProvider,
  RowDetailState,
  FilteringState,
  IntegratedSorting,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableColumnVisibility,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";

import "./index.css";

import { withStyles, makeStyles } from "@mui/styles";

// import ReactGA from "react-ga";
// ReactGA.initialize("G-8FXNV1NVFX", {
//   debug: true,
//   titleCase: false,
//   gaOptions: {},
// });

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

const styles = (theme) => ({
  tableStriped: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: "rgba(4,169,245,.05)",
    },
  },
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: "TableComponent" })(
  TableComponentBase
);

const getRupeesFormat = (amountValue = "") => {
  return (amountValue || "").toLocaleString("en-IN");
};

const viewPriceColumn = ({ row }) => {
  return <div>{`₹${getRupeesFormat(row.cost || 0)}`}</div>;
};

const viewBtn = ({ row }, action = [], actionClick, actionLabel = {}) => (
  <div>
    {action.includes("UPLOAD_AUDIO") ? (
      <Tooltip title="Record Audio">
        <AudiotrackOutlinedIcon
          title={
            actionLabel["UPLOAD_AUDIO"]
              ? actionLabel["UPLOAD_AUDIO"]
              : "Record Audio"
          }
          onClick={() => {
            actionClick("UPLOAD_AUDIO", row);
          }}
          style={{
            color: row.stop_audio && row.stop_audio != "" ? "#53CA00" : "",
            fontSize: 20,
            marginLeft: 10,
            cursor: "pointer",
          }}
        />
      </Tooltip>
    ) : null}
    {action.includes("EDIT") ? (
      <Tooltip title={actionLabel["EDIT"] ? actionLabel["EDIT"] : "Edit"}>
        <EditIcon
          title={actionLabel["EDIT"] ? actionLabel["EDIT"] : "Edit"}
          onClick={() => {
            actionClick("EDIT", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("PLAY") ? (
      <Tooltip title={actionLabel["PLAY"] ? actionLabel["PLAY"] : "Play Audio"}>
        <PlayCircleFilledWhiteIcon
          title={actionLabel["PLAY"] ? actionLabel["PLAY"] : "Play Audio"}
          onClick={() => {
            actionClick("PLAY", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("MAP") ? (
      <Tooltip title={actionLabel["MAP"] ? actionLabel["MAP"] : "Map"}>
        <PostAddIcon
          title={actionLabel["MAP"] ? actionLabel["MAP"] : "Map"}
          onClick={() => {
            actionClick("MAP", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("VIEW") &&
    (!("view_status" in row) || row["view_status"] === 1) ? (
      <Tooltip title={actionLabel["VIEW"] ? actionLabel["VIEW"] : "View"}>
        <VisibilityIcon
          title={actionLabel["VIEW"] ? actionLabel["VIEW"] : "View"}
          onClick={() => {
            actionClick("VIEW", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("SITE_VISIT_EDIT") &&
    (!("view_status" in row) || row["view_status"] === 1) ? (
      <Tooltip title={actionLabel["VIEW"] ? actionLabel["VIEW"] : "Edit Date"}>
        <EditIcon
          title={actionLabel["VIEW"] ? actionLabel["VIEW"] : "View"}
          onClick={() => {
            actionClick("VIEW", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("MAPS") ? (
      <Tooltip title={actionLabel["MAPS"] ? actionLabel["MAPS"] : "Maps"}>
        <RoomIcon
          title={actionLabel["MAPS"] ? actionLabel["MAPS"] : "Maps"}
          onClick={() => {
            actionClick("MAPS", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("ADD") ? (
      <Tooltip title={actionLabel["ADD"] ? actionLabel["ADD"] : "Add"}>
        <AddIcon
          title={actionLabel["ADD"] ? actionLabel["ADD"] : "Add"}
          onClick={() => {
            actionClick("ADD", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("MOVE_UP") ? (
      <Tooltip
        title={actionLabel["MOVE_UP"] ? actionLabel["MOVE_UP"] : "Move Up"}
      >
        <ArrowUpwardIcon
          title={actionLabel["MOVE_UP"] ? actionLabel["MOVE_UP"] : "Move Up"}
          onClick={() => {
            actionClick("MOVE_UP", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("MOVE_DOWN") ? (
      <Tooltip
        title={
          actionLabel["MOVE_DOWN"] ? actionLabel["MOVE_DOWN"] : "Move Down"
        }
      >
        <ArrowDownwardIcon
          title={
            actionLabel["MOVE_DOWN"] ? actionLabel["MOVE_DOWN"] : "Move Down"
          }
          onClick={() => {
            actionClick("MOVE_DOWN", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("ROUTE") ? (
      <Tooltip
        title={actionLabel["ROUTE"] ? actionLabel["ROUTE"] : "Direction"}
      >
        <DirectionsIcon
          title={actionLabel["ROUTE"] ? actionLabel["ROUTE"] : "Direction"}
          onClick={() => {
            actionClick("ROUTE", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("GOTO") ? (
      <Tooltip
        title={actionLabel["GOTO"] ? actionLabel["GOTO"] : "Go to Plot"}
      >
        <LaunchIcon
          title={actionLabel["GOTO"] ? actionLabel["GOTO"] : "Go to Plot"}
          onClick={() => {
            actionClick("GOTO", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("ADD_PEAK_TIME") ? (
      <Tooltip
        title={
          actionLabel["ADD_PEAK_TIME"]
            ? actionLabel["ADD_PEAK_TIME"]
            : "Add Peak Time"
        }
      >
        <AlarmAddIcon
          title={
            actionLabel["ADD_PEAK_TIME"]
              ? actionLabel["ADD_PEAK_TIME"]
              : "Add Peak Time"
          }
          onClick={() => {
            actionClick("ADD_PEAK_TIME", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}

    {action.includes("GENERATE") && row["generate"] === 1 ? (
      <Tooltip
        title={actionLabel["GENERATE"] ? actionLabel["GENERATE"] : "Generate"}
      >
        <AutorenewIcon
          title={actionLabel["GENERATE"] ? actionLabel["GENERATE"] : "Generate"}
          onClick={() => {
            actionClick("GENERATE", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}

    {action.includes("ROLEMAPP") ? (
      <Tooltip
        title={actionLabel["ROLEMAPP"] ? actionLabel["ROLEMAPP"] : "Role Mapp"}
      >
        <EventNoteIcon
          title={
            actionLabel["ROLEMAPP"] ? actionLabel["ROLEMAPP"] : "Role Mapp"
          }
          onClick={() => {
            actionClick("ROLEMAPP", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}

    {action.includes("EMAIL") ? (
      <Tooltip title={actionLabel["EMAIL"] ? actionLabel["EMAIL"] : "Email"}>
        <EmailIcon
          title={actionLabel["EMAIL"] ? actionLabel["EMAIL"] : "Email"}
          onClick={() => {
            actionClick("EMAIL", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
    {action.includes("DELETE") &&
    (!("is_delete" in row) || row["is_delete"] === 1) ? (
      <Tooltip title={actionLabel["DELETE"] ? actionLabel["DELETE"] : "Delete"}>
        <DeleteForeverIcon
          title={actionLabel["DELETE"] ? actionLabel["DELETE"] : "Delete"}
          onClick={() => {
            actionClick("DELETE", row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    ) : null}
  </div>
);

const DataGrid = (props) => {
  const {
    rows,
    columns,
    tableColumnExtensions,
    defaultHiddenColumnNames,
    loadGrid,
    filterdata,
    totalRecords,
    action,
    actionClick,
    filteringStateColumnExtensions,
    sortingStateColumnExtensions,
    actionLabel = {},
    disableServerSide = false,
  } = props;

  const table = {
    columns: columns,
    currencyColumns: [],
    tableColumnExtensions: tableColumnExtensions,
    filteringStateColumnExtensions: filteringStateColumnExtensions,
    rows: rows,
    sorting: [],
    sortingStateColumnExtensions: sortingStateColumnExtensions,
    defaultHiddenColumnNames: defaultHiddenColumnNames,
    totalCount: 0,
    pageSize: 10,
    pageSizes: [5, 10, 15, 25, 50, 100, 250, 500, 1000, 2000],
    currentPage: 0,
    viewColumns: ["action"],
    priceColumns: ["cost"],
    dialogOpen: false,
    joinDialogOpen: false,
    search: false,
    filters: [],
    deleteRow: "",
    joinRow: "",
    joinUrl: "",
  };

  const [tableData, setTableData] = useState(table);
  const classes = useStyles();

  const changeFilters = (filters) => {
    setTableData({
      ...tableData,
      filters: filters,
    });
  };

  useEffect(() => {
    loadData();
  }, [
    tableData.currentPage,
    tableData.sorting,
    tableData.pageSize,
    tableData.filters,
    filterdata,
  ]);

  function loadData() {
    const { sorting, pageSize, currentPage } = tableData;
    const columnSorting = sorting[0];
    let orderby = "";
    if (columnSorting) {
      const sortingDirectionString =
        columnSorting.direction === "desc" ? " desc" : " asc";
      orderby = {
        columnName: columnSorting.columnName,
        dir: sortingDirectionString,
      };
    }
    let inputs = {
      pageSize: pageSize,
      skip: pageSize * currentPage,
      orderby: JSON.stringify(orderby),
      filters: JSON.stringify(tableData.filters),
      filterdata: JSON.stringify(filterdata),
    };
    loadGrid(inputs);
  }

  useEffect(() => {
    setTableData({
      ...tableData,
      rows: rows,
      totalCount: parseInt(totalRecords),
      search: false,
    });
  }, [rows, totalRecords]);

  function changeSorting(sorting) {
    // ReactGA.event({
    //   category: "Sort Changed",
    //   action: JSON.stringify(sorting),
    // });
    setTableData({
      ...tableData,
      loading: true,
      sorting,
    });
  }

  function changeCurrentPage(currentPage) {
    setTableData({
      ...tableData,
      loading: true,
      currentPage,
    });
  }

  function changePageSize(pageSize) {
    const { totalCount, currentPage: stateCurrentPage } = tableData;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(stateCurrentPage, totalPages - 1);
    setTableData({
      ...tableData,
      loading: true,
      pageSize,
      currentPage,
    });
  }

  return (
    <Grid
      className="tableGrid"
      rows={tableData.rows}
      columns={tableData.columns}
    >
      <SortingState
        sorting={tableData.sorting}
        onSortingChange={changeSorting}
        columnExtensions={tableData.sortingStateColumnExtensions}
      />
      {disableServerSide && <IntegratedSorting />}
      <PagingState
        currentPage={tableData.currentPage}
        onCurrentPageChange={changeCurrentPage}
        pageSize={tableData.pageSize}
        onPageSizeChange={changePageSize}
      />
      <CustomPaging totalCount={tableData.totalCount} />
      <RowDetailState defaultExpandedRowIds={tableData.defaultExpandedRowIds} />
      <FilteringState
        onFiltersChange={changeFilters}
        columnExtensions={tableData.filteringStateColumnExtensions}
      />
      {disableServerSide && <IntegratedFiltering />}
      <DataTypeProvider
        for={tableData.priceColumns}
        formatterComponent={(row) => {
          return viewPriceColumn(row);
        }}
      />
      <DataTypeProvider
        for={tableData.viewColumns}
        formatterComponent={(row) => {
          return viewBtn(row, action, actionClick, actionLabel);
        }}
      />
      <Table
        columnExtensions={tableData.tableColumnExtensions}
        tableComponent={TableComponent}
      />
      <TableHeaderRow showSortingControls />
      <TableColumnVisibility
        defaultHiddenColumnNames={tableData.defaultHiddenColumnNames}
      />
      {!props.hideFilter && <TableFilterRow />}
      <PagingPanel pageSizes={tableData.pageSizes} />
    </Grid>
  );
};

export default DataGrid;
