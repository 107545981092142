/* eslint-disable react/jsx-pascal-case */
import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import Avatar from "@mui/material/Avatar";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
//Other lib imports
import { isBrowser, isMobile } from "react-device-detect";
import { useGoogleLogout } from "react-google-login";
//Custom imports
import logo from "../../Assets/logo_with_text.png";
import * as CSS from "./Style.js";
import { clientId } from "../../util/constants/generalConstant";
/* const clientId =
  "749795274272-rbo6pubta1t12egbbbs96lj80mdssumj.apps.googleusercontent.com"; */

const PromoterPDPHeader = (props) => {
  const { goToTab, headerDetails, openLogin } = props;

  const navigate = useNavigate();

  const options = [
    "Gallery",
    "Board of Directors",
    "About Us",
    "Team",
    "Review",
    "available projects",
  ];

  const menuOptions = [
    "View Profile",
    "View Favorites",
    "View Site Visit",
    "View Queries",
    // "Saved Search",
  ];

  const [loginState, setLoginState] = useState({
    isLogin: false,
  });
  const [userDetails, setUserDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const menuRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuClose = (event) => {
    if (menuRef.current && menuRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("authToken") || null;
    if (token) {
      setLoginState({
        ...loginState,
        isLogin: true,
      });
    }

    const user = localStorage.getItem("user") || null;
    if (user) {
      const userData = JSON.parse(user);
      setUserDetails(userData);
    }
  }, []);

  const menuNavigate = (menuId) => {
    let redirectUrl = "profile";
    if (menuId === 1) {
      redirectUrl = "favourites";
    } else if (menuId === 2) {
      redirectUrl = "sitevisit";
    } else if (menuId === 3) {
      redirectUrl = "queries";
    } else if (menuId === 4) {
      redirectUrl = "create-search";
    }

    if (redirectUrl !== "") {
      navigate(`/${redirectUrl}`, {
        state: {},
      });
    }
  };

  const onLogoutSuccess = (res) => {
    // console.log("Logged out Success");
    // alert("Logged out Successfully ✌");
    localStorage.removeItem("user");
    localStorage.removeItem("authToken");
    window.location.reload();
  };

  const onFailure = () => {
    console.log("Handle failure cases");
  };

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  return (
    <>
      <CSS.logoContainer
        src={logo}
        alt="#"
        onClick={() =>
          navigate("/", {
            state: {},
          })
        }
      />
      <CSS.BtnContainer>
        <IconButton className={"iconBtns"}>
          <HomeIcon
            onClick={() =>
              navigate("/", {
                state: {},
              })
            }
          />
        </IconButton>
        {isBrowser &&
          headerDetails.map((option, index) =>
            option.visible ? (
              <CSS.CustBtn onClick={() => goToTab(option.id)} key={index}>
                {option.name}
              </CSS.CustBtn>
            ) : null
          )}
        {isMobile && (
          <>
            <CSS.CustBtnGrp variant="text" ref={anchorRef}>
              <CSS.CustBtn onClick={handleToggle}>
                {options[selectedIndex]}
              </CSS.CustBtn>
              <CSS.CustBtn
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <MenuOpenIcon />
              </CSS.CustBtn>
            </CSS.CustBtnGrp>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {headerDetails.map((option, index) =>
                          option.visible ? (
                            <MenuItem
                              key={index}
                              selected={index === selectedIndex}
                              onClick={(event) => {
                                handleMenuItemClick(event, index);
                                goToTab(option.id);
                              }}
                            >
                              {option.name}
                            </MenuItem>
                          ) : null
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        )}
        {loginState.isLogin && (
          <>
            <Avatar
              alt="Remy Sharp"
              src={(userDetails && userDetails.imageUrl) || ""}
              onClick={() => setMenuOpen(true)}
              onMouseOver={() => setMenuOpen(true)}
              ref={menuRef}
            />
            <IconButton className={"iconBtns"}>
              <LogoutIcon
                onClick={() =>
                  window.confirm("Are you sure you want to logout?")
                    ? signOut()
                    : ""
                }
              />
            </IconButton>
          </>
        )}
        {!loginState.isLogin && (
          <IconButton className={"iconBtns"}>
            <LoginIcon onClick={() => openLogin(true)} />
          </IconButton>
        )}
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={menuOpen}
          role={undefined}
          anchorEl={menuRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleMenuClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {menuOptions.map((option, index) => (
                      <MenuItem
                        key={option}
                        onClick={(event) => {
                          menuNavigate(index);
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </CSS.BtnContainer>
    </>
  );
};

export default PromoterPDPHeader;
