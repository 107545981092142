import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import FormControl from "@mui/material/FormControl";
//Other lib imports
//Custom imports
import GetMapLocationInstruction from "../../Overlays/GetMapLocationInstruction/GetMapLocationInstruction";

const AddPropertyStepFour = (props) => {
  const { nextStep, propData, inputChange, setReusableDialogObj } = props;
  const [open, setOpen] = useState(false);

  const closeReusableDialog = () => {
    setReusableDialogObj({
      open: false
    });
  }

  const onBackBtnClk = () => {
    nextStep(3);
  };

  const onNextBtnClk = () => {
    if(propData.location) {
      if(propData.location.startsWith("https://goo.gl/maps/")) {
        nextStep(5);
      } else {
        setReusableDialogObj({
          open: true,
          title: '',
          content: 'Please enter a valid input',
          btnArr: [
            {
              label: 'OK',
              fun: closeReusableDialog
            }
          ]
        });
      }
    } else {
      setReusableDialogObj({
        open: true,
        title: '',
        content: 'Please enter all mandatory inputs',
        btnArr: [
          {
            label: 'OK',
            fun: closeReusableDialog
          }
        ]
      });
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <GetMapLocationInstruction openFlag={open} setCloseFlag={setOpen} />
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Please share your property GPS location</span>
                <div>
                  <span className="heading-step">(Step 4 of 11)</span>
                </div>
              </div>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='Google Map Location*'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='location'
                  value={propData.location}
                  onChange={ e => inputChange(e) }
                  helperText={ `Ex: https://goo.gl/maps/jnhmPBBx9kfbmsi37` }
                />
              </FormControl>
              <div className='how-to-gmaps'>
                <a href='#' onClick={() => setOpen(true)} >How to get map location?</a>
              </div>
              <div className='how-to-gmaps' style={{ marginTop: '24px' }}>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={ () => window.open('https://www.google.com/maps', '_blank') }
                  className="login-button"
                >
                  Open Google Maps
                </Button>
              </div>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPropertyStepFour;
