import * as React from "react";
import { isMobile } from "react-device-detect";
//Material UI imports
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
//Custom imports
import "./PromoterPDPDivider.css";
import * as CSS from "../Style";

const PromoterPDPDivider = (props) => {
  const { sortingOnChange, cityData, totalCnt } = props;

  const [selectedOption, setSelectedOption] = React.useState("All Cities");

  const changeSorting = (e) => {
    setSelectedOption(e.target.value);
    sortingOnChange(e.target.value);
  };

  return (
    <Grid container justifyContent={ isMobile ? 'center' : "space-between" } >
      {/* <Grid
        item
        container
        textAlign="center"
        className="divider-text-margin-sm-md"
        display={{ lg: "none", md: "none", sm: "block", xs: "block" }}
      >
        {totalCnt > 0 && (
          <span className="divider-text">
            We found {totalCnt} plots for this promotor
          </span>
        )}
      </Grid> */}
      <Grid item>
        <CSS.PromoterGridTitles isMobile={isMobile} fromDivider={true}>
          available projects ({totalCnt})
        </CSS.PromoterGridTitles>
      </Grid>
      <Grid
        item
        className="divider"
        display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
      />
      {/* <Grid
        item
        className="divider-text-margin"
        display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
      >
        {totalCnt > 0 && (
          <span className="divider-text">
            We found {totalCnt} plots for this promotor
          </span>
        )}
      </Grid>
      <Grid
        item
        className="divider"
        display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
      /> */}
      <Grid item>
        <CSS.PromoterSelectTitles fromDivider={true}>
          select city
        </CSS.PromoterSelectTitles>
      </Grid>
      <Grid item>
        <FormControl>
          <Select
            value={selectedOption}
            inputProps={{ "aria-label": "Without label" }}
            style={isMobile ? {
              borderRadius: "100px",
              border: "1px solid #C1A788",
              fontSize: "10px",
              textTransform: "uppercase",
              fontWeight: 'bold',
              marginTop: '10px'
            } : {
              borderRadius: "100px",
              border: "1px solid #C1A788",
              fontSize: "14px",
              textTransform: "uppercase",
              fontWeight: 'bold'
            }}
            onChange={(e) => {
              changeSorting(e);
            }}
          >
            {cityData.map((option, index) => (
              <MenuItem value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PromoterPDPDivider;
