import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

//Other lib imports
import axios from "axios";

//Custom imports
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import * as CSS from "./style";

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

import "../Overlays/Captcha/Captcha.css";

import { baseUrl } from "../../util/config";

const PromoterRegistration = () => {
  const navigate = useNavigate();

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [registrationData, setRegistrationData] = useState({
    promotor_name: "",
    email: "",
    name: "",
    contact: "",
    website: "",
    address: "",
    comment: "",
    captcha: "",
  });
  const [errors, setErrors] = useState({});

  const registrationInputChange = (e) => {
    const modifiedKey = e.target.name;
    const modifiedValue = e.target.value;
    const updatedData = { ...registrationData };
    updatedData[modifiedKey] = modifiedValue;
    setRegistrationData(updatedData);
    validate({ [modifiedKey]: modifiedValue });
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "This field is required.";
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    if ("contact" in fieldValues) {
      temp.contact = fieldValues.contact ? "" : "This field is required.";
      if (fieldValues.contact)
        temp.contact =
          fieldValues.contact.length === 10 ? "" : "Mobile no is not valid.";
    }

    setErrors({
      ...temp,
    });
  };

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const onSubmitBtnClk = () => {
    if (
      !registrationData.promotor_name ||
      !registrationData.name ||
      !registrationData.email ||
      !registrationData.contact ||
      !registrationData.address ||
      (errors["email"] && errors["email"] !== "") ||
      (errors["contact"] && errors["contact"] !== "")
    ) {
      if (errors["email"] && errors["email"] !== "") {
        openSnackBar("Enter Valid Email Id", "error");
        return;
      }
      if (errors["contact"] && errors["contact"] !== "") {
        openSnackBar("Enter valid contact mobile no", "error");
        return;
      }
      openSnackBar("Enter all inputs", "error");
      return;
    }
    if (validateCaptcha(registrationData.captcha) == true) {
      doSubmit(registrationData);
    } else {
      openSnackBar("Invalid Captcha", "error");
    }
  };

  const doSubmit = (data) => {
    // setIsLoading(true);
    const payload = { ...data };
    axios
      .post(
        `${baseUrl}/plot_api/realestate_v1/promotor/add`,
        payload
      )
      .then(
        (res) => {
          console.log("res.data.data", res);
          if (res.data && res.data.status) {
            openSnackBar(
              "Your Registration is done successfully. Our Onboarding team will contact you for confirmation and Promoter Account Approval",
              "success"
            );
            setRegistrationData({
              ...registrationData,
              promotor_name: "",
              email: "",
              name: "",
              contact: "",
              website: "",
              address: "",
              comment: "",
              captcha: "",
            });
            loadCaptchaEnginge(6);
          } else {
            openSnackBar("User already exist", "error");
          }
        },
        (err) => {
          // setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  return (
    <CSS.ParentDiv>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">
                  Promoter Registration
                </span>
              </div>
              <div className="login-form-div">
                <TextField
                  name={"promotor_name"}
                  label="Promoter Name"
                  required
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  onChange={registrationInputChange}
                  value={registrationData.promotor_name}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"email"}
                  label="Company E-Mail"
                  required
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  onChange={registrationInputChange}
                  value={registrationData.email}
                  {...(errors["email"] && {
                    error: true,
                    helperText: errors["email"],
                  })}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"name"}
                  onChange={registrationInputChange}
                  value={registrationData.name}
                  required
                  label="Proprietor / Contact Person Name"
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"contact"}
                  onChange={registrationInputChange}
                  value={registrationData.contact}
                  required
                  label="Contact Mobile No"
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                  {...(errors["contact"] && {
                    error: true,
                    helperText: errors["contact"],
                  })}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"website"}
                  onChange={registrationInputChange}
                  value={registrationData.website}
                  label="Website (Optional)"
                  variant="outlined"
                  className="login-inputWidth"
                  size="small"
                />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"address"}
                  onChange={registrationInputChange}
                  value={registrationData.address}
                  required
                  label="Company HQ Address"
                  multiline
                  rows={4}
                  fullWidth={true}
                  className="login-inputWidth"
                />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"comment"}
                  onChange={registrationInputChange}
                  value={registrationData.comment}
                  label="Comment if any (max. 300 characters) (Optional)"
                  multiline
                  rows={4}
                  fullWidth={true}
                  className="login-inputWidth"
                  inputProps={{ maxLength: 300 }}
                />
              </div>
              <div className="login-form-div" style={{ textAlign: "center" }}>
                <LoadCanvasTemplate />
              </div>
              <div className="login-form-div">
                <TextField
                  name={"captcha"}
                  onChange={registrationInputChange}
                  value={registrationData.captcha}
                  label="Enter Captcha"
                  className="login-inputWidth"
                  variant="outlined"
                  size="small"
                />
              </div>

              <div className="login-form-div">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onSubmitBtnClk}
                  className="login-inputWidth login-button"
                  endIcon={<EastIcon />}
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </CSS.ParentDiv>
  );
};

export default PromoterRegistration;
