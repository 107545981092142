//Material UI imports
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
//Custom imports
import * as CSS from "../Style.js";
import GoogleLogin from "../../Login/GoogleLogin";

const GoogleLoginPopup = (props) => {
  const { openFlag, setCloseFlag } = props;

  return (
    <CSS.CustomizedDialog
      open={openFlag}
      onClose={() => setCloseFlag(false)}
      maxWidth={"lg"}
    >
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="row"
        margin="2em 0 2em 0;"
      >
        <CSS.CloseBtn>
          <IconButton
            aria-label="closePopup"
            onClick={() => setCloseFlag(false)}
          >
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn>
        <GoogleLogin closeDialog={() => setCloseFlag(false)} />
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default GoogleLoginPopup;
