import { useState, useEffect } from "react";
//Material UI imports
import Grid from "@mui/material/Grid";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NorthIcon from "@mui/icons-material/North";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import NorthWestIcon from "@mui/icons-material/NorthWest";
import SouthIcon from "@mui/icons-material/South";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
//Custom imports
import "./PlotDetailsPopup.css";
import * as CSS from "../Style.js";
import axios from "axios";
import GoogleLoginPopup from "../GoogleLoginPopup/GoogleLoginPopup";
import { priceInWords } from "../../../util/config";

import { baseUrl } from "../../../util/config";

const PlotDetailsPopup = (props) => {
  const {
    popupOpen,
    toolTipContent,
    setOpen,
    openSnackBar,
    showPrice = true,
    isEmiAvailable = false,
  } = props;

  const [fav, setFav] = useState(toolTipContent && toolTipContent.is_fav);
  const [openGoogleLoginPopup, setOpenGoogleLoginPopup] = useState(false);

  const token = localStorage.getItem("user") || null;

  useEffect(() => {
    if (toolTipContent) {
      setFav(toolTipContent && toolTipContent.is_fav);
    }
  }, [toolTipContent]);

  const onCloseBtnClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(false);
    return false;
  };

  const updateFav = (id, project_id) => {
    if (token) {
      const userDetails = JSON.parse(token);
      setFav((fav) => {
        let isFav = fav;
        if (isFav === 0) {
          isFav = 1;
        } else {
          isFav = 0;
        }

        const payload = {
          project: project_id,
          source_id: id,
          is_fav: isFav,
          user: userDetails && userDetails.googleId ? userDetails.googleId : "",
          type: 2,
        };
        axios
          .post(`${baseUrl}/plot_api/realestate/api/fav`, payload)
          .then(
            (res) => {
              if (res.data && res.data.status) {
                // handleChatClose();
                openSnackBar("Success", "success");
              }
            },
            (err) => {
              openSnackBar("Failed to submit", "success");
              console.log("err", err);
            }
          );
        return isFav;
      });
    } else {
      //setIsFavError(true);
    }
  };

  const getSoldStatus = (type) => {
    switch (type) {
      case 1:
        return "Sold";
      case 2:
        return "Booked";
      case 0:
        return "Available";
      default:
        return "";
    }
  };

  const getDirection = (type) => {
    switch (type) {
      case 1:
        return "East";
      case 2:
        return "West";
      case 3:
        return "North";
      case 4:
        return "South";
      case 5:
        return "North East";
      case 6:
        return "North West";
      case 7:
        return "South East";
      case 8:
        return "South West";
      default:
        return "";
    }
  };

  const getDirectionIcon = (type) => {
    switch (type) {
      case 1:
        return <EastIcon fontSize="30" />;
      case 2:
        return <WestIcon fontSize="30" />;
      case 3:
        return <NorthIcon fontSize="30" />;
      case 4:
        return <SouthIcon fontSize="30" />;
      case 5:
        return <NorthEastIcon fontSize="30" />;
      case 6:
        return <NorthWestIcon fontSize="30" />;
      case 7:
        return <SouthEastIcon fontSize="30" />;
      case 8:
        return <SouthWestIcon fontSize="30" />;
      default:
        return "";
    }
  };

  const getRupeesFormat = (amountValue = "") => {
    return (amountValue || "").toLocaleString("en-IN");
  };

  return (
    <>
      <CSS.CustomizedDialog
        open={popupOpen}
        onClose={onCloseBtnClick}
        maxWidth={"lg"}
      >
        <GoogleLoginPopup
          openFlag={openGoogleLoginPopup}
          setCloseFlag={setOpenGoogleLoginPopup}
        />
        <Grid
          container
          direction="column"
          gap="1.2em"
          justifyContent="center"
          alignItems="center"
          className="pdpPopup"
        >
          <div className="pdp-map-popup-oa-close-contr">
            <IconButton aria-label="closePopup" onClick={onCloseBtnClick}>
              <HighlightOffIcon />
            </IconButton>
          </div>
          <Grid
            item
            display="flex"
            direction="row"
            gap="1em"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <div className="pdp-map-popup-card-cntr">
              <span className="pdp-map-popup-card-heading">Plot No.</span>
              <span className="pdp-map-popup-card-main-heading">
                {(toolTipContent && toolTipContent.name) || ""}
              </span>
              <div className="pdp-map-popup-chip-container-green">
                <span className="pdp-map-popup-chip-contents">
                  {getSoldStatus(toolTipContent && toolTipContent.plot_status)}
                </span>
              </div>
            </div>
            <div className="pdp-map-popup-card-cntr">
              <span className="pdp-map-popup-card-heading">DIRECTION</span>
              <span className="pdp-map-popup-card-main-heading">
                {(toolTipContent &&
                  getDirectionIcon(parseInt(toolTipContent.direction))) ||
                  ""}
              </span>
              <div className="pdp-map-popup-chip-container-orange">
                <span className="pdp-map-popup-chip-contents">
                  {(toolTipContent &&
                    getDirection(parseInt(toolTipContent.direction))) ||
                    ""}
                </span>
              </div>
            </div>
          </Grid>
          <Grid
            item
            display="flex"
            direction="row"
            gap="1em"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <div className="pdp-map-popup-bigs-cntr">
              <span className="pdp-map-popup-heading">PLOT AREA</span>
              <span className="pdp-map-popup-big-vals">
                {(toolTipContent && toolTipContent.area) || ""}
              </span>
              <span className="pdp-map-popup-big-vals-unit">SQ.FT</span>
              <div className="pdp-map-popup-chip-container-orange">
                <span className="pdp-map-popup-chip-contents">
                  {toolTipContent && toolTipContent.is_corner === "yes"
                    ? "CORNER PLOT"
                    : "NOT A CORNER PLOT"}
                </span>
              </div>
              {!showPrice ? (
                <div className="pdp-map-popup-chip-container-green">
                  <span className="pdp-map-popup-chip-contents">
                    {isEmiAvailable ? "EMI AVAILABLE" : "EMI NOT AVAILABLE"}
                  </span>
                </div>
              ) : null}
            </div>
            <div className="pdp-map-popup-bigs-cntr">
              {showPrice ? (
                <>
                  <span className="pdp-map-popup-heading">COST</span>
                  <span className="pdp-map-popup-big-vals">
                    ₹
                    {getRupeesFormat(
                      toolTipContent && Math.round(toolTipContent.per_s_feet)
                    ) || 0}
                  </span>
                  <span className="pdp-map-popup-big-vals-unit">PER SQ.FT</span>
                  <div className="pdp-map-popup-chip-container-green">
                    <span className="pdp-map-popup-chip-contents">
                      {isEmiAvailable ? "EMI AVAILABLE" : "EMI NOT AVAILABLE"}
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          </Grid>
          {showPrice ? (
            <Grid
              item
              display="flex"
              direction="row"
              gap="1em"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <div
                className="pdp-map-popup-bigs-cntr"
                style={{ width: "100%" }}
              >
                <span className="pdp-map-popup-heading">TOTAL COST</span>
                <span className="pdp-map-popup-big-vals">
                  ₹
                  {getRupeesFormat(
                    toolTipContent && Math.round(toolTipContent.cost)
                  ) || ""}
                </span>
                {/* <span
                  className="pdp-map-popup-big-vals-unit"
                  style={{ alignSelf: "center" }}
                >
                  {priceInWords(
                    toolTipContent && Math.round(toolTipContent.cost)
                  ).toUpperCase() || ""}
                </span> */}
              </div>
            </Grid>
          ) : null}
          {toolTipContent && parseInt(toolTipContent.no_of_sides) > 0 && (
            <>
              <Grid item className="pdp-map-popup-heading">
                {toolTipContent && parseInt(toolTipContent.no_of_sides)} SIDES
              </Grid>
              <Grid
                item
                display="flex"
                direction="row"
                gap="1em"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <div>
                  <span className="pdp-map-popup-sub-heading">SIDE A</span>
                  <br />
                  <span className="pdp-map-popup-contents">
                    {(toolTipContent && toolTipContent.side1) || ""}
                  </span>
                </div>
                <div>
                  <span className="pdp-map-popup-sub-heading">SIDE B</span>
                  <br />
                  <span className="pdp-map-popup-contents">
                    {" "}
                    {(toolTipContent && toolTipContent.side2) || ""}
                  </span>
                </div>
                <div>
                  <span className="pdp-map-popup-sub-heading">SIDE C</span>
                  <br />
                  <span className="pdp-map-popup-contents">
                    {(toolTipContent && toolTipContent.side3) || ""}
                  </span>
                </div>
                <div>
                  <span className="pdp-map-popup-sub-heading">SIDE D</span>
                  <br />
                  <span className="pdp-map-popup-contents">
                    {(toolTipContent && toolTipContent.side4) || ""}
                  </span>
                </div>

                {toolTipContent && toolTipContent.no_of_sides > 4 && (
                  <div>
                    <span className="pdp-map-popup-sub-heading">SIDE E</span>
                    <br />
                    <span className="pdp-map-popup-contents">
                      {(toolTipContent && toolTipContent.side5) || ""}
                    </span>
                  </div>
                )}

                {toolTipContent && toolTipContent.no_of_sides > 5 && (
                  <div>
                    <span className="pdp-map-popup-sub-heading">SIDE F</span>
                    <br />
                    <span className="pdp-map-popup-contents">
                      {(toolTipContent && toolTipContent.side6) || ""}
                    </span>
                  </div>
                )}

                {toolTipContent && toolTipContent.no_of_sides > 6 && (
                  <div>
                    <span className="pdp-map-popup-sub-heading">SIDE G</span>
                    <br />
                    <span className="pdp-map-popup-contents">
                      {(toolTipContent && toolTipContent.side7) || ""}
                    </span>
                  </div>
                )}

                {toolTipContent && toolTipContent.no_of_sides > 7 && (
                  <div>
                    <span className="pdp-map-popup-sub-heading">SIDE H</span>
                    <br />
                    <span className="pdp-map-popup-contents">
                      {(toolTipContent && toolTipContent.side8) || ""}
                    </span>
                  </div>
                )}

                {toolTipContent && toolTipContent.no_of_sides > 8 && (
                  <div>
                    <span className="pdp-map-popup-sub-heading">SIDE I</span>
                    <br />
                    <span className="pdp-map-popup-contents">
                      {(toolTipContent && toolTipContent.side9) || ""}
                    </span>
                  </div>
                )}

                {toolTipContent && toolTipContent.no_of_sides > 9 && (
                  <div>
                    <span className="pdp-map-popup-sub-heading">SIDE J</span>
                    <br />
                    <span className="pdp-map-popup-contents">
                      {(toolTipContent && toolTipContent.side10) || ""}
                    </span>
                  </div>
                )}
              </Grid>
            </>
          )}

          {toolTipContent &&
            (toolTipContent.front_road === "yes" ||
              toolTipContent.back_road === "yes" ||
              toolTipContent.left_road === "yes" ||
              toolTipContent.right_road === "yes") && (
              <>
                <Grid item className="pdp-map-popup-heading">
                  ROADS
                </Grid>
                <Grid
                  item
                  display="flex"
                  direction="row"
                  gap="1em"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                >
                  <div>
                    <span className="pdp-map-popup-sub-heading">FRONT</span>
                    <br />
                    {toolTipContent && toolTipContent.front_road === "yes" ? (
                      <span className="pdp-map-popup-contents">
                        {(toolTipContent && toolTipContent.front_road_feet) +
                          "''" || "No"}
                      </span>
                    ) : (
                      <span className="pdp-map-popup-contents">No</span>
                    )}
                  </div>
                  <div>
                    <span className="pdp-map-popup-sub-heading">BACK</span>
                    <br />
                    {toolTipContent && toolTipContent.back_road === "yes" ? (
                      <span className="pdp-map-popup-contents">
                        {(toolTipContent && toolTipContent.back_road_feet) +
                          "''" || "No"}
                      </span>
                    ) : (
                      <span className="pdp-map-popup-contents">No</span>
                    )}
                  </div>
                  <div>
                    <span className="pdp-map-popup-sub-heading">LEFT</span>
                    <br />
                    {toolTipContent && toolTipContent.left_road === "yes" ? (
                      <span className="pdp-map-popup-contents">
                        {(toolTipContent && toolTipContent.left_road_feet) +
                          "''" || "No"}
                      </span>
                    ) : (
                      <span className="pdp-map-popup-contents">No</span>
                    )}
                  </div>
                  <div>
                    <span className="pdp-map-popup-sub-heading">RIGHT</span>
                    <br />
                    {toolTipContent && toolTipContent.right_road === "yes" ? (
                      <span className="pdp-map-popup-contents">
                        {(toolTipContent && toolTipContent.right_road_feet) +
                          "''" || "No"}
                      </span>
                    ) : (
                      <span className="pdp-map-popup-contents">No</span>
                    )}
                  </div>
                </Grid>
              </>
            )}
          {token && (
            <Grid item>
              <Button
                variant="outlined"
                className={
                  parseInt(fav) === 0
                    ? "pdp-lo-button-grp"
                    : "pdp-lo-button-grp-fav"
                }
                startIcon={<FavoriteBorderIcon />}
                onClick={() => {
                  updateFav(
                    toolTipContent.uploaded_id && toolTipContent.uploaded_id > 0
                      ? toolTipContent.uploaded_id
                      : toolTipContent.id,
                    toolTipContent.project_id
                  );
                }}
              >
                {parseInt(fav) === 0 ? "Make Favorite" : "Added to Favourite"}
              </Button>
            </Grid>
          )}
          {!token && (
            <Grid item>
              <Button
                variant="outlined"
                className={"pdp-lo-button-grp-disable"}
                onClick={() => {
                  // onCloseBtnClick();
                  setOpenGoogleLoginPopup(true);
                }}
              >
                Login to make favorite
              </Button>
            </Grid>
          )}
        </Grid>
      </CSS.CustomizedDialog>
    </>
  );
};

export default PlotDetailsPopup;
