import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
//Material UI imports
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PoolIcon from "@mui/icons-material/Pool";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ParkIcon from "@mui/icons-material/Park";
import FoundationIcon from "@mui/icons-material/Foundation";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import InputIcon from "@mui/icons-material/Input";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
//Custom imports
import { PlotCardSkeleton, PlotCardImg } from "./PlotCardSkeleton";
import "./PlotCard.css";
import plotImg from "../../../Assets/plot_sample.jpg";
import partialImg from "../../../Assets/partial_onboarding.png";

import { map } from "lodash";

const PlotCard = (props) => {
  const navigate = useNavigate();

  const {
    list,
    openPDP,
    setOpenGoogleLoginPopup,
    fav,
    updateFav,
    isPromotor = false,
    isLoading = false,
  } = props;

  const [userDetails, setUserDetails] = React.useState(null);

  React.useEffect(() => {
    const user = localStorage.getItem("user") || null;
    if (user) {
      const userData = JSON.parse(user);
      setUserDetails(userData);
    }
  }, []);

  const clickFavorite = (e, data) => {
    console.log("id", data, userDetails);
    e.stopPropagation();
    if (userDetails && userDetails.googleId) {
      updateFav(data.id, data);
    } else {
      setOpenGoogleLoginPopup(true);
    }
  };

  const openPromotors = (
    e,
    district,
    state,
    promotors,
    company_hash,
    company_userid
  ) => {
    e.stopPropagation();
    let path = `/promoter-pdp/residential_plots_${
      state != "" ? state.replace(/\s+/g, "").toLowerCase() : "state"
    }/residential_plots_${
      district != "" ? district.replace(/\s+/g, "").toLowerCase() : "district"
    }/${promotors.replace(/\s+/g, "").toLowerCase()}/${company_hash}`;
    if (company_hash != "") {
      navigate(path, {
        state: { id: company_userid },
      });
    }
  };

  const getPropTypeText = (propTypeArr) => {
    if (propTypeArr && propTypeArr.length) {
      let propTypeText = "";
      for (let pLoop in propTypeArr) {
        if (propTypeArr[pLoop] === "1") {
          propTypeText += " | Residential plots";
        }
        if (propTypeArr[pLoop] === "2") {
          propTypeText += " | Villas";
        }
        if (propTypeArr[pLoop] === "3") {
          propTypeText += " | Farm Lands";
        }
        if (propTypeArr[pLoop] === "4") {
          propTypeText += " | Farm House";
        }
        if (propTypeArr[pLoop] === "5") {
          propTypeText += " | Gated Community";
        }
        if (propTypeArr[pLoop] === "6") {
          propTypeText += " | Commercial Land";
        }
      }
      if (propTypeText) {
        propTypeText = propTypeText.slice(3);
      }
      return propTypeText;
    } else {
      return "";
    }
  };

  return (
    <>
      <Grid
        container
        // spacing={3}
        padding={{ lg: "1%", md: "1%", sm: "1%", xs: "2%" }}
      >
        {isLoading && <PlotCardSkeleton />}
        {list.length > 0 &&
          map(list, function (data, index) {
            let promoters_details = data.promoters_details
              ? data.promoters_details
              : {};
            let emi_details = data.emi ? JSON.parse(data.emi) : {};
            let seo_details = data.seo ? data.seo : {};
            return (
              <Grid
                lg={3}
                md={6}
                sm={12}
                xs={12}
                key={`item-${index}`}
                className="plot-grid"
              >
                <Card
                  className="plot-card"
                  style={
                    isMobile ? { padding: "0px" } : { padding: "24px 16px" }
                  }
                  onClick={(e) =>
                    openPDP(
                      e,
                      (seo_details && seo_details.state) || "",
                      (seo_details && seo_details.district) || "",
                      (seo_details && seo_details.taluk) || "",
                      (seo_details && seo_details.area1) || "",
                      (seo_details && seo_details.area2) || "",
                      data.company_promotors,
                      data.hash_key,
                      data.id,
                      data.name,
                      data
                    )
                  }
                >
                  <CardActionArea className="plot-action">
                    <CardContent className="card-cnt">
                      <div className="card-heading-contr">
                        <div className="card-heading">
                          <p style={{ margin: "0px", minHeight: "38px" }}>
                            {data && data.name}
                          </p>
                          <span className={"card-heading-type"}>
                            {data && getPropTypeText(data.property_type)}
                          </span>
                          <p className={"card-heading-address"}>
                            {data.plp_address && data.plp_address}
                          </p>
                        </div>
                        {data.status === 2 && (
                          <div>
                            <Tooltip title="Fully Onboarded">
                              <CheckCircleIcon
                                className="card-tick"
                                title="Checked"
                              />
                            </Tooltip>
                          </div>
                        )}

                        {data.status === 3 && (
                          <div>
                            <Tooltip title="Full Onboarding In Progress">
                              <img
                                className="card-tick"
                                src={partialImg}
                                width={"20px"}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <PlotCardImg data={data} plotImg={plotImg} />
                      <div
                        className={`card-promoters ${
                          isPromotor && "card-promoters-no-hover"
                        }`}
                        onClick={(e) =>
                          promoters_details.enable_promotor_page === 1 &&
                          !isPromotor
                            ? openPromotors(
                                e,
                                data.company_district,
                                data.company_state,
                                data.company_promotors,
                                data.company_hash,
                                data.company_userid
                              )
                            : () => false
                        }
                      >
                        <span>
                          {(data && data.company_promotors).toLowerCase()}
                        </span>
                      </div>
                      <div
                        className="card-info-contr"
                        style={{ textAlign: "center" }}
                      >
                        <div
                          className="card-info-left-cont"
                          style={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            width: "100%",
                          }}
                        >
                          <span className="card-info-text" style={{fontSize: 15, fontWeight: 500}}>{`${data && data.total_promoter_cnt} ${
                            data && data.total_promoter_cnt > 1
                              ? "Layouts"
                              : "Layout"
                          }`}</span>
                        </div>
                      </div>
                      <div className="card-info-contr">
                        <div
                          className="card-info-left-cont"
                          style={
                            isMobile
                              ? { paddingTop: "5px" }
                              : { paddingTop: "10px" }
                          }
                        >
                          <span>Availability</span>
                          <div>
                            <span className="card-info-text" style={{fontSize: 16}}>
                              {data && data.unsold_cnt} plots
                            </span>
                          </div>
                        </div>
                        <div
                          className="card-info-right-cont"
                          style={
                            isMobile
                              ? { paddingTop: "5px", minHeight: "40px" }
                              : { paddingTop: "10px" }
                          }
                        >
                          {data && data.amenities.length > 0 && (
                            <>
                              <span>Amenities</span>
                              <div>
                                <span className="card-info-amn-icons-cntr">
                                  <PoolIcon className="card-info-amn-icon" />
                                  <FitnessCenterIcon className="card-info-amn-icon" />
                                  <ParkIcon className="card-info-amn-icon" />
                                  <FoundationIcon className="card-info-amn-icon" />
                                  <Badge
                                    badgeContent={data && data.amenities.length}
                                    sx={{
                                      "& .MuiBadge-badge": {
                                        color: "white",
                                        backgroundColor: "#645d57",
                                        fontSize: "15px",
                                      },
                                    }}
                                    className="badge-icon"
                                  />
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          className="card-info-left-cont"
                          style={
                            isMobile
                              ? { paddingTop: "5px" }
                              : { paddingTop: "10px" }
                          }
                        >
                          <span>EMI</span>
                          <div>
                            <span className="card-info-text">
                              {emi_details && emi_details.available == "yes"
                                ? "Yes"
                                : "No"}
                            </span>
                          </div>
                        </div>
                        <div
                          className="card-info-right-cont"
                          style={
                            isMobile
                              ? { paddingTop: "5px", minHeight: "40px" }
                              : { paddingTop: "10px" }
                          }
                        >
                          <span>Price starts from</span>
                          <div>
                            <span className="card-info-text">
                              {data.plp_price
                                ? `₹${data.plp_price} ${
                                    data.plp_price_unit &&
                                    `/${data.plp_price_unit}`
                                  } `
                                : `Not Available`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                    <CardActions disableSpacing className="card-actns">
                      <IconButton aria-label="add to favorites">
                        {(data && fav.length === 0 && data.is_fav === 1) ||
                        fav.includes(data.id) ? (
                          <>
                            <FavoriteIcon
                              style={{ color: "#cc0000" }}
                              onClick={(e) => {
                                clickFavorite(e, data);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <FavoriteBorderIcon
                              onClick={(e) => {
                                clickFavorite(e, data);
                              }}
                            />
                          </>
                        )}
                      </IconButton>
                      <IconButton aria-label="Open plot">
                        <InputIcon
                          onClick={(e) =>
                            openPDP(
                              e,
                              (seo_details && seo_details.state) || "",
                              (seo_details && seo_details.district) || "",
                              (seo_details && seo_details.taluk) || "",
                              (seo_details && seo_details.area1) || "",
                              (seo_details && seo_details.area2) || "",
                              data.company_promotors,
                              data.hash_key,
                              data.id,
                              data.name,
                              data
                            )
                          }
                        />
                      </IconButton>
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default PlotCard;
