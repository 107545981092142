//Material UI imports
import Grid from "@mui/material/Grid";
//Custom imports
import * as CSS from "../Style.js";

const PLPPlotDetailsToolTip = (props) => {
  const { layoutName, promoterName, noPlots, projectClick, centPr, sqftPr } = props;

  return (
    <Grid
      container
      direction="column"
      gap="1em"
      justifyContent="center"
      alignItems="center"
      style={CSS.GlobalGrid}
    >
      <CSS.Sqft>{layoutName}</CSS.Sqft>
      <CSS.CardMessage promoterName={true}>{promoterName}</CSS.CardMessage>
      <CSS.CardContainer>
        <CSS.ChipContainer plotStatus={"startsFrom"}>
          <CSS.ChipContent plotStatus={"startsFrom"}>price starts from</CSS.ChipContent>
        </CSS.ChipContainer>
        <CSS.CardPriceContent>₹{Number(sqftPr).toFixed(0)}/sq.ft</CSS.CardPriceContent>
        <CSS.CardPriceContent>₹{Number(centPr).toFixed(0)}/cent</CSS.CardPriceContent>
        <CSS.ChipContainer plotStatus={"unsold"}>
          <CSS.ChipContent plotStatus={"unsold"}>available</CSS.ChipContent>
        </CSS.ChipContainer>
        <CSS.CardContainerContent>{noPlots} Plots</CSS.CardContainerContent>
      </CSS.CardContainer>
      {/* <CSS.Sqft>{layoutName}</CSS.Sqft>
      <CSS.CardMessage>by</CSS.CardMessage>
      <CSS.CardMessage promoterName={true}>{promoterName}</CSS.CardMessage> */}
      <CSS.CardLink onClick={projectClick}>
        Click on the location icon for details
      </CSS.CardLink>
    </Grid>
  );
};

export default PLPPlotDetailsToolTip; 
