/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DrawMaps from "./DrawMaps";
import axios from "axios";

import { baseUrl } from "../../util/config";

import "./Map.css";

const IMP_ICON =
  "https://storage.googleapis.com/am-songs/songs/20220620031236_important.png?GoogleAccessId=ads-395%40am-ads.iam.gserviceaccount.com&Expires=1686841956&Signature=Ws1uiWPxnfxmWEZ1u0wWPfh%2FZ0%2BwIz%2FDoWZC9EkHU1AQ5qzRI3GTJTji977KidjrQZNiSZP2aAKKiwV58I0SjhqMyXUHKPYxS%2BJ5jztEu%2BiySy7UTkurhdCr4UmL7U6gjaAUgAIaCZb3LIE9HVYCh77D%2BxaiiG7d4fLvWRuWJY%2BZl31uEupmqvdDDQwv17SEYOj4blscRCrhz%2BBVRCOqzIwzGu%2BNx26Gd9tkeEJDnclsdewVMMYMaAyHE8QaULdToLnZxaDDZr%2FDYp%2BrLu1CDX9FrwZwxjcEA12FGy%2FoOy0dssmiclvQhenGYnZ%2FZ9qGaglwOkHBxt82C5aLrBASyw%3D%3D";

const API_KEY = "AIzaSyBtRJ59UWCyTVENScWckaaqJas16Ymuuic";

const NearBy = ({
  lat = "13.086289405322379",
  lng = "80.29239116173903",
  polyline = [],
  show_boundary = 0,
  boundary_color = "",
  id,
  layout_image,
  isFav,
  plotStatus = -1,
  setPlotStatus,
  product,
  zoom = 16,
}) => {
  const [nearByLocations, setNearByLocations] = useState([]);
  const [showType, setShowType] = useState(null);

  const [allNearby, setAllNearby] = useState([]);
  const [nearbyShow, setNearbyShow] = useState(false);

  const [directionDetails, setDirectionDetails] = useState(null);
  const [allNearbyTypes, setAllNearbyTypes] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (id) {
      getAllNearbyType(id);
      getAllNearby();
    }
  }, [id]);

  React.useEffect(() => {
    if (allNearbyTypes.length > 0) {
      setShowType({
        ...showType,
        radius: 2000,
        name: "shops",
        image:
          "https://storage.googleapis.com/am-songs/songs/20220620031407_shop.png",
      });
    }
  }, [allNearbyTypes]);

  const getAllNearbyType = (id) => {
    const payload = { project_id: id, only_active: true };
    axios
      .post(
        `${baseUrl}/plot_api/realestate/nearbytypes/get`,
        payload
      )
      .then(
        (res) => {
          setAllNearbyTypes((res.data && res.data.list) || []);
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setDirectionDetails(null);
    if (showType && showType.name !== "local" && allNearbyTypes.length > 0) {
      setNearbyShow(false);
      const payload = {
        url: `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${lng}&radius=${showType.radius}&type=${showType.name}&name=${showType.name}&key=${API_KEY}`,
      };
      axios
        .post(
          `${baseUrl}/plot_api/realestate/nearby/getnearby`,
          payload
        )
        .then(
          (response) => {
            if (response.data && response.data.results) {
              const results = response.data.results;
              const locations = [];
              for (let i = 0; i < results.length; i++) {
                locations.push({
                  gps: results[i]["geometry"]["location"],
                  icon: showType.image || results[i]["icon"],
                  placeName: results[i]["name"] || 'unknown',
                });
              }
              setNearByLocations(locations);
            } else {
              setNearByLocations([]);
            }
          },
          (err) => {
            console.log("err", err);
          }
        );
    } else {
      if (showType && showType.name === "local") {
        setNearbyShow(true);
      }
      setNearByLocations([]);
    }
  }, [showType]);

  const getAllNearby = () => {
    const payload = { project_id: id };
    axios
      .post(`${baseUrl}/plot_api/realestate/nearby/get`, payload)
      .then(
        (res) => {
          const allRes = (res.data && res.data.list) || [];
          setAllNearby(allRes);
          const locations = [];
          for (let i = 0; i < allRes.length; i++) {
            locations.push({
              gps: allRes[i]["gps_location"],
              icon: IMP_ICON,
            });
          }
          setNearByLocations(locations);
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const nearByHelperText = () => {
    if (showType) {
      if (showType.name === "local") {
        return "";
      } else {
        try {
          const selectedName = document.querySelector(
            ".pdp-lo-button-grp-clckd .MuiChip-label"
          ).innerHTML;
          const selectedRadius = parseInt(showType.radius) / 1000;
          return `${nearByLocations.length} ${selectedName} within ${selectedRadius} Kms`;
        } catch (e) {
          console.log(e);
        }
      }
    }
    return;
  };

  const handleLocationHighlightClick = (el) => {
    const locations = [];
    locations.push({
      gps: el.gps_location,
      icon: IMP_ICON,
    });
    setNearByLocations(locations);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid
            container
            gap="1em"
            justifyContent="center"
            alignItems="center"
            margin="0 0 2em 0"
          >
            {allNearby && allNearby.length > 0 && (
              <Chip
                label="Location highlights"
                size="large"
                className="pdp-lo-button-grp"
                onClick={() => setShowType({ name: "local" })}
              />
            )}

            {(allNearbyTypes || []).map((el, i) => (
              <Chip
                label={capitalizeFirstLetter(el.label || el.name)}
                size="large"
                className={
                  showType === el || (showType && showType.name === el.name)
                    ? "pdp-lo-button-grp-clckd"
                    : "pdp-lo-button-grp"
                }
                onClick={() => {
                  setShowType(el);
                }}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container justifyContent="center" alignItems="center">
            <div className="sb-filter-widget">
              <div
                className={`sb-filter__layout-details sb-filter__layout-details--show ${
                  nearbyShow ? "show" : "hide"
                }`}
              >
                <div className="layout-details__body">
                  <div>
                    <div className="ltn__utilize-menu-inner ltn__scrollbar">
                      <div className="ltn__utilize-menu-head nearbyhead">
                        <span className="ltn__utilize-menu-title">
                          Local Highlights
                        </span>
                      </div>
                      <div className="mini-cart-product-area ltn__scrollbar">
                        <div className="layout-details__description">
                          {showType &&
                            showType.name === "local" &&
                            (allNearby || []).map((el, i) => (
                              <div
                                key={i}
                                onClick={() => handleLocationHighlightClick(el)}
                                style={{ cursor: "pointer" }}
                                className="sb-nearby-result-item"
                              >
                                <div className="sb-nearby__main-info">
                                  <div className="sb-nearby__result-title">
                                    {el.name}
                                  </div>
                                  <div className="sb-nearby__result-type">
                                    {el.type}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {allNearbyTypes.length > 0 && (
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: 12,
                    paddingBottom: "28px",
                    color: "#835400",
                    fontWeight: "bolder",
                  }}
                >
                  <span
                    style={{
                      padding: 10,
                      border: "1px solid #835400",
                      borderRadius: 6,
                      color: "white",
                      backgroundColor: "#835400",
                      fontSize: 20,
                    }}
                  >
                    {nearByHelperText()}
                  </span>
                </div>
              )}
              <div
                className="sb-filter__map"
                style={{ position: "relative", overflow: "hidden" }}
              >
                <DrawMaps
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  gps_location={{
                    lat,
                    lng,
                  }}
                  zoom_level={16}
                  type="roadmap"
                  onPolygonClick={(plot) => {}}
                  onMouseOver={(val) => {}}
                  nearByLocations={
                    showType
                      ? nearByLocations.length > 0
                        ? nearByLocations
                        : []
                      : []
                  }
                  setDirectionDetails={setDirectionDetails}
                  directionDetails={directionDetails}
                />
              </div>
              {
                directionDetails && (
                  <div className='nb-popup-cntr'>
                    <div className='nb-popup-close-btn'>
                        <IconButton
                          aria-label="closePopup"
                          onClick={() => setDirectionDetails(null)}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                    </div>
                    <div className='nb-popup-place-name'>
                      {directionDetails.placeName}
                    </div>
                    <div className='nb-popup-address'>
                      {directionDetails.to}
                    </div>
                    <div className='nb-popup-extr'>
                      <div>
                        Distance: <b>{directionDetails.distance}</b>
                      </div>
                      <div>
                        Travel Time: <b>{directionDetails.minutes}</b>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default NearBy;
