import { useState } from "react";
import { isMobile } from "react-device-detect";
//Material UI imports
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//Custom imports
import * as CSS from "../Style.js";
import helperImgOne from "../../../Assets/gmap_help_1.png";
import helperImgTwo from "../../../Assets/gmap_help_2.png";

const GetMapLocationInstruction = (props) => {
  const {
    openFlag,
    setCloseFlag,
  } = props;

  return (
    <CSS.CustomizedDialog
      open={openFlag}
      onClose={() => setCloseFlag(false)}
      // maxWidth={"md"}
    >
      <Grid
        container
        direction="column"
        gap="1em"
        justifyContent="center"
        alignItems="center"
      >
        <CSS.CloseBtn>
          <IconButton
            aria-label="closePopup"
            onClick={() => setCloseFlag(false)}
          >
            <HighlightOffIcon />
          </IconButton>
        </CSS.CloseBtn>
        <CSS.TitleContainer>
          <span className="title">Get GMap location Help</span>
        </CSS.TitleContainer>
        <CSS.youtubeHelpText>
          <img width="100%" src={helperImgOne} />
          <p>
            <ul>
              <li>Select the Location of the Property on Google Maps.</li>
              <li>Click “Share” Button on the Left side of the Map.</li>
            </ul>
          </p>
          <img width="100%" src={helperImgTwo} />
          <p>
            <ul>
              <li>Share Popup appears, Click “COPY LINK”.</li>
              <li>Paste the Link in “Google Maps Location” field in the Step 4 of registration process.</li>
            </ul>
          </p>
        </CSS.youtubeHelpText>
      </Grid>
    </CSS.CustomizedDialog>
  );
};

export default GetMapLocationInstruction;
