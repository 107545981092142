/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";

import { baseUrl } from "../../util/config";

import * as CSS from "../../Components/Overlays/Style.js";

const MobileVerify = ({
  setIsVerified,
  layout_name = "",
  promoters_name = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showMobile, setShowMobile] = useState(true);
  const [showOtp, setShowOtp] = useState(false);
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [otp, setOtp] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showMaxAttempt, setShowMaxAttempt] = useState(false);
  const [showInvalid, setShowInvalid] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [showOtpSent, setShowOtpSent] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [showAlreadyExist, setShowAlreadyExist] = useState(false);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const handleResend = () => {
    setIsLoading(true);
    setShowOtpSent(false);
    const payload = {
      mobile,
    };
    axios
      .post(`${baseUrl}/plot_api/realestate/company/resendotp`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            setIsLoading(false);
            setTimeLeft(60);
            setShowOtpSent(true);
          } else {
            if (res.data && res.data.isMaxAttempt) {
              setShowMaxAttempt(true);
              setShowOtpSent(false);
            }
            setIsLoading(false);
          }
        },
        (err) => {
          setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  const getOtp = () => {
    if (!name) {
      setShowNameError(true);
      return;
    }
    if (/^\d{10}$/.test(mobile) === false) {
      setShowError(true);
      return;
    }

    setIsLoading(true);
    const payload = {
      name,
      mobile,
    };
    axios
      .post(`${baseUrl}/plot_api/realestate/visitors/mpdp/getotp`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            setIsLoading(false);
            setShowMobile(false);
            setShowOtp(true);
            setTimeLeft(60);
            setShowOtpSent(true);
          } else {
            if (res.data && res.data.isMobileExist) {
              setShowAlreadyExist(true);
              setIsLoading(true);
              setTimeout(() => {
                setIsVerified(true);
                setIsLoading(false);
              }, 2000);
            }
            setIsLoading(false);
          }
        },
        (err) => {
          setIsLoading(false);
          console.log("err", err);
        }
      );
  };

  const verifyMobile = () => {
    if (!otp) {
      setShowError(true);
      return;
    }
    setIsLoading(true);
    const payload = {
      otp,
      mobile,
      layout_name,
      promoters_name,
    };
    axios.post(`${baseUrl}/plot_api/realestate/mpdp/verifyotp`, payload).then(
      (res) => {
        if (res.data && res.data.status) {
          setIsLoading(false);
          localStorage.setItem("mIsVerified", true);
          localStorage.setItem("mUserId", res.data.user_id);
          setIsVerified(true);
        } else {
          setShowInvalid(true);
          setIsLoading(false);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const renderVerificationSuccess = () => {
    return (
      <div className="ltn__appointment-inner">
        <h4>Your entered mobile no {mobile} is successfully verified</h4>
      </div>
    );
  };

  const renderMobileEnter = () => {
    return (
      <>
        <CSS.TitleContainer>
          <span className="title">OTP Verification</span>
        </CSS.TitleContainer>
        <TextField
          style={{ marginTop: 10 }}
          name={"name"}
          onChange={(e) => {
            setShowNameError(false);
            setName(e.target.value);
          }}
          value={name}
          label="Name"
          fullWidth={true}
          helperText={showNameError ? "Enter the Name!" : ""}
          error={showNameError}
        />
        <TextField
          name={"mobile"}
          autoComplete="off"
          onChange={(e) => {
            if (isNaN(e.target.value)) {
              return;
            }
            setMobile(e.target.value);
            setShowAlreadyExist(false);
          }}
          value={mobile || ""}
          label="Mobile"
          style={{ marginTop: 10 }}
          helperText={
            showError
              ? "Empty or Invalid input!"
              : "Valid Indian mobile number only"
          }
          error={showError}
          type={"tel"}
          pattern="[0-9]*"
          inputProps={{
            autocomplete: "new-password",
            form: {
              autocomplete: "off",
            },
          }}
        />
        <CSS.TitleContainer>
          <p style={{ textAlignLast: "center" }}>
            {showAlreadyExist ? (
              <small style={{ color: "red" }}>
                This mobile number is already verified. Please wait redirecting.
              </small>
            ) : (
              <small>We will send the OTP to above entered mobile number</small>
            )}
          </p>
        </CSS.TitleContainer>
        <CSS.submitBtn
          variant="outlined"
          onClick={() => (!isLoading ? getOtp() : {})}
        >
          {isLoading ? "Loading..." : "GET OTP"}
        </CSS.submitBtn>
      </>
    );
  };

  const renderOtpEnter = () => {
    return (
      <>
        <CSS.TitleContainer>
          <span className="title">Verify OTP</span>
          <p>
            <small style={{ color: "red" }}>
              (*) OTP sent to your registered mobile number
            </small>
          </p>
        </CSS.TitleContainer>
        <TextField
          style={{ marginTop: 10 }}
          name={"name"}
          onChange={(e) => {
            setShowNameError(false);
            setName(e.target.value);
          }}
          value={name}
          label="Name"
          fullWidth={true}
          helperText={showNameError ? "Enter the Name!" : ""}
          error={showNameError}
          disabled
        />
        <TextField
          name={"mobile"}
          autoComplete="off"
          onChange={(e) => {
            if (isNaN(e.target.value)) {
              return;
            }
            setMobile(e.target.value);
            setShowAlreadyExist(false);
          }}
          value={mobile || ""}
          label="Mobile"
          fullWidth={true}
          style={{ marginTop: 10 }}
          disabled
        />
        <CSS.TitleContainer>
          <p>
            <span
              onClick={() => {
                setShowOtp(false);
                setShowMobile(true);
                setTimeLeft(null);
                setOtp(null);
                setShowInvalid(false);
                setShowError(false);
                setShowOtpSent(false);
              }}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "blue",
              }}
            >
              Change Mobile Number
            </span>
          </p>
        </CSS.TitleContainer>
        <TextField
          name="otp"
          autoComplete="off"
          onChange={(e) => {
            if (isNaN(e.target.value)) {
              return;
            }
            setOtp(e.target.value);
          }}
          value={otp || ""}
          label="OTP"
          fullWidth={true}
          style={{ marginTop: 10 }}
          helperText={showError ? "Empty field!" : " "}
          error={showError}
          inputProps={{
            autocomplete: "new-password",
            form: {
              autocomplete: "off",
            },
          }}
        />
        {showInvalid && <span style={{ color: "red" }}>Invalid OTP</span>}
        {showMaxAttempt ? (
          <CSS.TitleContainer>
            <p>
              <span style={{ color: "#ff5a3c" }}>
                Maximum Attempt Reached, Try again after 15 Minutes.
              </span>
            </p>
          </CSS.TitleContainer>
        ) : (
          <CSS.TitleContainer>
            <p>
              {!timeLeft ? (
                <span
                  onClick={() => handleResend()}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#ff5a3c",
                  }}
                >
                  Resend
                </span>
              ) : (
                <span style={{ color: "#ff5a3c" }}>
                  You can try the resend OTP option after{" "}
                  <b style={{ color: "blue" }}>{timeLeft}</b> seconds.
                </span>
              )}
            </p>
          </CSS.TitleContainer>
        )}
        {/* <CSS.TitleContainer>
          <span
            onClick={() => {
              setShowOtp(false);
              setShowMobile(true);
              setTimeLeft(null);
              setOtp(null);
              setShowInvalid(false);
              setShowError(false);
              setShowOtpSent(false);
            }}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "blue",
              marginBottom: 20,
            }}
          >
            Change Mobile No.
          </span>
        </CSS.TitleContainer> */}
        <CSS.submitBtn
          variant="outlined"
          style={{ width: "fit-content", marginTop: 20 }}
          onClick={() => (!isLoading ? verifyMobile() : {})}
        >
          {isLoading ? "Loading..." : "Verify OTP"}
        </CSS.submitBtn>
        {/* <CSS.submitBtn
          variant="outlined"
          onClick={() => {
            setShowOtp(false);
            setShowMobile(true);
            setTimeLeft(null);
            setOtp(null);
            setShowInvalid(false);
            setShowError(false);
            setShowOtpSent(false);
          }}
          style={{ width: "fit-content", marginBottom: 20 }}
        >
          Change Mobile Number
        </CSS.submitBtn> */}
      </>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {showMobile && renderMobileEnter()}
      {showOtp && renderOtpEnter()}
      {showSuccess && renderVerificationSuccess()}
    </div>
  );
};
export default MobileVerify;
