import React, { useState, useEffect } from "react";

//Material UI imports
import TextField from "@mui/material/TextField";

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";

import * as CSS from "../Style.js";

import "./Captcha.css";

const CaptchaTest = (props) => {
  const {
    askUsModalInputChange,
    askUsPopupData,
    askUsModalSubmit,
    openSnackBar,
  } = props;

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const doSubmit = () => {
    if (
      !askUsPopupData.name &&
      !askUsPopupData.category &&
      !askUsPopupData.description
    ) {
      openSnackBar("Enter all inputs", "error");
      return;
    }
    if (validateCaptcha(askUsPopupData.captcha) == true) {
      askUsModalSubmit();
    } else {
      openSnackBar("Invalid Captcha", "error");
    }
  };

  return (
    <>
      <div className="col-md-12" style={{ width: "100%", textAlign: "center" }}>
        <LoadCanvasTemplate />
      </div>
      <TextField
        name={"captcha"}
        onChange={askUsModalInputChange}
        value={askUsPopupData.captcha}
        label="Enter Captcha"
        fullWidth={true}
      />
      <CSS.customBtn variant="outlined" onClick={doSubmit}>
        Submit
      </CSS.customBtn>
    </>
  );
};

export default CaptchaTest;
