import styled from 'styled-components';
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { isMobile } from "react-device-detect";

const GlobalGrid = {
    background: '#FFFFFF',
    border: '1px solid #FFEEDD',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)',
    width: 'fit-content',
    padding: '10px',
}

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 10px;
    background: #634F35;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
`;

const CardContainerTitle = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-variant: small-caps;
    color: #FBDEBC;
`;

const CardContainerContent = styled.div`
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 2px;
    color: #FFEEDD;
`;

const CardPriceContent = styled.div`
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 2px;
    color: #FFEEDD;
`;

const Sqft = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 14px;
    text-align: center;
    color: #382F24;
`;

const ChipContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    gap: 2px;
    background: ${props => props.plotStatus === 'unsold' ? '#b6df82' : '#ffdca1'};
    border: 1px solid #52643E;
    border-radius: 32px;
`;

const ChipContent = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: -0.25px;
    font-variant: small-caps;
    color: ${props => props.plotStatus === 'unsold' ? '#52643E' : 'ed0012'};
`;

const CardMessage = styled.span`
    font-style: normal;
    font-weight: ${props => props.promoterName ? '600' : '400'};
    font-size: ${props => props.promoterName ? '14px' : '12px'};
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #666666;
`;

const CardLink = styled.span`
    font-style: normal;
    font-weight: ${props => props.promoterName ? '600' : '400'};
    font-size: ${props => props.promoterName ? '14px' : '12px'};
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.25px;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
`;

const CustomizedDialog = styled(Dialog)`
    & .MuiPaper-root {
        border: 1px solid #FFEEDD;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        padding: ${props => props.isMobile ? '10px' : '20px' };
        width: ${props => props.isMobile ? '100%' : props.askUs ? '25%' : '' };
    }
`;

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    color: #000000;

    .update-schedule-title {
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        padding-top: ${isMobile ? '20px' : '30px'};
    }

    .title {
        font-size: 21px;
        line-height: 25px;
        margin-top: 10px;
    }

    .info {
        font-size: 12px;
        line-height: 14px;
        opacity: 0.7;
    }

    .site-visit-success {
        font-size: 16px;
        line-height: 26px;
        padding-top: ${isMobile ? '20px' : '14px'};
        text-align: center;
    }
`;

const CloseBtn = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
`;

const customBtn = styled(Button)`
    background-color: #ffddb5 !important;
    color: #835400 !important;
    border-color: wheat !important;
    text-transform: none !important;
`;

const submitBtn = styled(Button)`
    background-color: #ffddb5 !important;
    color: #835400 !important;
    border-color: wheat !important;
    text-transform: none !important;
    width: 40%;
    place-self: center;
`;

const customAnchor = styled.a`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #835400;
    text-decoration: none;
`;

const errorAlert = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-decoration: none;
    color: red;
    display: block;
    text-align: center;
    width: ${props => props.isMobile ? '' : '350px' };
    word-wrap: break-word;
    padding-top: 24px;
}
`;

const favTitle = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 14px;
    align-self: center;
    color: #382F24;
`;

const favContent = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    align-self: center;
    color: #382F24;
`;

const youtubeHelpText = styled.div`
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: -0.25px;
    color: #000000;
    padding: 0px 10px;
`;

export { 
    GlobalGrid, 
    CardContainer, 
    CardContainerTitle,
    CardContainerContent,
    CardPriceContent,
    youtubeHelpText,
    Sqft,
    ChipContainer,
    ChipContent,
    CardMessage,
    CustomizedDialog,
    TitleContainer,
    CloseBtn,
    customBtn,
    submitBtn,
    customAnchor,
    errorAlert,
    favTitle,
    favContent,
    CardLink
}