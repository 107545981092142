import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import WestIcon from "@mui/icons-material/West";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

//Custom imports
import * as CSS from "../style";
import helperImg from "../../../Assets/youtube_upload_help.png";
import { useSnackbar } from "../../../hooks/useSnackbar";
import Snackbar from "../../snackbar";
import XHR from "../../../util/pxhr";
import VideoUploadInstruction from "../../Overlays/VideoUploadInstruction/VideoUploadInstruction";

const PromoterProfileStepFive = (props) => {
  const navigate = useNavigate();
  const { nextStep } = props;

  const [videoUrls, setVideoUrls] = useState([]);
  const [preview, setPreview] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [open, setOpen] = useState(false);

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    user_id: "",
    general_information: {
      door_no: "",
      street: "",
      address1: "",
      address2: "",
      town: "",
      city: "",
      taluk: "",
      district: "",
      state: "",
      pincode: "",
      country: "",
      landmark: "",
      no_of_projects: "",
      about_company: "",
    },
    board_of_directors: [],
    team: [],
    feedback: [],
    gallery: [],
  });

  const onAddBtnClk = () => {
    let videoId = "";
    if (youtubeUrl.includes("/")) {
      videoId = youtubeUrl.split("/").pop();
      setVideoUrls([...videoUrls, { fullUrl: youtubeUrl, videoId }]);
      setYoutubeUrl("");
      setPreview(false);

      const dataCopy = JSON.parse(JSON.stringify(data));
      let assets = dataCopy.gallery || [];
      assets.push({ type: "youtube", url: youtubeUrl });
      console.log("assets", assets);
      setData({
        ...data,
        gallery: assets,
      });
    } else {
      alert("Please enter a valid URL.");
    }
  };

  useEffect(() => {
    const promotor_user = localStorage.getItem("promotor_user") || null;
    if (promotor_user) {
      const userData = JSON.parse(promotor_user);
      setUserDetails(userData);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      getPromotorDetails(userDetails.id);
    }
  }, [userDetails]);

  useEffect(() => {
    let slideVideos = [];
    if (data && data.gallery.length) {
      data.gallery.forEach((ele, index) => {
        if (ele.type === "youtube") {
          const vdId = ele.url.split("/").pop();
          slideVideos.push({ fullUrl: ele.url, videoId: vdId });
        }
      });
    }
    setVideoUrls(slideVideos);
  }, [data]);

  const getPromotorDetails = (id) => {
    setIsLoading(true);
    const payload = { company_id: id };
    XHR.post("realestate/promoters/get", payload).then(
      (res) => {
        setIsLoading(false);
        setData((res.data && res.data.companies) || {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const onPreviewBtnClk = () => {
    setPreview(true);
  };

  const onBackBtnClk = () => {
    nextStep(4);
  };

  const onNextBtnClk = () => {
    nextStep(6);
  };

  const updateProject = () => {
    setIsLoading(true);
    const payload = { ...data, company_id: userDetails.id };
    XHR.post("realestate/promoters/update", payload).then(
      (res) => {
        setIsLoading(false);
        openSnackBar("Updated Successfully", "success");
        getPromotorDetails(userDetails.id);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleRemoveGallery = (url, type) => {
    const assets =
      data &&
      data.gallery &&
      data.gallery.filter((el) => el.type === type && el.url !== url);

    const allAssets =
      data && data.gallery && data.gallery.filter((el) => el.type !== type);

    setData({
      ...data,
      gallery: allAssets.concat(assets),
    });
  };

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <VideoUploadInstruction openFlag={open} setCloseFlag={setOpen} />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">
                  Upload Youtube Videos
                </span>
                <div>
                  <span className="login-form-heading-step">
                    (Optional Step 5 of 6)
                  </span>
                </div>
              </div>
              <div className="login-form-div">
                <div>
                  <span className="login-form-content-step">
                    You can add videos of your layout, office celebration,
                    office premises.
                  </span>
                </div>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Youtube Video URL"
                  value={youtubeUrl}
                  onChange={(e) => setYoutubeUrl(e.target.value)}
                  variant="outlined"
                  required
                  className="login-inputWidth"
                  size="small"
                />
                <IconButton
                  size="medium"
                  onClick={onAddBtnClk}
                  className="login-button view-uploaded-logo"
                >
                  <ControlPointIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  size="medium"
                  onClick={() => setOpen(true)}
                  className="login-button view-uploaded-logo"
                >
                  <LiveHelpIcon fontSize="inherit" />
                </IconButton>
              </div>
              <div className="login-form-div youtube-help-text">
                <div className="youtube-video-list">
                  {videoUrls.map((ele, idx) => {
                    return (
                      <a
                        href={ele.fullUrl}
                        className="youtube-video-links"
                        target="_blank"
                        rel="noreferrer"
                        key={idx}
                      >
                        <Chip
                          label={ele.fullUrl}
                          variant="outlined"
                          onDelete={(e) => {
                            e.preventDefault();
                            handleRemoveGallery(ele.fullUrl, "youtube");
                          }}
                        />
                      </a>
                    );
                  })}
                </div>
                {/* <p>
                {videoUrls.map((ele, idx) => {
                  return (
                    <>
                      <li style={{ listStyleType: "decimal" }} key={idx}>
                        <a
                          href={ele.fullUrl}
                          style={{
                            cursor: "pointer",
                            color: "black",
                            textDecoration: "underline",
                          }}
                          target="_blank"
                          rel="noreferrer"
                          key={idx}
                        >
                          {ele.fullUrl}
                        </a>
                      </li>
                    </>
                  );
                })}
              </p> */}
              </div>
              <div className="profile-back-next-btn-cntr">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onBackBtnClk}
                  className="login-button"
                  startIcon={<WestIcon />}
                >
                  Back
                </Button>
                {/* <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => setOpen(true)}
                  className="login-button"
                >
                  Help
                </Button> */}
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => updateProject()}
                  className="login-button"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onNextBtnClk}
                  className="login-button"
                  endIcon={<EastIcon />}
                >
                  Next
                </Button>
              </div>
              {preview && videoUrls.length > 0 && (
                <iframe
                  style={{ width: "100%", height: "100%" }}
                  src={
                    "https://www.youtube.com/embed?rel=0&playlist=" +
                    videoUrls.map((ele) => ele.videoId)
                  }
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="true"
                />
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default PromoterProfileStepFive;
