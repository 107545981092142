import * as React from "react";
//Material UI imports
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { createTheme, ThemeProvider } from "@mui/material/styles";
//Custom imports
import "./Divider.css";

const theme = createTheme({
  palette: {
    neutral: {
      main: "#643f00",
      contrastText: "#643f00",
    },
  },
});

const Divider = (props) => {
  const { sortingOnChange, totalCount, switchMapOption, switchMap, setRaduis, promoterTotal } =
    props;

  let area = '';
  const geo_location = localStorage.getItem("GEO_LOCATION") || null;
  if (geo_location) {
    const geoData = JSON.parse(geo_location);
    const [ firstPartAddress ] = (geoData.formatted_address).split(',');
    area = firstPartAddress;
  }

  const [selectedOption, setSelectedOption] = React.useState("DEFAULT");
  const [raduisOption, setRaduisOption] = React.useState(10000);

  const changeSorting = (e) => {
    setSelectedOption(e.target.value);
    sortingOnChange(e.target.value);
  };

  const changeRaduis = (e) => {
    setRaduisOption(e.target.value);
    setRaduis(e.target.value);
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid
        item
        container
        textAlign="center"
        className="divider-text-margin-sm-md"
        display={{ lg: "none", md: "none", sm: "block", xs: "block" }}
      >
        {totalCount > 0 && (
          <span className="divider-text">
            {
              area ? (
                `We found ${totalCount} layouts from ${promoterTotal} promoters in ${area}`
              ) : (
                `We found ${totalCount} layouts from ${promoterTotal} promoters` 
              )
            }
          </span>
        )}
      </Grid>
      <Grid item>
        {!switchMap && (
          <FormControl>
            <Select
              value={selectedOption}
              inputProps={{ "aria-label": "Without label" }}
              style={{
                borderRadius: "100px",
                border: "1px solid #C1A788",
                fontSize: "10px",
              }}
              onChange={(e) => {
                changeSorting(e);
              }}
            >
              <MenuItem value={"DEFAULT"}>DEFAULT SORTING</MenuItem>
              <MenuItem value={"RMIN"}>SQFT PRICE - LOW TO HIGH</MenuItem>
              <MenuItem value={"RMAX"}>SQFT PRICE - HIGH TO LOW</MenuItem>
            </Select>
          </FormControl>
        )}
        {switchMap && (
          <FormControl>
            <Select
              value={raduisOption}
              inputProps={{ "aria-label": "Without label" }}
              style={{
                borderRadius: "100px",
                border: "1px solid #C1A788",
                fontSize: "10px",
              }}
              onChange={(e) => {
                changeRaduis(e);
              }}
            >
              <MenuItem value={"DEFAULT"}>SELECT RADIUS</MenuItem>
              <MenuItem value={2000}>2 KMS</MenuItem>
              <MenuItem value={3000}>3 KMS</MenuItem>
              <MenuItem value={4000}>4 KMS</MenuItem>
              <MenuItem value={5000}>5 KMS</MenuItem>
              <MenuItem value={10000}>10 KMS</MenuItem>
            </Select>
          </FormControl>
        )}
      </Grid>
      <Grid
        item
        className="divider"
        display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
      />
      <Grid
        item
        className="divider-text-margin"
        display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
      >
        {(totalCount > 0 || totalCount === 0) && (
          <span className="divider-text">
            {
              area ? (
                `We found ${totalCount} layouts from ${promoterTotal} promoters in ${area}`
              ) : (
                `We found ${totalCount} layouts from ${promoterTotal} promoters` 
              )
            }
          </span>
        )}
      </Grid>
      <Grid
        item
        className="divider"
        display={{ lg: "block", md: "block", sm: "none", xs: "none" }}
      />
      <Grid item className="divider-switch-margin">
        <ThemeProvider theme={theme}>
          <FormControlLabel
            value="Maps view"
            control={
              <Switch
                checked={switchMap}
                color="neutral"
                onClick={() => {
                  switchMapOption(!switchMap);
                }}
              />
            }
            label="Maps view"
            labelPlacement="end"
          />
        </ThemeProvider>
      </Grid>
    </Grid>
  );
};

export default Divider;
