import * as React from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
//Material UI imports
import Grid from "@mui/material/Grid";
import CancelIcon from "@mui/icons-material/Cancel";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

//Custom imports
// import LoadingScreen from "../Default/LoadingScreen";
import "./Plp.css";
import Divider from "./Divider/Divider";
import PlotCard from "./PlotCard/PlotCard";
import Filter from "./Filter/Filter";
import Dialog from "../Dialog";
import GoogleMapsAutoComplete from "../Maps/GoogleAutoComplete";
import GoogleLoginPopup from "../Overlays/GoogleLoginPopup/GoogleLoginPopup";
import PartialOnboard from "../Overlays/PartialOnboard/PartialOnboard";
import GoogleSearch from "../../Components/PLP/GoogleSearch/GoogleSearch";
import PLPHeader from "../HeaderMenu/PLPHeader";
import OtpLoginPopup from "../Overlays/OtpLoginPopup/OtpLoginPopup";

//Store imports
import { getPLPList } from "../../store/plp/actionCreator";
import { getAllAmenities } from "../../store/common/actionCreator";
import { baseUrl, prodBuild } from "../../util/config";

const Plp = (props) => {
  const { 
    getPLPList, plp, getAllAmenities, amenities, total, isFav, loading, promoterTotal 
  } = props;
    
  const navigate = useNavigate();

  const [loginState, setLoginState] = React.useState({
    isLogin: false,
    isOtp: false,
  });
  const [openGoogleLoginPopup, setOpenGoogleLoginPopup] = React.useState(false);
  const [mapPopupData, setMapPopupData] = React.useState({
    layout_name: "",
    promoters_name: "",
  });
  const [openOTPLoginPopup, setOpenOTPLoginPopup] = React.useState(false);
  const [openPartialOnboardPopup, setOpenPartialOnboardPopup] =
    React.useState(false);
  const [switchMap, setSwitchMap] = React.useState(false);
  const [radius, setRaduis] = React.useState(10000);
  const [partialData, setPartialData] = React.useState(null);
  const [dialogShow, setDialogShow] = React.useState(false);
  const [dialogProps, setDialogProps] = React.useState({});
  const [filter, setFilter] = React.useState({
    sorting: "",
    filter: "",
    user: "",
    lat: "",
    lng: "",
    address: "",
    advance_filter: "",
    pincode_filter: "",
    map_filter: "",
    from: 1,
    radius: "",
  });

  const [filterAddress, setFilterAddress] = React.useState("");
  const [showBanner, setShowBanner] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState(null);
  const [fav, setFav] = React.useState((isFav && isFav) || []);
  const [searchLocations, setSearchLocations] = React.useState([]);

  React.useEffect(() => {
    const token = localStorage.getItem("authToken") || null;
    const isOtpLogin = localStorage.getItem("mIsVerified") || false;
    if (token) {
      setLoginState({
        ...loginState,
        isLogin: true,
        isOtp: isOtpLogin,
      });
    } else {
      setLoginState({
        ...loginState,
        isOtp: isOtpLogin,
      });
    }
    const user = localStorage.getItem("user") || null;
    if (user) {
      const userData = JSON.parse(user);
      setUserDetails(userData);
      setFilter({
        ...filter,
        user: userData && userData.googleId ? userData.googleId : "",
      });
    }

    const geo_location = localStorage.getItem("GEO_LOCATION") || null;
    
    if (geo_location) {
      const geoData = JSON.parse(geo_location);
      setFilterAddress(geoData);
      if (geoData && geoData.geometry && geoData.geometry.location) {
        setFilter({
          ...filter,
          lat: geoData.geometry.location.lat,
          lng: geoData.geometry.location.lng,
          address: geoData.formatted_address,
        });
      }
    }

    localStorage.removeItem("GEO_LOCATION");
    setFilterAddress(null);

    const pdpback = localStorage.getItem("pdpback") || false;
    if (pdpback !== "false") {
      localStorage.setItem("pdpback", false);
      window.location.reload();
    }
    // insertRera();
  }, []);

  React.useEffect(() => {
    getAllAmenities();
  }, []);

  React.useEffect(() => {
    if (loginState.isLogin && loginState.isOtp) {
      setShowBanner(true);
      const user_id = localStorage.getItem("mUserId");
      const payload = { user_id };
      axios
        .post(`${baseUrl}/cms_api/realestate/getPreferenceByUserId`, payload)
        .then(
          (res) => {
            if (res?.data?.data) {
              if (res?.data?.data.length !== 0) {
                setShowBanner(false);
              }
            }
          },
          (err) => {
            console.log("err coming..");
            console.log(err);
          }
        );
    }
  }, [loginState]);

  React.useEffect(() => {
    if (showBanner) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }, [showBanner, plp]);

  const setAutoCompleteLocation = (locationData) => {
    if (locationData.lat !== "") {
      locationData.user =
        userDetails && userDetails.googleId ? userDetails.googleId : "";
      setFilter({
        ...filter,
        lat: locationData.lat,
        lng: locationData.lng,
        address: locationData.address || "",
      });
    } else {
      setFilter({
        ...filter,
        lat: "",
        lng: "",
        address: "",
      });
    }
  };

  React.useEffect(() => {
    let param = filter;
    param.user =
      userDetails && userDetails.googleId ? userDetails.googleId : "";
    if (switchMap) {
      if (filter.lat !== "") {
        let param = filter;
        param.radius = parseInt(radius / 1000);
        param.user =
          userDetails && userDetails.googleId ? userDetails.googleId : "";
        getPLPList(param);
      }
    } else {
      const user = localStorage.getItem("user") || null;
      if (user) {
        if (filter.user != "") {
          getPLPList(param);
        }
      } else {
        getPLPList(param);
      }
    }
  }, [filter]);

  const loadPLPList = () => {
    let param = filter;
    param.user =
      userDetails && userDetails.googleId ? userDetails.googleId : "";
    if (switchMap) {
      if (filter.lat !== "") {
        let param = filter;
        param.radius = parseInt(radius / 1000);
        param.user =
          userDetails && userDetails.googleId ? userDetails.googleId : "";
        getPLPList(param);
      }
    } else {
      const user = localStorage.getItem("user") || null;
      if (user) {
        if (filter.user != "") {
          getPLPList(param);
        }
      } else {
        getPLPList(param);
      }
    }
  };

  React.useEffect(() => {
    if (!switchMap) {
      setFilter({
        ...filter,
        radius: "",
      });
    } else {
      loadPLPList();
    }
  }, [switchMap]);

  React.useEffect(() => {
    setFilter({
      ...filter,
      radius: radius,
    });
  }, [radius]);

  React.useEffect(() => {
    const locations = [];
    for (let i = 0; i < plp.length; i++) {
      locations.push({
        gps: plp[i]["gps_location"],
        icon: plp[i]["is_partial"]
          ? "https://storage.googleapis.com/am-songs/images/20220911025940_map_icon_partial.png?GoogleAccessId=ads-395%40am-ads.iam.gserviceaccount.com&Expires=1694012381&Signature=6J4Mlfumuq9u6mIaFkQw9jLqcAcr8BTiaXWRB%2BekpkIpCKpZdPZa%2Bf0qd9df%2FPvM6z%2FtGY6YLjWk93CGCwoz8P5WMTc99xUotGTWpO9G39qXuN1KZxsyLwthMQRTmL08ty%2FZVoQPHr%2BXCL09RJUHl44qWuaWHzKj1hQY1M%2FEyqs7idWQ61gFIjaTrlaQTGOQWhJlSc%2BoTArvZDImx9rryROhIMaq4uecegUSV8F3ANVlQxW9hpn5TRC93LF3lv5dJdvVEq0y1sOcsAi1CI8oQgcM59XqBvOO6ddUHoLeZZNjRgcGL4q4e8i5J6NR5K88CBAK6EBiWw7P6MNmuilT0g%3D%3D"
          : "https://storage.googleapis.com/am-songs/images/20220820063053_project_view.png",
        project_data: plp[i],
      });
    }
    setSearchLocations(locations);
  }, [plp]);

  const filterOnChange = (filterData) => {
    let newFilter = {};
    filterData.map((ele) => {
      if (ele.displayVal !== "ANY" && ele.displayVal !== "ALL") {
        if (ele.key === "emi") {
          newFilter[ele.key] = ele.val.toLowerCase();
        } else if (
          ele.key === "area" ||
          ele.key === "rate" ||
          ele.key === "range"
        ) {
          newFilter[ele.key] = {};
          newFilter[ele.key]["from"] = ele.val[0];
          newFilter[ele.key]["to"] = ele.val[1];
        } else if (
          ele.key === "direction" ||
          ele.key === "amenities" ||
          ele.key === "availability" ||
          ele.key === "property_type"
        ) {
          let dataId = [];
          ele.val.filter((data) => {
            if (data.ticked) dataId.push(data.id);
          });
          newFilter[ele.key] = dataId;
        }
      }
    });
    setFilter({
      ...filter,
      filter: newFilter,
    });
  };

  const sortingOnChange = (sortData) => {
    if (sortData != "") {
      setFilter({
        ...filter,
        sorting: sortData,
      });
    }
  };

  const closeDialog = () => {
    setDialogShow(false);
    setTimeout(() => {
      setDialogProps({});
    }, 100);
  };

  // const insertRera = () => {
  //   const payload = {};
  //   axios.post(`${baseUrl}/plot_api/analytics/addrera`, payload).then(
  //     (res) => {
  //       console.log("Success", res);
  //     },
  //     (err) => {
  //       console.log("err", err);
  //     }
  //   );
  // };

  const switchMapOption = (isChecked) => {
    if (isChecked) {
      const geo_location = localStorage.getItem("GEO_LOCATION") || null;
      if (!geo_location) {
        localStorage.setItem(
          "GEO_LOCATION",
          JSON.stringify({
            address_components: [
              {
                long_name: "Devanahalli",
                short_name: "Devanahalli",
                types: ["locality", "political"],
              },
              {
                long_name: "Southegowdanahalli",
                short_name: "Southegowdanahalli",
                types: ["administrative_area_level_3", "political"],
              },
              {
                long_name: "Karnataka",
                short_name: "KA",
                types: ["administrative_area_level_1", "political"],
              },
              {
                long_name: "India",
                short_name: "IN",
                types: ["country", "political"],
              },
              {
                long_name: "562110",
                short_name: "562110",
                types: ["postal_code"],
              },
            ],
            formatted_address: "Devanahalli, Southegowdanahalli, Karnataka 562110, India",
            geometry: {
              location: {
                lat: 13.2437197,
                lng: 77.7172413,
              },
            },
            place_id: "ChIJzUFteoxOqDsRo2BeLL3hSkM",
            html_attributions: [],
          })
        );
        setFilterAddress({
          address_components: [
            {
              long_name: "Devanahalli",
              short_name: "Devanahalli",
              types: ["locality", "political"],
            },
            {
              long_name: "Southegowdanahalli",
              short_name: "Southegowdanahalli",
              types: ["administrative_area_level_3", "political"],
            },
            {
              long_name: "Karnataka",
              short_name: "KA",
              types: ["administrative_area_level_1", "political"],
            },
            {
              long_name: "India",
              short_name: "IN",
              types: ["country", "political"],
            },
            {
              long_name: "562110",
              short_name: "562110",
              types: ["postal_code"],
            },
          ],
          formatted_address: "Devanahalli, Southegowdanahalli, Karnataka 562110, India",
          geometry: {
            location: {
              lat: 13.2437197,
              lng: 77.7172413,
            },
          },
          place_id: "ChIJzUFteoxOqDsRo2BeLL3hSkM",
          html_attributions: [],
        });
        setFilter({
          ...filter,
          lat: 13.2437197,
          lng: 77.7172413,
          address: "Devanahalli, Southegowdanahalli, Karnataka 562110, India",
        });
      } else {
        const geoData = JSON.parse(geo_location);
        setFilterAddress(geoData);
        if (geoData && geoData.geometry && geoData.geometry.location) {
          setFilter({
            ...filter,
            lat: geoData.geometry.location.lat,
            lng: geoData.geometry.location.lng,
            address: geoData.formatted_address,
          });
        }
      }
    } else {
      // localStorage.removeItem("GEO_LOCATION");
      // setFilterAddress(null);
      // setFilter({
      //   ...filter,
      //   lat: "",
      //   lng: "",
      //   address: "",
      // });
    }
    setSwitchMap(isChecked);
  };

  const openPDP = (
    e,
    state,
    district,
    taluk,
    area1,
    area2,
    promotors,
    company_hash,
    company_userid,
    name,
    data
  ) => {
    e.stopPropagation();

    let path = "/pdp";

    if (state) {
      path = `${path}/${state.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (district) {
      path = `${path}/${district.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (taluk) {
      path = `${path}/${taluk.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (area1) {
      path = `${path}/${area1.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (area2) {
      path = `${path}/${area2.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (promotors) {
      path = `${path}/${promotors.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (name) {
      path = `${path}/${name.replace(/\s+/g, "").toLowerCase()}`;
    }

    path = `${path}/${company_hash}`;

    // let path = `/pdp/residential_plots_${
    //   state != "" ? state.replace(/\s+/g, "").toLowerCase() : "state"
    // }/residential_plots_${
    //   district != "" ? district.replace(/\s+/g, "").toLowerCase() : "district"
    // }/${
    //   promotors != ""
    //     ? promotors.replace(/\s+/g, "").toLowerCase()
    //     : "promotors "
    // }/${name.replace(/\s+/g, "").toLowerCase()}/${company_hash}`;
    // console.log("HASHHHH&&&&&&&&", path);

    if (company_hash != "") {
      // Call PDP

      const payload = {
        project_id: data.id,
        name: "OPEN PDP FROM PLP",
        type: "OPEN_PDP_FROM_PLP",
        data: [],
      };
      axios.post(`${baseUrl}/plot_api/analytics/add`, payload).then(
        (res) => {
          console.log("Success", res);
        },
        (err) => {
          console.log("err", err);
        }
      );

      navigate(path, {
        state: { id: company_userid, isfav: false },
      });
    }
    // } else {
    //   setOpenPartialOnboardPopup(true);
    //   setPartialData(data);
    // setDialogShow(true);
    // setDialogProps({
    //   title: "Full onboarding in progress...",
    //   message: <h1>Hai</h1>,
    //   onClose: (yes) => {
    //     closeDialog(true);
    //   },
    //   button: [],
    // });
    // }
  };

  const updateFav = (id, data) => {
    if (userDetails && userDetails.googleId) {
      var isFavAction = 0;
      setFav((fav) => {
        const copyItems = fav.length > 0 ? [...fav] : [...isFav];
        let isFavStatus = 0;
        if (copyItems.includes(id)) {
          copyItems.splice(copyItems.indexOf(id), 1);
        } else {
          copyItems.push(id);
          isFavStatus = 1;
          isFavAction = 1;
        }
        const payload = {
          project: id,
          source_id: id,
          is_fav: isFavStatus,
          user: userDetails && userDetails.googleId ? userDetails.googleId : "",
          type: 1,
        };
        axios.post(`${baseUrl}/plot_api/realestate/api/fav`, payload).then(
          (res) => {
            if (res.data && res.data.status) {
              // handleChatClose();
              // openSnackBar("Success", "success");
            }
          },
          (err) => {
            // openSnackBar("Failed to submit", "success");
            console.log("err", err);
          }
        );
        return copyItems;
      });
      // sucessFavourite(data, isFavAction);
    }
  };

  // const sucessFavourite = (data, isFavAction) => {
  // setDialogShow(true);
  // setDialogProps({
  //   message: (
  //     <>
  //       <CSS.favTitle>{data && data.name}</CSS.favTitle>
  //       <CSS.favContent>
  //         {isFavAction === 1
  //           ? `Successfully added to your favourite`
  //           : `Successfully removed your favourite`}
  //       </CSS.favContent>
  //     </>
  //   ),
  //   onClose: (yes) => {
  //     closeDialog(true);
  //   },
  //   button: [],
  // });
  // };

  const setIsVerified = () => {
    setLoginState({
      ...loginState,
      isOtp: true,
    });
    setOpenOTPLoginPopup(false);
    // navigate(`/addProperty`);
  };

  return (
    <>
      <GoogleLoginPopup
        openFlag={openGoogleLoginPopup}
        setCloseFlag={setOpenGoogleLoginPopup}
      />
      <OtpLoginPopup
        openFlag={openOTPLoginPopup}
        setCloseFlag={setOpenOTPLoginPopup}
        setIsVerified={setIsVerified}
        layout_name={mapPopupData.layout_name}
        promoters_name={mapPopupData.promoters_name}
      />
      <PartialOnboard
        openFlag={openPartialOnboardPopup}
        setCloseFlag={setOpenPartialOnboardPopup}
        toolTipContent={partialData}
      />
      <Dialog open={dialogShow} {...dialogProps} />
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        { !prodBuild && showBanner && (
          <div className="banner-contr">
            <div
              className="banner-text"
              onClick={() =>
                navigate("/addPreference", { state: { id: null } })
              }
            >
              <NotificationsIcon />
              <span>
                Please save your preference to buy the dream plot with easy
                effort
              </span>
              <ArrowForwardIcon />
            </div>
            <div>
              <IconButton
                className={"iconBtns"}
                onClick={() => setShowBanner(false)}
              >
                <CancelIcon />
              </IconButton>
            </div>
          </div>
        )}
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="3em 0 2em 0;"
        >
          <PLPHeader
            loginStateFromParent={loginState}
            openLogin={setOpenGoogleLoginPopup}
            setOpenGoogleLoginPopup={setOpenGoogleLoginPopup}
            setOpenOTPLoginPopup={setOpenOTPLoginPopup}
          />
        </Grid>
        <Grid
          container
          lg={10}
          md={10}
          sm={12}
          xs={12}
          style={{ textAlign: "center", justifyContent: "center" }}
        >
          <GoogleMapsAutoComplete
            setLocation={setAutoCompleteLocation}
            setDefaultInput={
              (filterAddress && filterAddress.formatted_address) || ""
            }
          />
        </Grid>
        <Grid item lg={10} md={10} sm={11} xs={11}>
          <Filter
            fliterFor={"plp"}
            amenities={amenities}
            filterOnChange={filterOnChange}
          />
        </Grid>
        <Grid item lg={10} md={10} sm={11.5} xs={11.5}>
          <Divider
            sortingOnChange={sortingOnChange}
            totalCount={total}
            switchMap={switchMap}
            switchMapOption={switchMapOption}
            setRaduis={setRaduis}
            promoterTotal={promoterTotal}
          />
        </Grid>
        {!switchMap && (
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <PlotCard
              list={plp}
              openPDP={openPDP}
              setOpenGoogleLoginPopup={setOpenGoogleLoginPopup}
              fav={fav}
              updateFav={updateFav}
              isLoading={loading}
            />
          </Grid>
        )}

        {switchMap && filter.lat !== "" && (
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} margin="1em">
              <GoogleSearch
                searchLocations={searchLocations}
                data={filter}
                setLocation={setAutoCompleteLocation}
                loginState={loginState}
                setOpenOTPLoginPopup={setOpenOTPLoginPopup}
                setMapPopupData={setMapPopupData}
                setOpenGoogleLoginPopup={setOpenGoogleLoginPopup}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    plp: state.plp.list,
    total: state.plp.total,
    amenities: state.common.amenities,
    isFav: state.plp.isFav,
    loading: state.plp.loading,
    promoterTotal: state.plp.promoterTotal
  };
};
const mapDispatchToProps = {
  getPLPList,
  getAllAmenities,
};
export default connect(mapStateToProps, mapDispatchToProps)(Plp);
