import styled from 'styled-components';
import { isMobile } from "react-device-detect";

const CloseBtn = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
`;

const PopupBtns = styled.div`
    display: flex;
    gap: 10px;
    
    .pdp-tlt-button {
        background-color: #835400 !important;
        color: #ffddb5 !important;
        border-color: wheat !important;
        text-transform: none !important;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    gap: 3px;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    align-items: center;
`;

const NameAddressContainer = styled.div`
    display: flex;
    gap: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
`;

const Name = styled.span`
    font-weight: bold;
    text-align: center;
`;

const Address = styled.span`
    font-size: 12px;
    text-align: center;
`;

const Acres = styled.span`
    text-align: center;
    font-weight: 600;
    font-size: 12px;
`;

const CarouselContainer = styled.div`
    width: 50%;
`;

export { 
    CloseBtn,
    PopupBtns,
    Name,
    Address,
    Acres,
    InfoContainer,
    NameAddressContainer,
    CarouselContainer
}