import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import { Carousel } from "react-responsive-carousel";
import ContactPopup from "../../Overlays/ContactPopup/ContactPopup";

import * as CSS from "./MapPopup_Style";

const MapPopup = (props) => {

    const { setPDPpopupObj, PDPpopupObj, openPDP, loginState, setOpenOTPLoginPopup, setMapPopupData } = props;
    const { project_data, type } = PDPpopupObj;

    // console.log('props coming..')
    // console.log(props)

    const [slides, setSlides] = useState([]);
    const [openContactPopup, setOpenContactPopup] = useState(false);

    useEffect(() => {
        let slideImage = [];
        if (project_data && project_data.gallery.length) {
            project_data.gallery.forEach((ele, index) => {
                if (ele.type === "image") {
                    slideImage.push({
                        name: "Image_" + index,
                        imgTag: <img src={ele.url} alt={index} />,
                    });
                }
            });
        }
        slideImage.length > 0 && setSlides(slideImage);
    }, [project_data]);

    const logo = project_data?.promotor_address?.logo || null;
    const company_promotors = project_data?.company_promotors || null;
    const name = project_data?.name || null;
    const fulladdress = project_data?.fulladdress || null;
    const total_plots = project_data?.total_plots || null;
    const total_area = project_data?.total_area || null;

    const dtcp_number = project_data?.general_information?.dtcp_number || null;
    const rera_no = project_data?.general_information?.rera_no || null;
    const rera = rera_no ? `RERA NO: ${rera_no}` : ``;
    const dtcp = dtcp_number ? `DTCP NO: ${dtcp_number}` : ``;

    const total_sold = project_data?.total_sold || null;
    const total_booked = project_data?.total_booked || null;
    const total_unsold = project_data?.total_unsold || null;
    let soldText = '';
    if(project_data?.status === 2) {
        soldText = `${`Total plots: ${total_plots ? total_plots : "NA"}`} ${ total_sold ? ` | Sold: ${total_sold}` : '' } ${ total_booked ? ` | Booked: ${total_booked}` : '' } ${ total_unsold ? ` | Available: ${total_unsold}` : '' }`;
    }
    const plp_price_cent = project_data?.plp_price_cent || 'NA';
    const plp_price_sq_ft = project_data?.plp_price_sq_ft || 'NA';
    const priceText = `Price starts from: ₹${Number(plp_price_cent).toFixed(0)}/cent | ${Number(plp_price_sq_ft).toFixed(0)}/sq.ft`;

    const amenities = project_data?.amenities && project_data?.amenities.length || '0';
    const amenitiesText = `No of Amenities: ${amenities}`;

    const closePopup = () => {
        setPDPpopupObj({ open: false })
    }

    const viewPdp = () => {
        closePopup();
        openPDP(project_data, type);
    }

    const getContactBtnClick = () => {
        const layout_name = project_data?.name || '';
        const promoters_name = project_data?.company_promotors || '';
        if(!loginState.isOtp) {
            setMapPopupData({ layout_name, promoters_name });
            setOpenOTPLoginPopup(true);
        } else {
            setOpenContactPopup(true);
        }
    }

    const dlBroucherBtnClick = () => {
        const layout_name = project_data?.name || '';
        const promoters_name = project_data?.company_promotors || '';
        if(!loginState.isOtp) {
            setMapPopupData({ layout_name, promoters_name });
            setOpenOTPLoginPopup(true);
        } else {
            const broucherUrl = project_data?.general_information?.brochure || null;
            if(broucherUrl) {
                window.open(broucherUrl);
            } else {
                alert("Broucher not available at the moment. Please try again later.")
            }
        }
    }

    return (
        <Grid
          container
          direction="column"
          gap="1em"
          justifyContent="center"
          alignItems="center"
          padding="5px"
        >
            <ContactPopup
                openFlag={openContactPopup && loginState.isOtp}
                setCloseFlag={setOpenContactPopup}
                contact={ project_data?.contact ? JSON.parse(project_data?.contact) : null }
            />
            <CSS.CloseBtn>
                <IconButton
                  aria-label="closePopup"
                  onClick={() => closePopup()}
                >
                  <HighlightOffIcon />
                </IconButton>
            </CSS.CloseBtn>
            {/* <img src={logo} width={'150px'} /> */}
            <CSS.NameAddressContainer>
                <CSS.Name>{name}</CSS.Name>
                <CSS.Address>By</CSS.Address>
                <CSS.Name>{company_promotors}</CSS.Name>
                <CSS.Address>{fulladdress}</CSS.Address>
            </CSS.NameAddressContainer>
            <CSS.PopupBtns>
                <Button size="small" className="pdp-tlt-button" variant="contained" onClick={getContactBtnClick}>Get Contact</Button>
                <Button size="small" className="pdp-tlt-button" variant="contained" onClick={dlBroucherBtnClick}>Download Brochure</Button>
            </CSS.PopupBtns>
            <CSS.CarouselContainer>
                {
                    slides.length && (
                        <Carousel
                            showArrows={true}
                            stopOnHover={false}
                            showThumbs={false}
                            autoPlay={true}
                            infiniteLoop={true}
                        >
                            {slides.map((ele) => {
                            return (
                                <div>
                                    <img src={ele.imgTag.props.src} height={ '100px' } />
                                </div>
                            );
                            })}
                        </Carousel>
                    )
                }
            </CSS.CarouselContainer>
            <CSS.InfoContainer>
                <CSS.Acres>{total_plots} plots in {total_area} acres</CSS.Acres>
                {/* <LocationOnIcon /> */}
                {/* <div>{noText}</div> */}
                <div>{soldText}</div>
                <div>{priceText}</div>
                <div>{rera}</div>
                <div>{dtcp}</div>
                <div>{amenitiesText}</div>
            </CSS.InfoContainer>
            <CSS.PopupBtns>
                <Button size="small" className="pdp-tlt-button" variant="contained" onClick={() => viewPdp()} >View Layout Detail Page</Button>
            </CSS.PopupBtns>
        </Grid>
    )
}

export default MapPopup;