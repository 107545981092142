import { GET_ALL_PLP, IS_LOADING } from "./actionType";

export const initialState = {
  list: [],
  total: 0,
  isFav: [],
  loading: false,
  promoterTotal: 0
};

const plpReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_ALL_PLP: {
      const newState = {
        ...state,
        list: data.projects,
        total: data.total,
        isFav: data.isFav,
        loading: false,
        promoterTotal: data.promoterTotal
      };
      return newState;
    }
    case IS_LOADING: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    default:
      return state;
  }
};

export default plpReducer;
