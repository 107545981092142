//Custom imports
import logo from "../../Assets/logo_with_text.png";
import * as CSS from "./Style.js";

const MPDPHeader = (props) => {
  return (
    <>
      <CSS.logoContainerV1 src={logo} alt="#" />
    </>
  );
};

export default MPDPHeader;
