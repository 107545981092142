import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import WestIcon from "@mui/icons-material/West";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//Custom imports
import * as CSS from "../style";
import { useSnackbar } from "../../../hooks/useSnackbar";
import Snackbar from "../../snackbar";
import XHR from "../../../util/pxhr";

const PromoterProfileStepFour = (props) => {
  const navigate = useNavigate();
  const { nextStep } = props;

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    user_id: "",
    general_information: {
      door_no: "",
      street: "",
      address1: "",
      address2: "",
      town: "",
      city: "",
      taluk: "",
      district: "",
      state: "",
      pincode: "",
      country: "",
      landmark: "",
      no_of_projects: "",
      about_company: "",
    },
    board_of_directors: [],
    team: [],
    feedback: [],
    gallery: [],
  });

  const [updateData, setUpdateData] = useState(null);

  const [openUpload, setOpenUpload] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [uploadObj, setUploadObj] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const [promotionData, setPromotionData] = useState([]);

  const handleUploadClose = () => {
    setOpenUpload(false);
    setUploadType(null);
    setUploadObj(null);
    setUploadStatus(null);
    setUploading(false);
  };

  const onFileChange = (event) => {
    setUploadStatus(null);
    setUploading(false);
    setUploadObj(event.target.files[0]);
  };

  const handleConfirmUpload = () => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append("file", uploadObj);
    formData.append("type", uploadType === "image" ? "I" : "S");
    XHR.post("ads/upload", formData, config).then(
      (res) => {
        setUploading(false);
        setUploadStatus(res.data || null);
      },
      (err) => {
        setUploading(false);
        console.log("err", err);
      }
    );
  };

  const handlePreview = () => {
    if (uploadStatus && uploadStatus.url) {
      window.open(uploadStatus.url, "_blank");
    }
  };

  const handleSave = () => {
    if (uploadType === "image" || uploadType === "youtube") {
      const dataCopy = JSON.parse(JSON.stringify(data));
      let assets = dataCopy.gallery || [];
      assets.push({ type: uploadType, url: uploadStatus.url });
      console.log("assets", assets);
      setData({
        ...data,
        gallery: assets,
      });
    } else if (
      uploadType === "boardPhoto" ||
      uploadType === "teamPhoto" ||
      uploadType === "feedbackPhoto"
    ) {
      setUpdateData({
        ...updateData,
        photo: uploadStatus.url,
      });
    } else if (uploadType === "logo") {
      setData({
        ...data,
        general_information: {
          ...data.general_information,
          logo: uploadStatus.url,
        },
      });
    } else {
      setData({
        ...data,
        general_information: {
          ...data.general_information,
          layout: uploadStatus.url,
        },
      });
    }

    handleUploadClose();
  };

  useEffect(() => {
    const promotor_user = localStorage.getItem("promotor_user") || null;
    if (promotor_user) {
      const userData = JSON.parse(promotor_user);
      setUserDetails(userData);
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      getAllPromotorsData(userDetails.id);
      getPromotorDetails(userDetails.id);
    }
  }, [userDetails]);

  const getPromotorDetails = (id) => {
    setIsLoading(true);
    const payload = { company_id: id };
    XHR.post("realestate/promoters/get", payload).then(
      (res) => {
        setIsLoading(false);
        setData((res.data && res.data.companies) || {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getAllPromotorsData = (id) => {
    setIsLoading(true);
    const payload = { promoters_id: id };
    XHR.post("realestate/promoters/getdata", payload).then(
      (res) => {
        setIsLoading(false);
        setPromotionData((res.data && res.data.list) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleAddMultipleData = () => {
    const payload = {
      ...updateData,
      promoters_id: userDetails.id,
      type: "team",
    };
    XHR.post("realestate/promoters/updatedata", payload).then(
      (res) => {
        console.log("res.status", res);
        if (res.data.status) {
          setIsLoading(false);
          setUpdateData(null);
          getAllPromotorsData(userDetails.id);
          openSnackBar("Added / Updated Successfully", "success");
        } else {
          openSnackBar("Already Exists", "error");
        }
      },
      (err) => {
        setIsLoading(false);
        alert("Something went wrong");
        console.log("err", err);
      }
    );
  };

  const onBackBtnClk = () => {
    nextStep(3);
  };

  const onNextBtnClk = () => {
    nextStep(5);
  };

  const maxNumberOfPhotsValidation = () => {
    const assets =
      data &&
      data.gallery &&
      data.gallery.filter((el) => el.type === 'image');
    return assets.length;
  }

  const handleRemoveGallery = (index, type) => {
    const assets =
      data && data.gallery && data.gallery.filter((el) => el.type === type);

    const allAssets =
      data && data.gallery && data.gallery.filter((el) => el.type !== type);

    assets.splice(index, 1);
    setData({
      ...data,
      gallery: allAssets.concat(assets),
    });
  };

  const renderPreviewGallery = (previewType) => {
    const assets =
      data &&
      data.gallery &&
      data.gallery.filter((el) => el.type === previewType);
    return (
      <>
        {assets && assets.length > 0 && (
          <CSS.PromoterDirCardContainer>
            <ImageList cols={6}>
              {(assets || []).map((el, i) => (
                <ImageListItem key={i}>
                  <img
                    src={`${el.url}`}
                    srcSet={`${el.url}`}
                    alt={el.url}
                    loading="lazy"
                    style={{ height: 100, cursor: "pointer" }}
                    onClick
                  />
                  <div className="photos-delete" loading="lazy">
                    <Tooltip
                      title="Delete"
                      onClick={() => handleRemoveGallery(i, previewType)}
                    >
                      <DeleteIcon />
                    </Tooltip>
                  </div>
                </ImageListItem>
              ))}
            </ImageList>
          </CSS.PromoterDirCardContainer>
        )}
      </>
    );
  };

  const updateProject = () => {
    setIsLoading(true);
    const payload = { ...data, company_id: userDetails.id };
    XHR.post("realestate/promoters/update", payload).then(
      (res) => {
        setIsLoading(false);
        getPromotorDetails(userDetails.id);
        openSnackBar("Updated Successfully", "success");
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Dialog
        open={openUpload}
        size="md"
        fullWidth
        onClose={handleUploadClose}
        aria-labelledby="form-dialog-title"
      >
        {/* <DialogTitle id="form-dialog-title">Upload</DialogTitle> */}
        <DialogContent>
          <Grid
            container
            direction="column"
            gap="1em"
            justifyContent="center"
            alignItems="center"
          >
            <div style={{ position: "absolute", top: "0px", right: "0px" }}>
              <IconButton aria-label="closePopup" onClick={handleUploadClose}>
                <HighlightOffIcon />
              </IconButton>
            </div>
            <div style={{ marginTop: "2%" }}></div>
          {/* <Grid container spacing={1}> */}
            {(uploadType === "image" ||
              uploadType === "logo" ||
              uploadType === "layout" ||
              uploadType === "boardPhoto" ||
              uploadType === "teamPhoto" ||
              uploadType === "feedbackPhoto") && (
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="image-button-file"
                  onChange={onFileChange}
                  type="file"
                />
                {!uploadObj ? (
                  <label htmlFor="image-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      className="login-button"
                    >
                      Choose Image
                    </Button>
                  </label>
                ) : (
                  <>
                    <Typography style={{ marginBottom: 10, textAlign: "center" }}>
                      {uploadObj.name || "Image"}
                    </Typography>
                    {!uploadStatus ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          onClick={handleConfirmUpload}
                          style={{ marginRight: 10 }}
                          className="login-button"
                        >
                          {uploading ? "Uploading" : "Upload Image"}
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            className="login-button"
                          >
                            Choose New Image
                          </Button>
                        </label>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePreview}
                          style={{ marginRight: 10 }}
                          component="span"
                          className="login-button"
                        >
                          Preview Image
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            style={{ marginRight: 10 }}
                            color="primary"
                            component="span"
                            className="login-button"
                          >
                            Choose New Image
                          </Button>
                        </label>
                        {uploadStatus && (
                          <label htmlFor="image-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              className="login-button"
                              onClick={handleSave}
                            >
                              Save
                            </Button>
                          </label>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleUploadClose} color="primary">
            Close
          </Button>
          {uploadStatus && (
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          )}
        </DialogActions> */}
      </Dialog>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">Add Photos</span>
                <div>
                  <span className="login-form-heading-step">(Step 4 of 6)</span>
                </div>
              </div>
              <div className="login-form-div">
                <div>
                  <span className="login-form-content-step">
                    <ol className='photos-instructions'>
                      <li>
                        You can add photos of your awards, achievements, office premises or also your happy customers.
                      </li>
                      <li>
                        Minimum 1 photo should be added.
                      </li>
                      <li>
                        Maximum 10 photos can be added.
                      </li>
                    </ol>
                  </span>
                </div>
              </div>
              <div className="login-form-div">
                {
                  maxNumberOfPhotsValidation() < 10 && <>
                      <Button
                        variant="outlined"
                        size="medium"
                        className="login-button"
                        startIcon={<CloudUploadIcon />}
                        onClick={() => {
                          setUploadType("image");
                          setOpenUpload(true);
                        }}
                      >
                        Upload Photo
                      </Button>
                      <div>
                        <span className="login-form-div-helper-text">
                          (PNG or JPG file less than 2MB)
                        </span>
                      </div>
                    </>
                }
              </div>
              <div className="profile-back-next-btn-cntr">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onBackBtnClk}
                  className="login-button"
                  startIcon={<WestIcon />}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  className="login-button"
                  onClick={() => updateProject()}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onNextBtnClk}
                  className="login-button"
                  endIcon={<EastIcon />}
                >
                  Next
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="2em 0 2em 0"
          padding="0 4em 0 4em"
        >
          {renderPreviewGallery("image")}
        </Grid>
      </Grid>
    </>
  );
};

export default PromoterProfileStepFour;
