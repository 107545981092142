/* eslint-disable react/jsx-pascal-case */
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
//Material UI imports
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//Custom imports
import * as CSS from "../Style.js";
import moment from "moment";

import { baseUrl } from "../../../util/config";

const SiteVisitPopup = (props) => {
  const navigate = useNavigate();
  const {
    openFlag,
    setCloseFlag,
    onSubmit,
    isSiteVisitDone,
    setIsSiteVisitDone,
  } = props;
  const [siteVisitPopupData, setSiteVisitPopupData] = useState({
    name: "",
    date: new Date(),
  });

  const siteVisitModalInputChange = (e) => {
    let modifiedKey = "";
    let modifiedValue = "";
    if (e.$d) {
      modifiedKey = "date";
      modifiedValue = e.format("YYYY-MM-DD");
    } else {
      modifiedKey = e.target.name;
      modifiedValue = e.target.value;
    }
    const updatedData = { ...siteVisitPopupData };
    updatedData[modifiedKey] = modifiedValue;
    setSiteVisitPopupData(updatedData);
  };

  const siteVisitModalSubmit = () => {
    onSubmit(siteVisitPopupData);
  };

  const siteDateDisplay =
    siteVisitPopupData &&
    siteVisitPopupData.date &&
    moment(new Date(siteVisitPopupData.date)).format("DD/MM/YYYY");

  return (
    <CSS.CustomizedDialog
      open={openFlag}
      onClose={() => setCloseFlag(false)}
      maxWidth={"lg"}
    >
      {isSiteVisitDone && isSiteVisitDone.status !== "S" ? (
        <Grid
          container
          direction="column"
          gap="1em"
          justifyContent="center"
          alignItems="center"
        >
          {isSiteVisitDone && isSiteVisitDone.status === "F" && (
            <>
              <CSS.errorAlert isMobile={isMobile}>
                {`You have already registered a site visit on ${
                  isSiteVisitDone.data && isSiteVisitDone.data.date
                }.`}
              </CSS.errorAlert>
              <a href={`${baseUrl}/sitevisit`}>Click here to modify date.</a>
            </>
          )}
          <CSS.CloseBtn>
            <IconButton
              aria-label="closePopup"
              onClick={() => setCloseFlag(false)}
            >
              <HighlightOffIcon />
            </IconButton>
          </CSS.CloseBtn>
          <CSS.TitleContainer>
            <span className="title">Schedule Site Visit</span>
          </CSS.TitleContainer>
          <TextField
            name={"name"}
            onChange={siteVisitModalInputChange}
            value={siteVisitPopupData.name}
            label="Your Name"
            fullWidth={true}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Site Visit Date"
              minDate={new Date()}
              maxDate={new Date().setMonth(new Date().getMonth() + 1)}
              value={siteVisitPopupData.date}
              onChange={(newValue) => siteVisitModalInputChange(newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth={true} />
              )}
            />
          </LocalizationProvider>
          <CSS.customBtn variant="outlined" onClick={siteVisitModalSubmit}>
            Submit
          </CSS.customBtn>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          gap="1em"
          justifyContent="center"
          alignItems="center"
        >
          <CSS.CloseBtn>
            <IconButton
              aria-label="closePopup"
              onClick={() => {
                setIsSiteVisitDone({
                  ...isSiteVisitDone,
                  status: "",
                  data: {},
                });
                setCloseFlag(false);
              }}
            >
              <HighlightOffIcon />
            </IconButton>
          </CSS.CloseBtn>
          <CSS.TitleContainer>
            <span className="site-visit-success">
              {`Hi ${siteVisitPopupData.name},`}
            </span>
            <span className="site-visit-success">
              {`Your site visit request for the ${siteDateDisplay} has been submitted successfully.`}
            </span>
            <span className="site-visit-success">
              {`You'll get the call from Sales team soon.`}
            </span>
            <span className="site-visit-success">
              {`Thank you for using `}
              <a href={`${baseUrl}/`}>findmyplots.com</a>
            </span>
            <CSS.submitBtn
              style={{ marginTop: 20, width: 140 }}
              onClick={() => {
                navigate("/sitevisit");
              }}
            >
              View Site Visit
            </CSS.submitBtn>
          </CSS.TitleContainer>
        </Grid>
      )}
    </CSS.CustomizedDialog>
  );
};

export default SiteVisitPopup;
