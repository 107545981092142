import * as React from "react";
import DrawMapsRadius from "../../Maps/DrawMapsRadius";
import MapPopup from './MapPopup';
import MapSortPopup from "./MapSortPopup";
import { getTouchDevice } from "../../../util/config";
import { isMobile } from "react-device-detect";
//CSS imports
import "../../Maps/Map.css";
import "./GoogleSearch.css";

const API_KEY = "AIzaSyBtRJ59UWCyTVENScWckaaqJas16Ymuuic";

const GoogleSearch = (props) => {
  const { data, searchLocations, loginState, setOpenOTPLoginPopup, setMapPopupData, setOpenGoogleLoginPopup } = props;

  const [zoom, setZoom] = React.useState(11);
  const [PDPpopupObj, setPDPpopupObj] = React.useState({ open: false });
  const [defaultSortArr, setDefaultSortArr] = React.useState([]);
  const [gpsLocation, setGpsLocation] = React.useState({ lat: 13.086289405322379, lng: 80.29239116173903 });
  const [infoWindowTrigger, setInfoWindowTrigger] = React.useState(null);

  React.useEffect(() => {
    if(isMobile) {
      if(PDPpopupObj.open) {
        document.body.style.overflowY = 'hidden';
      } else {
          document.body.style.overflowY = 'scroll';
      }
    }
  }, [PDPpopupObj]);

  React.useEffect(() => {
    const updatedDefaultSortArr = searchLocations.map((el) => {
      const plp_price_sq_ft = parseInt(el?.project_data?.plp_price_sq_ft);
      const plp_price_cent = parseInt(el?.project_data?.plp_price_cent);
      const gps_lat = parseFloat(el?.project_data?.gps_lat);
      const gps_long = parseFloat(el?.project_data?.gps_long);
      const newObj = {
        plp_price_sq_ft,
        plp_price_cent,
        name: el?.project_data?.name,
        plp_address: el?.project_data?.plp_address,
        zipcode: el?.project_data?.zipcode,
        company_promotors: el?.project_data?.company_promotors,
        gps_lat,
        gps_long,
        is_fav: el?.project_data?.is_fav,
        id: el?.project_data?.id
      };
      return newObj;
    });
    setDefaultSortArr(updatedDefaultSortArr);
  }, [searchLocations]);
  
  React.useEffect(() => {
    if (data) {
      setGpsLocation({ lat: data.lat, lng: data.lng });
    }
  }, [data]);
  
  const isMobileView = getTouchDevice();

  const onZoomChanged = (zoom) => {
    setZoom(zoom);
  };

  const Map = (props) => (
    <DrawMapsRadius
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `530px` }} />}
      mapElement={<div style={{ height: `100%`, borderRadius: 25 }} />}
      gps_location={props.gps_location}
      onClick={props.onMapClick}
      isProject={props.isProject}
      zoom_level={props.zoom_level}
      onZoomChanged={props.onZoomChanged}
      drawingControlEnabled={props.drawingControlEnabled}
      overlay={props.overlay}
      polyline={props.polyline}
      show_boundary={props.show_boundary}
      boundary_color={props.boundary_color}
      circle={props.circle}
      searchLocations={props.searchLocations}
      projectClick={props.onProjectClick}
      PDPpopupObj={PDPpopupObj}
      isMobile={isMobileView}
      infoWindowTrigger={infoWindowTrigger}
    />
  );

  const PDPpopup = (project_data, type) => {
    setPDPpopupObj({ open: true, project_data, type });
  }

  const openPDP = (project_data, type) => {
    let path = `pdp/residential_plots_${
      project_data.company_state != ""
        ? project_data.company_state.replace(/\s+/g, "").toLowerCase()
        : "state"
    }/residential_plots_${
      project_data.company_district != ""
        ? project_data.company_district.replace(/\s+/g, "").toLowerCase()
        : "district"
    }/${
      project_data.company_promotors != ""
        ? project_data.company_promotors.replace(/\s+/g, "").toLowerCase()
        : "promotors "
    }/${project_data.name.replace(/\s+/g, "").toLowerCase()}/${
      project_data.hash_key
    }`;
    if (project_data.company_hash != "" && type === "C") {
      window.open(`${window.location.origin}/${path}`, "_blank");
    }
  };

  const onMapClick = (e) => {
    props.setLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  // console.log("data", data);

  return (
    <div>
      {
        PDPpopupObj.open && (
          <div className={isMobile ? 'map-pdp-popup-mobile-container' : 'map-pdp-popup-container'}>
            <div className={isMobile ? 'map-pdp-popup-mobile' : 'map-pdp-popup'}>
              <MapPopup 
                setPDPpopupObj={setPDPpopupObj} 
                PDPpopupObj={PDPpopupObj} 
                openPDP={openPDP} 
                loginState={loginState}
                setOpenOTPLoginPopup={setOpenOTPLoginPopup}
                setMapPopupData={setMapPopupData}
              />
            </div>
          </div>
        )
      }
      {
        PDPpopupObj.open === false && !isMobile && (
          <div className={isMobile ? '' : 'map-sort-button'}>
            <MapSortPopup 
              defaultSortArr={defaultSortArr}
              setZoom={setZoom}
              setGpsLocation={setGpsLocation}
              gpsLocation={gpsLocation}
              setInfoWindowTrigger={setInfoWindowTrigger}
              infoWindowTrigger={infoWindowTrigger}
              setOpenGoogleLoginPopup={setOpenGoogleLoginPopup}
            />
          </div>
        )
      }
      {data && data.lat && data.lng && (
        <Map
          gps_location={gpsLocation}
          isProject={true}
          circle={{
            position: (data && { lat: data.lat, lng: data.lng }) || {
              lat: 13.086289405322379,
              lng: 80.29239116173903,
            },
            radius: (data && data.radius * 1000) || 3000,
            options: {
              fillColor: "#653f00",
              strokeColor: "#653f00",
              strokeOpacity: 1,
              strokeWeight: 1,
              fillOpacity: 0.1,
              clickable: false,
              draggable: false,
              editable: false,
              visible: true,
              zIndex: 1,
            },
          }}
          onMapClick={onMapClick}
          onZoomChanged={onZoomChanged}
          zoom_level={zoom || 10}
          drawingControlEnabled={false}
          polyline={(data && data.polyline) || []}
          show_boundary={data && data.show_boundary}
          boundary_color={data && data.boundary_color}
          searchLocations={searchLocations || []}
          onProjectClick={PDPpopup}
        />
      )}
    </div>
  );
};

export default GoogleSearch;
