import * as React from "react";
import { connect } from "react-redux";

import "./LuckyDraw.css";

const LuckyDraw = (props) => {
  return (
    <div class="main-area center-text">
      <div class="display-table">
        <div class="display-table-cell">
          <h1 class="title font-white">
            <b>Comming Soon</b>
          </h1>
          <p class="desc font-white">Page is currently under construction.</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(LuckyDraw);
