import { GET_ALL_AMENITIES } from "./actionType";
import XHR from "../../util/xhr";

export function getAllAmenities(inputs = {}) {
  return function (dispatch) {
    return XHR.post("common/amenities", inputs).then(
      (res) => {
        dispatch({
          type: GET_ALL_AMENITIES,
          data: res.data.list,
        });
        return Promise.resolve({ status: true, msg: "Success" });
      },
      (err) => {
        return Promise.resolve({
          status: false,
          msg: "Unable to Connect server...",
        });
      }
    );
  };
}
