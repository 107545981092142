import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import WestIcon from "@mui/icons-material/West";
import Avatar from "@mui/material/Avatar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import makeStyles from "@mui/styles/makeStyles";

//Custom imports
import * as CSS from "../style";
import helperImg from "../../../Assets/youtube_upload_help.png";
import { useSnackbar } from "../../../hooks/useSnackbar";
import Snackbar from "../../snackbar";
import XHR from "../../../util/pxhr";

let publicUrl = process.env.PUBLIC_URL + "/";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const AddLayoutStepOne = (props) => {
  const { nextStep, id = null } = props;
  const classes = useStyles();
  const [submitClicked, setSubmitClicked] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const onBackBtnClk = () => {
    nextStep(4);
  };

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const onNextBtnClk = () => {
    nextStep(6);
  };

  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  const [allAmenities, setAllAmenties] = useState([]);
  const [errors, setErrors] = useState({});

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "This field is required.";
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    if ("mobile" in fieldValues) {
      temp.mobile = fieldValues.mobile ? "" : "This field is required.";
      if (fieldValues.mobile)
        temp.mobile =
          fieldValues.mobile.length === 10 ? "" : "Mobile no is not valid.";
    }

    setErrors({
      ...temp,
    });
  };

  useEffect(() => {
    if (id) {
      getProjectDetails(id);
    }
  }, [id]);

  const getProjectDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/get", payload).then(
      (res) => {
        setIsLoading(false);
        setData((res.data && res.data.data) || {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleRemoveContact = (index) => {
    let contactCopy = data.contact
      ? JSON.parse(JSON.stringify(data.contact))
      : [];
    if (index > -1) {
      contactCopy.splice(index, 1);
    }
    setData({
      ...data,
      contact: contactCopy,
    });
  };

  const updateProjectData = () => {
    setIsLoading(true);
    const payload1 = JSON.parse(JSON.stringify({ ...data }));
    // console.log("payload1", payload1);
    XHR.post("realestate/updateinfo", payload1).then(
      (res) => {
        setIsLoading(false);
        openSnackBar("Success", "success");
        getProjectDetails(id);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleAddMultipleData = () => {
    if (
      !updateData ||
      !updateData.name ||
      !updateData.mobile ||
      !updateData.email
    ) {
      openSnackBar("Please fill the data", "error");
      return;
    } else {
      // Validate the email and mobile no

      let contactCopy = data.contact
        ? JSON.parse(JSON.stringify(data.contact))
        : [];
      let mobileExists = null;
      let emailExists = null;
      if (updateData.hasOwnProperty("id")) {
        mobileExists = contactCopy.find(
          (el) => el.mobile === updateData.mobile && el.id !== updateData.id
        );
        emailExists = contactCopy.find(
          (el) => el.email === updateData.email && el.id !== updateData.id
        );
      } else {
        mobileExists = contactCopy.find(
          (el) => el.mobile === updateData.mobile
        );
        emailExists = contactCopy.find((el) => el.email === updateData.email);
      }

      if (mobileExists || emailExists) {
        if (mobileExists) {
          openSnackBar("Mobile Already Exists", "error");
          return;
        }
        if (emailExists) {
          openSnackBar("Email Already Exists", "error");
          return;
        }
      }

      if (!updateData.hasOwnProperty("id")) {
        let contactCopy = data.contact
          ? JSON.parse(JSON.stringify(data.contact))
          : [];
        contactCopy.push(updateData);
        setData({
          ...data,
          contact: contactCopy,
        });
        setUpdateData(null);
        // updateProjectData();
        openSnackBar("Added successfully", "success");
      } else {
        let contactCopy = data.contact
          ? JSON.parse(JSON.stringify(data.contact))
          : [];
        contactCopy[updateData.id] = updateData;
        setData({
          ...data,
          contact: contactCopy,
        });
        // updateProjectData();
        setUpdateData(null);
        openSnackBar("Updated successfully", "success");
      }
    }
  };

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={6} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              padding="25px"
            >
              <div className="login-form-div">
                <span className="login-form-heading">
                  Add Contacts for Layout {data && data.name}
                </span>
                <div>
                  <span className="login-form-heading-step">(Step 5 of 7)</span>
                </div>
              </div>
              <div className="login-form-div">
                <TextField
                  label="Name"
                  variant="outlined"
                  required
                  className="login-inputWidth"
                  size="small"
                  value={(updateData && updateData.name) || ""}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      name: e.target.value,
                    });
                    validate({ ["name"]: e.target.value });
                  }}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="Phone"
                  variant="outlined"
                  required
                  size="small"
                  value={(updateData && updateData.mobile) || ""}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      mobile: e.target.value,
                    });
                    validate({ ["mobile"]: e.target.value });
                  }}
                  {...(errors["mobile"] && {
                    error: true,
                    helperText: errors["mobile"],
                  })}
                  type="number"
                  className={`${classes.input} login-inputWidth`}
                />
              </div>
              <div className="login-form-div">
                <TextField
                  label="E-Mail"
                  variant="outlined"
                  required
                  className="login-inputWidth"
                  size="small"
                  value={(updateData && updateData.email) || ""}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      email: e.target.value,
                    });
                    validate({ ["email"]: e.target.value });
                  }}
                  {...(errors["email"] && {
                    error: true,
                    helperText: errors["email"],
                  })}
                />
              </div>
              <div className="profile-back-next-btn-cntr">
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onBackBtnClk}
                  className="login-button"
                  startIcon={<WestIcon />}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  className="login-button"
                  onClick={() => {
                    handleAddMultipleData();
                  }}
                >
                  Add / Update Contact
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => updateProjectData()}
                  className="login-button"
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={onNextBtnClk}
                  className="login-button"
                  endIcon={<EastIcon />}
                >
                  Next
                </Button>
              </div>
            </Box>
          </Paper>
        </Grid>
        <Grid
          container
          gap="3em"
          justifyContent="center"
          alignItems="center"
          direction="row"
          margin="2em 0 2em 0"
          padding="0 4em 0 4em"
        >
          {data &&
            data.contact &&
            data.contact.length > 0 &&
            data.contact.map((row, i) => (
              <CSS.PromoterDirCardContainer>
                <Avatar
                  alt={row.name}
                  sx={{ width: 100, height: 100 }}
                  src={row.photo}
                />
                <div className="pdp-contact-card-nme">{row.name}</div>
                <div className="pdp-contact-card-contacts">
                  <MailOutlineIcon />
                  {row.email}
                </div>
                <div className="pdp-contact-card-contacts">
                  <LocalPhoneIcon />
                  {row.mobile}
                </div>
                <div className="pdp-contact-card-contacts">
                  <IconButton
                    size="large"
                    className="login-button"
                    onClick={() => handleRemoveContact(i)}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    size="large"
                    className="login-button"
                    onClick={() => {
                      setUpdateData({ ...row, id: i });
                    }}
                  >
                    <BorderColorIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </CSS.PromoterDirCardContainer>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

export default AddLayoutStepOne;
