/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import axios from "axios";
import Select from "react-select";
import Grid from "@mui/material/Grid";
import DataGrid from "../DataGrid";

import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import PLPHeader from "../HeaderMenu/PLPHeader";

import { baseUrl } from "../../util/config";

// import "./recommendation-add.css";

const directionOption = [
  { value: 1, label: "East" },
  { value: 2, label: "West" },
  { value: 3, label: "North" },
  { value: 4, label: "South" },
  { value: 5, label: "North East" },
  { value: 6, label: "North West" },
  { value: 7, label: "South East" },
  { value: 8, label: "South West" },
];

const Recommendations = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [recommendationsDetails, setRecommendationsDetails] = useState(null);
  const [recommendationsList, setRecommendationsList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [stateDetails, setStateDetails] = useState([]);
  const [districtDetails, setDistrictDetails] = useState([]);

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const [viewType, setViewType] = useState("details");
  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  const [open, setOpen] = React.useState(false);
  const [toolTipContent, setToolTipContent] = React.useState(null);

  useEffect(() => {
    const user = localStorage.getItem("user") || null;
    if (user) {
      const userData = JSON.parse(user);
      setUserDetails(userData);
      getStateDetails();
    }
  }, []);

  const handleChange = (key, value) => {
    setRecommendationsDetails({
      ...recommendationsDetails,
      [key]: value,
    });
  };

  const getRecommendationsList = (user_id = "") => {
    setIsLoading(true);
    const payload = {
      user_id,
    };
    axios
      .post(
        `${baseUrl}/plot_api/realestate/recommendations/details`,
        payload
      )
      .then(
        (res) => {
          setIsLoading(false);
          if (res.data && res.data.status) {
            setRows((res.data && res.data.data) || []);
            setTotalRecords((res.data && res.data.data.length) || 0);
          }
        },
        (err) => {
          setIsLoading(false);
          setRows([]);
        }
      );
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const payload = {
      user_id: userDetails.googleId || "",
      details: recommendationsDetails,
    };
    axios
      .post(
        `${baseUrl}/plot_api/realestate/recommendations/update`,
        payload
      )
      .then(
        (res) => {
          if (res.data && res.data.status) {
            setIsLoading(false);
            setShowSuccess(true);
            setViewType("listdetails");
            openSnackBar("Updated Successfully", "success");
          }
        },
        (err) => {
          openSnackBar("Update Failed", "error");
          setIsLoading(false);
        }
      );
  };

  const getStateDetails = () => {
    const payload = {};
    axios.post(`${baseUrl}/plot_api/common/state`, payload).then(
      (res) => {
        if (res.data && res.data.state) {
          setStateDetails(res.data.state || []);
        }
      },
      (err) => {
        setStateDetails([]);
      }
    );
  };

  const getDistrictDetails = (state_id = "") => {
    const payload = {
      state_id,
    };
    axios
      .post(`${baseUrl}/plot_api/common/district`, payload)
      .then(
        (res) => {
          if (res.data && res.data.district) {
            setDistrictDetails(res.data.district || []);
          }
        },
        (err) => {
          setDistrictDetails([]);
        }
      );
  };

  const handleStateChange = (selectedOption) => {
    if (selectedOption.state_id) {
      getDistrictDetails(selectedOption.state_id);
      handleChange("state", selectedOption);
    }
  };

  const handleDistrictChange = (selectedOption) => {
    if (selectedOption.districtid) {
      handleChange("district", selectedOption);
    }
  };

  const handlePreview = () => {
    if (
      !recommendationsDetails ||
      !recommendationsDetails.name ||
      !recommendationsDetails.price_from ||
      !recommendationsDetails.price_to ||
      !recommendationsDetails.sfeet_from ||
      !recommendationsDetails.sfeet_to ||
      !recommendationsDetails.district ||
      !recommendationsDetails.state ||
      !recommendationsDetails.direction
    ) {
      setShowError(true);
      return;
    }
    handleSubmit();
  };

  const columns = [
    { name: "sno", title: "S.No" },
    { name: "name", title: "Name" },
    { name: "price_from", title: "Price From" },
    { name: "price_to", title: "Price To" },
    { name: "sfeet_from", title: "SFeet From" },
    { name: "sfeet_to", title: "SFeet To" },
    { name: "state", title: "State" },
    { name: "district", title: "District" },
    { name: "direction", title: "Direction" },
    { name: "action", title: "Action" },
  ];

  const tableColumnExtensions = [
    { columnName: "sno", width: 100, wordWrapEnabled: true },
    { columnName: "name", wordWrapEnabled: true },
    { columnName: "direction", wordWrapEnabled: true },
    { columnName: "action", align: "center" },
  ];

  const defaultHiddenColumnNames = [];

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "action", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "action", sortingEnabled: false },
  ];

  const loadGrid = () => {
    const token = localStorage.getItem("user") || null;
    let userDetails;
    if (token) {
      userDetails = JSON.parse(token);
    }
    getRecommendationsList(
      userDetails && userDetails.googleId ? userDetails.googleId : ""
    );
  };

  const actionClick = (type, data) => {
    if (type === "VIEW") {
      handleRoute("/recommendationslist", {
        details: { ...data.details, ...data },
      });
      setToolTipContent((data && data) || []);
      setOpen(true);
    } else if (type === "EDIT") {
      setRecommendationsDetails({ ...data.details, id: data.id });
      setViewType("addDetails");
    } else {
      handleDeleteClick(data);
    }
  };

  const handleDeleteClick = (el) => {
    setRecommendationsDetails(el);
    setIsDelete(true);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setIsLoading(true);
    const payload = { id: recommendationsDetails.id };
    axios
      .post(
        `${baseUrl}/plot_api/realestate/recommendations/delete`,
        payload
      )
      .then(
        (res) => {
          setIsLoading(false);
          if (res.data && res.data.status) {
            openSnackBar("Deleted Successfully", "success");
            getRecommendationsList(userDetails.googleId || "");
          }
        },
        (err) => {
          setIsLoading(false);
        }
      );
  };

  const handleRoute = (path, props) => {
    navigate(path, {
      state: props,
    });
  };

  return (
    <div className="ltn__appointment-area pb-120">
      <Grid
        item
        lg={10}
        md={10}
        sm={11}
        xs={11}
        style={{ textAlign: "center" }}
      >
        <Grid
          container
          gap="1em"
          justifyContent="center"
          alignItems="center"
          direction="column"
          margin="2em 0 2em 0;"
        >
          <PLPHeader />
        </Grid>
      </Grid>

      <div className="container">
        {viewType === "addDetails" ? (
          <div className="ltn__appointment-inner">
            <Grid item xs={12} md={12} lg={12}>
              <div className="btn-wrapper" style={{ textAlign: "right" }}>
                <button
                  className="btn theme-btn-1 btn-effect-1 text-uppercase addbtn"
                  type="submit"
                  onClick={() => setViewType("listDetails")}
                >
                  Back
                </button>
              </div>
            </Grid>
            <h2>Create/update your Search to get an automated alert</h2>
            <p>
              <small>(*) These fields are mandatory</small>
            </p>
            <div className="row">
              <div className="col-md-6">
                <h6>Search Name *</h6>
                <div className="input-item input-item-name">
                  <input
                    value={
                      (recommendationsDetails && recommendationsDetails.name) ||
                      ""
                    }
                    onChange={(e) => handleChange("name", e.target.value)}
                    type="text"
                    name="name"
                    className={
                      showError &&
                      recommendationsDetails &&
                      !recommendationsDetails.name
                        ? "error"
                        : ""
                    }
                    placeholder="Search Name"
                  />
                </div>
              </div>
            </div>
            <h3>Price range</h3>
            <div className="row">
              <div className="col-md-6">
                <h6>From *</h6>
                <div className="input-item input-item-name">
                  <input
                    value={
                      (recommendationsDetails &&
                        recommendationsDetails.price_from) ||
                      ""
                    }
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                        return;
                      }
                      handleChange("price_from", e.target.value);
                    }}
                    type="text"
                    name="price_from"
                    className={
                      showError &&
                      recommendationsDetails &&
                      !recommendationsDetails.price_from
                        ? "error"
                        : ""
                    }
                    placeholder="From"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h6>To *</h6>
                <div className="input-item input-item-name">
                  <input
                    value={
                      (recommendationsDetails &&
                        recommendationsDetails.price_to) ||
                      ""
                    }
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                        return;
                      }
                      handleChange("price_to", e.target.value);
                    }}
                    type="text"
                    name="price_to"
                    placeholder="To"
                    className={
                      showError &&
                      recommendationsDetails &&
                      !recommendationsDetails.price_to
                        ? "error"
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <h3>Square Feet</h3>
            <div className="row">
              <div className="col-md-6">
                <h6>From *</h6>
                <div className="input-item input-item-name">
                  <input
                    value={
                      (recommendationsDetails &&
                        recommendationsDetails.sfeet_from) ||
                      ""
                    }
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                        return;
                      }
                      handleChange("sfeet_from", e.target.value);
                    }}
                    type="text"
                    name="sfeet_from"
                    className={
                      showError &&
                      recommendationsDetails &&
                      !recommendationsDetails.sfeet_from
                        ? "error"
                        : ""
                    }
                    placeholder="From"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h6>To *</h6>
                <div className="input-item input-item-name">
                  <input
                    value={
                      (recommendationsDetails &&
                        recommendationsDetails.sfeet_to) ||
                      ""
                    }
                    onChange={(e) => {
                      if (isNaN(e.target.value)) {
                        return;
                      }
                      handleChange("sfeet_to", e.target.value);
                    }}
                    type="text"
                    name="sfeet_to"
                    placeholder="To"
                    className={
                      showError &&
                      recommendationsDetails &&
                      !recommendationsDetails.sfeet_to
                        ? "error"
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <h3>Direction Facing *</h3>
            <div className="row">
              <div className="col-md-6" style={{ marginBottom: 20 }}>
                <Select
                  options={directionOption}
                  value={
                    (recommendationsDetails &&
                      recommendationsDetails.direction) ||
                    []
                  }
                  isMulti
                  onChange={(e) => handleChange("direction", e)}
                  placeholder="Select Direction"
                  className={
                    showError &&
                    recommendationsDetails &&
                    !recommendationsDetails.direction
                      ? "error"
                      : ""
                  }
                />
              </div>
            </div>
            <h3>Location</h3>
            <div className="row">
              <div className="col-md-6" style={{ marginBottom: 20 }}>
                <h6>State *</h6>
                <Select
                  options={stateDetails}
                  getOptionLabel={(option) => option.state_title}
                  getOptionValue={(option) => option.state_id}
                  onChange={handleStateChange}
                  value={
                    (recommendationsDetails && recommendationsDetails.state) ||
                    ""
                  }
                  className={
                    showError &&
                    recommendationsDetails &&
                    !recommendationsDetails.state
                      ? "error"
                      : ""
                  }
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
              </div>
              <div className="col-md-6" style={{ marginBottom: 20 }}>
                <h6>District *</h6>
                <Select
                  options={districtDetails}
                  getOptionLabel={(option) => option.district_title}
                  getOptionValue={(option) => option.districtid}
                  onChange={handleDistrictChange}
                  value={
                    (recommendationsDetails &&
                      recommendationsDetails.district) ||
                    ""
                  }
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  className={
                    showError &&
                    recommendationsDetails &&
                    !recommendationsDetails.district
                      ? "error"
                      : ""
                  }
                />
              </div>
            </div>
            <div className="btn-wrapper text-center mt-30">
              <button
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                type="submit"
                disabled={!recommendationsDetails}
                onClick={() => (!isLoading ? handlePreview() : {})}
              >
                {isLoading ? "Loading..." : "Save"}
              </button>
            </div>
          </div>
        ) : (
          <div className="ltn__appointment-inner">
            <Grid item xs={12} md={12} lg={12}>
              <div className="btn-wrapper" style={{ textAlign: "right" }}>
                <button
                  className="btn theme-btn-1 btn-effect-1 text-uppercase addbtn"
                  type="submit"
                  onClick={() => {
                    setViewType("addDetails");
                    setRecommendationsDetails(null);
                  }}
                >
                  Create Search
                </button>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <DataGrid
                rows={rows}
                columns={columns}
                tableColumnExtensions={tableColumnExtensions}
                defaultHiddenColumnNames={defaultHiddenColumnNames}
                filteringStateColumnExtensions={filteringStateColumnExtensions}
                sortingStateColumnExtensions={sortingStateColumnExtensions}
                loadGrid={loadGrid}
                totalRecords={totalRecords}
                action={["VIEW", "EDIT", "DELETE"]}
                actionClick={actionClick}
              />
            </Grid>
          </div>
        )}
      </div>
      {/* {isLoading ? (
        <div
          className="cv-spinner"
          style={{ position: "fixed", left: "50%", top: "0%" }}
        >
          <span className="spinner"></span>
        </div>
      ) : null} */}
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
      <Dialog
        open={isDelete}
        size="md"
        fullWidth
        onClose={() => {
          setRecommendationsDetails(null);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
        <DialogContent>Delete this item?</DialogContent>
        <DialogActions>
          <button
            className="btn theme-btn-1 btn-effect-1 text-uppercase"
            type="submit"
            style={{ padding: 10 }}
            onClick={() => setIsDelete(false)}
          >
            Cancel
          </button>
          <button
            className="btn theme-btn-1 btn-effect-1 text-uppercase"
            type="submit"
            style={{ padding: 10 }}
            onClick={() => handleConfirmDelete()}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Recommendations;
