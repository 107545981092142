import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
//Other lib imports
import axios from "axios";
//Custom imports
import { baseUrl } from "../../../util/config";

const AddPropertyStepEleven = (props) => {

  const navigate = useNavigate();
  const { nextStep, propData, inputChange, setPropData, centCalculator, setReusableDialogObj } = props;
  const [isLoading, setIsLoading] = useState(false);
 
  const closeReusableDialog = (arg) => {
    setReusableDialogObj({
      open: false
    });
    if(arg) {
      navigate(arg);
    }
  }

  const closeDialogAndNavigateToOpt = (arg) => {
    setReusableDialogObj({
      open: false
    });
    if(arg) {
      navigate("/addPropertyOptional", arg);
    }
  }

  const onBackBtnClk = () => {
    nextStep(10);
  };

  const submitConf = (arg) => {
    if(arg) {
      const action = propData?.id ? 2 : 1;
      const payload = { ...propData, action };
      setIsLoading(true);
      axios.post(`${baseUrl}/plot_api/realestate_v1/property/update`, payload).then(
        (res) => {
          if(res?.data?.id && res?.data?.status) {
            setIsLoading(false);
            setPropData({ ...propData, id: res?.data?.id });
            if(action === 1) {
              setReusableDialogObj({
                open: true,
                title: '',
                content: 'Your Property has been submitted successfully. Do you want to proceed further to Add photos?',
                btnArr: [
                  {
                    label: 'Yes',
                    fun: closeDialogAndNavigateToOpt,
                    param: { state: { id: res?.data?.id } }
                  },
                  {
                    label: 'No',
                    fun: closeReusableDialog,
                    param: '/property'
                  }
                ]
              });
            } else {
              setReusableDialogObj({
                open: true,
                title: '',
                content: 'Property details updated successfully.',
                btnArr: [
                  {
                    label: 'OK',
                    fun: closeReusableDialog,
                    param: '/property'
                  }
                ]
              });
            }
          } else {
            setIsLoading(false);
            setReusableDialogObj({
              open: true,
              title: '',
              content: 'something went wrong.. Please try again later..',
              btnArr: [
                {
                  label: 'OK',
                  fun: closeReusableDialog,
                  param: '/'
                }
              ]
            });
          }
        },
        (err) => {
          setIsLoading(false);
          setReusableDialogObj({
            open: true,
            title: '',
            content: 'something went wrong.. Please try again later..',
            btnArr: [
              {
                label: 'OK',
                fun: closeReusableDialog,
                param: '/'
              }
            ]
          });
          console.log('err came..');
          console.log(err);
        }
      );
    } else {
      setReusableDialogObj({
        open: false
      });
    }
  }

  const onNextBtnClk = () => {
    if(propData.expectedPrice) {
      setReusableDialogObj({
        open: true,
        title: 'Are you sure?',
        content: 'Please confirm to submit your property',
        btnArr: [
          {
            label: 'Yes',
            fun: submitConf,
            param: true
          },
          {
            label: 'No',
            fun: submitConf,
            param: false
          }
        ]
      });
    } else {
      setReusableDialogObj({
        open: true,
        title: '',
        content: 'Please enter all mandatory inputs',
        btnArr: [
          {
            label: 'OK',
            fun: closeReusableDialog
          }
        ]
      });
    }
  };

  const pricePerSqftCalculator = () => {
    const calculatedVal = parseInt(propData.expectedPrice) / parseInt(propData.plotArea);
    if(calculatedVal) {
      return calculatedVal.toFixed(2);
    }
    return 0;
  }

  const pricePerCentCalculator = () => {
    const totalCent = centCalculator();
    const calculatedVal = parseInt(propData.expectedPrice) / totalCent;
    if(calculatedVal) {
      return calculatedVal.toFixed(2);
    }
    return 0;
  }

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">What is your Selling Price?</span>
                <div>
                  <span className="heading-step">(Step 11 of 11)</span>
                </div>
              </div>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='Market Value'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='market_value'
                  value={propData.market_value}
                  onChange={ e => inputChange(e) }
                  type={'number'}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                  }}
                />
              </FormControl>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='Total Expected Price*'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='expectedPrice'
                  value={propData.expectedPrice}
                  onChange={ e => inputChange(e) }
                  type={'number'}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                  }}
                />
              </FormControl>
              <div className="login-form-div">
                <b>Total Area:</b> {propData.plotArea} Sq.ft || {centCalculator()} cent
              </div>
              <div className="login-form-div">
                <b>Price per unit:</b> ₹{pricePerSqftCalculator()}/Sq.ft || ₹{pricePerCentCalculator()}/cent
              </div>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPropertyStepEleven;
