import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
//Other lib imports
//Custom imports

const AddPropertyStepFive = (props) => {
  const { nextStep, propData, inputChange, stateDetails, districtDetails, cityDetails, setReusableDialogObj } = props;

  const closeReusableDialog = () => {
    setReusableDialogObj({
      open: false
    });
  }

  const onBackBtnClk = () => {
    nextStep(4);
  };

  const onNextBtnClk = () => {
    if(propData.city && propData.district && propData.state) {
      nextStep(6);
    } else {
      setReusableDialogObj({
        open: true,
        title: '',
        content: 'Please enter all mandatory inputs',
        btnArr: [
          {
            label: 'OK',
            fun: closeReusableDialog
          }
        ]
      });
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Tell me your Property’s City Name</span>
                <div>
                  <span className="heading-step">(Step 5 of 11)</span>
                </div>
              </div>
              <FormControl fullWidth className='form-control-container'>
                <InputLabel>State*</InputLabel>
                <Select
                  label='State*'
                  name='state'
                  value={propData.state}
                  onChange={ e => inputChange(e) }
                >
                  {
                    stateDetails.map((stateDetail, index) => {
                      return <MenuItem key={index} value={stateDetail.state_id}>{stateDetail.state_title}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl fullWidth className='form-control-container'>
                <InputLabel>District*</InputLabel>
                <Select
                  label='District*'
                  name='district'
                  value={propData.district}
                  onChange={ e => inputChange(e) }
                >
                  {
                    districtDetails.map((districtDetail, index) => {
                      return <MenuItem key={index} value={districtDetail.districtid}>{districtDetail.district_title}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl fullWidth className='form-control-container'>
                <InputLabel>City*</InputLabel>
                <Select
                  label='City*'
                  name='city'
                  value={propData.city}
                  onChange={ e => inputChange(e) }
                >
                  {
                    cityDetails.map((cityDetail, index) => {
                      return <MenuItem key={index} value={cityDetail.id}>{cityDetail.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='Other City Name'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='otherCity'
                  value={propData.otherCity}
                  onChange={ e => inputChange(e) }
                />
              </FormControl>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPropertyStepFive;
