import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Material UI imports
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import WestIcon from "@mui/icons-material/West";
import FormControl from "@mui/material/FormControl";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
//Other lib imports
//Custom imports

const AddPropertyStepSix = (props) => {
  const { nextStep, propData, inputChange, setReusableDialogObj } = props;

  const closeReusableDialog = () => {
    setReusableDialogObj({
      open: false
    });
  }

  const onBackBtnClk = () => {
    nextStep(5);
  };

  const onNextBtnClk = () => {
    if(propData.address) {
      if(propData.address.length > 250) {
        setReusableDialogObj({
          open: true,
          title: '',
          content: 'Address should be within 250 characters',
          btnArr: [
            {
              label: 'OK',
              fun: closeReusableDialog
            }
          ]
        });
        return;
      }
    }
    if(propData.pinCode) {
      if((/^[0-9]{6}$/).test(propData.pinCode)) {
        //Validation passed
      } else {
        setReusableDialogObj({
          open: true,
          title: '',
          content: 'PIN code is invalid',
          btnArr: [
            {
              label: 'OK',
              fun: closeReusableDialog
            }
          ]
        });
        return;
      }
    }
    nextStep(7);
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={4} md={8} xs={11}>
          <Paper
            elevation={24}
            bgcolor="white"
            style={{ borderRadius: "20px" }}
          >
            <Box padding="25px">
              <div className="heading-container">
                <span className="heading">Tell me your Property’s Full Address</span>
                <div>
                  <span className="heading-step">(Step 6 of 11)</span>
                </div>
              </div>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='Plot Address'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='address'
                  value={propData.address}
                  multiline
                  rows={4}
                  onChange={ e => inputChange(e) }
                />
              </FormControl>
              <FormControl fullWidth className='form-control-container'>
                <TextField
                  label='PIN Code'
                  InputLabelProps={{style: {fontSize: '20px'}}}
                  inputProps={{style: {fontSize: '20px'}}}
                  variant="standard"
                  name='pinCode'
                  type={'number'}
                  value={propData.pinCode}
                  onChange={ e => inputChange(e) }
                />
              </FormControl>
              <div className="action-btn-cntr">
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onBackBtnClk}
                    className="login-button"
                    startIcon={<WestIcon />}
                  >
                    Back
                  </Button>
                </div>
                <div className="login-form-div">
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={onNextBtnClk}
                    className="login-button"
                    endIcon={<EastIcon />}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPropertyStepSix;
