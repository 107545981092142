import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Custom imports
import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../snackbar";
import axios from "axios";

import { baseUrl } from "../../util/config";

const ValidatePDP = () => {
  let { state, district, promotors, projectname, mobile, hash } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      mobile &&
      mobile.length === 10 &&
      hash &&
      state &&
      district &&
      promotors &&
      projectname
    ) {
      handlePromotorMobile();
    } else {
      openSnackBar("Not valid URL", "error");
    }
  }, [mobile]);

  const handlePromotorMobile = () => {
    const payload = {
      project_hash: hash,
      mobile: mobile,
    };
    axios
      .post(`${baseUrl}/plot_api/analytics/intermediate`, payload)
      .then(
        (res) => {
          if (res.data && res.data.status) {
            let path = `/pdp/${state}/${district}/${promotors}/${projectname}/${hash}`;
            // console.log("HASHHHH&&&&&&&&", path);
            if (hash != "") {
              // Call PDP
              navigate(path, {
                state: { id: hash, isfav: false },
              });
            }
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
  };

  const { isActive, message, severity, openSnackBar, setIsActive } =
    useSnackbar();

  return (
    <>
      <Snackbar
        severity={severity}
        open={isActive}
        message={message}
        onClose={setIsActive}
      />
    </>
  );
};

export default ValidatePDP;
