import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
//Material UI imports
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
//Other lib imports
import axios from "axios";
//Custom imports
import * as CSS from "./Style";
import PropertyHeader from "../HeaderMenu/PropertyHeader";
import PropertyCard from './PropertyCard/PropertyCard';
import { baseUrl } from "../../util/config";

const Property = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  useEffect(() => {
    const user_id = localStorage.getItem("mUserId");
    const payload = { user_id };
    setIsLoading(true);
    axios.post(`${baseUrl}/plot_api/realestate_v1/property/userid`, payload).then(
      (res) => {
        setIsLoading(false);
        if(res?.data?.list) {
          if(res?.data?.list.length === 0) {
            setPropertyList(['NA']);
          } else {
            setPropertyList([...res?.data?.list]);
          }
        } else {
          setPropertyList(['NA']);
        }

      },
      (err) => {
        setIsLoading(false);
        console.log('err coming..');
        console.log(err);
      }
    );
  }, []);
  return (
    <CSS.ParentDiv isMobile={isMobile}>
      <Grid
        container
        gap="1em"
        justifyContent="center"
        alignItems="center"
        direction="column"
        margin="2em 0 2em 0;"
      >
        <PropertyHeader showAddProperty={true} />
      </Grid>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
      {
        propertyList.length && (
          <Grid item lg={10} md={10} sm={12} xs={12}>
            {
              propertyList[0] === 'NA' ? (
                <div className='no-prop'>No Properties found</div>
              ) : (
                <PropertyCard propertyList={propertyList} setIsLoading={setIsLoading} />
              )
            }
          </Grid>
        )
      }
    </CSS.ParentDiv>
  );
};

export default Property;
