import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AlertSnackbar({
  open,
  onClose,
  message,
  severity,
  anchorOrigin = { vertical: "bottom", horizontal: "right" },
}) {
  if (!severity) {
    severity = "error"; //warning , info , success ,warning
  }
  if (!message) {
    message = "We will get back to you soon!!!";
  }
  if (!anchorOrigin) {
    anchorOrigin = { vertical: "bottom", horizontal: "right" };
  }
  return (
    <div>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={4000}
        onClose={() => onClose(false)}
      >
        <div>
          <Alert onClose={() => onClose(false)} severity={severity}>
            {message}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
}
